import React from 'react'
import * as PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'
import style from './View.Style'
import KpiItem from './components/kpi_item/View'

const KpistList = props => {
  const { classes, list, onUpdateKpiSuccess, onDeleteKpi, onItemEdit, ideaId } = props
  return (
    <div className={classes.list}>
      {list.length > 0 &&
        list.map(item => {
          return (
            <div className={classes.item} key={item.id}>
              <KpiItem
                ideaId={ideaId}
                onUpdateKpiSuccess={onUpdateKpiSuccess}
                onDeleteKpi={onDeleteKpi}
                onItemEdit={onItemEdit}
                classes={classes}
                id={item.id}
                type={item.type}
                unit={item.unit}
                range={item.range}
                expected={item.expected}
                current={item.current}
                name={item.name}
                success={item.success}
              />
            </div>
          )
        })}
    </div>
  )
}

KpistList.defaultProps = {
  list: [],
}

KpistList.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape({})),
  onUpdateKpiSuccess: PropTypes.func.isRequired,
  onDeleteKpi: PropTypes.func.isRequired,
  onItemEdit: PropTypes.func.isRequired,
  ideaId: PropTypes.string.isRequired,
}

export default withStyles(style)(KpistList)
