import { HIDE_LOADER, SHOW_LOADER } from './ActionConstants'
import { REDUX_TYPES_TO_HIDE_LOADER, REDUX_TYPES_TO_SHOW_LOADER } from '../LoaderConstants'

export function checkLoaderType(type) {
  if (type === SHOW_LOADER || REDUX_TYPES_TO_SHOW_LOADER.find(it => it === type) !== undefined)
    return SHOW_LOADER
  if (type === HIDE_LOADER || REDUX_TYPES_TO_HIDE_LOADER.find(it => it === type) !== undefined)
    return HIDE_LOADER
  return undefined
}
