import { persistReducer } from 'redux-persist'
import * as types from 'actions/sync/auth/Constants'
import { GET_ACTIVE_ORGANIZATIONS_SUCCESS } from '../actions/sync/organizations/Constants'
import { GET_ACTIVE_TYPES_SUCCESS } from '../actions/sync/types/Constants'
import {
  FULL_INFO_FAILURE,
  FULL_INFO_STARTED,
  FULL_INFO_SUCCESS,
  GET_DOMAIN_LOGIN_INFO_FAILURE,
  GET_DOMAIN_LOGIN_INFO_STARTED,
  GET_DOMAIN_LOGIN_INFO_SUCCESS,
  SET_CURRENT_DOMAIN,
  CHANGE_UI
} from '../actions/sync/domain/Constants'
import {
  APPROVED_REASONS_SUCCESS,
  CONFIG_SUCCESS,
  REJECT_REASONS_SUCCESS,
  WHITELIST_DOMAIN_FAILURE,
  WHITELIST_DOMAIN_STARTED,
  WHITELIST_DOMAIN_SUCCESS,
} from '../services/TypesConstants'
import { GET_ACTIVE_GOALS_SUCCESS } from '../actions/sync/goals/Constants'
import { createPersistReducerConfig } from '../store/Persistence.Utils'

export const initialState = {
  whiteListDomain:'',
  isLoading:false,
  config: {
    defaultColors: {
      primary: '',
      secondary: '',
      headerText: '',
    },
    isOrganizationsActive: false,
    isTypesActive: false,
    organizationsName: '',
    organizationHelp: '',
    organizationTypeName: '',
    organizationTypeHelp: '',
    useHelpSection: false,
  },
  currentDomain: '',
  lastDomainVisited: '',
  dataLoaded: false,
  dataLoading: false,
  domain: {
    domainName:'',
    newIdeas: '0',
    totalInvestments: '0',
    desktopAccesses: 0,
    mobileAccesses: 0,
    iosAccesses: 0,
    androidAccesses: 0,
    implementedIdeas: 0,
    ideasVisits: 0,
    activeUsers: 0,
    publishedComments: 0,
    domainCreationDate: null,
  },
  domainConfiguration: {
    challengeProposal: false,
    logo: null,
    organizationsName: '',
    organizationHelp: '',
    organizationTypeName: '',
    organizationTypeHelp: '',
    isOrganizationsActive: false,
    isTypesActive: false,
    isPreviousValidationEnabled: false,
    isLogisticActiveOnCreate: false,
    useHelpSection: false,
    contributeCurrencySymbol: '',
    logisticCurrencyConfiguration: {},
    contributeCurrencyConfiguration: {},
    hasCurrencyIconToLeft: true,
    currencyConfiguration: {},
    currencyIcon: 'nextcoin4',
    ideaStatusStepper: {
      approvedLabel: '',
      approvedColor: '',
      discardedLabel: '',
      discardedColor: '',
      implementedLabel: '',
      implementedColor: '',
      pendingSupportLabel: '',
      pendingSupportColor: '',
      pilotActiveLabel: '',
      pilotActiveColor: '',
      roundClosedLabel: '',
      roundClosedColor: '',
      supportedLabel: '',
      supportedColor: '',
    },
    splashConfiguration: {},
    isBudgetActive: false,
    isTimeActive: false,
    isOtherActive: false,
    isContributionActive: false,
    isPrivateFlowActive: false,
    privateBannerSrc: '',
    isChallengeMandatoryForIdeaCreation: false,
    isAnonymousIdeas: false,
    isAnonymousIdeasMandatory: false,
    isWelcomeScreenEnable: false,
    isServiceLineActivated: false,
  },
  info: [],
  adminRole: true,
  goals: [],
  organizations: [],
  folderOrganizations: [],
  types: [],
  badges: [],
  nextinits: [],
  rejectedReasons: [],
  approvedReasons: [],
  infoPoints: [],
  isLoadedLoginInfo: false,
  loginInfo: {
    useCreateAccount: false,
    useCustomColor: false,
    useCustomTextColor: false,
    useCustomImg: false,
    useCustomText: false,
    useCustomBgImg: false,
    customColor: '',
    customTextColor: '',
    customImg: '',
    customText: '',
    customBgImg: '',
    accessMedia: {
      Google: true,
    },
  },
  new_ui: false,
}

const domainReducer = (state = initialState, action) => {
  switch (action.type) {
    case REJECT_REASONS_SUCCESS:
      return {
        ...state,
        rejectedReasons: action.response,
      }
    case APPROVED_REASONS_SUCCESS:
      return {
        ...state,
        approvedReasons: action.response,
      }
    case CONFIG_SUCCESS:
      return {
        ...state,
        config: action.response,
      }

    case WHITELIST_DOMAIN_STARTED:
      return{
      ...state,
        isLoading:true,
    }
    case WHITELIST_DOMAIN_SUCCESS:
      return{
        ...state,
        isLoading:false,
        whiteListDomain: action.responsem
    }
    case WHITELIST_DOMAIN_FAILURE:
      return{
        ...state,
        isLoading:false,
    }

    case FULL_INFO_STARTED:
      return {
        ...state,
        dataLoading: true,
      }
    case FULL_INFO_SUCCESS:
      return {
        ...state,
        dataLoaded: true,
        dataLoading: false,
        badges: action.response.badges,
        nextinits: action.response.nextinits,
        domain: {
          ...state.domain,
          domainName: action.response.domain.domainName,
          newIdeas: action.response.domain.newIdeas,
          totalInvestments: action.response.domain.totalInvestments,
          desktopAccesses: action.response.domain.desktopAccesses,
          mobileAccesses: action.response.domain.mobileAccesses,
          iosAccesses: action.response.domain.iosAccesses,
          androidAccesses: action.response.domain.androidAccesses,
          createdIdeas: action.response.domain.createdIdeas,
          implementedIdeas: action.response.domain.implementedIdeas,
          ideasVisits: action.response.domain.ideasVisits,
          activeUsers: action.response.domain.activeUsers,
          investmentsMade: action.response.domain.investmentsMade,
          publishedComments: action.response.domain.publishedComments,
          domainCreationDate: action.response.domain.domainCreationDate,
        },
        domainConfiguration: {
          challengeProposal: action.response.domainConfiguration.challengeProposal,
          logo: action.response.domainConfiguration.logo,
          customFooterText: action.response.domainConfiguration.customFooterText,
          organizationsName: action.response.domainConfiguration.organizationsName,
          organizationHelp: action.response.domainConfiguration.organizationHelp,
          organizationTypeName: action.response.domainConfiguration.organizationTypeName,
          organizationTypeHelp: action.response.domainConfiguration.organizationTypeHelp,
          isOrganizationsActive: action.response.domainConfiguration.isOrganizationsActive,
          isTypesActive: action.response.domainConfiguration.isTypesActive,
          useHelpSection: action.response.domainConfiguration.useHelpSection,
          isPreviousValidationEnabled:
            action.response.domainConfiguration.previousValidationEnabled,
          logisticCurrencyConfiguration:
            action.response.domainConfiguration.logisticCurrencyConfiguration,
          contributeCurrencyConfiguration:
            action.response.domainConfiguration.contributeCurrencyConfiguration,
          logistic: action.response.domainConfiguration.logistic,
          isLogisticActiveOnCreate: action.response.domainConfiguration.isLogisticActiveOnCreate,
          logisticCurrencySymbol: action.response.domainConfiguration.logisticCurrencySymbol,
          contributeCurrencySymbol: action.response.domainConfiguration.contributeCurrencySymbol,
          hasCurrencyIconToLeft: action.response.domainConfiguration.hasCurrencyIconToLeft,
          currencyConfiguration: action.response.domainConfiguration.currencyConfiguration,
          currencyIcon: action.response.domainConfiguration.currencyIcon,
          isBudgetActive: action.response.domainConfiguration.isBudgetActive,
          isTimeActive: action.response.domainConfiguration.isTimeActive,
          isOtherActive: action.response.domainConfiguration.isOtherActive,
          isContributionActive: action.response.domainConfiguration.isContributionActive,
          ideaStatusStepper: action.response.domainConfiguration.ideaStatusStepper,
          splashConfiguration: action.response.domainConfiguration.splashConfiguration,
          language: action.response.domainConfiguration.lang,
          isPrivateFlowActive: action.response.domainConfiguration.isPrivateFlowActive,
          privateBannerSrc: action.response.domainConfiguration.privateBannerSrc,
          isChallengeMandatoryForIdeaCreation:
            action.response.domainConfiguration.isChallengeMandatoryForIdeaCreation,
          isAnonymousIdeas: action.response.domainConfiguration.isAnonymousIdeas,
          isAnonymousIdeasMandatory: action.response.domainConfiguration.isAnonymousIdeasMandatory,
          isWelcomeScreenEnable: action.response.domainConfiguration.isWelcomeScreenEnable,
          isServiceLineActivated: action.response.domainConfiguration.isServiceLineActivated,
        },
      }
    case FULL_INFO_FAILURE:
      return {
        ...state,
        dataLoading: false,
      }
    case types.POST_GOOGLE_LOGIN_SUCCESS:
    case types.PASSWORD_LOGIN_SUCCESS:
    case types.SSO_PASSWORD_LOGIN_SUCCESS:
      return {
        ...state,
        info: action.response.domainInfo,
      }
    case SET_CURRENT_DOMAIN:
      return {
        ...state,
        currentDomain: action.domain,
      }
    case GET_ACTIVE_ORGANIZATIONS_SUCCESS:
      return {
        ...state,
        organizations: action.response.organizations,
        folderOrganizations: action.response.folderOrganizations,
      }
    case GET_ACTIVE_TYPES_SUCCESS:
      return {
        ...state,
        types: action.response.types,
      }
    case GET_ACTIVE_GOALS_SUCCESS:
      return {
        ...state,
        goals: action.response.goals,
      }
    case GET_DOMAIN_LOGIN_INFO_SUCCESS:
      return {
        ...state,
        loginInfo: action.response,
        isLoadedLoginInfo: true,
      }
    case GET_DOMAIN_LOGIN_INFO_STARTED:
      return {
        ...state,
        isLoadedLoginInfo: false,
      }
    case GET_DOMAIN_LOGIN_INFO_FAILURE:
      return {
        ...state,
        isLoadedLoginInfo: true,
      }
    case types.SET_LOGGEDOUT:
      return {
        ...initialState,
        // lastDomainVisited: state.currentDomain,
      }
    case CHANGE_UI:
      return {
        ...state,
        new_ui: !state.new_ui
      }
    default:
      return state
  }
}

const persistDomainReducerConfig = createPersistReducerConfig('RootDomain', ['currentDomain'])

const persistDomainReducer = persistReducer(persistDomainReducerConfig, domainReducer)

export default persistDomainReducer
