/* eslint-disable react/prefer-stateless-function */
import React, { Fragment,useEffect, useState } from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import styles from './View.Style'
import { ClickAwayListener, Container } from '@material-ui/core';
import Challenge_card from 'components_newui/challenge_card/View'
import TeamMember from './components/team_member/View'
import joinTeam from './imgs/joinTeam.png';
import remove_icon from 'img/close-system.png'
import clock from './imgs/clock.png';
import CommentsSection from './components/comments_section/View'
import share from './imgs/share.svg';
import reportIcon from './imgs/report.svg';
import ExpandingDetails from './components/expanding_details/View'
import Divider from '@material-ui/core/Divider'
import SummaryCard from './components/summary_card/View'
import Carousel from 'react-material-ui-carousel'
import Breadcrumbs from 'components_newui/pageBreadcrumbsMenu/View'
import { getStyledStatus } from 'services/GetStatusStyle'
import { Button } from '@material-ui/core';
import InvestorsModal from './components/investors_modal/View'
import AcceptRejectSection from './components/accept_reject_section/View'
import i18n from 'support/i18n'
import CustomButton from './components/button/View'
import status_constants from 'common_constants/idea_states_constants'
import InvestSection from './components/invest_section/View'
import StatusSection from './components/status_section/View'
import Doc from './components/document/View'
import JoinTeamDialog from './components/dialogs/join_team/View'
import SendJoinSection from './components/send_join_section/View'
import AdminFeedback from './components/admin_feedback_evaluation/View'
import TransferLeader from './components/transfer_leader/View'
import FileUploader from 'components_newui/new_ui_file_uploader/View'
import SingleCarousel from 'components_newui/SingleCarousel/View'
import InvestmentSection from './components/investment_section/View'
import KpiSection from './components/kpi_section/View'
import ContributionSection from './components/contribution_section/View'

const NewIdeaDetail = props => {
    const {
        classes,
        idea,
        feedbackFromNova,
        boardDecision,
        pitchDecision,
        userEmail,
        investInIdea,
        onReportClick,
        investmentDetailsText,
        investmentIdea,
        localInnovator,
        localAdmin,
        isAdmin,
        isDraft,
        isInnovator,
        isOwner,
        isPromoter,
        sameOrigin,
        onValidatePendingIdeaClick,
        onRejectPendingIdeaClick,
        onFeedbackClick,
        postAcceptJoinIdeaTeam,
        postRejectJoinIdeaTeam,
        postAcceptJoinInnovationTeam,
        postRejectJoinInnovationTeam,
        navigateToExplore,
        navigateToAllIdeas,
        onDeleteClick,
        onEditClick,
        approvedReasons,
        onApproveClick,
        onDiscardClick,
        rejectedReasons,
        currencyIcon,
        userBalance,
        isContributionActive,
        isTimeActive,
        isBudgetActive,
        isOtherActive,
      getContributions,
      setContributionTime,
      setContributionBudget,
      setContributionOther,
      contributeCurrencySymbol,
      ownContribution,
      contributors,
        onShareIdea,
        ideaStatusStepper,
        onRequestJoinIdeaTeam,
        navigateToChallengeDetail,
        onPostComment,
        onDeleteComment,
        onVoteComment,
        onEditComment,
        navigateToUserProfile,
        onSearchSuggestions,
        suggestions,
        onResetSuggestionList,
        kpis,
        onUpdateKpiAction,
        onUpdateKpiSuccess,
        onDeleteKpi,
        onSubmitKpiForm,
        isFormInUse,
        canSendIdeaTeamInvitations,
        canSendPilotProjectTeamInvitations,
        isAnonymous,
        pilotTeam,
        pendingInvitationsPilotTeamUsers,
        pendingInvitationsUsers,
        promoterEmail,
        isSearchingForUser,
        onSendInvitationClick,
        onPendingUserRemoveClick,
        isAlreadyInvited,
        ownerEmail,
        csrfToken,
        onActivatePilot,
        onImplementPilot,
        onTransferLeader,
        currentStatus,
        match,
    } = props
    const {
        title,
        challenge,
        createdDate,
        description,
        documents,
        endDate,
        goal,
        goals,
        hasLoaded,
        images,
        investors,
        totalInvestors,
        videos,
        postingCommentStatus,
        isLoading,
        minInvestment,
        maxInvestment,
        owner,
        submitter,
        organization,
        pledged,
        status,
        type,
        team,
        comments,
        userInvestmentsMap,
        isPendingValidation,
        investment,
        showPendingTeamInvitationSection,
        showPendingInnovationTeamInvitationSection,
        isLoadingPendingIdea,
        isProcessingTeamInvitation,
        isProcessingInnovationTeamInvitation,
        isGlobal,
        isInvestmentInProcess,
        statusMessageText,
        statusMessageTitle,
        totalComments,
        
    } = idea

    const idea_id =  match.params.id
    const [showInvestors, setShowInvestors] = useState(false)
    const [carousel_content, setCarouselContent] = useState([])
    const [styledStatus, setStyledStatus] = useState('')
    const [statusColor, setStatusColor] = useState('')
    const [statusImg, setStatusImg] = useState('')
    const [daysLeft, setDaysLeft] = useState(0)

    const [showTransferLeader,setShowTransferLeader] = useState(false)
    const [selectedTransferUser,setSelectedTransferUser] = useState({})
    const [showJoinTeamDialog, setShowTeamJoin] = useState(false)
    const [showmoreHeaderText, setShowmoreHeaderText] = useState("")

    useEffect(() => {
        setDaysLeft(Math.ceil(Math.abs(endDate - new Date()) / (1000 * 60 * 60 * 24)));

        const { name, image, color } = getStyledStatus(status)
        setStyledStatus(name)
        setStatusColor(color)
        setStatusImg(image)

        const content = images.map((img,i) => {
            // console.log(img)
            return (
                <img key={'img' + i} src={img.src} className={classes.ideaPic} alt="ideapic" />
            )
        })
        setCarouselContent(content)
    }, [idea])



    useEffect(() => {
        const { getIdea, getInvestors, getIsUserInvitedToInnovationIdeaTeam,
            getIsUserInvitedToIdeaTeam, getIdeaTeam, getComments,
            getRejectedReasons, getApprovedReasons, getIsTeamRequestActive
        } = props
        getIsUserInvitedToInnovationIdeaTeam(idea_id)
        getIsUserInvitedToIdeaTeam(idea_id)
        getIdea(idea_id)
        getInvestors(idea_id)
        getIdeaTeam(idea_id)
        getComments(idea_id)
        getRejectedReasons()
        getApprovedReasons()
        getIsTeamRequestActive(idea_id)

    }, [idea_id])

    useEffect(() => {
        filter_description()
    }, [description])

    const pageHierarchy = [
        { string: "Home", navigate: navigateToExplore },
        { string: "Ideas", navigate: navigateToAllIdeas },
        { string: "Idea details" }
    ]

    const filter_description = () => {
        let showmoreheadervalue, showmoresubheadervalue = ""
        let regex = /(<br \/>)/g;
        let header = description.split(regex)
        if (header[0] != 'undefined') {
            showmoreheadervalue = header[0] + header[1] + header[2] + header[3] + header[4] + header[5] + header[6] + header[7] + header[8] + header[9]
            setShowmoreHeaderText(showmoreheadervalue)
        }
    }


    const FileUploaderFn = ()=>{
      const {onDocumentUpload,isDocumentLoading} = props
      const handleDocumentUpload = (file) =>{

          onDocumentUpload(idea_id,file)
      }
      const handleDeleteDocument = (documentId) =>{
          const { onDocumentDelete } = props
          const id = match && match.params && match.params.id ? match.params.id : undefined
          onDocumentDelete(id, documentId)
      }
          return(
              <FileUploader
              dropText="Browse file"
              isDocumentLoading={isDocumentLoading}
              onFileSelect={handleDocumentUpload}
              documentfiles={documents}
              handleDeleteDocument={handleDeleteDocument}
              csrfToken={csrfToken}
              canDelete={isAdmin || isInnovator || isOwner || localAdmin || localInnovator}
          />
          )
      }

    const handleSendInvitation = (email) => {
        onSendInvitationClick(email, idea_id)
    }
    const handleDeleteUserFromTeamInitiative =(email) =>{
        onPendingUserRemoveClick(idea_id, email, true)
    }

  const handleDeleteUserFromTeam = (email) =>{
    onPendingUserRemoveClick(idea_id, email)
  }

  const handlePostComment = (comment, parentId) =>{
    // const { onPostComment } = this.props
    onPostComment(comment, parentId, idea_id)
  }

  const handleDeleteComment =(commentId) =>{
    // const { onDeleteComment } = this.props
    onDeleteComment(commentId, idea_id)
  }

  const handleVoteComment =(commentId)=> {
    // const { onVoteComment } = this.props
    onVoteComment(commentId, idea_id)
  }

   const handleEditComment =(comment, commentId) =>{
    // const { onEditComment } = this.props
    onEditComment(comment, commentId, idea_id)
  }

  const onDraftPublisIdea = (investmentAmount ) =>{
    const {publishIdea } = props
    publishIdea(idea_id)
  }

const  handleTransferLeader = () => {

    onTransferLeader(idea_id, selectedTransferUser.email)
    setShowTransferLeader(false)
    setSelectedTransferUser({})
    // this.setState({ showTransferLeader: false, selectedTransferUser: {} })
  }

 const handleSelectSuggestion = (user) => {
    console.log("user on search",user)
    setShowTransferLeader(false)
    setSelectedTransferUser(user)
    // this.setState({ showTransferLeader: false, selectedTransferUser: user })
  }

const handleSaveContribution = ()=> {
    const { onSaveContribution, ownContribution } = props
    onSaveContribution(idea_id, ownContribution.userId)
  }

const  handleDeleteContribution = () => {
    const { onDeleteContribution } = props
    onDeleteContribution(idea_id)
  }

  const handleDiscardReason = () => {
    const { onDiscardClick, rejectedReasons } = props
    onDiscardClick(idea_id, rejectedReasons)
  }
 const isPilotActive = status === status_constants.PILOT_ACTIVE

 const submitNameNotAvailable = submitter.fullName === '' ? submitter && submitter.email.substring(0, submitter.email.lastIndexOf("@")) : submitter.fullName  
// console.log("dsdsdsdonClickUserProfile", submitNameNotAvailable)

    return (
      <Fragment>
          <Breadcrumbs crumbs={pageHierarchy} />
        <Container>


          <div className={classes.innerWidth} style={{ paddingBottom: '32px' }}>
            <figure className={classes.status} style={{ backgroundColor: statusColor }}>
              <img src={statusImg} alt={`${status} icon`} style={{ width: '20px' }} />
              <figcaption style={{ paddingRight: '8px' }}>{styledStatus}</figcaption>
            </figure>

            {/* {!isDraft && (
              <StatusSection
                ideaStatusStepper={ideaStatusStepper}
                currentStatus={status}
                messageText={statusMessageText}
                messageTitle={statusMessageTitle}
                isPendingValidation={isPendingValidation}
              />
            )} */}

            {!isDraft && (boardDecision || pitchDecision || feedbackFromNova) && (
              <AdminFeedback
                onCheckText={rejectedReasons}
                messageText={feedbackFromNova}
                boardDecision={boardDecision}
                pitchDecision={pitchDecision}
              />
            )}

            <div className={classes.adminBox}>
              {(isAdmin ||
                isInnovator ||
                (localAdmin && isGlobal && sameOrigin) ||
                (localInnovator && isGlobal && sameOrigin) ||
                (isOwner && isPendingValidation) ||
                (status === status_constants.DRAFT && isOwner)) && (
                <div className={classes.adminBox1}>
                  <CustomButton
                    text={i18n.t('IDEA.EDIT')}
                    action={() => onEditClick(idea_id)}
                    btnDisable={
                      styledStatus && styledStatus.toUpperCase() === status_constants.DISCARDED
                    }
                  />
                  <CustomButton
                    text={i18n.t('DELETE_IDEA')}
                    action={() => onDeleteClick(idea_id)}
                    bgColor="#E60000"
                  />
                </div>
              )}
              {currentStatus !== status_constants.DISCARDED &&
                currentStatus !== status_constants.DRAFT &&
                (isAdmin ||
                  (localInnovator && sameOrigin) ||
                  (localAdmin && sameOrigin) ||
                  localInnovator) && (
                  <div>
                    <CustomButton text={i18n.t('IDEA.DISCARD')} action={handleDiscardReason} />
                  </div>
                )}

              {status === status_constants.DRAFT &&
                (isAdmin ||
                  isInnovator ||
                  (localInnovator && sameOrigin) ||
                  (localAdmin && sameOrigin) ||
                  isOwner) && (
                  <div className={classes.adminBox2}>
                    <CustomButton
                      text={i18n.t('EXPLORE_IDEA.PUBLISH_IDEA')}
                      action={onDraftPublisIdea}
                      btnDisable={isLoading}
                    />
                  </div>
                )}

              {(status === status_constants.PENDING_SUPPORT ||
                status === status_constants.SEEKING_FUNDING ||
                status === status_constants.ROUND_CLOSED ||
                status === status_constants.DISCARDED) &&
                ((localAdmin && isGlobal && sameOrigin) ||
                  (localInnovator && isGlobal && sameOrigin) ||
                  isAdmin ||
                  localInnovator) && (
                  <div className={classes.adminBox2}>
                    <CustomButton
                      text={i18n.t('EXPLORE_IDEA.APPROVE')}
                      action={() => onApproveClick(idea_id, approvedReasons)}
                    />
                  </div>
                )}
              {currentStatus === status_constants.APPROVED_BY_IT &&
                (isAdmin || isInnovator || localAdmin || localInnovator) && (
                  <div className={classes.adminBox2}>
                    <CustomButton
                      text={i18n.t('EXPLORE_IDEA.ACTIVATE_PILOT')}
                      action={() => onActivatePilot(idea_id)}
                    />
                  </div>
                )}
              {currentStatus === status_constants.PILOT_ACTIVE &&
                (isAdmin || isInnovator || localAdmin || localInnovator) && (
                  <div className={classes.adminBox2}>
                    <CustomButton
                      text={i18n.t('EXPLORE_IDEA.MARK_IMPLEMENTED')}
                      action={() => onImplementPilot(idea_id)}
                    />
                  </div>
                )}
              {(isAdmin || isInnovator || localAdmin || localInnovator) &&
                (currentStatus === status_constants.PILOT_ACTIVE ||
                  currentStatus === status_constants.IMPLEMENTED ||
                  currentStatus === status_constants.APPROVED_BY_IT) && (
                  <div className={classes.adminBox2}>
                    <CustomButton
                      text={i18n.t('EXPLORE_IDEA.TRANSFER')}
                      action={() => setShowTransferLeader(prev => !prev)}
                    />
                  </div>
                )}
            </div>
            <TransferLeader
              showTransferLeader={showTransferLeader}
              suggestions={suggestions}
              onSearchSuggestions={onSearchSuggestions}
              onSelectSuggestion={handleSelectSuggestion}
              onTransferLeader={handleTransferLeader}
              selectedUser={selectedTransferUser}
              onRemoveUser={() => setSelectedTransferUser()}
              isSearchingForUser={isSearchingForUser}
            />

            {isPendingValidation && (
              <AcceptRejectSection
                classes={{ root: classes.pendingValidationContainer }}
                message={i18n.t('IDEA_STATUS.PENDING_VALIDATION')}
                isLoading={isLoadingPendingIdea}
                canAcceptOrDecline={
                  isInnovator || isAdmin || ((localAdmin || localInnovator) && sameOrigin)
                }
                acceptButtonLabel={i18n.t('IDEA_STATUS.VALIDATE')}
                declineButtonLabel={i18n.t('IDEA_STATUS.REJECT')}
                feedbackButtonLabel={i18n.t('IDEA_STATUS.FEEDBACK')}
                onAcceptClick={() => onValidatePendingIdeaClick(idea_id)}
                onDeclineClick={() => onRejectPendingIdeaClick(idea_id)}
                onFeedbackClick={() => onFeedbackClick(idea_id)}
              />
            )}
            {showPendingTeamInvitationSection && (
              <AcceptRejectSection
                message={i18n.t('IDEA_TEAM.WANT_JOIN')}
                isLoading={isProcessingTeamInvitation}
                onAcceptClick={() => postAcceptJoinIdeaTeam(idea_id)}
                onDeclineClick={() => postRejectJoinIdeaTeam(idea_id)}
              />
            )}
            {showPendingInnovationTeamInvitationSection && (
              <AcceptRejectSection
                message={i18n.t('IDEA_INNOVATION_TEAM.WANT_JOIN')}
                isLoading={isProcessingInnovationTeamInvitation}
                onAcceptClick={() => postAcceptJoinInnovationTeam(idea_id)}
                onDeclineClick={() => postRejectJoinInnovationTeam(idea_id)}
              />
            )}

            {statusMessageText && (
              <div className={classes.infocard}>
                <p>{statusMessageTitle}</p>
                <span dangerouslySetInnerHTML={{ __html: statusMessageText }} />
              </div>
            )}

            <div className={classes.titularPartIdea}>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                {(status === status_constants.PENDING_SUPPORT ||
                  status === status_constants.SEEKING_FUNDING) && (
                  <div className={classes.leftToInvest}>
                    <img src={clock} style={{ marginRight: '4px', height: '28px' }} />

                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        fontSize: '16px',
                        marginLeft: '10px',
                      }}
                    >
                      <span className={classes.boldFont}>{`${daysLeft} days`}</span>
                      &nbsp;left to invest
                    </div>
                  </div>
                )}
                <Button onClick={() => onShareIdea(idea_id)} className={classes.share}>
                  <img src={share} />
                </Button>
              </div>
              <p className={classes.heading}>
                {title} {/*<img src={greenTick}/>*/}
              </p>
            </div>

            <div className={classes.uploadedPicsAndVids}>
              {/* <Carousel
                className={classes.ideaCarousel}
                indicatorIconButtonProps={{
                  style: {
                    color: '#CCCCCC',
                  },
                }}
                activeIndicatorIconButtonProps={{
                  style: {
                    color: '#8512FF',
                  },
                }}
                navButtonsProps={{
                  style: {
                    marginLeft: '15px',
                    marginRight: '15px',
                  },
                }}
              >
                {carousel_content}
              </Carousel> */}

              <div  className={classes.ideaCarousel} >
                <SingleCarousel>
                    {idea.images.map(i => <img key={i.src} src={i.src} className={classes.images} alt='thumbnail' />)}
                </SingleCarousel>
              </div>
              
              <div className={classes.summaryHolder}>
                {(status === status_constants.PENDING_SUPPORT ||
                  status === status_constants.SEEKING_FUNDING) && (
                  <div className={classes.titleBar}>
                    <InvestSection
                      minInvestment={minInvestment}
                      maxInvestment={maxInvestment}
                      currencyIcon={currencyIcon}
                      userBalance={userBalance}
                      onInvestButtonClick={amount => investInIdea(idea_id, amount)}
                      isInvestmentInProcess={isInvestmentInProcess}
                    />
                  </div>
                )}
                <SummaryCard
                  investors={investors}
                  showInvestors={() => setShowInvestors(true)}
                  totalInvestors={totalInvestors}
                  pledged={pledged}
                  goal={goal}
                  userEmail={userEmail}
                  userInvestmentsMap={userInvestmentsMap}
                  submitterName={submitNameNotAvailable}
                  submitterEmail={submitter.email}
                  createdDate={createdDate}
                  onClickUserProfile={navigateToUserProfile}

                  
                />
              </div>
            </div>

            {!isDraft && (
              <StatusSection
                isPendingValidation={isPendingValidation}
                currentStatus={status}
                ideaStatusStepper={ideaStatusStepper}
                statusMessageText={statusMessageText}
                statusMessageTitle={statusMessageTitle}
              />
            )}

            <span className={classes.ideaDescription}>
              <span className={classes.heading}>Idea description</span>
              <div className={classes.reportIdea} onClick={() => onReportClick(idea_id)}>
                <img style={{ paddingRight: '8px' }} src={reportIcon} />
                Report this idea
              </div>
            </span>

            <ExpandingDetails
              text={description}
              headertext={showmoreHeaderText}
              isExapndable={false}
            />

            {videos.externalVideo && (
              <div className={classes.ideaDescriptionVideo}>
                {' '}
                <p className={classes.heading}>Video </p>{' '}
                <iframe
                  className={classes.iframe}
                  title="ytVideo"
                  width="540"
                  height="320"
                  src={videos.externalVideo}
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />{' '}
              </div>
            )}

          {(isAdmin || isInnovator || isOwner || localAdmin || localInnovator) && (
            <>
                <Divider className={classes.divider} />
                <p className={classes.boldFont}>Uploaded files</p>
                  <FileUploaderFn />
               </> )}
            {/* {documents.length > 0 && (
              <>
                <Divider className={classes.divider} />
                <p className={classes.boldFont}>Uploaded files</p>

                

                {documents.map((doc) => {
                  return (
                    <>
                      <Doc key={doc.key} doc={doc} csrfToken={csrfToken} />
                    </>
                  )
                })}
              </>
            )} */}

            {Object.keys(goals).length > 0 && (
              <>
                <Divider className={classes.divider} />
                <div className={classes.heading}>Strategic priorities</div>
                <ul className={classes.strategicBullets}>
                  {Object.keys(goals).map((key, i) => {
                    return (
                      <li key={goals[key].name} className={classes.strategicBullet}>
                        {goals[key].name}
                      </li>
                    )
                  })}
                </ul>
              </>
            )}

            {challenge && challenge.id !== '' && (
              <>
                <Divider className={classes.divider} />
                <div className={classes.heading}>Tagged challenge</div>
                <Challenge_card
                  challenge={challenge}
                  ideaDetailChallenge={true}
                  tagging={false}
                  tagged={false}
                  urlID={challenge.id}
                  // onClick={(e, chal, tagged) => {
                  //   navigateToChallengeDetail(challenge.id)
                  // }}
                />
              </>
            )}
          </div>

          {/* {isContributionActive &&
              userEmail &&
              !isDraft &&
              (isTimeActive || isBudgetActive || isOtherActive) && (
                <ContributionSection
                  userEmail={userEmail}
                  contributors={contributors}
                  ownContribution={ownContribution}
                  isTimeActive={isTimeActive}
                  isBudgetActive={isBudgetActive}
                  isOtherActive={isOtherActive}
                  currencySymbol={contributeCurrencySymbol}
                  getContributions={getContributions}
                  onTimeChange={setContributionTime}
                  onBudgetChange={setContributionBudget}
                  onOtherChange={setContributionOther}
                  onSaveContribution={handleSaveContribution}
                  onDeleteContribution={handleDeleteContribution}
                />
              )} */}

          {/* {isPilotActive && (isAdmin || isInnovator || localAdmin || localInnovator) && (
              <KpiSection
                ideaId={idea_id}
                total={kpis ? kpis.length : 0}
                list={kpis}
                onUpdateKpiAction={onUpdateKpiAction}
                onUpdateKpiSuccess={onUpdateKpiSuccess}
                onDeleteKpi={onDeleteKpi}
                onSubmit={onSubmitKpiForm}
                isFormInUse={isFormInUse}
              />
            )} */}

          {/* {(investmentIdea.seedInvestors.length > 0 ||
              investmentIdea.firstRoundInvestors.length > 0 ||
              investmentIdea.secondRoundInvestors.length > 0 ||
              investmentIdea.thirdRoundInvestors.length > 0) && (
              <InvestmentSection investment={investmentIdea} onInvestorClick={navigateToUserProfile} />
            )} */}

          <div
            className={classes.innerWidth}
            style={{
              // backgroundColor: '#FFFFFF',
              paddingTop: '48px',
              paddingBottom: '48px',
            }}
          >
            {canSendPilotProjectTeamInvitations && (
              <SendJoinSection
                isAnonymous={isAnonymous}
                sectionTitle={i18n.t('IDEA.INITIATIVE_TEAM')}
                canAddMembers={isAdmin || isInnovator || isPromoter || localAdmin || localInnovator}
                inputLabel={i18n.t('IDEA.INVITE_MEMBER_IT')}
                team={pilotTeam}
                inputHelpText={i18n.t('IDEA.INVITE_PROMOTERS')}
                suggestions={suggestions}
                pendingInvitationsUsers={pendingInvitationsPilotTeamUsers}
                highlightedUserEmail={promoterEmail}
                onSendInvitationClick={handleSendInvitation}
                onSearchSuggestions={onSearchSuggestions}
                onPendingUserClick={navigateToUserProfile}
                onTeamUserClick={navigateToUserProfile}
                onPendingUserRemoveClick={handleDeleteUserFromTeamInitiative}
                isSearchingForUser={isSearchingForUser}
                onRequestJoinIdeaTeam={onRequestJoinIdeaTeam}
                canJoinTeam={false}
              />
            )}

            {!isPendingValidation && (
              <Fragment>
                <SendJoinSection
                  isAnonymous={isAnonymous}
                  sectionTitle={i18n.t('IDEA.THE_TEAM')}
                  canAddMembers={isOwner && canSendIdeaTeamInvitations}
                  inputLabel={`${i18n.t('IDEA.INVITE_MEMBER_JOIN')} ${title}`}
                  team={team}
                  suggestions={suggestions}
                  pendingInvitationsUsers={pendingInvitationsUsers}
                  highlightedUserEmail={ownerEmail}
                  canJoinTeam={!isOwner}
                  isSearchingForUser={isSearchingForUser}
                  onSendInvitationClick={handleSendInvitation}
                  onSearchSuggestions={onSearchSuggestions}
                  onPendingUserClick={navigateToUserProfile}
                  onTeamUserClick={navigateToUserProfile}
                  onPendingUserRemoveClick={handleDeleteUserFromTeam}
                  onRequestJoinIdeaTeam={onRequestJoinIdeaTeam}
                  ideaId={idea_id}
                  isAlreadyInvited={isAlreadyInvited}
                />
                <CommentsSection
                  comments={comments}
                  totalComments={totalComments}
                  onPostComment={handlePostComment}
                  postingCommentStatus={postingCommentStatus}
                  onDeleteComment={handleDeleteComment}
                  onVoteComment={handleVoteComment}
                  onEditComment={handleEditComment}
                  onUserClick={navigateToUserProfile}
                  userInvestmentsMap={userInvestmentsMap}
                  currencyIcon={currencyIcon}
                  onShareIdea={() => onShareIdea(idea_id)}
                  isAdminOrInnovator={isAdmin || isInnovator || localAdmin || localInnovator}
                  userEmail={userEmail}
                  searchSuggestions={onSearchSuggestions}
                  suggestions={suggestions}
                  resetUserSuggestions={onResetSuggestionList}
                />
              </Fragment>
            )}
          </div>

          {/* <div className={classes.innerWidth}>
                <div className={classes.heading}>Comments ({totalComments})</div>

                <CommentsSection
                    dividerclass={classes.divider}
                    comments={comments}
                    onPostComment={(comment, parentId) => onPostComment(comment, parentId, idea_id)}
                    postingCommentStatus={postingCommentStatus}
                    onDeleteComment={commentId => onDeleteComment(commentId, idea_id)}
                    onVoteComment={commentId => onVoteComment(commentId, idea_id)}
                    onEditComment={(comment, commentId) => onEditComment(comment, commentId, idea_id)}
                    onUserClick={navigateToUserProfile}
                    userInvestmentsMap={userInvestmentsMap}
                    onShareIdea={() => onShareIdea(idea_id)}
                    isAdminOrInnovator={isAdmin || isInnovator || localAdmin || localInnovator}
                    userEmail={userEmail}
                    searchSuggestions={onSearchSuggestions}
                    suggestions={suggestions}
                    resetUserSuggestions={onResetSuggestionList}
                />
            </div> */}

          <InvestorsModal
            showInvestors={showInvestors}
            classes={classes}
            setShowInvestors={setShowInvestors}
            remove_icon={remove_icon}
            totalInvestors={totalInvestors}
            investmentDetailsText={investmentDetailsText}
            investment={investment}
          />
        </Container>
      </Fragment>
    )
}

export default withStyles(styles)(NewIdeaDetail)
