const styles = theme => ({
  root: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  header: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
    paddingBottom: theme.spacing(2),
    '& > p': {
      flex: 1,
    },
    '& > button': {
      fontSize: '1.5rem',
    },
  },
  form: {
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
    '& > div >div:first-child': {
      display: 'flex',
      flexWrap: 'wrap',
      '& > div': {
        flex: 1,
      },
      '& > div:first-child': {
        marginRight: theme.spacing(2),
      },
    },
    '& > div:last-child': {
      marginLeft: theme.spacing(2),
    },
  },
  helpText: {
    fontSize: '0.75rem',
  },
  buttonsWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& > button': {
      margin: theme.spacing(2, 0, 0, 2),
    },
  },
})

export default styles
