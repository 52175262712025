import { ServiceCall } from 'support/network/ServiceCallAction'
import * as types from 'actions/sync/nova_research/Constants'
import { transformDocumentUploadResponse } from './ResponseUtils'
import { prepareHeadersFromState } from '../HeadersUtils'

const GET_DOCUMENT_URL = 's/idea/admin/novaresearch/documents'

// export function getNovaResearchDocument(onFailure) {
//   const apiCall = new ServiceCall()
//   apiCall.startedReqType = GET_NOVA_RESEARCH_DOCUMENT_STARTED
//   apiCall.successReqType = GET_NOVA_RESEARCH_DOCUMENT_SUCCESS
//   apiCall.failureReqType = GET_NOVA_RESEARCH_DOCUMENT_FAILURE
//   apiCall.setEndpointFromState = () => GET_DOCUMENT_URL
//   apiCall.method = 'GET'
//   apiCall.getHeadersFromState = prepareHeadersFromState
//   apiCall.transformResponseDataWithState = transformDocumentUploadResponse
//   apiCall.onFailure = onFailure
//   apiCall.auth = true
//   return apiCall.getAction()
// }

export function getNovaResearchDocument(onFailure) {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = types.GET_NOVA_RESEARCH_DOCUMENT_STARTED
  apiCall.successReqType = types.GET_NOVA_RESEARCH_DOCUMENT_SUCCESS
  apiCall.failureReqType = types.GET_NOVA_RESEARCH_DOCUMENT_FAILURE
  apiCall.setEndpointFromState = () => GET_DOCUMENT_URL
  apiCall.method = 'GET'
  apiCall.auth = true
  apiCall.onFailure = onFailure
  // apiCall.transformResponseDataWithState = (_, state) =>
  //   transformDeleteDocument(documentId, state.data.createIdea.documents)
  apiCall.transformResponseDataWithState = transformDocumentUploadResponse
  return apiCall.getAction()
}
