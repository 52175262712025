

const styles = theme => ({
    caseStudySection:{
        padding:'0px 0px 24px'
    },
    content:{
        width:'100%'
    },
    blockContent:{
        padding:'2rem 0',
       
        '@media (min-width:900px)':{
            display:'grid',
            gridGap:'20px',
            gridTemplateColumns:'1.6fr 1fr',
        } 

    },
    contentTitle:{
        fontSize:theme.fontSize.fontsize32,
        fontFamily:theme.fontFamily.fontFamilyRegular,
        fontWeight:400,
        lineHeight:1,
        color:theme.palette.Text.TextBlack33,
        margin:'0px  0px 16px 0px'
    },
    contentDesc:{
        fontSize:theme.fontSize.fontsize16,
        fontFamily:theme.fontFamily.fontFamilyRegular,
        fontWeight:400,
        lineHeight:1.5,
        color:theme.palette.Text.TextBlack33
    },
    imgblock:{
        maxWidth:'300px',
        marginLeft:'auto'
    },
    img:{
        width:'100%',
        display:'block'
    },
    wrpCard:{
        
        '@media (min-width:900px)':{
            display:'grid',
            gridGap:'20px',
            gridTemplateColumns:'1fr 1fr',
        }  
    },
    caseCardCount:{
        fontSize:theme.fontSize.fontsize16,
        fontFamily:theme.fontFamily.fontFamilyRegular,
        fontWeight:400,
        lineHeight:1.5,
        color:'#6D45FF',
        margin:'0 0 1rem'
    },
    caseCardLoadBlock:{
        textAlign:'center',
        margin:'2rem 0',
    }
    


})

 export default styles 