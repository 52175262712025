import React from 'react'
import * as PropTypes from 'prop-types'

const LoaderView = props => {
  const { children, show, loader } = props
  return (
    <div style={{ flex: 1 }}>
      {show && loader}
      <div style={{ flex: 1, backgroundColor: 'transparent' }}>{children}</div>
    </div>
  )
}

LoaderView.propTypes = {
  show: PropTypes.bool.isRequired,
  loader: PropTypes.element.isRequired,
  children: PropTypes.node.isRequired,
}

export default LoaderView
