const styles = () => ({
  root: {
    position: 'relative',
    backgroundColor: '#FFFFFF'
  },
  suggestionList: {
    position: 'absolute',
  },
})

export default styles
