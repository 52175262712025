import React from 'react'
import * as PropTypes from 'prop-types'
import { withStyles, TextField } from '@material-ui/core'
import i18n from 'support/i18n'
import style from './View.Style'
import SelectedUserList from '../emails_search/components/selected_users_list/View'

const EmailFormToUsers = props => {
  const { classes, users, onTellYourContactTextChange, hasError, emailTextLabel } = props
  return (
    <div className={classes.root}>
      <span className={classes.selectedUserText}>
        {i18n.t('PEOPLE_SELECTOR.SELECTED_CONTACTS')}
      </span>
      <SelectedUserList
        classes={{
          root: classes.selectedUserContainer,
        }}
        users={users.map(user => ({
          photoSrc: user.photoSrc,
          fullName: user.fullName,
        }))}
      />
      <div className={classes.tellYourContactWrapper}>
        <TextField
          label={emailTextLabel}
          error={hasError}
          multiline
          rows="10"
          rowsMax="10"
          onChange={onTellYourContactTextChange}
          margin="none"
          variant="outlined"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
        />
      </div>
    </div>
  )
}

EmailFormToUsers.propTypes = {
  users: PropTypes.arrayOf(
    PropTypes.shape({
      email: PropTypes.string,
      photo: PropTypes.string,
      balance: PropTypes.string,
      fullname: PropTypes.string,
      investedFunds: PropTypes.string,
    }),
  ).isRequired,
  onTellYourContactTextChange: PropTypes.func.isRequired,
  hasError: PropTypes.bool.isRequired,
  emailTextLabel: PropTypes.string.isRequired,
}

export default withStyles(style)(EmailFormToUsers)
