const styles = () => ({
  background:{
    backgroundColor: "#fbfaff", //body should have this background color in the main .css for the new site design 
    padding: "32px 85px",
    fontFamily: 'VodafoneRg-Regular',
    color: '#333333'
  },
  title: {
    fontSize: '32px',
    marginBottom: '32px',
    marginTop: '48px'
  },
  flex_and_center: {
    display: "flex",
    justifyContent: "center",
    
  },
})

export default styles
