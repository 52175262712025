const styles = theme => ({
  editor: {
    width: '100%',
    height: '200px',
    border: '1px solid rgba(0, 0, 0, 0.54)',
  },
  description: {
    // marginBottom: theme.spacing(1),
    // marginTop: theme.spacing(2),
  },
  content: {
    margin: '10px',
    color: 'rgba(0, 0, 0, 0.54)',
    fontWeight: '400',
    fontSize: '16px',
    marginLeft: '12px',
  },
})

export default styles
