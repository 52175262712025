import React from "react";
import pdfIcon from '../../imgs/document-pdf-system.png';
import defaultimg from 'img/default-photo.png'
import withStyles from '@material-ui/core/styles/withStyles'
import styles from './View.Style'


import pngIcon from '../../imgs/png.png';
import jpgIcon from '../../imgs/jpg.png';
import fileIcon from '../../imgs/document-system.png';
import docIcon from '../../imgs/document-doc-system.png';
import zipIcon from '../../imgs/document-zip-system.png';
import mediaIcon from '../../imgs/video-frame-system.png'

const trim_name = name => {
    const name_arr = name.split('/')
    return(name_arr[name_arr.length - 1])
}


const Doc = props => {
    const {classes,doc,csrfToken} = props
    let uploadFileIcon = fileIcon;
    // console.log("document",props)
    let a = trim_name(doc.name).split(".")
    // console.log("tri",a)
    let f = a[a.length-1]
    // console.log("f",f)

    switch(f){
        case 'pdf' :
        uploadFileIcon = pdfIcon
        break;
        case 'doc' :
            uploadFileIcon = docIcon
            break;
        case 'png' :
        uploadFileIcon = pngIcon
        break;
        case 'txt' :
        uploadFileIcon = fileIcon
        break;
        case 'jpg' :
        uploadFileIcon = jpgIcon
        break;
        case 'zip' :
        uploadFileIcon = zipIcon
        break;
        case 'mp4':
        case 'mp3':
            uploadFileIcon = mediaIcon
            break;
        default:
        uploadFileIcon = fileIcon
    }

    // console.log('doc')
    return(
        <figure className={classes.documentUpload}>
            <a href={`${doc.src}?_nxt_csrf=${csrfToken}`} target='_blank' className={classes.docsAlign} title={trim_name(doc.name)}>
            <img src={uploadFileIcon} alt={trim_name(doc.name)} title={trim_name(doc.name)}/>
            <figcaption>{trim_name(doc.name).substring(0,20)}</figcaption>
            </a>
        </figure>
    )
}

export default withStyles(styles)(Doc)