const styles = theme => ({
  children: {
    flex: 1,
    backgroundColor: 'transparent',
  },
  container: {
    flex: 1,
    backgroundColor: 'transparent',
  },
  iconButton: {
    right: 10,
    bottom: 10,
    position: 'fixed',
    opacity: 0.7,
    zIndex: theme.zIndex.modal,
  },
})

export default styles
