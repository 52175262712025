import createStyles from '@material-ui/core/styles/createStyles'

const styles = theme =>
  createStyles({
    "@keyframes upAndDown": {
        "0%": {
          transform: "translateY(0)"
        },
        "100%": {
          transform: "translateY(0)"
        }, 
        "50%":{
            transform: 'translateY(-30%)'
        },
      },
    header: {
        position: 'relative',
        margin: 'auto',
        width: '100%',

        objectFit: 'cover',
        height: '100vh',
        objectPosition: 'top',
        '& img': {
            '@media(min-width:768px)':{
                
            },
            width: '100%',
          },
    },
    headerImage:{
        height:'100%',
        width:'100%',
        objectFit:'cover'
    },
    headerOverlay: {
        position: 'absolute',
        top: '0',
        left: '0',
        textAlign: 'center',
        width: '100%',
        height: '100%',
        '& img':{
            width: '32px',
            position: 'absolute',
            top: '93%',
            left: '49%',
            transform: 'translate(-50%, -50%)',
            animation: `$upAndDown 3s infinite`
        }
    },
    logo: {
        position: 'absolute',
        top: '32px',
        left: '0',
        textAlign: 'left',
        width: '150px !important',
        height: '31px',
        paddingLeft:'15px',
        '@media(min-width:768px)':{
            // transform: 'translate(-40%, -40%)',
        }
    },
    welcomeSpan: { 
        position: 'absolute',
        top: '51%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        color: '#ffffff',
    },
    welcomeHeader:{
        '@media(min-width:768px)':{
            fontSize: '60px',
        },
        fontFamily: 'VodafoneLt-Regular',
        fontSize: '38px',
        margin: '0',
    },
    welcomeText:{
        '@media(min-width:768px)':{
            fontSize:'32px'
        },
        fontFamily: 'VodafoneRg-Regular',
        fontSize: '24px',
        margin: '10px 0 10px',
    },
    getStarted: {
        borderRadius: '20px',
        fontFamily: 'VodafoneRg-Regular',
        fontSize: '18px',
        width: '161px',
        margin: '10px 0 0',
    },
    loginPopup:{
        display: 'flex', 
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '140px',
        '& p':{
            fontFamily: 'VodafoneRg-Regular',
            fontSize: '20px'
        }
    },
    loginButtons: {
        width: '360px',
        display: 'flex', 
        justifyContent: 'space-around'
    },
    about:{
        textAlign: 'center',
        color: '#333333',
        margin: '0 85px',
    },
    aboutHeader: {
        fontFamily: 'VodafoneRg-Regular', 
        fontSize: '40px',
        width: '192px',
        margin: '25px auto 10px', 
        textAlign: 'center',
    },
    aboutText: {
        fontFamily: 'VodafoneLt-Regular',
        fontSize: '24px',
        width: '800px',
        margin: '15px auto 10px', 
        textAlign: 'center',
    }, 
    down: {
        animation: `$upAndDown 2s infinite`
    },
  },)
  

export default styles
