import React from 'react'
import * as PropTypes from 'prop-types'
import { Image, Overlay } from 'components'
import { withStyles, IconButton } from '@material-ui/core'
import defaultPhoto from 'img/default-photo.png'
import clsx from 'clsx'
import styles from './View.Style'

const IdeaAvatar = props => {
  const {
    classes,
    name,
    secondLine,
    photoSrc,
    isAdmin,
    hasDeleteButton,
    onDeleteButtonClick,
  } = props
  return (
    <div className={classes.avatar}>
      <Image className={classes.image} defaultSrc={defaultPhoto} alt={name} src={photoSrc} />
      {hasDeleteButton && (
        <IconButton
          disableFocusRipple
          disableTouchRipple
          disableRipple
          component="span"
          size="small"
          onClick={event => {
            event.stopPropagation()
            onDeleteButtonClick()
          }}
        >
          <span className="icon-close" />
        </IconButton>
      )}
      <Overlay classes={{ overlay: classes.overlay }}>
        {isAdmin && <span className="icon-owner" />}
        <div className={clsx(classes.nameWrapper, isAdmin ? classes.padded : classes.noPadded)}>
          <span>{name}</span>
          <span>{secondLine}</span>
        </div>
      </Overlay>
    </div>
  )
}

IdeaAvatar.defaultProps = {
  name: '-',
  secondLine: '',
  photoSrc: '',
  isAdmin: false,
  hasDeleteButton: false,
  onDeleteButtonClick: () => {},
}

IdeaAvatar.propTypes = {
  name: PropTypes.node,
  secondLine: PropTypes.node,
  photoSrc: PropTypes.string,
  isAdmin: PropTypes.bool,
  hasDeleteButton: PropTypes.bool,
  onDeleteButtonClick: PropTypes.func,
}

export default withStyles(styles)(IdeaAvatar)
