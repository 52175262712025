import createStyles from '@material-ui/core/styles/createStyles'
const styles = () =>
  createStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        marginRight: '100px',
        minWidth: '100px',
    },
    member_img: {
        width: '100px',
        height: '100px',
        objectFit: 'cover',
        borderRadius: '10px',
        objectPosition: 'center'
    },
    member_name: { 
        fontFamily: 'VodafoneRg-Bold',
        color: '#333333',
        fontSize: '16px',
        marginTop: '16px'
    }
  })
export default styles