import React, { useEffect } from 'react'
import * as PropTypes from 'prop-types'
import { UserAgentApplication } from 'msal'
import { Router } from 'react-router'
import NavigationService from '../../NavigationService'
import LoggedInNavigator from '../logged_in_navigator'
import LoggedOutNavigator from '../logged_out_navigator'

const RootNav = props => {
  const {
    loggedIn,
    logging,
    domain,
    setDomain,
    onRouteChange,
    hasDomainLoaded,
    isAdmin,
    isInnovator,
    isDomainInMaintenance,
    onMaintenanceDomain,
  } = props
  const customHistory = NavigationService.getHistory()

  if (loggedIn && domain) {
    NavigationService.setDomain(domain)
  }

  if (window.location.hash.includes('id_token')) {
    // new UserAgentApplication('ffa25e67-06a1-42e2-9955-64b54d4ea4f8', null, null)
    // prod client id
    new UserAgentApplication('fdefd2ec-fad9-4e94-a123-4ee6e2798d93', null, null)
    //new UserAgentApplication('Hqy8Q~wO8EJbMeUspmZ10ZnDs1Z3RN~yffK7gcPC', null, null)
  }

  useEffect(() => {
    const urlDomain = customHistory.location.pathname.split('/')[1]
    if (loggedIn && urlDomain && domain !== urlDomain) {
      NavigationService.setDomain(urlDomain)
      setDomain(urlDomain)
    }
  })
  useEffect(() => {
    onRouteChange(customHistory.location.pathname)
    customHistory.listen(location => {
      onRouteChange(location.pathname)
      window.scrollTo(0, 0)
    })
  }, [])

  useEffect(() => {
    if (!isAdmin && !isInnovator && hasDomainLoaded && isDomainInMaintenance) {
      onMaintenanceDomain()
    }
  }, [hasDomainLoaded, isDomainInMaintenance])
  return (
    <Router history={customHistory}>
      {loggedIn && <LoggedInNavigator loggedIn={loggedIn} />}
      {!loggedIn && <LoggedOutNavigator logging={logging} loggedIn={loggedIn} />}
    </Router>
  )
}

RootNav.propTypes = {
  loggedIn: PropTypes.bool.isRequired,
  domain: PropTypes.string.isRequired,
  setDomain: PropTypes.func.isRequired,
  onMaintenanceDomain: PropTypes.func.isRequired,
  hasDomainLoaded: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  isInnovator: PropTypes.bool.isRequired,
  isDomainInMaintenance: PropTypes.bool.isRequired,
}

export default RootNav
