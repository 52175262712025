const styles = theme => ({
  root: {
    marginTop: theme.spacing(4),
    padding: theme.spacing(2),
    // display: 'flex',
    // alignItems: 'center',
    // justifyContent: 'space-between',
  },
  buttonsContainer: {
    marginTop:'16px',
    '& > button:first-child': {
      marginRight: theme.spacing(1),
    },
    '& > button:last-child': {
      marginLeft: theme.spacing(1),
    },
  },
  buttonProgress: {
    position: 'absolute',
  },
})

export default styles
