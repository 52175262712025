const styles = theme => ({
  root: {
    listStyle: 'none',
    '& > li': {
      display: 'flex',
      borderTop: '1px solid #dad7d7',
      padding: theme.spacing(2, 1),
    },
  },
  avatar: {
    '& > img': {
      borderRadius: '50%',
      width: '50px',
      height: '50px',
    },
  },
  userName: {
    fontSize: '14px',
    fontWeight: 700,
    marginRight: theme.spacing(1),
  },
  comment: {
    flex: 1,
    padding: theme.spacing(1),
  },
  firstRow: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '12px',
    color: '#666666',
  },
  secondRow: {
    display: 'flex',
    fontSize: '12px',
    padding: theme.spacing(1, 0),
    whiteSpace: 'pre',
  },
  thirdRow: {
    display: 'flex',
    padding: theme.spacing(1),
    fontSize: '14px',
    '& > div:first-child': {
      width: '100%',
    },
    '& > div': {
      display: 'flex',
      alignItems: 'flex-end',
    },
    position: 'relative',
  },
  fourthRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: '14px',
    color: '#666666',
  },
  buttonsWrapper: {
    display: 'flex',
    color: theme.palette.primary.main,
  },
  suggestionList: {
    position: 'absolute',
  },
  currency_icon: {
    height: '20px'
  },
  fullinvested: {
    fontFamily: 'VodafoneRg-Regular',
    fontSize: '16px',
    color: '#000000',
    display: 'flex',
    alignItems:'center',
  },
  invested: {
    fontFamily: 'VodafoneRg-Bold',
    margin: '8px'
  }
})

export default styles
