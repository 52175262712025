import { combineReducers } from 'redux'
import I18nReducer from 'support/i18n/redux/Reducer'
import ModalAlertReducer from 'support/popup_dialogs/modal_alert_controller/ModalAlertReducer'
import LoaderReducer from 'support/loader/redux/Reducer'
import AuthReducer from './AuthReducer'
import DataReducer from './DataReducer'
import ExploreReducer from './ExploreReducer'

const reducers = combineReducers({
  auth: AuthReducer,
  data: DataReducer,
  explore: ExploreReducer,
  i18n: I18nReducer,
  modalAlertReducer: ModalAlertReducer,
  loader: LoaderReducer,
})

export default reducers
