const styles = theme => ({
  root: {
    padding: theme.spacing(4, 2, 2, 2),
    width: '800px',
    position: 'relative',
    '& > button': {
      position: 'absolute',
      right: 0,
      top: 0,
    },
    '& > button:hover': {
      backgroundColor: 'unset',
    },
  },
})

export default styles
