import React, { Component, Fragment } from 'react'
import i18n from 'support/i18n'
import { isValidEmail } from 'support/utils/auth'
import HelpWidgetMainDialog from './components/main_dialog/View'
import HelpWidgetEmailDialog from './components/emails_dialog/View'

class HelpWidgetDialog extends Component {
  constructor(props) {
    super(props)
    this.state = {
      mainStep: true,
      subject: '',
      msg: '',
      email: '',
      image: '',
      imageBase64: '',
      errorMessageComment: '',
      errorSubjectComment: '',
      errorMessageEmail: '',
    }
    this.setHelpForm = this.setHelpForm.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.onNextStep = this.onNextStep.bind(this)
    this.handleCloseDialog = this.handleCloseDialog.bind(this)
    this.handleUploadImage = this.handleUploadImage.bind(this)
    this.onDeleteImage = this.onDeleteImage.bind(this)
  }

  onDeleteImage() {
    this.setState({ imageBase64: '', image: '' })
  }

  onNextStep(subject, msg) {
    this.setState({ errorSubjectComment: '', errorMessageComment: '' })
    if (subject === '') {
      this.setState({
        errorSubjectComment: i18n.t('REQUIRED_INPUT'),
      })
    }
    if (msg === '') {
      this.setState({
        errorMessageComment: i18n.t('REQUIRED_INPUT'),
      })
    }
    if (subject && msg) {
      this.setState({ mainStep: false })
    }
  }

  onSubmit() {
    const { onSubmit } = this.props
    const { subject, msg, email, imageBase64 } = this.state

    if (email === '') {
      this.setState({
        errorMessageEmail: i18n.t('REQUIRED_INPUT'),
      })
    } else if (!isValidEmail(email)) {
      this.setState({
        errorMessageEmail: i18n.t('EXPLORE_IDEA.CONTRIBUTE.INVALID_FORMAT'),
      })
    } else {
      this.setState({
        errorMessageEmail: '',
      })
      onSubmit(subject, msg, email, imageBase64)
    }
  }

  setHelpForm(dataState) {
    this.setState(dataState)
  }

  handleUploadImage({ target }) {
    if (!target.files.length) return
    const reader = new FileReader()
    reader.readAsDataURL(target.files[0])

    reader.onload = event => {
      if (event.target.readyState === FileReader.DONE) {
        const src = event.target.result
        this.setState({ imageBase64: src, image: target.files[0].name })
      }
    }
  }

  handleCloseDialog() {
    const { onClose } = this.props
    onClose()
  }

  render() {
    const {
      mainStep,
      subject,
      msg,
      email,
      image,
      errorMessageComment,
      errorSubjectComment,
      errorMessageEmail,
    } = this.state
    return (
      <Fragment>
        {mainStep ? (
          <HelpWidgetMainDialog
            subject={subject}
            msg={msg}
            image={image}
            onDeleteImage={this.onDeleteImage}
            errorMessageComment={errorMessageComment}
            errorSubjectComment={errorSubjectComment}
            setHelpForm={this.setHelpForm}
            onNextStep={this.onNextStep}
            handleCloseDialog={this.handleCloseDialog}
            handleUploadImage={this.handleUploadImage}
          />
        ) : (
          <HelpWidgetEmailDialog
            email={email}
            errorMessageEmail={errorMessageEmail}
            setHelpForm={this.setHelpForm}
            onSubmit={this.onSubmit}
            handleCloseDialog={this.handleCloseDialog}
          />
        )}
      </Fragment>
    )
  }
}

export default HelpWidgetDialog
