import React from 'react'
import { connect } from 'react-redux'
import { getIdeas } from 'services/ideas'
import {
  getEvaluationIdea,
  getEvaluationArchivedIdea,
  getEvaluationPendingIdea,
  getEvaluationArchivedIdeaById,
  restoreEvaluationArchivedIdeaById,
  postEvaluationIdea,
  draftEvaluationIdea,
  getEvaluationIdeaTitle,
  postEvaluationIdeaStatus,
  getEvaluationIdeaById,
  saveEvaluationIdeaById,
  shareEvaluationIdeaById,
  getExpertCommentsMail,
} from 'services/evaluation'
import { FIRST_LIMIT_CONSTANT } from 'common_constants/Evaluation'

import {
  showPopupErrorSnackbar,
  showPopupSuccessSnackbar,
  showPopupDialog,
} from 'support/popup_dialogs'

import { getIdea } from 'services/create_idea'
import i18n from 'support/i18n'
import { increaseEvaluationStep, decreaseEvaluationStep } from 'actions/sync/evaluation'
import Navigation from 'support/navigation'
import View from './View'
// import ShareIdea from './components/share_idea/View'
// import { hideDialog } from 'support/popup_dialogs/modal_alert_controller/ModalActions'
// import { FIRST_LIMIT_CONSTANT } from '../../common_constants/Ideas'

const mapStateToProps = state => {
  return {
    ideas: state.data.evaluation.data,
    ideasChallenge: state.data.challenge.ideas,
    totalEvaluationIdea: state.data.evaluation.total,
    
    // totalIdeasChallenge: state.data.challenge.totalIdeas,
    hasMoreIdeasToLoad: state.data.evaluation.hasMore,

    ideasArchived: state.data.evaluation.dataArchived,
    totalArchived: state.data.evaluation.totalArchived,
    hasMoreArchivedIdeasToLoad: state.data.evaluation.hasMoreArchived,

    title: state.data.createIdea.title,
    images: state.data.createIdea.images,
    description: state.data.createIdea.description,
    ideaIdVal: state.data.createIdea,
    isAdmin: state.data.user.admin,
    localAdmin: state.data.user.localAdmin,
    isEvaluator: state.data.user.evaluator,
    localEvaluator: state.data.user.localEvaluator,
    // title: state.data.evaluation.data.title,
    // images: state.data.evaluation.data.images,
    // description: state.data.evaluation.data.text,
    evaluationIdea: state.data.evaluation.ideaevaluation,
    commentsList: state.data.evaluation.list,
    videoSrc: state.data.createIdea.videos.externalVideo || state.data.createIdea.videos.fileVideo,
    activeStep: state.data.evaluation.activeStep,
    
    // commentListGraph: state.data.evaluationAdmin.list,
    evaluationAdmin: state.data.evaluationAdmin,
    commentListGraph: state.data.evaluationAdmin.list,
    commentListForAdmin: state.data.evaluationAdmin.commentsList,
    ideaTitle: state.data.evaluationAdmin.ideaevaluation.ideaTitle,
    ideaText: state.data.evaluationAdmin.ideaevaluation.ideaText,
    boardReviewDate: state.data.evaluationAdmin.ideaevaluation.boardReviewDate,
    boardStatus: state.data.evaluationAdmin.ideaevaluation.boardStatus,
    feedbackForIdeaOwner: state.data.evaluationAdmin.ideaevaluation.feedbackForIdeaOwner,
    boardComment: state.data.evaluationAdmin.ideaevaluation.boardComment,
    ideaPitchDate: state.data.evaluationAdmin.ideaevaluation.ideaPitchDate,
    pitchStatus: state.data.evaluationAdmin.ideaevaluation.pitchStatus,
    roundClosed: state.data.evaluationAdmin.ideaevaluation.roundClosed,
    adminImages: state.data.evaluationAdmin.ideaevaluation.images,

    archivedIdeaTitle: state.data.evaluationAdmin.archiveidea.ideaTitle,
    archivedIdeaText: state.data.evaluationAdmin.archiveidea.ideaText,
    archivedBoardReviewDate: state.data.evaluationAdmin.archiveidea.boardReviewDate,
    archivedBoardStatus: state.data.evaluationAdmin.archiveidea.boardStatus,
    archivedFeedbackForIdeaOwner: state.data.evaluationAdmin.archiveidea.feedbackForIdeaOwner,
    archivedBoardComments: state.data.evaluationAdmin.archiveidea.boardComment,
    archivedIdeaPitchDate: state.data.evaluationAdmin.archiveidea.ideaPitchDate,
    archivedPitchStatus: state.data.evaluationAdmin.archiveidea.pitchStatus,
    archivedRoundClosed: state.data.evaluationAdmin.archiveidea.roundClosed,
    archivedAdminImages: state.data.evaluationAdmin.archiveidea.images,

    expertCommentMail: state.data.evaluationAdmin.expertCommentMail,

    adminEvaluationLoading: state.data.evaluationAdmin.evaluationLoading,
    evaluationLoading: state.data.evaluation.evaluationLoading,
    
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getPendingIdeas: () => {
      dispatch(getIdeas({ limit: 10, offset: 0 }))
    },
    navigateToIdea: id => {
      dispatch(Navigation.navigateToIdea(id))
    },
    navigateToIdeaDetail: id => {
      dispatch(Navigation.navigateToIdea(id))
    },
    navigateToUserProfile: userEmail => {
      Navigation.navigateToUserProfile(userEmail)
    },
    navigateToEvaluationIdeaDetails: id => {
      Navigation.navigateToEvaluationIdeaDetails(id)
    },
    getIdea: id => {
      dispatch(getIdea(id))
    },
    getEvaluationIdeaTitle: id => {
      const onSuccess = data => {
        //console.log(`onSuccess title`)
      }
      const onFailure = ({ data }) => {
        //console.log(`onFailure title`)
      }
      dispatch(getEvaluationIdeaTitle(id, onSuccess, onFailure))
    },

    getEvaluationIdea: (limit = FIRST_LIMIT_CONSTANT, offset = 0) => {
      const onSuccess = data => {
        //console.log(`onSuccess`)
      }
      const onFailure = ({ data }) => {
        //console.log(`onFailure`)
      }
      dispatch(getEvaluationIdea(limit, offset, onSuccess, onFailure))
    },

    getEvaluationArchivedIdea: (limit = FIRST_LIMIT_CONSTANT, offset = 0) => {
      const onSuccess = data => {
        //console.log(`onSuccess`)
      }
      const onFailure = ({ data }) => {
        //console.log(`onFailure`)
      }
      dispatch(getEvaluationArchivedIdea(limit, offset, onSuccess, onFailure))
    },

    getEvaluationPendingIdea: (limit = FIRST_LIMIT_CONSTANT, offset = 0) => {
      const onSuccess = data => {
        //console.log(`onSuccess`)
      }
      const onFailure = ({ data }) => {
        //console.log(`onFailure`)
      }
      dispatch(getEvaluationPendingIdea(limit, offset, onSuccess, onFailure))
    },
    getEvaluationDraft: title => {
      const onSuccess = data => {
        //dispatch(increaseEvaluationStep())
      }
      const onFailure = ({ data }) => {
        //dispatch(decreaseEvaluationStep())
      }
      dispatch(draftEvaluationIdea(title, onSuccess, onFailure))
    },
    getEvaluationIdeaPost: (title, q1c, q1m = 0, q2c, q2m = 0, q3c, q3m = 0) => {
      const onSuccess = data => {
        //console.log(`data error:  ${JSON.stringify(data)}`)
        dispatch(increaseEvaluationStep())
      }
      const onFailure = ({ data }) => {
        //console.log(`data error:  ${JSON.stringify(data)}`)
        const messageTag =
          data && data.error && data.error.error
            ? `BACKEND_ERRORS.${data.error.error}`
            : 'BACKEND_ERRORS.UNEXPECTED'
        //showPopupDialog(<ErrorModal description={i18n.t(messageTag)} />, dispatch)
        showPopupErrorSnackbar(i18n.t(messageTag), dispatch)
      }
      dispatch(postEvaluationIdea(title, q1c, q1m, q2c, q2m, q3c, q3m, onSuccess, onFailure))
    },

    getEvaluationIdeaStatusPost: (title, status, email) => {
      const onSuccess = data => {
        dispatch(increaseEvaluationStep())
      }
      const onFailure = ({ data }) => {
        //console.log(`data error:  ${JSON.stringify(data)}`)
        const messageTag =
          data && data.error && data.error.error
            ? `BACKEND_ERRORS.${data.error.error}`
            : 'BACKEND_ERRORS.UNEXPECTED'
        //showPopupDialog(<ErrorModal description={i18n.t(messageTag)} />, dispatch)
        showPopupErrorSnackbar(i18n.t(messageTag), dispatch)
      }
      dispatch(postEvaluationIdeaStatus(title, status, email, onSuccess, onFailure))
    },

    goNext: () => {
      dispatch(increaseEvaluationStep())
    },
    goBack: () => {
      dispatch(decreaseEvaluationStep())
    },
    getEvaluationIdeaById: id => {
      dispatch(getEvaluationIdeaById(id))
    },

    getEvaluationArchivedIdeaById: id => {
      const onSuccess = data => {
        //console.log(`onSuccess`)
      }
      const onFailure = ({ data }) => {
        //console.log(`onFailure`)
      }
      dispatch(getEvaluationArchivedIdeaById(id, onSuccess, onFailure))
    },
    restoreEvaluationArchivedIdeaById: id => {
      const onSuccess = data => {
        //console.log(`onSuccess`)
        showPopupSuccessSnackbar('Idea restored successfully', dispatch)
        window.location.reload()
      }
      const onFailure = ({ data }) => {
        const messageTag =
          data && data.error && data.error.error
            ? `BACKEND_ERRORS.${data.error.error}`
            : 'BACKEND_ERRORS.UNEXPECTED'
        //showPopupDialog(<ErrorModal description={i18n.t(messageTag)} />, dispatch)
        showPopupErrorSnackbar(i18n.t(messageTag), dispatch)
        window.location.reload()
      }
      dispatch(restoreEvaluationArchivedIdeaById(id, onSuccess, onFailure))
    },
    restoreEvaluationArchivedIdeaByIdUpdated: id => {
      const onSuccess = data => {
        window.location.reload()
      }
      const onFailure = ({ data }) => {
        window.location.reload()
      }
      dispatch(restoreEvaluationArchivedIdeaById(id, onSuccess, onFailure))
    },

    saveEvaluationIdeaById: (
      saveOrShare,
      id,
      boardReviewDate,
      ideaPitchDate,
      boardStatus,
      pitchStatus,
      feedbackForIdeaOwner,
      boardComments,
    ) => {
      const onSuccess = data => {
        //dispatch(increaseEvaluationStep())
        //dispatch(shareEvaluationIdeaById(id))
        const onSuccess = data => {
          //dispatch(increaseEvaluationStep())
          //showPopupSuccessSnackbar(i18n.t('IDEA.SHARE_SUCCESS'), dispatch)
          window.location.reload()
          //dispatch(Navigation.navigateToEvaluationBoard())
        }
        const onFailure = ({ data }) => {
          //console.log(`data error:  ${JSON.stringify(data)}`)
          const messageTag =
            data && data.error && data.error.error
              ? `BACKEND_ERRORS.${data.error.error}`
              : 'BACKEND_ERRORS.UNEXPECTED'
          //showPopupDialog(<ErrorModal description={i18n.t(messageTag)} />, dispatch)
          showPopupErrorSnackbar(i18n.t(messageTag), dispatch)
        }
        if (saveOrShare) dispatch(shareEvaluationIdeaById(id, onSuccess, onFailure))
      }
      const onFailure = ({ data }) => {
        //console.log(`data error:  ${JSON.stringify(data)}`)
        const messageTag =
          data && data.error && data.error.error
            ? `BACKEND_ERRORS.${data.error.error}`
            : 'BACKEND_ERRORS.UNEXPECTED'
        //showPopupDialog(<ErrorModal description={i18n.t(messageTag)} />, dispatch)
        showPopupErrorSnackbar(i18n.t(messageTag), dispatch)
      }
      dispatch(
        saveEvaluationIdeaById(
          id,
          boardReviewDate,
          ideaPitchDate,
          boardStatus,
          pitchStatus,
          feedbackForIdeaOwner,
          boardComments,
          onSuccess,
          onFailure,
        ),
      )
    },

    shareEvaluationIdeaById: id => {
      const onSuccess = data => {
        //dispatch(increaseEvaluationStep())
        //showPopupSuccessSnackbar(i18n.t('IDEA.SHARE_SUCCESS'), dispatch)
        //window.location.reload()
        dispatch(Navigation.navigateToEvaluationBoard())
      }
      const onFailure = ({ data }) => {
        //console.log(`data error:  ${JSON.stringify(data)}`)
        const messageTag =
          data && data.error && data.error.error
            ? `BACKEND_ERRORS.${data.error.error}`
            : 'BACKEND_ERRORS.UNEXPECTED'
        //showPopupDialog(<ErrorModal description={i18n.t(messageTag)} />, dispatch)
        showPopupErrorSnackbar(i18n.t(messageTag), dispatch)
      }
      dispatch(shareEvaluationIdeaById(id, onSuccess, onFailure))
    },
    errorMessage: msz => {
      showPopupErrorSnackbar(msz, dispatch)
    },
    getExpertCommentsMail: id => {
      const onSuccess = data => {
        // console.log(`onSuccess`)
      }
      const onFailure = ({ data }) => {
        // console.log(`onFailure`)
      }
      dispatch(getExpertCommentsMail(id, onSuccess, onFailure))
    },

    // onShareIdea: () => showPopupDialog(<ShareIdea />, dispatch),
    // onClose: () => dispatch(hideDialog()),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(View)
