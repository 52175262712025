import React, { Component, useEffect, useState } from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import * as PropTypes from 'prop-types'
import i18n from 'support/i18n'
import styles from './View.Style'
import defaultPhoto from 'img/default-photo.png'
import { ClickAwayListener } from '@material-ui/core';
import Idea_Panel from '../../components_newui/idea_panel/View'
import Breadcrumbs from 'components_newui/pageBreadcrumbsMenu/View'
import SuccessRequest from './components/popups/request_success/View'
import coins_ico from 'img/seeking_funding.png'
import investments_ico from 'img/invstment_sack.png'
import no_badges_image from 'img/no_badges.png'
import upload_img from 'img/upload_photo.png'
import no_img from 'img/no_image_yet.png'
import Button from 'components_newui/button/View'

const Profile = props => {
  const {
    classes,
    match,
    navigateHome,
    myEmail,
    photoSrc,
    fullName,
    email,
    invested,
    balance,
    userBadges,
    allBadges,
    navigateToInvestments,
    navigateToBalance,
    navigateToIdea,
    onRequestInvestmentClick,
    ideas,
    hasDomainLoaded
  } = props

  const pageHierarchy = [
    {string: "Home", navigate: navigateHome}, 
    {string: "Profile", navigate: ()=>{}}
  ] 
  const isMe = match.params.email.toLowerCase() === 'me' || match.params.email === myEmail
  const userEmail = isMe ? myEmail : match.params.email

  const [showBadgesGuide, setShowBadgesGuide] = useState(false)
  const [showUploadPhoto, setShowUploadPhoto] = useState(false)

  const getProfile = () => {
    const { getMyProfile, getUserProfile } = props
    isMe ? getMyProfile() : getUserProfile(userEmail)
  }

  const getProfileData = userEmail => {
    const {
      getUserIdeas,
      getUserIdeasTeam,
      getUserIdeasPilotTeam,
      getUserIdeasInvestedIn,
      getUserOrganisations,
      isOrganizationsActive,
      getUserContributions,
      getMyIdeasToInvestIn,
      getTimeline,
      resetTimeline,
    } = props
    resetTimeline()
    getUserIdeas(userEmail)
    getUserIdeasTeam(userEmail)
    getUserIdeasInvestedIn(userEmail)
    getUserIdeasPilotTeam(userEmail)
    getUserContributions(userEmail)
    
    getMyIdeasToInvestIn()
    if (isOrganizationsActive) {
      getUserOrganisations()
    }
    getTimeline(userEmail)
  }

  useEffect(() => {
    getProfile()
    hasDomainLoaded && getProfileData(userEmail)
  }, [hasDomainLoaded])



  return(
    <>
          <Breadcrumbs crumbs={pageHierarchy} />
      {
        (showBadgesGuide || showUploadPhoto) && 
        <>
        <div style={{
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
            position: 'fixed',
            opacity: '60%',
            backgroundColor: '#333333',
            zIndex: 35,
        }}/> 
        <ClickAwayListener onClickAway={()=>{
          setShowBadgesGuide(false);
          setShowUploadPhoto(false)
          }}>
            <div>
            {showBadgesGuide &&<div className={classes.badges_popup}>
                {allBadges.map(badge => (
                  <div className={classes.badge}>
                    <img src={badge.imgSrc} />
                    <p className={classes.badgeText}>
                      {i18n
                      .t(`BADGES.${badge.name.toUpperCase()}`)}
                    </p>
                  </div>
                ))}
            </div>}
            {showUploadPhoto && 
            <div className={classes.photo_upload} style={{height: '40%'}}>
              <div style={{
                display: 'flex',
                justifyContent:'center',
                alignItems: 'center',
                marginBottom: '30px'
              }}>
                <img src={no_img} className={classes.profilePhoto} />
                <div style={{marginLeft: '32px'}}>
                  <div style={{
                    color: '#6551FF',
                    textDecoration: 'underline',
                    fontSize: '20px',
                    cursor: 'pointer',
                    marginBottom: '8px'
                  }}>Upload photograph</div>
                  <div style={{
                    fontSize: '16px',
                    color: '#808080'
                  }}>
                    Max size: 3MB | Formats: JPG, PNG.
                  </div>
                </div>
              </div>

              <div style={{display: 'flex'}}>
                <Button text='Cancel' styled={false} onClick={()=>setShowUploadPhoto(false)} />
                <Button text='Save'  onClick={()=>{}/*Upload photo here*/} />
              </div>
            </div>}
            </div>
        </ClickAwayListener>
        
        </>

      }
      <div className={classes.background}>
        <div className={classes.topStrip}>
          <div style={{display: 'flex', alignItems:'center'}}>
            <img src={photoSrc} className={classes.profilePhoto} 
              style={{cursor: 'pointer'}}
              onError={({currentTarget}) => {
                currentTarget.onerror = null
                currentTarget.src = defaultPhoto
              }}
              onMouseEnter={({currentTarget}) => {
                currentTarget.src=upload_img
              }}
              onMouseLeave={({currentTarget}) => {
                currentTarget.src=photoSrc
              }}
              onClick={()=>setShowUploadPhoto(true)}
            />
            <div style={{marginLeft: '32px'}}>
              <div style={{fontSize: '40px', marginBottom: '8px'}}>{fullName}</div>
              <div style={{fontSize: '18px'}}>{email}</div>
              {!isMe && 
              <div style={{
                  cursor: 'pointer',
                  color: '#6551FF',
                  fontSize: '20px',
                  textDecoration: 'underline',
                  marginTop: '8px'
                }}
                onClick={() => onRequestInvestmentClick(userEmail)}
              >
                Request Investment
              </div>}
            </div>
          </div>
          <div style={{display: 'flex', alignItems:'center', justifyContent:'center'}}>
            <div style={{borderRight: '1px solid #979797', paddingRight: '51px'}}>
              <div className={classes.balanceSubtitle}>
                <img src={coins_ico} className={classes.icon}/>
                Current Balance
              </div>
              <div className={classes.balance}>{balance}</div>
              <div className={classes.detailsLink} onClick={navigateToBalance}>Details</div>
            </div>
            <div style={{marginLeft: '48px'}}>
              <div className={classes.balanceSubtitle}>
                <img src={investments_ico} className={classes.icon}/>
                Invested Funds
              </div>
              <div className={classes.balance}>{invested}</div>
              <div className={classes.detailsLink} onClick={navigateToInvestments}>Details</div>
            </div>
          </div>
        </div>
        <div style={{padding: '0 85px'}}>
          <div className={classes.heading}>
            Badges &amp; Medals ({userBadges.length})
          </div>
          <div style={{display: 'flex', justifyContent:'space-between',marginBottom: '48px'}}>
            <div style={{
              fontSize: '18px'
            }}>Badges and medals will show up here as you earn them.</div>
            <div 
              style={{
                color: '#6D45FF', 
                fontSize: '16px', 
                textDecoration: 'underline',
                cursor: 'pointer'}}
              onClick={()=>setShowBadgesGuide(true)}
              >
              Badges and medals guide
            </div>
          </div>
          {userBadges.length > 0 ?
          <>
            {userBadges.map(badge => (
              <div className={classes.badge}>
                <img src={badge.imgSrc} />
                <p className={classes.badgeText}>
                  {i18n
                  .t(`BADGES.${badge.name.toUpperCase()}`)}
                </p>
              </div>
            ))}
          </>
          :
          <div style={{display: 'flex', alignItems: 'center', justifyContent:'center', flexDirection:'column'}}>
            <img src={no_badges_image} style={{width: '262px'}} />
            <div style={{color: '#6D45FF', fontFamily:'VodafoneRg-Bold', fontSize: '48px', marginTop:'32px'}}>
              No Badges &amp; Medals
            </div>
          </div>
          }
          <div className={classes.heading}>
            Ideas posted ({ideas.length})
          </div>
          <div className={classes.idea_grid}>
            {ideas.map(idea => 
              <Idea_Panel 
                idea={idea} 
                onMainButtonClick={()=>navigateToIdea(idea.id)} 
              />
            )}
          </div>


          {/*
          //Challenges not yet integrated
          <div className={classes.heading}>
            Challenges proposed ({ideas.length})
          </div>*/}

        </div>
      </div>
    </>
  )
}

export default withStyles(styles)(Profile)
