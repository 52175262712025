import React from "react"
import withStyles from '@material-ui/core/styles/withStyles'
import styles from "./View.Style"

const Wraper = ({classes,children}) => {
  return (
    <div className={classes.WrapContainer}>
      {children}
    </div>
    )
}

export default withStyles(styles)(Wraper)