import React, { useEffect, useState } from 'react'
import * as PropTypes from 'prop-types'
import { TextField, withStyles } from '@material-ui/core'
import clsx from 'clsx'
import SuggestionList from 'components/autosuggestion/components/suggestion_list/View'
import styles from './View.Style'

const MentionTextField = props => {
  const {
    classes,
    className,
    resetUserSuggestions,
    label,
    onTextChange,
    textValue,
    onMentionType,
    userSuggestions,
    textFieldRows,
    id,
  } = props
  const [isFocusedText, setIsTextFocused] = useState(false)

  const handleCommentText = event => {
    event.preventDefault()
    const mention = event.target.value.match(/^\B@(\w+)|[^<]\B@(\w+)/gm)
    if (mention && mention[0] && mention[0].length > 3) {
      onMentionType(mention[0].trim())
    }
    onTextChange(event.target.value)
  }

  const handleSuggestionSelect = user => {
    const firstCharacter = textValue
      .match(/^\B@(\w+)|[^<]\B@(\w+)/)[0]
      .charAt(0)
      .replace('@', '')
    onTextChange(
      textValue.replace(/^\B@(\w+)|[^<]\B@(\w+)/, `${firstCharacter}<@${user.fullName}>`),
    )
    resetUserSuggestions()
  }
  useEffect(() => {
    const listItem = document.querySelector(`#users-list-${id}`)
    if (listItem) listItem.scrollIntoView()
  }, [])

  // useEffect(() => {
  //   const listItem = document.querySelector(`#users-list-${id}`)
  //   if (listItem) listItem.scrollIntoView()
  // }, [userSuggestions])

  return (
    <div className={clsx(classes.root, className)}>
      <TextField
        multiline
        fullWidth
        label={label}
        rows={textFieldRows}
        onBlur={() => {
          setTimeout(() => {
            setIsTextFocused(false)
            resetUserSuggestions()
          }, 300)
        }}
        onFocus={() => setIsTextFocused(true)}
        variant="outlined"
        value={textValue}
        onChange={handleCommentText}
      />
      {isFocusedText && !!userSuggestions.length && (
        <div id={`users-list-${id}`} className={classes.suggestionList}>
          <SuggestionList
            lastSuggestionIndex={userSuggestions.length - 1}
            onSuggestionSelect={handleSuggestionSelect}
            suggestions={userSuggestions}
            listWidth="400px"
          />
        </div>
      )}
    </div>
  )
}
MentionTextField.defaultProps = {
  className: '',
  label: '',
  textFieldRows: 1,
}

MentionTextField.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  resetUserSuggestions: PropTypes.func.isRequired,
  label: PropTypes.string,
  onTextChange: PropTypes.func.isRequired,
  textValue: PropTypes.string.isRequired,
  onMentionType: PropTypes.func.isRequired,
  userSuggestions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  textFieldRows: PropTypes.number,
}

export default withStyles(styles)(MentionTextField)
