import React from 'react'
import { connect } from 'react-redux'
import View from './View'


const challenges = [
    {
        name:'harry challenge',
        images:[{url:"https://www.industrialempathy.com/img/remote/ZiClJf-1920w.jpg"}],
        desc:"this is the description",
        proposerName:"harry",
        ends: 1643281670000,
        numIdeas: 5},
        {
        name:'harry challenge',
        images:[{url:"https://www.industrialempathy.com/img/remote/ZiClJf-1920w.jpg"}],
        desc:"this is the description",
        proposerName:"harry",
        ends: 1643281670000,
        numIdeas: 5},
        {
        name:'harry challenge',
        images:[{url:"https://www.industrialempathy.com/img/remote/ZiClJf-1920w.jpg"}],
        desc:"this is the description",
        proposerName:"harry",
        ends: 1643281670000,
        numIdeas: 5}
]

//{src: "", key: "", name: ""}
const mapStateToProps = state => ({
    draft_ideas: [1, 2, 3, 4],
    images: [],
    categories: ['Blockchain', 'Future Working', 'Other'],
    challenges: challenges,
})


export default connect(mapStateToProps,null,)(View)