import React, { Component, Fragment, useEffect } from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import * as PropTypes from 'prop-types'
import i18n from 'support/i18n'
import InvestmentsTable from 'components/investments_table/View'
import TotalInvestments from './components/total_investments/View'
import styles from './View.Style'
import Breadcrumbs from 'components_newui/pageBreadcrumbsMenu/View'
import top_image from 'img/investments_graphic.png'
import defaultPhoto from 'img/default-photo.png'

const timeSection = {
  display: 'flex',
    justifyContent: 'center',
    alignItems:'center',
    width:'80px', height: '48px',
}

const Balance = props => {
  const {
    classes,
    navigateHome,
    navigateToProfile,
    fullInfoLoaded, 
    getInvestments, 
    userEmail,
    totalInvested, 
    totalProfit, 
    ideas
  } = props

  const pageHierarchy = [
    {string: "Home", navigate: navigateHome}, 
    {string: "Profile", navigate: navigateToProfile},
    {string: "Current balance details", navigate: ()=>console.log('navigate')}
  ] 

  

  useEffect(() => {
    getInvestments(userEmail)
  },[fullInfoLoaded])
    console.log(ideas)
  return(
    <>
     <Breadcrumbs crumbs={pageHierarchy} />
      <div className={classes.background}>
        <div style={{
          display: 'flex', 
          justifyContent: 'space-between',
          marginBottom: '34px'
        }}>
          <div>
            <div style={{
              fontSize: '40px'
            }}>Current Balance &amp; Activity Details</div>
            <div style={{
              fontSize: '18px',
              marginTop: '16px'
            }}>Further description to be written here.......</div>
          </div>
          <img src={top_image} style={{width:'300px'}}/>
        </div>

        <div style={{display: 'flex', justifyContent:'space-between', marginBottom:'32px'}}>
          <div style={{display: 'flex', cursor: 'pointer', fontSize:'18px'}}>
            <div style={{...timeSection, borderRadius: '24px 0 0 24px',color:'white', background:'#333333',border: '1px solid #333333'}}>
              1M
            </div>
            <div className={classes.timeSection}>
              3M
            </div>
            <div className={classes.timeSection}>
              6M
            </div>
            <div style={{...timeSection, borderRadius: '0 24px 24px 0', background:'white',border: '1px solid #6551FF'}}>
              1Y
            </div>
          </div>
          <div style={{
            fontSize: '16px',
            color: '#6D45FF',
            textDecoration: 'underline',
            cursor: 'pointer'
          }}>Balance details</div>
        </div>
        
        <div className={classes.investmentsGrid}>
          <div className={classes.gridHeader}>Idea</div>
          <div className={classes.gridHeader}>Status</div>
          <div className={classes.gridHeader}>Invested</div>
          <div className={classes.gridHeader}>Profit</div>
          {ideas.map(idea => (
            <>
              <div className={classes.gridItem}>
                <img src={idea.image} style={{
                  width: '57px',
                  height: '57px',
                  objectFit:'cover',
                  objectPosition:'center',
                  borderRadius:'15px',
                  marginRight: '16px'
                  }} 
                  onError={({currentTarget}) => {
                    currentTarget.onerror = null
                    currentTarget.src = defaultPhoto
                  }}
                />
                <div>{idea.title}</div>
              </div>
              <div className={classes.gridItem}>{idea.status}</div>
              <div className={classes.gridItem}>{idea.invested}</div>
              <div className={classes.gridItem}>{idea.profit}</div>
            </>
          ))}
        </div>
      </div>
    </>
  )
}

export default withStyles(styles)(Balance)
