import React, { Fragment, Component } from 'react'
import * as PropTypes from 'prop-types'
import { Paper, Typography, Button, withStyles } from '@material-ui/core'
import i18n from 'support/i18n'
import clsx from 'clsx'
import { makeItRealValues } from 'pages/idea/detail/Constants'
import NxtContributionCurrency from 'components/nxt_contribution_currency'
import styles from './View.Style'
import ContributionForm from './components/contribution_form/View'
import ContributorsList from './components/contributors_list/View'
import OwnContribution from './components/own_contribution/View'

class ContributionSection extends Component {
  constructor(props) {
    super(props)
    const { isTimeActive, isBudgetActive, isOtherActive } = props
    this.inputsActive = []
    if (isTimeActive) this.inputsActive.push(makeItRealValues.TIME)
    if (isBudgetActive) this.inputsActive.push(makeItRealValues.BUDGET)
    if (isOtherActive) this.inputsActive.push(makeItRealValues.OTHER)
    this.state = { isFormVisible: false, errors: {} }
    this.handleOnSaveContribution = this.handleOnSaveContribution.bind(this)
    this.handleOnDeleteContribution = this.handleOnDeleteContribution.bind(this)
    this.isMakeItRealDisable = this.isMakeItRealDisable.bind(this)
  }

  isMakeItRealDisable() {
    const { ownContribution } = this.props
    return this.inputsActive.find(inputActive => !!ownContribution[inputActive]) === undefined
  }

  handleOnSaveContribution() {
    const {
      onSaveContribution,
      ownContribution,
      isTimeActive,
      isBudgetActive,
      isOtherActive,
    } = this.props
    const errors = {}
    if (
      isTimeActive &&
      ownContribution.time &&
      (ownContribution.time.length > 3 ||
        Number.isNaN(Number(ownContribution.time)) ||
        Number(ownContribution.time) < 1)
    ) {
      errors.time = i18n.t('EXPLORE_IDEA.CONTRIBUTE.INVALID_TIME')
    }
    if (
      isBudgetActive &&
      ownContribution.budget &&
      (ownContribution.budget.length > 7 ||
        Number.isNaN(Number(ownContribution.budget)) ||
        Number(ownContribution.budget) < 1)
    ) {
      errors.budget = i18n.t('EXPLORE_IDEA.CONTRIBUTE.INVALID_BUDGET')
    }
    if (isOtherActive && ownContribution.other && ownContribution.other.length > 120) {
      errors.other = i18n.t('EXPLORE_IDEA.CONTRIBUTE.INVALID_OTHER')
    }

    if (!Object.keys(errors).length) {
      onSaveContribution()
      this.setState({ isFormVisible: false })
    }
    this.setState({ errors })
  }

  handleOnDeleteContribution() {
    const { onDeleteContribution } = this.props
    onDeleteContribution()
    this.setState({ isFormVisible: false })
  }

  render() {
    const {
      classes,
      ownContribution,
      isTimeActive,
      isBudgetActive,
      isOtherActive,
      contributors,
      currencySymbol,
      onTimeChange,
      onBudgetChange,
      onOtherChange,
      userEmail,
    } = this.props
    const { isFormVisible, errors } = this.state

    const { totalTime, totalBudget } = contributors.reduce(
      (acc, contributor) => ({
        totalTime: acc.totalTime + Number(contributor.time),
        totalBudget: acc.totalBudget + Number(contributor.budget),
      }),
      { totalTime: 0, totalBudget: 0 },
    )

    const defaultOwnContribution = contributors.find(cont => cont.userId === userEmail) || {
      time: '',
      budget: '',
      other: '',
    }
    const isEditing =
      defaultOwnContribution.time || defaultOwnContribution.budget || defaultOwnContribution.other
    return (
      <div className={classes.root}>
        <h2 className={classes.titleSection}>
          <span className="icon-working" />
          {i18n.t('EXPLORE_IDEA.CONTRIBUTE.CONTRIBUTE_TO_MAKE_IT_HAPPEN')}
        </h2>
        {contributors.length > 0 && (
          <div className={classes.contributorsHeader}>
            <div>{`${contributors.length} ${i18n.t('EXPLORE_IDEA.CONTRIBUTE.CONTRIBUTORS')}`}</div>
            <div className={classes.yourContribution}>
              {isTimeActive && (
                <div className={clsx(classes.textValueElement, classes.noBorder)}>
                  <div>
                    <span>{totalTime}</span>
                    <span>h</span>
                  </div>
                  <span>{i18n.t('EXPLORE_IDEA.CONTRIBUTE.HOURS')}</span>
                </div>
              )}
              {isBudgetActive && (
                <div className={clsx(classes.textValueElement, classes.noBorder)}>
                  <div>
                    <span>
                      <NxtContributionCurrency>{totalBudget}</NxtContributionCurrency>
                    </span>
                  </div>
                  <span>{i18n.t('EXPLORE_IDEA.CONTRIBUTE.CASH')}</span>
                </div>
              )}
            </div>
          </div>
        )}
        <ContributorsList contributors={contributors} />
        <Paper className={classes.formSection}>
          {!isFormVisible && (
            <Fragment>
              {isEditing ? (
                <OwnContribution
                  isTimeActive={isTimeActive}
                  isBudgetActive={isBudgetActive}
                  isOtherActive={isOtherActive}
                  contribution={defaultOwnContribution}
                />
              ) : (
                <Typography>{i18n.t('EXPLORE_IDEA.CONTRIBUTE.BE_THE_FIRST')}</Typography>
              )}
              <Button
                id="add-edit-contribution"
                variant="contained"
                color="primary"
                onClick={() => this.setState({ isFormVisible: true })}
              >
                {isEditing
                  ? i18n.t('EXPLORE_IDEA.CONTRIBUTE.EDIT_YOUR_CONTRIBUTION')
                  : i18n.t('EXPLORE_IDEA.CONTRIBUTE.ADD_YOUR_CONTRIBUTION_BTN')}
              </Button>
            </Fragment>
          )}
          {isFormVisible && (
            <ContributionForm
              timeValue={ownContribution.time}
              budgetValue={ownContribution.budget}
              otherValue={ownContribution.other}
              onTimeChange={onTimeChange}
              onBudgetChange={onBudgetChange}
              onOtherChange={onOtherChange}
              defaultTime={defaultOwnContribution.time}
              defaultBudget={defaultOwnContribution.budget}
              defaultOther={defaultOwnContribution.other}
              onClose={() => this.setState({ isFormVisible: false })}
              currencySymbol={currencySymbol}
              isEditing={isEditing}
              isBudgetActive={isBudgetActive}
              isTimeActive={isTimeActive}
              isOtherActive={isOtherActive}
              onSaveContribution={this.handleOnSaveContribution}
              onDeleteContribution={this.handleOnDeleteContribution}
              errors={errors}
              canMakeItReal={!this.isMakeItRealDisable()}
            />
          )}
        </Paper>
      </div>
    )
  }
}

ContributionSection.defaultProps = {
  currencySymbol: '$',
  isTimeActive: false,
  isBudgetActive: false,
  isOtherActive: false,
}

ContributionSection.propTypes = {
  contributors: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      photoSrc: PropTypes.string,
    }),
  ).isRequired,
  ownContribution: PropTypes.shape({
    time: PropTypes.string,
    budget: PropTypes.string,
    other: PropTypes.string,
  }).isRequired,
  onTimeChange: PropTypes.func.isRequired,
  onBudgetChange: PropTypes.func.isRequired,
  onOtherChange: PropTypes.func.isRequired,
  userEmail: PropTypes.string.isRequired,
  onSaveContribution: PropTypes.func.isRequired,
  onDeleteContribution: PropTypes.func.isRequired,
  currencySymbol: PropTypes.string,
  isTimeActive: PropTypes.bool,
  isBudgetActive: PropTypes.bool,
  isOtherActive: PropTypes.bool,
}

export default withStyles(styles)(ContributionSection)
