import { createStyles } from '@material-ui/core'

const styles = () =>
  createStyles({
    container:{
        fontFamily: 'VodafoneRg-Regular',
        fontSize: '18px',
        border: '1px solid #CCCCCC',
        borderRadius: '20px',
        // maxWidth: '478px',
        height: '290px',
        backgroundColor: '#FFFFFF'
    },
    titles:{
        color: '#646464',
        paddingTop: '24px',
        display: 'flex', 
        flexDirection: 'row',

    },
    detailsContainer: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      textAlign: 'center',
      paddingBottom: '15px',
      flex: 1,
    },
    header: {
      fontSize: '18px',
      marginBottom: '16px'
    },
    icon: {
      width: '44px',
      marginBottom: '8px'
    },
    big_number: {
      fontFamily: 'VodafoneRg-Bold',
      fontSize: '32px',
      color: '#6D45FF',
      marginBottom: '4px'
    },
    progressBar: {
      width: '90%',
      height: '5px',
      backgroundColor: '#D8D8D8'
    },
    submittedBy:{
      fontSize: '16px',
      color: '#666666',
      paddingLeft: '5%',
      paddingTop: '16px'
    },
    bold: {
      fontSize: '18px',
      color: '#333333',
      fontFamily: 'VodafoneRg-Bold'
    },
    investorsModal: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 400,
      height: 600,
    },
    btnBlank:{
      backgroundColor:'transparent',
      border:'0',
      padding:'0',
      boxShadow:'none',
      fontSize: '18px',
      fontFamily: 'VodafoneRg-Bold',
      cursor: 'pointer',
      color:'#e60001',
        borderBottom:'1px solid #e60001',
    }
  })

export default styles
