import React, { useState } from 'react'
import * as PropTypes from 'prop-types'
import * as kpiTypes from 'common_constants/Kpis'
import i18n from 'support/i18n'
import { Button, ButtonGroup } from '@material-ui/core'

const KpisFilter = props => {
  const { applyFilter, resetFilter } = props
  const [buttonActive, setButtonActive] = useState({
    all: true,
    qualitative: false,
    quantitative: false,
  })

  const onAllClick = () => {
    resetFilter()
    setButtonActive({
      all: true,
      qualitative: false,
      quantitative: false,
    })
  }
  const onQualitativeClick = () => {
    applyFilter(kpiTypes.QUALITATIVE)
    setButtonActive({
      all: false,
      qualitative: true,
      quantitative: false,
    })
  }
  const onQuantitativeClick = () => {
    applyFilter(kpiTypes.QUANTITATIVE)
    setButtonActive({
      all: false,
      qualitative: false,
      quantitative: true,
    })
  }

  return (
    <div>
      <ButtonGroup variant="contained" aria-label="kpis filter">
        <Button color={buttonActive.all ? 'primary' : ''} onClick={onAllClick}>
          {i18n.t('ALL')}
        </Button>
        <Button color={buttonActive.qualitative ? 'primary' : ''} onClick={onQualitativeClick}>
          {i18n.t('EXPLORE_IDEA.QUALITATIVE')}
        </Button>
        <Button color={buttonActive.quantitative ? 'primary' : ''} onClick={onQuantitativeClick}>
          {i18n.t('EXPLORE_IDEA.QUANTITATIVE')}
        </Button>
      </ButtonGroup>
    </div>
  )
}

KpisFilter.propTypes = {
  applyFilter: PropTypes.func.isRequired,
  resetFilter: PropTypes.func.isRequired,
}

export default KpisFilter
