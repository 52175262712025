import storageSession from 'redux-persist/es/storage'

const persistorNamePrefix = 'public'

export function createPersistReducerConfig(key, whitelist, skipPrefix = false) {
  return {
    timeout: 30000,
    key: `${skipPrefix ? '' : persistorNamePrefix}${key}`,
    storage: storageSession,
    whitelist,
  }
}
