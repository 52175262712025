import { connect } from 'react-redux'
import { getActiveChallenges } from 'services/challenges'
import { putDraftChallenges } from 'services/challenge_steps'
import Navigation from 'support/navigation/index'
import AnalyticsService from 'support/analytics'
import View from './View'

const mapStateToProps = state => {
  return {
    draft_challenges: state.data.ChallengeDraft,
    challenges: state.data.challenge.challenges,
    isAdmin: state.data.user.admin,
    isInnovator: state.data.user.isInnovator,
    localAdmin: state.data.user.localAdmin,
    canProposeChallenge:
      !state.data.user.admin &&
      !state.data.user.isInnovator &&
      !state.data.user.localAdmin &&
      state.data.domain.domainConfiguration.challengeProposal,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getActiveChallenges: () => dispatch(getActiveChallenges()),
    getFilteredChallenges: status => {
      dispatch(getActiveChallenges(status))
      AnalyticsService.trackEvent({
        category: 'swiper',
        action: 'click',
        label: 'apply new filter',
      })
    },
    navigateToChallengeDetail: id => dispatch(Navigation.navigateToChallengeDetail(id)),
    navigateToNewChallenge: () => dispatch(Navigation.navigateToChallengeNew()),
    navigateToExplore: () => dispatch(Navigation.navigateToExplore()),
    navigateToProposeChallenge: () => dispatch(Navigation.navigateToProposeChallenge()),
    navigateToNewIdea: challengeId => dispatch(Navigation.navigateToNewIdea(challengeId)),
    navigateToUserProfile: email => {
      dispatch(Navigation.navigateToUserProfile(email))
    },
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(View)
