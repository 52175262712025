import { Fragment } from 'react'
import { ClickAwayListener, Container } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import React, { useEffect, useState } from 'react'
import TextEditor from 'components/text_editor/View'
import warning_ico from 'img/warning.png'
import ChallengePreview from 'components_newui/challenge_summary_preview/View'
import i18n from 'support/i18n'
import VideoForm from 'components_newui/new_ui_video_form/View'
import Breadcrumbs from 'components_newui/pageBreadcrumbsMenu/View'
import TextInputOneLine from '../../components_newui/TextInputOneLine/View'
import navigation from '../../support/navigation'
import ImageUploader from '../../components_newui/new_ui_image_uploader/View'
import styles from './View.Style'
import Button from './Button'


const ProposeChallenge = (props) => {

    const {
        classes,
        draftChallenges,
        initializeChallenge,
        challengeDescription,
        onTitleChange,
        onWhyImportantChange,
        onDescriptionChange,
        onSelectImageMain,
        onSelectMainImage,
        deleteImage,
        navigateToChallenges,
        submitDraft,
        postProposeChallengeMethod,
        imagesLength,
        externalVideoURL,
        onUploadExternalVideo,
        onRemoveExternalVideo,
    } = props

    const { title, whyImportant, description, images, mainImageValue } = challengeDescription
    const [showPreview, setShowPreview] = useState(false)
    const [externalVideo, setExternalVideo] = useState(undefined)
    const [fileVideo, setFileVideo] = useState(undefined)

    const [titleError, setTitleError] = useState("")
    const [imageError, setimageError] = useState("")
    const [challengeTitle, setChallengeTitle] = useState("")
    const [validChallenge, setValidChallenge] = useState(true)
    const [validWhyImportant, setValidWhyImportant] = useState(true)
    const [validChallengeDescription, setValidChallengeDescription] = useState(true)

    const [descriptionError, setdescriptionError] = useState("")


    useEffect(() => {
        initializeChallenge()
    }, [])


    const pageHierarchy = [
        {string: "Home", navigate: ()=>navigation.navigateToExplore()}, 
        { string: "Propose challenge", navigate: () =>{}}
    ]


    const postProposeChallengeMethodFn = () => {
        // console.log('in post')
        // submitDraftSupportLaunchChallenge()
        postProposeChallengeMethod()
    }


    // validator

    const validateChallengeFormData = () => {
        // Add data validation here
        let isValid = true
        // const hypenRegex =  new RegExp(/[-!$%^&*()+|~=`{}\[\]:\/;<>?,.@#]/)
        const hypenRegex =  new RegExp(/[-!$%^*+|~=`{}\[\]:\/;<>?.@#]/)
        if (!title || hypenRegex.test(title)) {
            isValid = false;
            setTitleError(i18n.t('IDEA.TITLE_ERROR'))
            setValidChallenge(false)
        }
        if (!description) {
            isValid = false;
            setdescriptionError(i18n.t('IDEA.DESC_ERROR'))
            setValidChallengeDescription(false)
        }
        if (whyImportant === "") {
            isValid = false;
            setdescriptionError(i18n.t('IDEA.DESC_ERROR'))
            setValidWhyImportant(false)
        }
        if (images.length === 0) {
            isValid = false;
            setimageError(i18n.t('IDEA.IMAGE_ERROR'))
        }

        return isValid
    }


    // on save draft button
    const submitChallengeDraft = () => {
        if (validateChallengeFormData()) {
            submitDraft()
        }
    }
    
    const reDirectToDraftChallenge = () => {
        navigation.navigateToChallengeDraft()
    }

    const CompoentImageUpload = () =>{
        return(
            <ImageUploader
            subtitle='Browse image (Max 5)'
            imageUploadError={""}
            onSelectImage={onSelectImageMain}
            deleteImage={deleteImage}
            images={images}
            mainImageValue={mainImageValue}
            canChooseMainImage
            UploadLimit={5}
            imagesLength={imagesLength}
        />
        )
    }

    return (
        <Fragment>
            <Breadcrumbs crumbs={pageHierarchy} />
            <Container>
                <main className={classes.background}>
                  
                    <div className={classes.content_container}>
                        <div className={classes.upper_background}>
                            <p className={classes.title}>Propose a Challenge</p>
                            {/* <p className={classes.invested_ideas_number} onClick={reDirectToDraftChallenge}>Draft challenges ({
                                //draftChallenges[0].challengeDescription && draftChallenges[0].challengeDescription.title !== '' ?
                                //  draftChallenges.length : '0'

                            })</p> */}
                        </div>
                        <div className={classes.lower_background}>
                            <div className={classes.create_idea_box}>
                                <div className={classes.create_title}>Propose a Challenge</div>
                                <div className={classes.create_subtitle}>Let's throw an interesting challenge to see what innovative ideas we can come up with</div>
                                <p className={classes.idea_title_in}>Challenge title <span className={classes.astrickColor}>*</span></p>
                                <TextInputOneLine
                                    width={780}
                                    placeholder='Enter challenge title'
                                    char_limit={50}
                                    value={title}
                                    valid={validChallenge}
                                    handleChange={event => { const value = event.target.value; onTitleChange(value); setValidChallenge(value.length > 0 ? true : false) }}
                                />
                                <div className={classes.idea_title_char_limit}>
                                    <span className={classes.idea_title_chars}>{title.length}</span>/50
                                </div>
                                {titleError &&  <p className={classes.error_message}>{titleError}</p>}

                                <div className={classes.secondary_header}>Upload image/video <sup className={classes.astrickColor}>*</sup></div>
                                <div className={classes.smaller_subtitle}>At least one is mandatory</div>
                                <div style={{ display: "flex", alignItems: 'top', paddingBottom: '50px' }}>
                                   <CompoentImageUpload />
                                    <div className={classes.vertical_line} />
                                    <VideoForm
                                        onExternalVideoError={e => {}}
                                        externalVideoSrc={externalVideoURL}
                                        onChangeExternalVideo={onUploadExternalVideo}
                                        onSelectFileVideo={() => {}}
                                        onRemoveFileVideo={onRemoveExternalVideo}
                                        isVideoLoading={false}
                                        isVideoReady={false}
                                        isVideoRemoving={false}
                                        hasVideoError={false}
                                    />
                                </div>
                                <span className={classes.error_message}>{imageError}</span>
                                <div className={classes.horizontal_line} />
                                <div className={classes.secondary_header} style={{ marginBottom: '16px' }}>
                                    Challenge description <span className={classes.astrickColor}>*</span>
                                </div>

                                <div className={classes.smaller_subtitle}>Please describe the problem statement you wish to solve.</div>

                                <div className={`${classes.inputContainer} ${!validChallengeDescription && classes.invalid} `}>
                                    <TextEditor
                                        value={description}
                                        onTextChange={e => {
                                            onDescriptionChange(e)
                                            setValidChallengeDescription(e.length > 0 ? true : false)
                                            setdescriptionError('');
                                        }
                                        }
                                    />
                                    {!validChallengeDescription && <img src={warning_ico} className={classes.warning} alt='warning' />}
                                </div>
                                {descriptionError &&  <p className={classes.error_message}>{descriptionError}</p>}
                                <div className={classes.secondary_header} style={{ marginBottom: '16px' }}>Why is this important? <span className={classes.astrickColor}>*</span></div>
                                <div className={classes.smaller_subtitle}>Explain why your colleagues should publish solutions for this challenge.</div>

                                <div className={`${classes.inputContainer} ${!validChallengeDescription && classes.invalid} `}>
                                    <TextEditor
                                        value={whyImportant}
                                        onTextChange={e => {
                                            onWhyImportantChange(e)
                                            setValidWhyImportant(e.length > 0 ? true : false)
                                            setdescriptionError('');
                                        }}
                                    />
                                    {!validWhyImportant && <img src={warning_ico} className={classes.warning} alt='warning' />}
                                </div>
                                {descriptionError &&  <p className={classes.error_message}>{descriptionError}</p>}

                                <div className={classes.horizontal_line} style={{ marginTop: '32px', marginBottom: '32px' }} />

                                <div style={{ display: 'flex', paddingBottom: '48px' }}>
                                    <Button text='Cancel' clickCallBack={navigateToChallenges} />

                                    <div style={{ marginLeft: 'auto', display: 'flex' }}>
                                        {/* <Button text='Save as draft' clickCallBack={e => submitChallengeDraft(e)} /> */}
                                        <div style={{ marginLeft: '20px' }}>
                                            <Button width={177} text='Preview and submit' clickCallBack={e => validateChallengeFormData() ? setShowPreview(true) : setShowPreview(false)} />
                                        </div>
                                    </div>
                                </div>

                                {showPreview &&
                                    <Fragment>
                                        {/* <div className={classes.faded_out} />

                                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                                            <ClickAwayListener onClickAway={() => setShowPreview(false)}>
                                                <div className={classes.idea_preview}> */}
                                                    <ChallengePreview
                                                        showPreview={showPreview}
                                                        setShowPreview={setShowPreview}
                                                        // csrfToken={csrf}
                                                        handleCancleClick={() => setShowPreview(false)}
                                                        handleSubmitClick={() => postProposeChallengeMethodFn()}
                                                        title={title}
                                                        images={images}
                                                        video={ externalVideoURL}
                                                        description={description}
                                                        whyImportant={whyImportant}
                                                    />
                                                {/* </div>

                                            </ClickAwayListener>
                                        </div> */}
                                    </Fragment>
                                }

                            </div>
                        </div>
                    </div>
                </main>
            </Container>
        </Fragment>

    )
}

export default withStyles(styles)(ProposeChallenge)