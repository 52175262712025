import React from 'react'
import * as PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import { Button, Paper, CircularProgress } from '@material-ui/core'
import i18n from 'support/i18n'
import styles from './View.Style'

const AcceptRejectSection = props => {
  const {
    classes,
    canAcceptOrDecline,
    onAcceptClick,
    onDeclineClick,
    onFeedbackClick,
    message,
    isLoading,
    acceptButtonLabel,
    declineButtonLabel,
    feedbackButtonLabel,
  } = props

  return (
    <Paper className={classes.root}>
      <span>{message}</span>
      {canAcceptOrDecline && (
        <div className={classes.buttonsContainer}>
          <Button variant="contained" color="primary" onClick={onAcceptClick} disabled={isLoading}>
            {acceptButtonLabel}
            {isLoading && (
              <CircularProgress size={24} className={classes.buttonProgress} color="primary" />
            )}
          </Button>
          <Button color="primary" onClick={onDeclineClick} disabled={isLoading}>
            {declineButtonLabel}
            {isLoading && (
              <CircularProgress size={24} className={classes.buttonProgress} color="primary" />
            )}
          </Button>
          <Button color="primary" onClick={onFeedbackClick} disabled={isLoading}>
            {feedbackButtonLabel}
            {isLoading && (
              <CircularProgress size={24} className={classes.buttonProgress} color="primary" />
            )}
          </Button>
        </div>
      )}
    </Paper>
  )
}

AcceptRejectSection.defaultProps = {
  canAcceptOrDecline: true,
  acceptButtonLabel: i18n.t('VALIDATE'),
  declineButtonLabel: i18n.t('REJECT'),
}

AcceptRejectSection.propTypes = {
  acceptButtonLabel: PropTypes.string,
  declineButtonLabel: PropTypes.string,
  message: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  canAcceptOrDecline: PropTypes.bool,
  onAcceptClick: PropTypes.func.isRequired,
  onDeclineClick: PropTypes.func.isRequired,
}

export default withStyles(styles)(AcceptRejectSection)
