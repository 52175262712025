import { HIDE_DIALOG, HIDE_SNACKBAR, SHOW_DIALOG, SHOW_SNACKBAR } from './ModalActionConstants'

const initialState = {
  showedDialogs: 0,
  showSnackbar: false,
}

const modalAlertReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_DIALOG:
      return { ...state, showedDialogs: state.showedDialogs + 1 }

    case HIDE_DIALOG:
      return { ...state, showedDialogs: state.showedDialogs === 0 ? 0 : state.showedDialogs - 1 }

    case SHOW_SNACKBAR:
      return { ...state, showSnackbar: true }

    case HIDE_SNACKBAR:
      return { ...state, showSnackbar: false }

    default:
      return state
  }
}

export default modalAlertReducer
