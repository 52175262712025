import createStyles from '@material-ui/core/styles/createStyles'

const styles = theme =>
  createStyles({
    
    documentLabel:{
        fontSize: '16px',
        cursor: 'pointer'
    },
    documentUpload:{
        display:'inline-flex',
        flexDirection: 'column',
        paddingRight: '20px',
        alignItems: 'center',
        margin: '0px',
        '& img' :{
            width: '40px',
        },
    },
    docsAlign:{
      textAlign:'center',
      textDecoration:'none'
    }
  })

export default styles
