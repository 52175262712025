const styles = theme => ({
  root: {
    marginTop: theme.spacing(4.25),
    padding: theme.spacing(3),
    // backgroundColor: '#f5f5f5',
  },
  mainTitle: {
    fontFamily:theme.fontFamily.fontFamilyBold,
    fontSize: theme.spacing(2.25),
    margin: 0,
  },
  secondaryTitle: {
    fontSize: theme.spacing(1.75),
    margin: 0,
    fontFamily:theme.fontFamily.fontFamilyRegular,
  },
})

export default styles
