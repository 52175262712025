
import React, { useEffect, useState } from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import { Container } from '@material-ui/core'
import Breadcrumbs from 'components_newui/pageBreadcrumbsMenu/View'
import Wraper from '../../components_newui/Wraper/View'
import CustomButtom from '../../components_newui/customButton/View'
import { useForm } from "react-hook-form"
import styles from './View.Style'
import contactImg from 'img/contact_us.png'
import clsx from 'clsx'
import navigation from 'support/navigation'


const ContactUs = (props) => {

  const {classes,contactOnSubmit,userEmail,navigateHome} = props
  const {register,reset,formState:{errors,isDirty,isValid,isSubmitSuccessful}, handleSubmit} = useForm()
  // const [result,setResult] = useState()
  const [loading,setLoading] =  useState(false)
  
  React.useEffect(() => {
    if (!isSubmitSuccessful) { return }
      reset({ subject: "",msg:"",email:"" })
    
  }, [isSubmitSuccessful])

  const onSubmitContactUs = async (getDataOnSubmited)=> {
        setLoading(true)
      try {
      // await setResult(getDataOnSubmited)
      await contactOnSubmit(getDataOnSubmited)
      }
      catch(err){
        console.log('contact us...',err)
      }
      finally{
        setLoading(false)
      }  
  }

  const pageHierarchy = [
    {string: "Home", navigate: ()=>navigation.navigateToExplore()}, 
    {string: "Contact Us", navigate: ()=>{}}
  ] 

  
  return (
    <>
   
    <section className={classes.contactus}>
    <Breadcrumbs crumbs={pageHierarchy} />       
      <Container>

        <Wraper>
          <div className={classes.content}>
            <h1 className={classes.heading}>Contact us</h1>
            <p className={classes.contentPara}>Our most commonly found queries can be found within our FAQ – reach out for further questions or if you want to become a part of the NOVA community and to help organize events. We are looking forward to supporting you with your innovation journey!   </p>
          </div>
          <form onSubmit={handleSubmit(onSubmitContactUs)}>
          <div className={ clsx(classes.block2Grid,classes.addstyle) }>
              <div className={classes.imgblock}>
                <img src={contactImg} alt="img block"  />
              </div>
              <div className={classes.formBlock}>
                  <div className={classes.fieldBlock} >
                      <label>Add your email address <span className={classes.errorMessage}>*</span></label>
                      <input 
                        type='text' 
                        name='email' 
                        // defaultValue={userEmail}
                        placeholder='Email address' 
                        aria-invalid={errors.email ? "true" : "false"}  
                        {...register('email' , {
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: "invalid email address"
                          }
                        })} 
                      />
                      {errors.email && (
                        <p className={classes.errorMessage} role="alert">your email is required</p>
                      )}
                  </div>
                  <div className={classes.fieldBlock}>
                      <label>Request topic<span className={classes.errorMessage}>*</span></label>
                      <input 
                        type='text' 
                        name='subject' 
                        placeholder='Whats this about?' 
                        aria-invalid={errors.subject ? "true" : "false"}  
                        {...register('subject',{required: true})} 
                      />
                      {errors.subject && (
                        <p className={classes.errorMessage} role="alert">your Request topic is required</p>
                      )}
                  </div>
                  <div className={classes.fieldBlock}>
                      <label>Message</label>
                      <textarea  
                        name='msg' 
                        placeholder='Go ahead... we are listening...' 
                        {...register('msg')} 
                      />
                  </div>
              </div>
          </div>
          <div className={classes.block2Grid}>
            <div></div>
            <div className={classes.block2Grid}>
              <CustomButtom 
                text='Cancel'
                action={navigateHome}
                bgColor='transparent'
                Border="1px solid #fbfbfb"
              />
              <CustomButtom 
                type='submit'
                text='Submit'
                disabled={!isDirty || !isValid || loading}
              />
            </div>
          </div>
          </form>
        </Wraper>
      </Container>
    </section>
    
  </>)
}

export default withStyles(styles)(ContactUs)