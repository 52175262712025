import React, { Fragment, useState } from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import TextInputMultiLine from 'components_newui/TextInputMultiLine/View'
import * as PropTypes from 'prop-types'

const DescriptionBlock = props => {
    const {
        classes,
        description,
        value,
        setValue
    } = props
    const [valid, setValid] = useState(true)
    return(
        <div className={classes.container}>
            <div className={classes.text}>{description} <sup style={{color: '#E60000'}}>*</sup></div>
            <TextInputMultiLine 
                width={380}
                value={value}
                row_no={3}
                char_limit={150}
                valid={valid}
                handleChange={e => {
                    const value = e.target.value; 
                    setValue(value)
                    setValid(value.length > 0 && value.length < 151)
                }}
            />
            <div className={classes.char_limit}>
                <span className={classes.chars}>{value.length}</span>/150
            </div>
        </div>
    )
}

const styles = theme => ({
    container: {
        width:'380px',
    },
    text: {
        fontFamily: 'VodafoneRg-Regular',
        color: '#333333',
        fontSize: '18px',
        marginBottom: '8px',
        whiteSpace: 'nowrap',
    },
    char_limit: {
        fontFamily: "VodafoneRg-Regular",
        fontSize: "14px",
        color: "#2A2A2A",
        float: "right",
        paddingTop: "4px",
        paddingRight: "15px",
    },
    chars: {
        fontFamily: "VodafoneRg-Bold",
        color: "#6551FF",
        fontSize: "16px",
    },
})
  
export default withStyles(styles)(DescriptionBlock)


DescriptionBlock.defaultProps = {
    
}

DescriptionBlock.propTypes = {
    description: PropTypes.number,
    value: PropTypes.string,
    setValue: PropTypes.func.isRequired,
}