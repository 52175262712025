


const styles = theme =>({

    caseStudyBlock:{
       
        marginBottom:' 20px',
        background: '#fff',
        '&:nth-child(3n+1)':{
            gridColumn: 'span 2',
           
            '@media (min-width:900px)':{
                display:'flex',
            } 
        }
    },
    caseCardNumber:{
        textAlign:'center',
        boxSizing: 'border-box',
        position:'absolute',
        top:'0',
        right:'0',
        zIndex:'1',
        '&:before' :{
            content:" no-open-quote",
            position:'absolute',
            top: '-7px',
            right: '-21px',
            width: '70px',
            height: '35px',
            transform: 'rotate(45deg)',
            backgroundColor: '#0096e2',
            zIndex:'-1',
        },
           
    },
    caseContentNumber:{
        margin:0,
        padding:'4px',
        fontSize:theme.fontSize.fontsize13,
        fontFamily:theme.fontFamily.fontFamilyBold,
        color:'#fff',
        
    },
    img:{
        width:'100%',
        display:'block'
    },
    
    caseStudyImgBlock:{
       flex:1,
       position:'relative',
       overflow:'hidden',
       borderRadius:'10px'
    },
    caseStudyContentBlock:{
        padding:'20px',
        flex:1,
        '& a':{
            textDecoration:'none'
        }
    },
    
    caseStudyContentTitle:{
        display:'flex',
        flexWrap:'wrap',
        fontSize:theme.fontSize.fontsize24,
        fontFamily:theme.fontFamily.fontFamilyRegular,
        fontWeight:400,
        lineHeight:1,
        color:theme.palette.Text.TextBlack33,
        margin:'0px  0px 16px 0px',
        '@media (min-width:900px)':{
            fontSize:theme.fontSize.fontsize32,
        }
    },
    caseStudyContentSubTitle:{
        fontSize:theme.fontSize.fontsize20,
        fontFamily:theme.fontFamily.fontFamilyRegular,
        fontWeight:400,
        lineHeight:1,
        color:theme.palette.Text.TextBlack33,
        margin:'0 0 8px',
    },
    caseStudyContent:{
        fontSize:theme.fontSize.fontsize16,
        fontFamily:theme.fontFamily.fontFamilyRegular,
        fontWeight:400,
        lineHeight:1.5,
        color:theme.palette.Text.TextBlack33
    }
})

export default styles