import React from 'react'
import { connect } from 'react-redux'
import { createIdea } from 'services/create_idea'
import AlertModalView from 'support/popup_dialogs/views/components/alert_modal_view/AlertModalView'
import { hideDialog } from 'support/popup_dialogs/modal_alert_controller/ModalActions'
import { showPopupDialog } from 'support/popup_dialogs'
import i18n from 'support/i18n'
import ErrorSubmitModal from './ErrorSubmitContainer'
import SuccessSubmitModal from './SucessSubmitContainer'
import navigation from '../../../../support/navigation'

const mapStateToProps = () => ({
  title: i18n.t('CONFIRMATION_MESSAGE'),
  description: i18n.t('EXPLORE_IDEA.PUBLISH_CONFIRM'),
  mainButtonText: i18n.t('ACCEPT'),
  cancelable: true,
  cancelButtonText: i18n.t('CANCEL'),
})

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    
    mainButtonAction: () => {
      const onSuccess = response => {
        // console.log("res..idea post ===>",response)
        showPopupDialog(<SuccessSubmitModal urlId={response.id}  id={response.id} />, dispatch)
      
      }
      const onFailure = ({ data }) => {
        const messageTag =
          data && data.error && data.error.error
            ? `BACKEND_ERRORS.${data.error.error}`
            : 'BACKEND_ERRORS.UNEXPECTED'
        showPopupDialog(<ErrorSubmitModal description={i18n.t(messageTag)} />, dispatch)
      }
      dispatch(createIdea(ownProps.formData, onSuccess, onFailure))
      dispatch(hideDialog())
    },
    cancelButtonAction: () => dispatch(hideDialog()),
    
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AlertModalView)
