import { withStyles } from '@material-ui/core/styles'
import * as PropTypes from 'prop-types'
import {Link} from 'react-router-dom'
import styles from './View.Style'
import React, { Fragment, useState } from 'react'
import star_img from '../../img/star_icon.png'
import corner_plus from '../../img/corner-plus.png'
import corner_tick from '../../img/corner-tick.png'
import default_img from 'img/hero_image.png'
import chevronright from 'img/chevron-right.png'
import { format } from 'date-fns'
import { getChallengeStatus } from '../../services/GetStatusStyle'
import * as pages from '../../common_constants/Pages'
import NavigationService from '../../support/navigation/NavigationService'

const Challenge_Card = props => {
    const {classes, challenge, onClick, tagged,RadioCheckedValue,taggedIcon,urlID} = props
    const { name, images, desc, proposerName,proposerEmail, ends,starts, status,numIdeas,rewardText, id } = challenge
    // const image = images[0].url
    const image = images && images.map(element => {
        return element.url
    })[0];
    // console.log('challege tagged',tagged)
    // const [isTagged, setIsTagged] = useState(tagged)
    let startDate;
    // console.log("start==> redirect",starts,numIdeas)
    if(starts !==null  || starts !== undefined){
        let startDateConvert = new Date(starts)
         startDate = format(startDateConvert,'dd MMM yy')
    }

    const { name: statusStr, color: statusCol } = getChallengeStatus(status)

    let trimmed_name = name
    if (trimmed_name.length > 50) trimmed_name = `${trimmed_name.substring(0,50)}...`
    let trimmed_desc = desc
    if (trimmed_desc.length > 65) trimmed_desc = `${trimmed_desc.substring(0,65)}...`
    // console.log('tag===>',challenge,tagged,tagging,props)

    // const onClickHandler = e => {
    //     onClick(e, challenge, tagging ? !isTagged : null)
    //     tagging && (setIsTagged(prev => !prev))
    // }

    const getDayStr = day => {
        const chars = ['th', 'st', 'nd', 'rd']
        if ((3 <day && day < 21) || (day % 10 > 3)) return `${day}${chars[0]} `
        else return `${day}${chars[day%10]} `
    }


    const expiry_date = new Date(ends)
    const current_date = new Date()
    const expired = expiry_date <= current_date
    const diffDays = Math.ceil( Math.abs(current_date - expiry_date) / (1000 * 60 * 60 * 24)) 
    let expiry_date_str
    if (expired) expiry_date_str = `${getDayStr(expiry_date.toLocaleDateString("en-US", {day: "numeric"}))}` +
                                    `${expiry_date.toLocaleDateString("en-US", {month: "short"})} ` +
                                    `${expiry_date.toLocaleDateString("en-US", {year: "numeric"})} `
    
          
const proposerNameNotAvailable = proposerName === '' ? proposerEmail && proposerEmail.substring(0, proposerEmail.lastIndexOf("@")) : proposerName  
    
const ROUTES = NavigationService.getRoutes()
let detailChallengePath = ROUTES[pages.DETAIL_CHALLENGE].pathWithOutID ;


    return(
        <div className={ classes.container} >
            {props.myChallenge && <div className={classes.draftstatus} style={{ backgroundColor: statusCol }}>{statusStr}</div>}
            {  RadioCheckedValue ? null   : taggedIcon && <img className={classes.corner_img} src={corner_plus}/> }
            {/* {tagged && <img className={ classes.corner_img} src={tagged ? corner_tick : corner_plus}/>} */}
            {/* onClick={onClick} */}
            <Link to={`${detailChallengePath}/${urlID}`} >
            <div className={ RadioCheckedValue ? classes.activeRadioChallenge  + " " + classes.box : classes.box} style={{borderTopLeftRadius: props.myChallenge ? "0px" : null }}  > 
                <div className={classes.left_col}>
                    <img className={classes.challenge_img} src={image}  onError={({currentTarget}) => {
                        currentTarget.onerror = null
                        currentTarget.src = default_img
                    }} />
                    
                {statusStr === 'PROPOSED' ? null : (
                    <div className={`${classes.expiry_box} ${expired && "expired"}`}>
                        <p className={`${classes.expiry_text} ${expired && "expired"}`}>
                            {expired ? "Expired on " : "Expires in "} <span className={`${classes.bold_expiry} ${expired && "expired"}`}>{expired ? expiry_date_str : diffDays}</span> {!expired && ' days'}
                        </p>
                       {
                        props.myChallengeHide ? <></> :
                        <p className={`${classes.expiry_text} ${expired && "expired"}`}>
                            Idea tagged <span className={`${classes.bold_expiry} ${expired && "expired"}`}>{numIdeas}</span>
                        </p>
                        }
                    </div>
                )}

                </div>
                <div className={classes.right_col}>
                <h3 className={classes.challenge_name}>{trimmed_name}</h3>
               {props.myChallengeHide ? <></> : <div className={classes.challenge_author}>Proposed By&nbsp;<span className={classes.author_name}>{proposerNameNotAvailable}</span></div>
               }{
                props.myChallenge &&  <>
               {startDate && <div className={classes.challenge_author}>Submitted on&nbsp;<span className={classes.author_name}>{startDate}</span></div>}
                <div className={classes.rewardwrp}><img src={star_img} className={classes.rewardstar} alt='reward star icon' /> <span className={classes.rewardsText}>{ rewardText ? rewardText : 'Rewards'}  </span> </div>
                </>}
                {
                props.ideaDetailChallenge &&  <>
               {/* {startDate && <div className={classes.challenge_author}>Submitted on&nbsp;<span className={classes.author_name}>{startDate}</span></div>} */}
                <div className={classes.rewardwrp}><img src={star_img} className={classes.rewardstar} alt='reward star icon' /> <span className={classes.rewardsText}>{ rewardText ? rewardText : 'Rewards'}  </span> </div>
                </>}


                <div className={classes.challenge_desc}>{trimmed_desc}</div>
                { taggedIcon ?  <div className={classes.details} /> : <div className={classes.details} >  View details  <img src={chevronright} alt='rightarrow' className={classes.arrowright} />  </div> }
                </div>
            </div>
        </Link>
        </div>
    )
}


Challenge_Card.defaultProps = {
    tagged: false,
    tagging: false,
}

Challenge_Card.propTypes = {
    challenge: PropTypes.object.isRequired,
    tagged: PropTypes.bool,
    tagging: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
}


export default withStyles(styles)(Challenge_Card)