const styles = theme => ({
  imageContainer: {
    position: 'relative',
    width: '97px',
  },
  img: {
    display: 'block',
    overflow: 'hidden',
    width: '97px',
    height: '97px',
    borderRadius: '10px',
  },
  deleteButton: {
    position: 'absolute',
    right: -5,
    top: -5,
    height: '20px',
    width: '20px',
    cursor: 'pointer',
  },
  radioButtonMain: {
    paddingLeft: theme.spacing(1.5),
    "& .MuiIconButton-root":{
      padding:'9px 4px'
    },
    "& .MuiFormControlLabel-label":{
      fontSize:'0.7rem'
    }
  },
  deleteBtn:{
    background:'transparent',
    border:'none',
    padding:'0',
  }
})

export default styles
