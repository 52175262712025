import React, { useState } from 'react'
import * as PropTypes from 'prop-types'
import i18n from 'support/i18n'
import withStyles from '@material-ui/core/styles/withStyles'
import styles from './View.Style'
import search_icon from 'img/search-icon.png'
import close_icon from 'img/cancel-or-no-ideas.png'

const SearchPopup = props => {
  const { classes, search, popular, close } = props
  const [query, setQuery] = useState('')
  return (
    <div className={classes.popup}>
      <img src={close_icon} className={classes.close_icon} onClick={close} />
      <div className={classes.txtsize40} style={{fontSize: '40px'}}>Search</div>
      <div style={{position: 'relative'}}>
        <input 
          className={classes.bar} 
          value={query} 
          onChange={e => setQuery(e.target.value)}
          onKeyDown={e => {
            e.key === 'Enter' && search(query)
          }}/>
        <div 
          className={classes.bar_submit}
          onClick={()=>search(query)}
        >
          <img src={search_icon} className={classes.search_ico} />
        </div>
      </div>

      <div className={classes.txtsize32} style={{fontSize: '32px'}}>Popular searches</div>
      <div>
        {popular.map(term => (
          <span 
            onClick={() => setQuery(term)}
            className={classes.crumb}>
              {term}
          </span>
        ))}
      </div>
    </div>
  )
}

SearchPopup.propTypes = {
  search: PropTypes.func.isRequired,
  popular: PropTypes.array.isRequired,
  close: PropTypes.func.isRequired
}

export default withStyles(styles)(SearchPopup)
