const styles = theme => ({
  avatar: {
    position: 'relative',
    width: '141px',
    margin: theme.spacing(2, 2, 2, 0),
    '& > span': {
      position: 'absolute',
      right: theme.spacing(-1.5),
      top: theme.spacing(-1.5),
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    '& > span:hover': {
      backgroundColor: theme.palette.common.black,
    },
  },
  image: {
    width: '100%',
    height: '141px',
  },
  overlay: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: theme.spacing(0.5),
    color: theme.palette.common.white,
    boxSizing: 'border-box',
    backgroundColor: 'rgba(51, 51, 51, 0.85)',
    fontSize: '0.75rem',
    flexDirection: 'row',
    minHeight: '26%',
    '& > span': {
      backgroundColor: theme.palette.primary.main,
      position: 'absolute',
      padding: theme.spacing(1, 0),
      top: '0',
      left: '0',
      fontSize: '20px',
      height: '35px',
      '&:after': {
        content: '""',
        display: 'block',
        borderLeft: '10px solid transparent',
        paddingTop: '11px',
        borderRight: '10px solid transparent',
        borderBottom: '12px solid #f0f0f0',
      },
    },
  },
  noPadded: {
    paddingLeft: theme.spacing(1),
  },
  padded: {
    paddingLeft: theme.spacing(3),
  },
  nameWrapper: {
    display: 'flex',
    flexDirection: 'column',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    '& > span': {
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
  },
})

export default styles
