import { connect } from 'react-redux'
import View from './View'

const mapStateToProps = state => {
  return {
    defaultColors: state.data.domain.config.defaultColors,
  }
}

export default connect(
  mapStateToProps,
  null,
)(View)
