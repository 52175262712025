import React from 'react'
// import { makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Divider from '@material-ui/core/Divider'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'
import Typography from '@material-ui/core/Typography'
import Dialog from '@material-ui/core/Dialog'
import Slide from '@material-ui/core/Slide'
import { withStyles } from '@material-ui/core/styles'

import PropTypes from 'prop-types'

import SentimentDissatisfiedIcon from '@material-ui/icons/SentimentDissatisfied'
import SentimentSatisfiedAltIcon from '@material-ui/icons/SentimentSatisfiedAltOutlined'
import SentimentVerySatisfiedIcon from '@material-ui/icons/SentimentVerySatisfied'
import Rating from '@material-ui/lab/Rating'

import MuiDialogTitle from '@material-ui/core/DialogTitle'

import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  root1: {
    width: '100%',
    maxWidth: '36ch',
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
  dialogPaper: {
    minHeight: '70vh',
    maxHeight: '30vh',
  },
  dialogPaper2: {
    minHeight: '40vh',
  },
})
const customIcons = {
  1: {
    icon: <SentimentDissatisfiedIcon />,
    label: 'Dissatisfied',
  },
  2: {
    icon: <SentimentSatisfiedAltIcon />,
    label: 'Satisfied',
  },
  3: {
    icon: <SentimentVerySatisfiedIcon />,
    label: 'Very Satisfied',
  },
}
function IconContainer(props) {
  const { value, ...other } = props
  return <span {...other}>{customIcons[value].icon}</span>
}

IconContainer.propTypes = {
  value: PropTypes.number.isRequired,
}
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

function EvaluatorCommentsList(props) {
  const [open, setOpen] = React.useState(true)
  const { expertCommentMail, handleDialog, classes, isAdmin } = props

  // var avg = 0

  const handleClose = () => {
    setOpen(false)
    handleDialog()
  }

  return (
    <Dialog
      fullWidth={true}
      classes={{ paper: isAdmin ? classes.dialogPaper : classes.dialogPaper2 }}
      maxWidth={isAdmin ? 'md' : 'sm'}
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      TransitionComponent={Transition}
    >
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        Previous Comments
      </DialogTitle>
      {expertCommentMail.map((data, index) => {
        return (
          <List>
            <ListItem alignItems="flex-start">
              <ListItemAvatar>
                <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
              </ListItemAvatar>
              <ListItemText
                primary={data.emailIdLoggedInUser}
                secondary={
                  <React.Fragment>
                    <Typography component="span" variant="body2" color="textPrimary">
                      {data.expertComments}
                    </Typography>
                    <div>
                      <Rating name="read-only" value={data.ratings} readOnly />
                    </div>
                  </React.Fragment>
                }
              />
            </ListItem>
            <Divider variant="inset" component="li" />
          </List>
        )
      })}
    </Dialog>
  )
}

export default withStyles(styles)(EvaluatorCommentsList)
