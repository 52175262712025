import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import { FormControlLabel, RadioGroup, TextField, withStyles } from '@material-ui/core'
import i18n from 'support/i18n'
import Button from '@material-ui/core/Button'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import styles from './View.Style'
import NxtRadio from 'components/nxt_radio/View'

class DiscardView extends Component {
  constructor(props) {
    super(props)
    this.state = {
      discardReason: '',
      checkedValue: '',
      
      returnInvestment: false,
    }
    this.handleTextFieldChange = this.handleTextFieldChange.bind(this)
    this.handleOnClick = this.handleOnClick.bind(this)
    this.handleOnCLickOnCheck = this.handleOnCLickOnCheck.bind(this)
  }

  handleTextFieldChange(event) {
    const { value } = event.target
    this.setState({ discardReason: value })
  }

  handleOnClick(discardReason, returnInvestment) {
    const { id, onClick } = this.props
    onClick(id, discardReason, returnInvestment)
  }

  handleOnCLickOnCheck(event) {
    const { value } = event.target
    const { checkedValue } = this.state

    if (checkedValue === value) {
      this.setState({ discardReason: '', checkedValue: '' })
    } else {
      this.setState({ discardReason: value, checkedValue: value })
    }
  }

  render() {
    const { classes, cancelButtonAction, rejectedReasons } = this.props
    const { checkedValue, discardReason, returnInvestment } = this.state

// let discardReasonWithCheckedValue;
// if(discardReason === checkedValue){
//   discardReasonWithCheckedValue = checkedValue
//   console.log('discardReason === checkedValue',discardReasonWithCheckedValue)
// } else if(discardReason !== checkedValue){
//   const textTrim = `${checkedValue} ${ discardReason}`
//   discardReasonWithCheckedValue =  textTrim
//   console.log('discardReason !== checkedValue',textTrim)
// } else  {
//   discardReasonWithCheckedValue = discardReason
//   console.log('else',discardReasonWithCheckedValue)
// }


    return (
      <div className={classes.dialogBox}>
        <DialogTitle id="alert-dialog-title">{i18n.t('CONFIRMATION_MESSAGE')}</DialogTitle>
        <DialogContent>
          <span dangerouslySetInnerHTML={{ __html: i18n.t('EXPLORE_IDEA.DISCARD_IDEA') }} />
          <div>
            <RadioGroup value={checkedValue}>
              {rejectedReasons.data.length > 0 &&
                rejectedReasons.data.map(reason => {
                  return (
                    <FormControlLabel
                      control={<NxtRadio />}
                      onClick={this.handleOnCLickOnCheck}
                      label={
                        <div>
                          <p>{reason.title}</p>
                          <small>{reason.text}</small>
                        </div>
                      }
                      value={`${reason.title}\r${reason.text}`}
                    />
                  )
                })}
            </RadioGroup>
          </div>
          <TextField
            color="primary"
            id="filled-multiline-static"
            fullWidth
            label={i18n.t('IDEA.MESSAGE')}
            multiline
            onChange={this.handleTextFieldChange}
            rows="4"
            margin="normal"
            value={discardReason}
            variant="filled"
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => this.handleOnClick(discardReason, returnInvestment)}
          >
            {i18n.t('ACCEPT')}
          </Button>
          <Button variant="contained" color="secondary" onClick={cancelButtonAction}>
            {i18n.t('CANCEL')}
          </Button>
        </DialogActions>
      </div>
    )
  }
}

DiscardView.defaultProps = {
  id: '',
  rejectedReasons: {},
}

DiscardView.propTypes = {
  id: PropTypes.string,
  rejectedReasons: PropTypes.shape({}),
  onClick: PropTypes.func.isRequired,
  cancelButtonAction: PropTypes.func.isRequired,
}

export default withStyles(styles)(DiscardView)
