import createStyles from '@material-ui/core/styles/createStyles'

const styles = theme =>
  createStyles({
    block:{
      width:'100%',
      '@media(min-width:768px)':{
        display: 'flex', 
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',
        // margin: '0 30px',
        width: '340px',
        pointerEvents: 'none',
      }
    },
    icon: {
      width: '128px',
      margin: '0 auto',
      pointerEvents: 'none',
    },
    title:{
        fontFamily: 'VodafoneRg-Bold',
        fontSize: '24px',
        pointerEvents: 'none',
    },
    description:{
        fontFamily: 'VodafoneRg-Regular',
        fontSize: '16px',
        pointerEvents: 'none',
    }
        
  },)
  

export default styles
