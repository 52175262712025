import React from "react";
import withStyles from '@material-ui/core/styles/withStyles'
import styles from './View.Style'
import investor_img from 'img/Investor@2x.png'
import ideator_img from 'img/Ideator@2x.png'
import contributor_types from "../../contributor_type";
import defaultPhoto from 'img/default-photo.png'

const Contributor = props => {
    const {classes, contributor, type} = props
    const {
        fullname,
        ideas,
        investments,
        amountInvested,
        photo
    } = contributor

    const value = type === contributor_types.IDEATOR ? 
        `${ideas} ideas`
        :
        `${amountInvested} invested`
    const surround_img = type === contributor_types.IDEATOR ? ideator_img : investor_img

    return(
        <div className={classes.container}>
            <div className={classes.circle_cropper}>
                <img className={classes.profile_photo} src={photo || defaultPhoto} onError={({currentTarget}) => {
                    currentTarget.onerror = null
                    currentTarget.src = defaultPhoto
                }} />
            </div>
            <img src={surround_img} className={classes.medal_img} />
            <div className={classes.name}>{fullname}</div>
            <div className={classes.value}>{value}</div>
        </div>
    )
}

export default withStyles(styles)(Contributor)