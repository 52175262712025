const styles = theme => ({
  root: {
    width: 500,
    padding: theme.spacing(2),
    margin: '32px',
  },
  inputContainer: {
    margin: theme.spacing(2, 0),
  },
  subtext:{
    fontSize: '18px',
    marginTop: '16px',
    marginBottom: '8px'
  },
  buttons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
})

export default styles
