import React from 'react'
import { Chart } from 'react-google-charts'

const CommentsPieChart = props => {
  const { title, list, loadPrevComments, loadPrevCommentsNo } = props

  if (list.length > 0) {
    return (
      <Chart
        chartType="PieChart"
        loader={<div>Loading Stats</div>}
        data={list}
        options={{
          //legend: none,
          title: title,
        }}
        rootProps={{ 'data-testid': '1' }}
        chartEvents={[
          {
            callback: ({ chartWrapper, google }) => {
              const chart = chartWrapper.getChart()
              chart.container.addEventListener(
                'click',
                () => loadPrevCommentsNo && loadPrevComments(loadPrevCommentsNo),
              )
            },
            eventName: 'ready',
          },
        ]}
      />
    )
  } else {
    return (
      <div>
        <div>{title}</div>
        <br></br>
        <span>No data to display!</span>
      </div>
    )
  }
}

export default CommentsPieChart
