const styles = theme => ({
  popup: {
    position: 'fixed',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 9000,
    padding: '80px 60px',
    maxWidth:'75%',
    overflowY: 'scroll',
    backgroundColor: '#F6F5FF',
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: 'VodafoneRg-Regular',
    color: '#333333',
    '@media (max-width:1024px)':{
      padding:'20px',
      
    }
  },
  close_icon: {
    position: 'absolute',
    width: '42px',
    top: '10px',
    right: '10px',
    cursor: 'pointer',
    filter: 'brightness(0) saturate(100%)',
    '@media (max-width:1024px)':{
      width:'30px',
    }
  },
  bar: {
    width: '420px',
    backgroundColor: '#FFFFFF',
    borderRadius: '24px',
    border: '1px solid #6551FF',
    padding: '0 70px 0 16px',
    height: '48px',
    fontSize: '20px',
    margin: '32px auto',
    display: 'flex',
    '@media (max-width:1024px)':{
     width:'190px'
      
    }
  },
  bar_submit: {
    position: 'absolute',
    width: '59px',
    backgroundColor: '#6551FF',
    right: 0,
    top: '33px',
    height: '48px',
    borderRadius: '0 24px 24px 0',
    cursor: 'pointer'
  },
  search_ico: {
    filter: 'brightness(0) saturate(100%) invert(99%) sepia(1%) saturate(3%) hue-rotate(60deg) brightness(115%) contrast(100%)',
    width: '30px',
    position: 'absolute',
    top: '8px',
    left: '12px',
  },
  crumb: {
    backgroundColor: '#FFFFFF',
    padding: '0 16px',
    height: '40px',
    borderRadius: '20px',
    fontSize: '16px',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '5px 10px',
    cursor: 'pointer'
  },
  txtsize32:{
    fontSize: '32px',
    '@media (max-width:1024px)':{
      fontSize:'18px'
    }
  },
  txtsize40:{
    fontSize:'40px',
    '@media (max-width:1024px)':{
      fontSize:'18px'
    }
  }

})

export default styles
