import React, { Fragment, useState, useEffect } from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import styles from './View.Style'
import TextEditor from 'components/text_editor/View'
import Button from 'components_newui/button/View'

const JoinTeamDialog = props => {
    const [reason, setReason] = useState('')
    const {
        classes,
        onCancelPopup,
        onRequestJoinIdeaTeam,
        idea_id
    } = props
    return (
        <div className={classes.container}>
            <div className={classes.title}>
                Join the idea team
            </div>
            <div className={classes.subtitle}>
                Explain why you want to be part of the idea team.
            </div>
            <TextEditor
                value={reason}
                onTextChange={e => setReason(e)}
            />
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                <Button
                    text='Cancel'
                    onClick={onCancelPopup}
                    styled={false}
                />
                <Button
                    text='Send Request'
                    onClick={() => onRequestJoinIdeaTeam(idea_id,reason)}
                    styled={true}
                />
            </div>
        </div>
    )
}

export default withStyles(styles)(JoinTeamDialog)