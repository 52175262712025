const styles = theme => ({
  root: {
    cursor: 'pointer',
    display: 'flex',
    height: 'fit-content',
    padding: theme.spacing(2),
    position: 'relative',
    flex: 0.5,
    borderTop: '1px solid #E1E1E1',
    '& > img': {
      width: '88px',
    },
    '&:hover': {
      backgroundColor: '#f6f6f6',
    },
    '&:focus,&:hover,&active': {
      outline: 'unset',
    },
    '& > .icon-tick': {
      position: 'absolute',
      right: 0,
    },
  },
  balanceLabel: {
    color: '#8D8D8D',
  },
  isSelected: {
    backgroundColor: '#f6f6f6',
  },
  amountLabel: {
    color: '#8D8D8D',
    fontSize: '0.875rem',
  },
  amountValue: {
    color: '#666',
    fontSize: '1.125rem',
  },
  userDetailWrapper: {
    padding: theme.spacing(0, 1),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  fullName: {
    fontSize: '1.25rem',
    maxWidth: '10rem',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  fundsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  balanceWrapper: {
    display: 'flex',
    flexDirection: 'column',
    '& > span': {
      fontSize: '0.875rem',
    },
    paddingRight: theme.spacing(1.75),
    borderRight: '1px solid #8d8d8d',
  },
  investedFundsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    '& > span': {
      fontSize: '0.875rem',
    },
    paddingLeft: theme.spacing(1.75),
  },
})

export default styles
