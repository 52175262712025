import { combineReducers } from 'redux'
import ChallengeReducer from './ChallengeReducer'
import DomainReducer from './DomainReducer'
import IdeaReducer from './IdeaReducer'
import UserReducer from './UserReducer'
import CreateIdeaReducer from './CreateIdeaReducer'
import ChallengeSteps from './ChallengeStepsReducer'
import ChallengeDraft from './ChallengeDraftReducer'
import UserProfileReducer from './UserProfileReducer'
import RankingReducer from './RankingReducer'
import HowItWorksReducer from './HowItWorksReducer'
import DashboardReducer from './DashboardReducer'
import MaintenanceReducer from './MaintenanceReducer'
import EvaluationReducer from './EvaluationReducer'
import EvaluationAdminReducer from './EvaluationAdminReducer'
import AboutusReducer from './AboutusReducers'
import NovaResearchReducer from './NovaResearchReducers'

const reducers = combineReducers({
  challenge: ChallengeReducer,
  challengeSteps: ChallengeSteps,
  ChallengeDraft:ChallengeDraft,
  domain: DomainReducer,
  idea: IdeaReducer,
  user: UserReducer,
  createIdea: CreateIdeaReducer,
  userProfile: UserProfileReducer,
  ranking: RankingReducer,
  howItWorks: HowItWorksReducer,
  dashboard: DashboardReducer,
  maintenance: MaintenanceReducer,
  evaluation: EvaluationReducer,
  evaluationAdmin: EvaluationAdminReducer,
  aboutus: AboutusReducer,
  novaResearch: NovaResearchReducer,
})

export default reducers
