import React from 'react'
import { withStyles } from '@material-ui/core'
import i18n from 'support/i18n'
import style from './View.Style'

const UsersNotFound = props => {
  const { classes } = props
  return (
    <div className={classes.root}>
      <span className={classes.noText}>{i18n.t('PEOPLE_SELECTOR.NO_RESULTS')}</span>
      <span className={classes.noResultEmoticon}>:-(</span>
    </div>
  )
}

export default withStyles(style)(UsersNotFound)
