import background from 'img/bg-idea-status.png'

const styles = () => ({
  statusItems: {
    margin: '10px 0px',
    padding: '5px',
    listStyle: 'none',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '60px',
    width: '150px',
  },
  disabledStatus: {
    color: '#999',
  },
  iconContainer: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 40,
    height: 40,
  },
  iconContainerNext: {
    opacity: 0.4,
  },
  iconsStyles: {
    position: 'absolute',
    height: '24px',
    width: '24px',
    fontSize: '18px',
    fontFamily: 'VodafoneRg-Regular',
    textAlign: 'center',
    lineHeight: '24px',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 0,
  },
  primaryProgressContainer: {
    position: 'absolute',
    width: 40,
    height: 40,
  },
  secondaryProgressContainer: {
    position: 'absolute',
    width: 30,
    height: 30,
  },
  textContainer: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    fontFamily: 'VodafoneRg-Bold',
    fontSize: '16px',
    paddingTop: '16px',
    color: '#7834FF',
    textTransform: 'uppercase',
  },
  textContainerNext: {
    opacity: 0.4,
    color: '#999',
    fontFamily: 'VodafoneRg-Regular',
  },
  textContainerPrevious: {
    color: '#999',
    fontFamily: 'VodafoneRg-Regular',
  },
})

export default styles
