const styles = () => ({
  top: {
    gridArea: 'top',
    gridColumn: 2,
    gridRow: 2,
    backgroundColor: "#fbfaff", //body should have this background color in the main .css for the new site design 
    padding: "32px 85px",
    fontFamily: 'VodafoneRg-Regular',
    color: '#333333'
  },
  box: {
    borderRadius: '4px',
    backgroundColor: '#fff',
    boxShadow:
      'inset 0 1px 0 rgba(0,0,0,.1), inset 0 -1px 0 rgba(0,0,0,.4), 0 1px 2px rgba(0,0,0,.2)',
    marginBottom: '50px',
    padding: '15px',
    width: '75%',
    paddingLeft: '58px',
    paddingBottom: '50px'
  },
  
  check: {
    margin: '10px 0px 10px 0px',
    float: 'left',
    clear: 'both',
  },
})

export default styles
