import { withThemeCreator } from "@material-ui/styles"

const paddingInBoxSmall = "8px"
const padding_in_box = "16px"

const styles = theme =>({
    container: {
      fontSize: "0",
      padding: "0px 4px",
    },
    label:{
      margin:"0",
      backgroundColor: "#00B0CA",
      height: "24px",
      maxHeight: "24px",
      display: "inline-flex",
      alignItems: "center",
      color: "#FFFFFF",
      borderRadius: "10px 10px 0 0",
      fontFamily: theme.fontFamily.fontFamilyBold,

      '& p':{
        paddingLeft: "5px",
        paddingRight: "10px",
        margin: "0",
        fontSize: theme.fontSize.fontsize10,
        ['@media (min-width:600px)']:{
          fontSize:'14px'
        },
      },
      
    },
    img_icon: {
      height: "10px",
      paddingLeft: "8px",
      ['@media (min-width:600px)']:{
        height:'19px'
      },
    },
    box:{
      height: "145px",
      // width: "280px",
      width:'100%',
      maxWidth:'282px',
      borderRadius: "0 10px 10px 10px", 
      margin: "0",
      position:'relative',
      overflow: 'hidden',

      "& .ideaOverlay": {
          
        // height: "0",
        // visibility: "hidden",
        // opacity: "0",
        transitionDelay: "0s",
          position: 'absolute',
          bottom: '0px',
          left: '0',
          right: '0',
          width: '100%',
          background: '#fff',
          padding: "8px 0px",
          boxSizing: 'border-box',
          height:'52px',
          transition:'all 300ms linear',
        
      },
      "&:hover .ideaOverlay": {
        transition:'all 300ms linear',
        height:'100%',
        // height: "25%",
        // visibility: "visible",
        // opacity: "1",
        // transitionDelay: "0.5s",
        // transition: "height 0.4s linear",
      },
      '&:hover':{
        cursor: "pointer",
        // '& img': {
        //   maxHeight: "25%",
        //   height: "25%",
        // }
      },
      ['@media (min-width:600px)']:{
        height: "260px",
      }
    },
    
    imgblocks:{
      display:'block',
      width:'100%',
      height:'100%',
      transition:'all 300ms linear',
      '& img:hover':{
        transform:'scale(1.3)'
      }
    },


    idea_img: {
      maxWidth: "100%",
      width: "100%",
      maxHeight: "100%",
      height: "100%",
      objectFit: "cover",
      objectPosition: "center top",
      transition: "0.5s ease",
      borderRadius: "0 8px 0 0", 
    },
    idea_name: {
      fontSize: theme.fontSize.fontsize12,
      fontFamily: theme.fontFamily.fontFamilyBold,
      fontWeight:'normal',
      color:'#333',
      margin: "0",
      display: "flex",
      alignItems: "center",
      paddingLeft: paddingInBoxSmall,
      paddingRight: paddingInBoxSmall,
      height:'37px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display:' -webkit-box',
      '-webkit-line-clamp': '2',
      '-webkit-box-orient': 'vertical',
      ['@media (min-width:600px)']:{
        fontSize: theme.fontSize.fontsize16,
        paddingLeft: padding_in_box,
        paddingRight: padding_in_box,
      }
    },
    idea_description: {
      fontSize: theme.fontSize.fontsize12,
      fontFamily: theme.fontFamily.fontFamilyRegular,
      margin:'8px 0px',
      paddingLeft: paddingInBoxSmall,
      paddingRight: paddingInBoxSmall,
      display: "flex",
      alignItems: "center",
      color: "#333333",
      lineHeight: "1",
      boxShadow: "0px 24px 2px -24px #999999",
      ['@media (min-width:600px)']:{
        fontSize: theme.fontSize.fontsize16,
        paddingLeft: padding_in_box,
      paddingRight: padding_in_box,
      }
    },
    invest_component: {
      fontSize: theme.fontSize.fontsize12,
      display: "grid",
      gridTemplateColumns: "1fr 1fr",
      justifyItems: "center",
      gridRowGap: "0px",
      // height: "70%",
      paddingTop: "8px",
      paddingLeft: paddingInBoxSmall,
      paddingRight: paddingInBoxSmall,
      "& p": {
        padding: "0",
        margin: "0",
      },
      ['@media (min-width:600px)']:{
        fontSize: theme.fontSize.fontsize16,
        paddingLeft: padding_in_box,
      paddingRight: padding_in_box,
      }
      
    },
    invest_title: {
      fontFamily: 'VodafoneLt-Regular',
      color: '#333333',
    },
    invest_value: {
      fontFamily: 'VodafoneRg-Bold',
      color: '#333333',
    },
  })
  

export default styles
