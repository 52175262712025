import React, { Fragment, useState, useEffect } from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import styles from './View.Style'
import Breadcrumbs from 'components_newui/pageBreadcrumbsMenu/View'
import Carousel from 'react-material-ui-carousel'
import visitImg from 'img/visits.png'
import expiresImg from 'img/expiresDateIcon.png'
import taggedImg from 'img/tagged.png'
import pendingImg from 'img/pending-review-tick.png'
import extraBenefits from 'img/extrabenefit.png'
import starImg from 'img/star-with-circle.png'
import coinsImg from 'img/coins-with-circle.png'
import chevronImg from 'img/chevron-up-purple.png'
import FilterComponent from 'components/filter_component/View'
import noDraftIdeasImg from 'img/no_draft_idea.png'
import IdeaPanel from 'components_newui/idea_panel/View'
import SingleCarousel from 'components_newui/SingleCarousel/View'
import { getStyledStatus } from 'services/GetStatusStyle'
import states from 'common_constants/idea_states_constants'
import { Container } from '@material-ui/core'
import { challengeStatuses } from '../post_challenge/View.Utils'
import i18n from 'support/i18n'
import {ControlButtons} from '../../components_newui'
import { getChallengeStatus } from '../../services/GetStatusStyle'
import navigation from '../../support/navigation'

const { PENDING_VALIDATION } = states

const ChallengeDetails = props => {
    const {
        classes,
        challenge,navigateToUserProfile,
        challengePropose,
        navigateToAllChallenges,
        ideasChallenge,
        navigateToNewIdea,
        types,
        ideaStatusStepper,
        navigateToIdeaDetail,
        applySearch,
        resetSearch
    } = props

    const {
        approveMult,
        images,
        name,
        description,
        video,
        ends,
        implementedMult,
        importance,
        numIdeas,
        proposerEmail,
        sponsorEmail,
        proposerName,
        proposerPhoto,
        rewardText,
        rewardOther,
        rewardImage,
        rewardFor,
        sponsorName,
        sponsorPhoto,
        supportedMult,
        starts,
        proposedDate,
        visits,
        organisation, goalMult,
    } = challenge

    const proposerNameNotAvailable = proposerName === '' ? proposerEmail && proposerEmail.substring(0, proposerEmail.lastIndexOf("@")) : proposerName  

    console.log("ccc",proposedDate)

    const [showDetails] = useState(challenge.status !== PENDING_VALIDATION)
    const id = props.match.params.id
    const [activeFilters, setActiveFilters] = useState({
        status: [],
        types: [],
    })
    const [allButtons,setAllButtons] = useState([])
    const [showMore, setShowMore] = useState(true)
    const [ideasTag, setIdeasTags] = useState([])
    const [nameQuery, setNameQuery] = useState('')

    useEffect(() => {
        const { applyFilter, getChallengeById, match } = props
        getChallengeById(match.params.id)
        applyFilter(match.params.id)
    }, [])

    const challengeFilter = () => {
        const { applyFilter, getChallengeById, match } = props
        getChallengeById(match.params.id)
        applyFilter(match.params.id, null, activeFilters.status, activeFilters.types)
    }

    useEffect(() => {
        challengeFilter()
    }, [activeFilters])



    useEffect(() => {
        setIdeasTags(ideasChallenge)
    }, [ideasChallenge])

    // console.log("ssss", video)
    // Maybe have page hierarchy come from state????? at least get navigate functions from state
    const pageHierarchy = [
        {string: "Home", navigate: ()=>navigation.navigateToExplore()}, 
        { string: "All challenges", navigate: navigateToAllChallenges },
        { string: "Challenge details", navigate: () => console.log("Navigate...") }
    ]

    // const { name: statusStr, color: statusCol } = getStyledStatus(challenge.status)
    const { name: statusStr,image:statusIcon, color: statusCol } = getChallengeStatus(challenge.status)
    

    const formatDate = epochMs => {
        const date = new Date(epochMs)
        return (`${date.toLocaleDateString("en-US", { day: "numeric" })}/${date.toLocaleDateString("en-US", { month: 'numeric' })}/${date.toLocaleDateString("en-US", { year: 'numeric' })}`)
    }

// console.log('challenge.status',challenge.status,statusCol,statusStr)
    const adminAccessValidation = ()=>{
        const {
            challenge: { status },
            challenge: { isGlobal },
            isAdmin,
            isInnovator,
            isLocalAdmin,
            onEditClick,
            onDeleteClick,
            onFinishClick,
            onVisitorsClick,
            sameOrigin,
            countryId,
            origin,
          } = props
        
          const buttons = []
          if (isAdmin || isInnovator || isLocalAdmin) {
            if (status === challengeStatuses.PROPOSED) {
              if (isAdmin || isInnovator || (isLocalAdmin && sameOrigin && !isGlobal)) {
                buttons.push({
                  text: i18n.t('CHALLENGES.VALIDATE'),
                  action: () => onEditClick(id),
                })
              }
            }
            if (status !== challengeStatuses.DELETED) {
              if (status !== challengeStatuses.PROPOSED) {
                if (isAdmin || isInnovator || (isLocalAdmin && sameOrigin && !isGlobal)) {
                  buttons.push({ text: i18n.t('CHALLENGES.EDIT'), action: () => onEditClick(id) })
                }
              }
              if (isAdmin || isInnovator || (isLocalAdmin && sameOrigin && !isGlobal)) {
                buttons.push({
                  text: i18n.t('CHALLENGES.DELETE'),
                  action: () => onDeleteClick(id),
                  isNotPrimary: true,
                })
              }
            }
            if (status === challengeStatuses.ACTIVE) {
              if (isAdmin || isInnovator || (isLocalAdmin && sameOrigin && !isGlobal)) {
                buttons.push({ text: i18n.t('CHALLENGES.FINISH'), action: () => onFinishClick(id) })
              }
            }
            buttons.push({ text: i18n.t('CHALLENGES.VISITORS'), action: () => onVisitorsClick(id) })
          }
        setAllButtons(buttons)
    }


    useEffect(() => {
        adminAccessValidation()
    }, [challenge.status])





    return (
        <Fragment>
                <Breadcrumbs crumbs={pageHierarchy} />
            <Container>

                <main className={classes.background}>

                <figure className={classes.status} style={{ backgroundColor: statusCol }}>
                    {/* <img src={statusIcon} alt={`icon`} style={{ width: '20px' }} /> */}
                    <figcaption >{statusStr}</figcaption>
                </figure>

                    {/* <div className={classes.statusButton} style={{ backgroundColor: statusCol }}>
                        {statusStr}
                    </div> */}
                    <ControlButtons buttons={allButtons} text='challenge' />

                    <div style={{ display: 'flex', position: 'relative' }}>
                        <div className={classes.title}>{name}</div>
                       
                        <button type='button'
                            className={ statusStr === 'PROPOSED' || statusStr === 'DRAFT' ? `${ classes.btnDisabled} ${ classes.respondBtn}`  :  classes.respondBtn}
                            onClick={() => navigateToNewIdea(challenge.id)}
                            disabled={statusStr === 'PROPOSED' ||statusStr === 'DRAFT'}
                        >   
                            Respond with idea
                        </button>

                    </div>
                    <div className={classes.imageInfoPanel}>
                        <div className={classes.imagePanel}>
                            {/* <Carousel> */}
                            <SingleCarousel>
                                {challenge.images.map(i => <img key={i.src} src={i.src} className={classes.images} alt='thumbnail' />)}
                            </SingleCarousel>
                            {/* </Carousel> */}
                        </div>
                        <div style={{ flexGrow: 1, position: 'relative' }}>
                            <div className={classes.infoPanel}>
                                {showDetails ?
                                    <Fragment>
                                        <div className={ challenge.status === 'FINISHED' || challenge.status === 'PROPOSED' ? classes.infoGrid2 : classes.infoGrid}>
                                        {challenge.status === 'PROPOSED'  ? null :   <div className={classes.infoPanelHeaders}>Expires on</div> }
                                            {challenge.status === 'FINISHED'  ? null : 
                                                <div className={classes.infoPanelHeaders}>No. of visits</div>
                                            }
                                            <div className={classes.infoPanelHeaders}>Ideas tagged</div>
                                            {challenge.status === 'PROPOSED'  ? null :(  <div className={classes.infoPanelIcons} style={ challenge.status === 'FINISHED' ? {borderRight: '1px solid #EBEBEB'} : null}>
                                                <img src={expiresImg} className={classes.icon_img} alt='icon' />
                                                <div className={classes.infoPanelNumber}>{formatDate(ends)}</div>
                                            </div>)}
                                            {challenge.status === 'FINISHED' ? null :  
                                            <div className={classes.infoPanelIcons} style={{ borderRight: '1px solid #EBEBEB' }}>
                                                <img src={visitImg} className={classes.icon_img} alt='icon' />
                                                <div className={classes.infoPanelNumber}>{visits}</div>
                                            </div>
                                            }
                                            <div className={classes.infoPanelIcons}>
                                                <img src={taggedImg} className={classes.icon_img} alt='icon' />
                                                <div className={classes.infoPanelNumber}>{numIdeas}</div>
                                            </div>
                                        </div>
                                        {challenge.status === 'PROPOSED'  ? null :(   <div className={classes.sponsoredSubmitted}>
                                            Sponsored by:  <span className={classes.nameDateSpan}> {sponsorName}
                                                </span>
                                        </div> )}
                                    </Fragment>
                                    :
                                    <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', marginTop: '85px', marginBottom: '79px' }}>
                                        <img style={{ height: '44px' }} src={pendingImg}  alt='pending icon'/>
                                        <div className={classes.no_details_header}>Pending Review</div>
                                    </div>
                                }
                                <div className={classes.sponsoredSubmitted}>

                                    Submitted by:  <span className={classes.nameDateSpan}> 
                                        <button type='button' className={classes.btnBlank} onClick={()=>navigateToUserProfile(proposerEmail)}>{proposerNameNotAvailable}</button>
                                    </span>  on  <span className={classes.nameDateSpan}> {proposedDate ? proposedDate : formatDate(starts)}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    {showDetails &&
                        <div className={classes.reward_panel} >
                            <div className={classes.extraBenefitWrp} style={{ borderRight: '1px solid #EBEBEB' }}>
                                <img src={extraBenefits} className={classes.icon_img} alt="Extra Benefits" />
                                <div className={classes.extracontent}>
                                    <div className={classes.reward_header}>Get extra benefits:</div>
                                    <div className={classes.extraList}>
                                        <div className={classes.extraItem}>
                                            <p className={classes.extraBenefitValue}>{supportedMult ? supportedMult : 'X'}</p>
                                            <p className={classes.extraBenefitName}>Supported</p>
                                        </div>
                                        <div className={classes.extraItem}>
                                            <p className={classes.extraBenefitValue}>{goalMult ? goalMult : 'X'}</p>
                                            <p className={classes.extraBenefitName}>Approved</p>
                                        </div>
                                        <div className={classes.extraItem}>
                                            <p className={classes.extraBenefitValue}>{implementedMult ? implementedMult : 'X'}</p>
                                            <p className={classes.extraBenefitName}>Implemented</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{ borderRight: '1px solid #EBEBEB' }} className={classes.inner_reward_panel}>
                                <img src={starImg} className={classes.icon_img} alt='star icon' />
                                <div style={{ paddingLeft: '16px' }}>
                                    <div className={classes.reward_header}>Reward:</div>
                                    <div className={classes.reward_info}>
                                        Work with a team to pitch the idea to get funding building it along with the team
                                    </div>
                                </div>
                            </div>
                            <div className={classes.inner_reward_panel}>
                                <img src={coinsImg} className={classes.icon_img}  alt='cross icon'/>
                                <div style={{ paddingLeft: '16px' }}>
                                    <div className={classes.reward_header}>Who gets the reward:</div>
                                    <div className={classes.reward_info}>
                                        Users whose ideas become pilot projects
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

{video && (
              <div className={classes.ideaDescriptionVideo}>
                {' '}
                <p className={classes.header}>Video </p>{' '}
                <iframe
                  className={classes.iframe}
                  title="ytVideo"
                  width="540"
                  height="320"
                  src={video}
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />{' '}
              </div>
            )}


                    <div className={classes.challengeDesc}>
                        <div className={classes.header}>
                            Challenge description
                        </div>
                        <div className={classes.wrp}>
                            {/* <h3 className={classes.challengeDesc_question}>Tell us about the problem you are trying to solve.</h3> */}
                            <p className={classes.challengeDesc_ans}>
                                { /* description && description.replace(/<\/?[^>]+(>|$)/g, "") */}
                                <div dangerouslySetInnerHTML={{__html: description}} />
                            </p>
                        </div>

                        {showMore &&
                            <>
                                <div className={classes.header}>
                                    Why is this important?
                                </div>
                                <div className={classes.wrp}>
                                    {/* <h3 className={classes.challengeDesc_question}>Explain why your colleagues should publish solutions for this challenge.</h3> */}
                                    <p className={classes.challengeDesc_ans}>
                                        {/* importance.replace(/<\/?[^>]+(>|$)/g, "") */}
                                        <div dangerouslySetInnerHTML={{__html: importance}} />
                                    </p>
                                </div>
                            </>
                        }
                    </div>


                     <div className={classes.horizontal_line} />

                     <div className={classes.tagwrp}>
          <div className={classes.header}>Ideas tagged ({ideasTag.length})</div>

          <div className={classes.tagsfilter}>
            <FilterComponent
              activeFilters={activeFilters}
              serviceLineOrgs={[]}
              ideaStatusFilter={false}
              types={[]}
              handleActiveFilters={setActiveFilters}
              search={nameQuery}
              setSearch={setNameQuery}
              applySearch={() =>
                setIdeasTags(
                  ideasChallenge.filter(item =>
                    item.title.toLowerCase().includes(nameQuery.toLowerCase()),
                  ),
                )
              }
              resetSearch={() => {
                setNameQuery('')
                setIdeasTags(ideasChallenge)
              }}
            />
          </div>

          {ideasTag.length > 0 ? (
            <div className={classes.idea_grid}>
              {ideasTag.map(idea => (
                <IdeaPanel key={idea.id} urlId={idea.id} idea={idea} />
              ))}
            </div>
          ) : (
            <div className={classes.nofoundwrp}>
              <img src={noDraftIdeasImg} className={classes.no_idea_img} alt="noidea img" />
              <div className={classes.no_idea_title}>No ideas found</div>
            </div>
          )}
        </div>

                   
                    
                </main>
            </Container>
        </Fragment>
    )
}

export default withStyles(styles)(ChallengeDetails)