import { GET_RANKING_SUCCESS } from 'actions/sync/ranking/Constants'

export const initialState = {
  topInvestors: [],
  topPosts: [],
  topPotentialInvestors: [],
  topIdeas: [],
  topIdeasInvestors: [],
}

const rankingReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_RANKING_SUCCESS:
      return {
        ...state,
        topInvestors: action.response.topInvestors,
        topPosts: action.response.topPosts,
        topPotentialInvestors: action.response.topPotentialInvestors,
        topIdeas: action.response.topIdeas,
        topIdeasInvestors: action.response.topIdeasInvestors,
      }
    default:
      return state
  }
}

export default rankingReducer
