import { createStyles } from '@material-ui/core'

const styles = () =>
  createStyles({
    expandingBlock:{
        margin: 'none',
        textAlign: 'left',
        fontFamily: 'VodafoneRg-Regular',
        fontSize: '18px',
        background: 'none',
        boxShadow: 'none',
        '&.MuiExpansionPanel-root:before': {
            display: 'none',
        },
    },
    summary:{
        display: 'flex', 
        flexDirection: 'column',
        padding: '0px',
        width:'100%'
    },
    showMore:{
        background:'transparent',
        fontSize: '16px',
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        border:'0px',
        lineHeight:'0',
        width:'100%',
        "&.Mui-focused":{
            outline:'0px',
            boxShadow:'none'
        }
    },
    header: {
        textAlign: 'left',
        fontSize: '24px',
        fontFamily: 'VodafoneRg-Regular',
        marginBottom: '16px',
    },
    expansionPanelDetails:{
        textAlign: 'left',
        padding: '0px',
        
    },
    expansionheader: {
        'width': '100%',
    },
    content: {
        margin: "4px 0",
        background:"red",
        "&$expanded": {
          margin: "4px 0"
        }
      },
    
  })

export default styles
