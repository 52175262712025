
import React, { useState } from "react";

import filterdefault from 'img/filter-default.png'
import filterUp from 'img/filter-up.png'
import filterDown from 'img/filter-down.png'

const TableHead = (props) => {
 const   { columns, handleSorting } = props
  const [sortField, setSortField] = useState("");
  const [order, setOrder] = useState("asc");
  const handleSortingChange = (accessor) => {
    const sortOrder = accessor === sortField && order === "asc" ? "desc" : "asc";
    setSortField(accessor);
    setOrder(sortOrder);
    handleSorting(accessor, sortOrder);
  };

  return (
    <>
    <thead>
      <tr>
        {columns.map(({ label, accessor, sortable }) => {
          console.log('ddd',accessor,label)
          const cl = sortable
            ? sortField === accessor && order === "asc"
              ? "up"
              : sortField === accessor && order === "desc"
              ? "down"
              : "default"
            : "";
            const clicon = sortable
            ? sortField === accessor && order === "asc"
              ? filterdefault
              : sortField === accessor && order === "desc"
              ? filterdefault
              : filterdefault
            : "";
          return (
            <th
              key={accessor}
              onClick={sortable ? () => handleSortingChange(accessor) : null}
              className={cl}
              style={{backgroundImage:`url(${clicon})`}}
            >
              {label}
            </th>
          );
        })}
      </tr>
    </thead>
    </>
  );
};

export default TableHead