import { withThemeCreator, styled } from "@material-ui/styles"
const styles = theme =>({
    container:{
        height: "auto",
        width:'100%',
       
        paddingBottom: "30px",
        '& .slick-dots li':{
          margin:'0 2px'
        },
        '& .slick-dots li button:before':{
          fontSize:'12px'
        }
    },
    
  })

export default styles
