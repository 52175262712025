import React from 'react'
import * as PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'
import ContributorElement from './components/contributor_element/View'
import styles from './View.Style'

const ContributorsList = props => {
  const { classes, contributors } = props
  return (
    <ul className={classes.root}>
      {contributors.map(contributor => (
        <li key={contributor.name}>
          <ContributorElement
            isAdmin={contributor.isAdmin}
            photoSrc={contributor.photoSrc}
            name={contributor.name}
            budget={contributor.budget}
            time={contributor.time}
            other={contributor.other}
          />
        </li>
      ))}
    </ul>
  )
}

ContributorsList.propTypes = {
  contributors: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      userId: PropTypes.string,
      photoSrc: PropTypes.string,
      isAdmin: PropTypes.bool,
      time: PropTypes.string,
      budget: PropTypes.string,
      other: PropTypes.string,
    }),
  ).isRequired,
}

export default withStyles(styles)(ContributorsList)
