

import React from 'react'
import { connect } from 'react-redux'
import { getHeaderSection, getHelpSection, getRankings,getTestCountrySection,getTestCountryINSection ,getApiResponseEris} from 'services/explore'
// import { getHeaderSection, getHelpSection, getRankings } from 'services/explore'
import { applyFilterService, applySearchService } from 'services/ideas'
import { getActiveOrganizations } from 'services/organizations'
import { getGetExpiringNearFunded, applyIdeaMeService } from 'services/ideas'
import { getActiveTypes } from 'services/types'
import { getActiveChallenges } from 'services/challenges'
import { getUserOrganisations } from 'services/user_profile'
import { getInvestments } from 'services/user'
import { FIRST_LIMIT_CONSTANT } from 'common_constants/Ideas'
import Navigation from 'support/navigation'
import AnalyticsService from 'support/analytics'
import { showPopupDialog } from 'support/popup_dialogs'
import NewPrivateIdea from 'components/nxt_modal_new_private_idea'
import View from './View'

const mapStateToProps = state => {
  return {
    domainLoaded: state.data.domain.dataLoaded,
    ideas: state.data.idea.data,
    isIdeasLoading: state.data.idea.ideasLoading,
    challenges: state.data.challenge.challenges,
    totalIdeas: state.data.idea.total,
    hasMoreIdeasToLoad: state.data.idea.hasMore,
    footerInvestors: state.explore.ranking.footerInvestors,
    footerEntrepreneurs: state.explore.ranking.footerEntrepreneurs,
    headerSection: state.explore.headerSection,
    helpList: state.explore.helpList,
    organizationsName: state.data.domain.domainConfiguration.organizationsName,
    organizations: state.data.domain.organizations,
    folderOrganizations: state.data.domain.folderOrganizations,
    types: state.data.domain.types,
    typeTitle: state.data.domain.domainConfiguration.organizationTypeName,
    useHelpSection: state.data.domain.domainConfiguration.useHelpSection,
    ideaStatusStepper: state.data.domain.domainConfiguration.ideaStatusStepper,
    isOrganizationsActive: state.data.domain.domainConfiguration.isOrganizationsActive,
    isTypesActive: state.data.domain.domainConfiguration.isTypesActive,
    isPrivateFlowActive: state.data.domain.domainConfiguration.isPrivateFlowActive,
    privateBannerSrc: state.data.domain.domainConfiguration.privateBannerSrc,
    organisationsSelected: state.data.userProfile.organisations,
    hasUserOrganisationsLoaded: state.data.userProfile.hasUserOrganisationsLoaded,
    expiringIdeas: state.data.dashboard.expiring_ideas.data,
    userIdeas: state.data.idea.userIdeas,
    investments: state.data.user.investments.ideas,
    userEmail: state.data.user.userEmail,

    localAdmin: state.data.user.localAdmin,
    localInnovator: state.data.user.localInnovation,
    isOwner: state.data.createIdea.owner === state.data.user.userEmail,
    isAdmin: state.data.user.admin,
    isInnovator: state.data.user.isInnovator,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getIdeas: () => {
      dispatch(
        applyFilterService({
          challenge: "",
          organizations: [],
          status: [],
          types: [],
          ideasLocation: undefined,
          limit: FIRST_LIMIT_CONSTANT,
          offset: 0,
        }),
      )
    },
    getMyIdeas: () => {
      dispatch(applyIdeaMeService())
    },
    getInvestments: userEmail => {
      dispatch(getInvestments(userEmail))
    },
    getUserOrganisations: () => dispatch(getUserOrganisations()),
    applySearch: search => dispatch(applySearchService({ search })),
    resetSearch: () => dispatch(applySearchService()),
    getActiveChallenges: () => dispatch(getActiveChallenges()),
    getHeaderSection: () => dispatch(getHeaderSection()),
    getHelpSection: () => dispatch(getHelpSection()),
    getOrganization: () => dispatch(getActiveOrganizations()),
    getExpiringNearFunded: () => dispatch(getGetExpiringNearFunded()),
    getRankings: () => dispatch(getRankings()),
    getTypes: () => dispatch(getActiveTypes()),
    navigateToChallengeDetail: id => dispatch(Navigation.navigateToChallengeDetail(id)),
    navigateToExternal: url => dispatch(Navigation.navigateToExternal(url)),
    navigateToAllIdeas: () => dispatch(Navigation.navigateToAllIdeas()),
    navigateToChallenges: () => {
      Navigation.navigateToChallenges()
    },
    
    navigateToProposeChallenge: () => {
      Navigation.navigateToProposeChallenge()
    },
    navigateToNewChallenge:()=>{
      Navigation.navigateToChallengeNew()
    },
    navigateToIdea: (id, index) => {
      dispatch(Navigation.navigateToIdea(id))
      AnalyticsService.trackEvent({
        category: 'swiper',
        action: 'click',
        label: 'idea panel',
        value: index,
      })
    },
    navigateToInvestments: () => {
      dispatch(Navigation.navigateToInvestments())
    },
    navigateToMyInvestedIdea: () => {
      dispatch(Navigation.navigateToMyInvestedIdeas())
    },

    navigateToNewPrivateIdea: () => dispatch(Navigation.navigateToNewPrivateIdea()),
    submitYourIdea: () => {
      dispatch(Navigation.navigateToNewIdea())
      AnalyticsService.trackEvent({
        category: 'create-idea',
        action: 'click',
        label: 'explore page button',
      })
    },
    navigateToStaticHelpNextinit: () => dispatch(Navigation.navigateToStaticHelpNextinit()),
    navigateToHowItWorks: () => dispatch(Navigation.navigateToHowItWorks()),
    navigateToRanking: () => {
      dispatch(Navigation.navigateToRanking())
      AnalyticsService.trackEvent({
        category: 'home',
        action: 'click',
        label: 'rankings link',
      })
    },
    navigateToAboutus: () => {
      Navigation.navigateToAboutus()
    },
    navigateToUserProfile: user => dispatch(Navigation.navigateToUserProfile(user)),

    getTestCountrySection : ()=>{
      dispatch(getTestCountrySection())
    },
    getTestCountryINSection : ()=>{
      dispatch(getTestCountryINSection())
    },
    getApiResponseEris : ()=>{
      dispatch(getApiResponseEris())
    },


  }
  
}
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(View)
