import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import { withStyles, IconButton } from '@material-ui/core'
import style from './View.Style'
import EmailsSearch from './components/emails_search/View'
import SendEmailStepButtons from './components/send_email_step_buttons/View'
import EmailFormToUsers from './components/email_form_to_users/View'

class SendEmailDialog extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedUsers: [],
      activeStep: 0,
      firstSearchDone: false,
      textToSend: '',
    }
    this.handleItemClicked = this.handleItemClicked.bind(this)
    this.isSelected = this.isSelected.bind(this)
    this.handleNext = this.handleNext.bind(this)
    this.handlePrevious = this.handlePrevious.bind(this)
    this.setFirstSearchDone = this.setFirstSearchDone.bind(this)
  }

  componentDidUpdate(prevProps) {
    const { isLoadingUsers } = this.props
    if (isLoadingUsers !== prevProps.isLoadingUsers) {
      this.setFirstSearchDone()
    }
  }

  componentWillUnmount() {
    const { onUnmount } = this.props
    onUnmount()
  }

  setFirstSearchDone() {
    this.setState({
      firstSearchDone: true,
    })
  }

  handleItemClicked(user) {
    const { selectedUsers } = this.state
    if (this.isSelected(user.email)) {
      this.setState({
        selectedUsers: selectedUsers.filter(innerUser => innerUser.email !== user.email),
      })
    } else {
      this.setState({
        selectedUsers: [...selectedUsers, user],
      })
    }
  }

  handleNext() {
    const { activeStep, textToSend } = this.state
    if (activeStep === 0) {
      this.setState({
        activeStep: activeStep + 1,
      })
    } else if (textToSend.length > 0) {
      const { onSendInvite } = this.props
      const { selectedUsers } = this.state
      const commaSeparatedEmails = selectedUsers.reduce((acc, user, index) => {
        return `${acc}${user.email}${index !== selectedUsers.length - 1 ? ',' : ''}`
      }, '')
      onSendInvite(commaSeparatedEmails, textToSend)
    }
  }

  handlePrevious() {
    const { activeStep } = this.state
    const { onCloseDialog } = this.props
    if (activeStep === 1) {
      this.setState({
        activeStep: activeStep - 1,
      })
    } else {
      onCloseDialog()
    }
  }

  isSelected(userEmail) {
    const { selectedUsers } = this.state
    return !!selectedUsers.find(user => user.email === userEmail)
  }

  render() {
    const {
      classes,
      getSuggestions,
      usersSuggestion,
      isLoadingUsers,
      isLoading,
      onCloseDialog,
      searchEmailLabel,
      emailTextLabel,
    } = this.props
    const { selectedUsers, activeStep, firstSearchDone, textToSend } = this.state
    return (
      <div className={classes.root}>
        <IconButton
          aria-label="close"
          onClick={onCloseDialog}
          disableFocusRipple
          disableRipple
          disableTouchRipple
        >
          <span className="icon-close" />
        </IconButton>
        {activeStep === 0 && (
          <EmailsSearch
            searchEmailLabel={searchEmailLabel}
            isLoading={isLoadingUsers}
            onRetrieveSuggestions={getSuggestions}
            selectedUsers={selectedUsers}
            usersSuggestion={usersSuggestion}
            firstSearchDone={firstSearchDone}
            onUserClicked={this.handleItemClicked}
          />
        )}
        {activeStep === 1 && (
          <EmailFormToUsers
            users={selectedUsers}
            hasError={textToSend.length === 0}
            onTellYourContactTextChange={event =>
              this.setState({
                textToSend: event.target.value,
              })
            }
            emailTextLabel={emailTextLabel}
          />
        )}
        {firstSearchDone && (
          <SendEmailStepButtons
            isLoading={isLoading}
            activeStep={activeStep}
            canGoNext={selectedUsers.length > 0}
            onNextClick={this.handleNext}
            onBackClick={this.handlePrevious}
          />
        )}
      </div>
    )
  }
}

SendEmailDialog.defaultProps = {
  onUnmount: () => {},
}

SendEmailDialog.propTypes = {
  getSuggestions: PropTypes.func.isRequired,
  usersSuggestion: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isLoadingUsers: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func.isRequired,
  onSendInvite: PropTypes.func.isRequired,
  searchEmailLabel: PropTypes.string.isRequired,
  emailTextLabel: PropTypes.string.isRequired,
  onUnmount: PropTypes.func,
}

export default withStyles(style)(SendEmailDialog)
