const styles = theme => ({
  list: {
    '& > div:first-child': {
      borderTop: 0,
    },
  },
  item: {
    ...theme.flexRowCenter,
    height: '72px',
    padding: '10px 0',
    borderTop: '1px solid #bcbcbc',
    width: '100%',
  },
  status: {
    paddingRight: theme.spacing(1),
    flex: 2,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRight: '1px solid #e9e9e9',
    '& > label': {
      margin: 0,
    },
  },
  data: {
    flex: 14,
    padding: theme.spacing(2),
  },
  block: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  name: {
    marginBottom: theme.spacing(0.5),
  },
  result: {
    display: 'flex',
    '& > div': {
      flex: 1,
    },
  },
  actions: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '80%',
    '& > button': {
      width: '30px',
      height: '30px',
    },
  },
  semiCircle: {
    display: 'flex',
    flexDirection: 'column',
    '& > div:last-child': {
      marginTop: theme.spacing(0.5),
      display: 'flex',
      justifyContent: 'space-between',
      '& > span:first-child': {
        color: theme.palette.primary.main,
      },
      '& > span': {
        fontSize: theme.spacing(1.5),
        fontStretch: '75%',
        fontWeight: 600,
        fontFamily: 'HelveticaNeueCondensedBold,Helvetica Neue,sans-serif',
      },
    },
  },
})

export default styles
