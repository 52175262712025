const styles = theme => ({
  container: {
    borderRadius: '5px',
    textAlign: 'center',
    margin: theme.spacing(1.25),
    height: 356,
    width: 500,
  },
  icon: {
    display: 'flex',
    float: 'right',
  },
  iconStyle: {
    fontSize: '18px',
    border: '1px solid #646464',
    color: '#646464',
    borderRadius: '10px',
    cursor: 'pointer',
  },
  span: {
    marginTop: theme.spacing(1.25),
  },
  title: {
    fontSize: '18px',
    fontWeight: 600,
    color: '#4b4d4f',
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: 286,
    width: '100%',
  },
  inputTitle: {
    fontSize: '14px',
    color: '#646464',
    textAlign: 'left',
    margin: '20px 0px 0px 30px',
  },
  textInput: {
    fontSize: '14px',
    color: '#646464',
    padding: 8,
  },
  emailContainer: {
    marginTop: theme.spacing(3),
  },
  input: {
    width: '90%',
  },
  buttons: {
    width: '90%',
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'space-between',
  },
})

export default styles
