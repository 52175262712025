import { connect } from 'react-redux'
import { discardIdeaById } from 'services/ideas'
import { hideDialog } from 'support/popup_dialogs/modal_alert_controller/ModalActions'
import Navigation from 'support/navigation'
import DiscardView from './DiscardView'

const mapStateToProps = (state, ownProps) => ({
  id: ownProps.id,
  rejectedReasons: ownProps.rejectedReasons,
})

const mapDispatchToProps = dispatch => {
  return {
    onClick: (id, reason, returnInvestment) => {
      dispatch(discardIdeaById(id, reason, returnInvestment))
      dispatch(hideDialog())
      window.location.reload()
    },
    cancelButtonAction: () => dispatch(hideDialog()),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DiscardView)
