import {
  GET_FIRST_SEARCH_APPLY_FILTER_STARTED,
  GET_FIRST_SEARCH_APPLY_FILTER_SUCCESS,
  GET_FIRST_SEARCH_APPLY_FILTER_FAILURE,
  GET_ACTIVE_CHALLENGES_STARTED,
  GET_ACTIVE_CHALLENGES_SUCCESS,
  GET_ACTIVE_CHALLENGES_FAILURE,
  GET_CHALLENGE_BY_ID_STARTED,
  GET_CHALLENGE_BY_ID_SUCCESS,
  GET_CHALLENGE_BY_ID_FAILURE,
} from 'actions/sync/challenges/Constants'
import {
  FIRST_SEARCH_APPLY_FILTER_FAILURE,
  FIRST_SEARCH_APPLY_FILTER_STARTED,
  FIRST_SEARCH_APPLY_FILTER_SUCCESS,
  APPLY_SEARCH_IDEAS_SUCCESS,
  APPLY_FILTER_IDEAS_STARTED,
  APPLY_FILTER_IDEAS_FAILURE,
  APPLY_FILTER_IDEAS_SUCCESS,
  APPLY_SEARCH_IDEAS_STARTED,
  APPLY_SEARCH_IDEAS_FAILURE,
} from 'actions/sync/ideas/ideasActionConstans'

const initialState = {
  challenges: [],
  challengesFilter:[],
  challengeById: {
    approvedMult: 0,
    description: '',
    desc: '',
    ends: 0,
    goalMult: 0,
    id: '',
    ideas: [],
    images: [],
    implementedMult: 0,
    importance: '',
    mainPhoto: '',
    name: '',
    numIdeas: '',
    proposerName: '',
    proposerPhoto: '',
    rewardText: '',
    rewardOther: '',
    rewardImage: {},
    rewardFor: '',
    sponsorName: '',
    sponsorPhoto: '',
    starts: 0,
    supportedMult: 0,
    visits: 0,
    isGlobal: false,
    origin: '',
    createDate:'',
  },
  items: 0,
  ideas: [],
  totalIdeas: 0,
  total:0,
  challengesLoading: false,
  challengeIdeasLoading: false,
  hasMore:false
}

const challengeReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ACTIVE_CHALLENGES_STARTED:
    case GET_CHALLENGE_BY_ID_STARTED:
    case GET_FIRST_SEARCH_APPLY_FILTER_STARTED:
      return {
        ...state,
        challengesLoading: true,
        
      }
    case GET_FIRST_SEARCH_APPLY_FILTER_SUCCESS : 
      return {
        ...state,
        challenges:action.response.challenges,
        items: action.response.items,
        total: action.response.total,
        hasMore: action.response.items < action.response.total,
        createDate:action.response.challenges.createDate,
        challengesLoading: false,
      }
  
      

    case GET_ACTIVE_CHALLENGES_SUCCESS:
      let newitem =[...state.challenges, ...action.response.challenges]
      return {
        ...state,
        challenges:newitem,
        challengesFilter:action.response.challenges,
        items: action.response.items,
        createDate:action.response.challenges.createDate,
        challengesLoading: false,
        total:action.response.total,
        hasMore: newitem.length < action.response.total,
      }
    case GET_ACTIVE_CHALLENGES_FAILURE:
    case GET_CHALLENGE_BY_ID_FAILURE:
    case GET_FIRST_SEARCH_APPLY_FILTER_FAILURE:
      return {
        ...state,
        challengesLoading: false,
      }

    case GET_CHALLENGE_BY_ID_SUCCESS:
      return {
        ...state,
        challengeById: action.response.challenge,
        challengesLoading: false,
      }

    case FIRST_SEARCH_APPLY_FILTER_STARTED:
    case APPLY_FILTER_IDEAS_STARTED:
      return {
        ...state,
        challengeIdeasLoading: true,
      }

    case FIRST_SEARCH_APPLY_FILTER_SUCCESS:
      return {
        ...state,
        ideas: action.response.data,
        totalIdeas: action.response.total,
        challengeIdeasLoading: false,
      }

    case APPLY_FILTER_IDEAS_SUCCESS:
      return {
        ...state,
        ideas: [...state.ideas, ...action.response.data],
        challengeIdeasLoading: false,
      }
     
    case FIRST_SEARCH_APPLY_FILTER_FAILURE:
    case APPLY_FILTER_IDEAS_FAILURE:
      return {
        ...state,
        challengeIdeasLoading: false,
      }
    case APPLY_SEARCH_IDEAS_STARTED:
      return {
        ...state,
        challengeIdeasLoading: true,
      }
    case APPLY_SEARCH_IDEAS_SUCCESS:
      return {
        ...state,
        ideas: action.response.data,
        totalIdeas: action.response.total,
        challengeIdeasLoading: false,
      }
    case APPLY_SEARCH_IDEAS_FAILURE:
      return {
        ...state,
        challengeIdeasLoading: false,
      }

    default:
      return state
  }
}

export default challengeReducer
