const styles = () => ({
  root: {

    '& .MuiTypography-body1':{
      '@media (max-width:767px)':{
        fontSize:'13px'
      }
    }
  },
})

export default styles
