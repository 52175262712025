import { withStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import { ClickAwayListener, Container } from '@material-ui/core'
import React, { Fragment, useEffect, useRef, useState } from 'react'
import {Link} from 'react-router-dom'
import NotificationsMenu from '../../components/header/components/header_user_logo/components/notifications_menu/View'
import defaultPhoto from '../../img/default-photo.png'
import VoisIcon from '../../img/logo_vois.png'
import novaicon from '../../img/nova-logo.png'
import SimpleMenu from '../../components/simple_menu'
import HeaderLogo from './header_logo/View'
import HeaderDomains from './header_domains/View'
import { showPopupInfoSnackbar } from 'support/popup_dialogs'
import chevronDown from '../../img/ic_chevron_down.png'
import logo from '../../img/NOVA_Logo-Red-WhiteBG.jpg'
import closeIcon from '../../img/close-system.png'
import menuIcon from '../../img/menu-system.png'
import HeaderUserBalance from './header_user_balance/View'
import styles from './View.Style'
import * as pages from '../../common_constants/Pages'
import NavigationService from '../../support/navigation/NavigationService'
import { useDispatch } from 'react-redux'
import MenuItem from './MenuItem'

const Navbar = ({
  whiteListDomainLoading,
  whiteListDomainService,
  classes,
  onLogoClick,
  logoUrl,  
  notifications,
  showAdmin,
  userBalance,
  userName,
  userPhoto,
  domains,
  userLoginEmail,
  currentDomain,
  navigateToDomain,
  markNotificationsRead,
  submitYourIdea,
  commonUserData,
  isAdmin,isInnovator,
  canProposeChallenge,
}) => {
  // const { admin, isInnovator, localAdmin } = commonUserData
  const [showNavList, setShowNavList] = useState(false)
  const [showNotificationsMenu, setShowNotificationsMenu] = useState(false)
  const [openMenu, setOpenMenu] = useState(false)

  const ref= useRef()

  const ROUTES = NavigationService.getRoutes()
  let explorePath = ROUTES[pages.EXPLORE_PAGE].path;
  let ALL_IDEAS_Path = ROUTES[pages.ALL_IDEAS].path;
  let MY_IDEAS_PAGE_Path = ROUTES[pages.MY_IDEAS_PAGE].path;
  let MY_INVESTED_IDEAS_Path = ROUTES[pages.MY_INVESTED_IDEAS].path;
  let NEW_CHALLENGE_Path = ROUTES[pages.NEW_CHALLENGE].path;
  let CHALLENGES_PAGE_Path = ROUTES[pages.CHALLENGES_PAGE].path;
  let NOVA_RESEARCH_Path = ROUTES[pages.NOVA_RESEARCH].path;
  let ABOUT_US_Path = ROUTES[pages.ABOUT_US].path;
  let CaseStudyPath = ROUTES[pages.CASESTUDY].path;
  let CONTACTUS_Path = ROUTES[pages.CONTACTUS].path;
  let PROPOSE_CHALLENGE_path =  ROUTES[pages.PROPOSE_CHALLENGE].path

  useEffect(() => {
    const handler = (event) => {
        if (openMenu && ref.current && !ref.current.contains(event.target)) {
            setOpenMenu(false);
        }
    };
    document.addEventListener("mousedown", handler);
    document.addEventListener("touchstart", handler);
    return () => {
        // Cleanup the event listener
        document.removeEventListener("mousedown", handler);
        document.removeEventListener("touchstart", handler);
    };
}, [openMenu]);


  const toggleMenuHandler = () => {
    setOpenMenu(prevValue => !prevValue)
  }

  const closeSidebarMenu = () => {
    openMenu && setOpenMenu(false);
  };


  const onHandleInstanceValue = (numberSelected) =>{
    const domainNameValue = numberSelected.target.value
    // console.log('value:',numberSelected.target.value)
      whiteListDomainService(userLoginEmail,domainNameValue,whiteListDomainLoading)
  }


  const NavbarList = [  
    {
      path:explorePath,
      title:'Home',
    },
    {
      path:'/',
      title:'Ideas',
      sub:[
        {
          path:MY_IDEAS_PAGE_Path,
          title:'My ideas',
          adminRole:null,
        },
        {
          path:ALL_IDEAS_Path,
          title:'All ideas',
          adminRole:null,
        },
        {
          path:MY_INVESTED_IDEAS_Path,
          title:'My invested ideas',
          adminRole:null,
        },
      ]
    },
    {
      path:'/',
      title:'Challenges',
      sub:[
        {
          path:CHALLENGES_PAGE_Path,
          title:'All challenges',
          adminRole:null
        },
        {
          path:NEW_CHALLENGE_Path,
          title:'New challenge',
          adminRole: Boolean(isAdmin  || isInnovator)
        },
        {
          path:PROPOSE_CHALLENGE_path,
          title:'Propose challenge',
          adminRole: canProposeChallenge
        },
      ]
    },
    {
      path:CaseStudyPath,
      title:'Case studies',
    },
    {
      path:'/',
      title:'Help ',
      sub:[
        {
          path:ABOUT_US_Path,
          title:'About NOVA',
          adminRole:null,
        },
        {
          path:NOVA_RESEARCH_Path,
          title:'NOVA Research',
          adminRole:null,
        },
        {
          path:CONTACTUS_Path,
          title:'Contact Us',
          adminRole:null
        },
        
      ]
    },
    {
      path:'/',
      title:'Access Requests',
      buttonEle:true,
      sub:[
        {        
          path:'VOIS',
          title:'Vois'
        },
        {        
          path:'eris',
          title:'Nova'
        }
      ]
    },
  ]


  return (
    <Fragment>
      
      <nav className={classes.navsec}>
        <Container>
          <div className={classes.menuSection}>
            <div className={classes.menuSystem}>
              <button type="button" onClick={toggleMenuHandler} className={classes.menuHumbergar}>
                {openMenu ? <span className={clsx(classes.colorIcon, classes.burger, 'icon-close')} /> : <span className={clsx(classes.colorIcon,classes.burger, 'icon-menu')} />  } 
              </button>
              <HeaderLogo logoUrl={logoUrl} onLogoClick={onLogoClick} />
              <HeaderDomains navigateToDomain={navigateToDomain} domains={domains} />
            </div>
            
            {/* LEFT NESTED NAVIGATION */}
            <div className={openMenu ? classes.navlist : classes.leftNestedNavContainer} >
                    <MenuItem 
                      domains={domains}
                      navbarItem={NavbarList} 
                      onHandleInstanceValue={onHandleInstanceValue}  
                      whiteListDomainLoading={whiteListDomainLoading} 
                      closeSidebarMenu={closeSidebarMenu}
                    />
            </div>

            <div className={classes.rightNestedNavContainer}>
              <button
                onClick={() => submitYourIdea(false)}
                type="button"
                className={classes.postIdea}
                variant="contained"
              >
                Post an Idea
              </button>

              <HeaderUserBalance userBalance={userBalance} userName={userName} />
             
             <div className={classes.profilePicHolder}>
                <img
                  src={userPhoto}
                  className={classes.profilePic}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null
                    currentTarget.src = defaultPhoto
                  }}
                  alt="User Profile"
                />
                <div
                  className={classes.notifications}
                  onClick={() => setShowNotificationsMenu(true)}
                >
                  {notifications.isLoading ? '...' : notifications.totalUnread}
                  {showNotificationsMenu && (
                    <ClickAwayListener onClickAway={() => setShowNotificationsMenu(false)}>
                      <div style={{ position: 'relative', left: -50 }}>
                        <NotificationsMenu
                          id="notifications-menu"
                          notifications={notifications.notifications}
                          activities={notifications.activities}
                          unreadNotifications={notifications.unreadNotifications}
                          unreadActivities={notifications.unreadActivities}
                          markNotificationsRead={markNotificationsRead}
                        />
                      </div>
                    </ClickAwayListener>
                  )}
                </div>

                <div
                  className={clsx(classes.badge, classes.burger, 'icon-menu')}
                  onClick={() => setShowNavList(prev => !prev)}
                >
                  {showNavList && (
                    <ClickAwayListener onClickAway={() => setShowNavList(false)}>
                      <div className={classes.navList}>
                        <SimpleMenu id="user-menu" showAdmin={showAdmin} />
                      </div>
                    </ClickAwayListener>
                  )}
                </div>
              </div>

             

            </div>
          </div>
        </Container>
      </nav>
      <div className={classes.navDummyFill} />
    </Fragment>
  )
}

export default withStyles(styles)(Navbar)
