import format from 'date-fns/format';
import React from 'react'
import defaultAvtar from 'img/default-photo.png'
import newDefault from 'img/investors.svg'

const TableBody = ({ tableData, columns, classes }) => {
  // console.log('body',tableData,columns)
  return (
    <tbody>
      {tableData.map((data, i) => {
        // console.log("inside",data)
        let dataformat;

        if (data.created !== undefined && data.created !== null) {
          dataformat = format(new Date(data.created), 'PPP')
        }

        let emailData = data.email
        let stage = data.stage
        let amount = data.amount
        return (
          <tr key={i} >
            <>
              <td ><div className='middle'><img src={data.photoSrc ? data.photoSrc === '/images/default-photo.png' ? newDefault : data.photoSrc : newDefault} /> {data.name}</div></td>
              <td >{emailData}</td>
              <td >{dataformat}</td>
              <td >{stage}</td>
              <td >{amount}</td>
            </>

          </tr>
        );
      })}
    </tbody>
  );
};

export default TableBody;