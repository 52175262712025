import React, { Fragment, useState } from 'react'
import withStyles from '@material-ui/core/styles/withStyles'

import * as PropTypes from 'prop-types'

const Button = props => {
    const {classes, text, width, clickCallBack,disabled} = props
    return(
        <button 
            style={{width: `${width}px`}} className={ disabled ?  classes.button_shell + ' ' +  classes.vf_disabled : classes.button_shell}
            onClick={e => clickCallBack(e)}
            
        >
            {text}
        </button>
    )
}

const styles = theme => ({
    
    button_shell:{
        height: '44px',
        border: '1px solid #6551FF',
        backgroundColor: '#FFFFFF',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#6551FF',
        fontFamily: 'VodafoneRg-Regular',
        fontSize: '18px',
        borderRadius: '22.5px',
        cursor: 'pointer',
        transition: '0.5s',
        '&:hover':{
            backgroundColor: '#6551FF',
            color: '#FFFFFF',
        },
    },
    vf_disabled:{
        cursor:'default',
        'pointer-events':'none',
        color:'rgba(0, 0, 0, 0.26)',
        boxShadow: 'none',
        backgroundColor:' rgba(0, 0, 0, 0.12)',
        border:'0px'
    },
})

export default withStyles(styles)(Button)


Button.defaultProps = {
    text: 'button text',
    width: 145
}

Button.propTypes = {
    text: PropTypes.string.isRequired,
    width: PropTypes.number,
    clickCallBack: PropTypes.func.isRequired,
}