import { ABOUT_US_SUCCESS } from 'actions/sync/about_us/Constants'

const initialState = {
  aboutUsData: {},
}

const AboutusReducer = (state = initialState, action) => {
  // console.log(`Data response2: ${JSON.stringify(action.response)}`)
  switch (action.type) {
    case ABOUT_US_SUCCESS:
      return {
        ...state,
        aboutUsData: action.response.aboutUsData,
      }
    default:
      return state
  }
}

export default AboutusReducer
