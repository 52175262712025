const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  container: {
    display: 'flex',
    minHeight: '50vh',
    minWeidth: '50vh',
    flexFlow: 'row wrap',
    '& > div': {
      height: theme.spacing(67),
      width: theme.spacing(38.75),
      margin: 4,
    },
  },
  columnContainer: {
    height: '400px', // So that grids 1 & 4 go all the way down
    minHeight: 150, // Give minimum height to a div
    // border: '1px solid black',
    // border: '1px solid black',
    overflow: 'auto',
    //    fontSize: 30,
    textAlign: 'center',
  },
  inviteContainer: {
    height: '200px', // So that grids 1 & 4 go all the way down
    // minHeight: 50, // Give minimum height to a div
    // border: '1px solid black',
    // border: '1px solid black',
    overflow: 'auto',
    //    fontSize: 30,
    textAlign: 'center',
  },
  divFirst: {
    display: 'flex',
    alignItems: 'center',
  },
  whiteBackground: {
    backgroundColor: 'white',
  },
  icon: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  formControl: {
    margin: theme.spacing(3),
  },
  border: {
    bgcolor: 'background.paper',
    m: 1,
    style: { width: '5rem', height: '5rem' },
    borderColor: 'text.primary',
  },
})

export default styles
