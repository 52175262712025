const styles = theme => ({
    background:{
        backgroundColor: "#fbfaff", //body should have this background color in the main .css for the new site design 
        // padding: "32px 85px 32px 85px"
        paddingTop:'32px',
        paddingBottom:'32px'
    },
    top_section_text: {
        display: "flex",
        alignItems: "center", 
        justifyContent: "space-between", 
    },
    title: {
        fontSize: theme.fontSize.fontsize16,
        fontFamily: theme.fontFamily.fontFamilyRegular,
        color: theme.palette.Text.TextBlack33,
        ['@media (min-width:600px)']: { 
            fontSize: theme.fontSize.fontsize32,
        },
    },
    noIdeaImg: {
        height: '288px',
    },
    noIdeas: {
        fontFamily: 'VodafoneRg-Regular',
        fontSize: '47.8px',
        color: '#333333',
    },
    purpleNo: {
        fontFamily: 'VodafoneRg-Bold',
        color: '#7836FF'
    },
    investedIdeasNumber: {
        fontFamily:  theme.fontFamily.fontFamilyRegular,
        fontSize:  theme.fontSize.fontsize16,
        color: theme.palette.BlueText.TextLightBlue,
        cursor: 'pointer',
        paddingTop: "16px",
        background:'transparent',
        border:'0',
        ['@media (min-width:600px)']: { 
            fontSize: theme.fontSize.fontsize20,
        },
    },
    IdeaGridDraft:{
        display: "grid",
        marginTop: "32px",
        marginBottom: "60px",
        gridTemplateColumns: "repeat(2,minmax(128px,1fr))",
        gridGap: '8px',
        alignItems: "center",
        justifyItems: "center",
        ['@media (min-width:480px)']: { 
            gridTemplateColumns: "repeat(2,minmax(144px,1fr))",
        },
        ['@media (min-width:1024px)']: { 
            gridTemplateColumns: "repeat(3,minmax(238px,1fr))",
        },
    },
    IdeaGrid: {
        display: "grid",
        marginTop: "32px",
        marginBottom: "60px",
        gridTemplateColumns: "repeat(2,minmax(128px,1fr))",
        gridGap: '8px',
        alignItems: "center",
        justifyItems: "center",
        ['@media (min-width:480px)']: { 
            gridTemplateColumns: "repeat(3,minmax(144px,1fr))",
        },
        ['@media (min-width:1024px)']: { 
            gridTemplateColumns: "repeat(4,minmax(238px,1fr))",
        },
    }
  })
  

export default styles
