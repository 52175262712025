
const styles = theme => ({
    background:{
        backgroundColor: "#fbfaff", // body should have this background color in the main .css for the new site design 
    },
    content_container:{
        paddingLeft: "6%",
        paddingRight: "6%"
    },
    upper_background: {
        display: "flex",
        alignItems: "center", 
        justifyContent: "space-between", 
    },
    title: {
        fontFamily: 'VodafoneRg-Regular',
        fontSize: "32px",
        color: "#333333",
    },
    invested_ideas_number: {
        fontFamily: 'VodafoneRg-Regular',
        fontSize: "20px",
        paddingTop: "16px",
        color: "#7836FF",
    },
    lower_background: {
        display: "flex",
        justifyContent: "center", 
    },
    create_idea_box: {
        backgroundColor: "#FFFFFF",
        width: "780px",
        boxShadow: "4px 4px 20px 0 #EFEFEF",
        marginBottom: "48px",
        borderRadius: "6px",
        paddingLeft: "100px",
        paddingRight: "100px",
    },
    create_title: {
        textAlign: "center",
        fontFamily: "VodafoneRg-Regular",
        color: "#333333",
        fontSize: "40px",
        lineHeight: "40px",
        paddingTop: "48px",
    },
    idea_title_in: {
        fontFamily: "VodafoneRg-Regular",
        fontSize: "18px",
        color: "#333333",

    },
    idea_title_char_limit: {
        fontFamily: "VodafoneRg-Regular",
        fontSize: "14px",
        color: "#2A2A2A",
        float: "right",
        paddingTop: "4px",
        paddingRight: "15px",
    },
    idea_title_chars: {
        fontFamily: "VodafoneRg-Bold",
        color: "#6551FF",
        fontSize: "16px",
    },
    secondary_header: {
        fontFamily: "VodafoneRg-Regular",
        color: "#333333",
        fontSize: "32px",
        lineHeight: "40px",
        marginTop: "32px",
    },
    smaller_subtitle:{
        fontFamily: "VodafoneRg-Regular",
        color: "#333333",
        fontSize: "18px",
        lineHeight: "24px",
        margin: "15px 0 15px 0",
    },
    vertical_line: {
        height: '233px',
        width: '0.8px',
        backgroundColor: '#CCCCCC',
        alignSelf: 'center',
        justifySelf: 'center',
        marginLeft: '110px',
        marginRight: '40px',
    },
    horizontal_line: {
        height: '0.8px',
        width: '780px',
        backgroundColor: '#CCCCCC',
        alignSelf: 'center',
        justifySelf: 'center'
    },
    description_grid: {
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        columnGap: "20px",
        rowGap: "8px",
        marginBottom: '32px',
    },
    category_select: {
        width: '380px', 
        height: '48px',
        border: '1px solid #bfbfbf',
        borderRadius: '6px',
        '& .MuiInputBase-input': {
            padding: '7px 10px 6px 12px'
        },
        '& .MuiSelect-select:focus':{
            backgroundColor: '#FFFFFF'
        }
    },
    formLabel: {
        '& .MuiFormControlLabel-root': {
            margin: '10px'
        }
    },
    checkbox_formgroup: {
        color: '#333333',
        width: '100%',
        '& .MuiTypography-body1': {
            fontFamily: "VodafoneRg-Regular",
            fontSize: '18px',
            lineHeight: '24px',
            height: '24px',
        },
    },
    view_all_challenges: {
        textDecoration: 'underline',
        cursor: 'pointer',
        fontFamily: 'VodafoneRg-Regular',
        fontSize: "18px",
        paddingTop: "16px",
        color: "#7836FF",
    },
    idea_preview: {
        position: 'absolute',
        zIndex: 10,
        paddingTop: '150px',
        display: 'flex',
        justifyContent: 'center',
        minHeight: '100%',
        height: 'auto',
        top: 0
    },
    faded_out: {
        position: 'fixed',
        backgroundColor: '#000000',
        zIndex: 9,
        width: '100%',
        height: '100%',
        top: 0,
        left:0,
        opacity: 0.7
    },
    error_message:{
        fontSize: '12px',
        color: '#E60000'
    },
    singleTextAreaBox:{
        width:'100%',
        marginBottom:'2.5rem'
    },
    singleTextArea:{
        width:'100%',
        resize: 'none',
        marginBottom: '8px',
        padding: '16px'
    },
    radio_challenge:{
        "& > span":{
            opacity:0,
            visibility:'Hidden',
            height:0
        }
    },
    radioinput:{
        display: 'flex',
        flexWrap:'wrap', 
        justifyContent: 'space-between' 
    },
    ideaChallengeBox:{
        maxWidth:'380px'
    },
    challengeWrpbox:{
        '& .MuiFormGroup-root':{
        display: 'flex',
        flexWrap:'wrap', 
        justifyContent: 'space-between' ,
        flexDirection:'unset',
        }
    },
    checkboxRoot: {
        '&:hover': {
          backgroundColor: 'unset',
        },
        color: 'black',
        '& .icon-approved': {
          color: 'black',
          fontSize: '38px',
          width: '33px',
          height: '33px',
          border: '1px solid transparent',
          borderRadius: '4px',
          boxShadow: 'none',
          '@media (max-width:767px)':{
            fontSize:'24px',
            height:'24px'
          },
          '&:before': {
            marginTop: '-5px',
            display: 'block',
          },
        },
      },
      radioNotChecked: {
        color: 'black',
        width: '33px',
        fontSize: '38px',
        height: '33px',
        border: '1px solid #B6B6B6',
        borderRadius: '4px',
        boxShadow: '0 0 3px #666666 inset',
        '@media (max-width:767px)':{
          fontSize:'24px',
          height:'24px',
          width:'24px'
        },
      },
  })
  

export default styles
