import createStyles from '@material-ui/core/styles/createStyles'

const styles = theme =>
  createStyles({
    block:{

      display: 'flex', 
      flexDirection:'column',
      
      '@media(min-width:768px)':{
        justifyContent: 'center',
        alignContent: 'center',
        height: '397px',
        flexDirection:'inherit'
      },
    },
    icon: {
      '@media(min-width:768px)':{
        width: '397px',
        margin: '0',
        maxWidth:'100%',
    },
    width:'100%',
    maxWidth:'290px',
    margin:'0 auto',
    },
    number: {
      '@media(min-width:768px)':{
        fontSize:'150px',
        marginRight: '20px',
        marginLeft: '20px',
      },
      fontFamily: "VodafoneRg-Bold", 
      fontSize: '100px', 
      color: "#6551FF", 
      opacity: '0.1',
      textAlign: 'center',
      marginLeft:'0',
      marginRight:'0',
      marginTop:'0',
      marginBottom:'0',
    },
    title:{
        fontFamily: 'VodafoneRg-Bold',
        fontSize: '23px',
        textAlign: 'center',
    },
    description:{
        fontFamily: 'VodafoneRg-Regular',
        fontSize: '18px',
        textAlign: 'center',
    }, 
    textBlock: {
      
      '@media(min-width:768px)':{
        display: 'flex', 
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',
        width: '278px',
      },

    },
    mobFlexOrder:{
      order:3,
      '@media(min-width:768px)':{
        order:'initial'
      }
    },
    iconFlexOrder:{
      order:2,
      '@media(min-width:768px)':{
        order:'inherit'
      }
    }

        
  })
  

export default styles
