import { connect } from 'react-redux'
import AnalyticsService from 'support/analytics'
import { setCurrentDomain } from 'actions/sync/domain'
import { setLoggedOut } from 'actions/sync/auth'
import View from './View'

const mapStateToProps = state => {
  return {
    loggedIn: state.auth.loggedIn,
    logging: state.auth.logging,
    domain: state.data.domain.currentDomain,
    hasDomainLoaded: state.data.domain.dataLoaded,
    isAdmin: state.data.user.admin,
    isInnovator: state.data.user.isInnovator,
    isDomainInMaintenance: state.data.maintenance.isDomainInMaintenance,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setDomain: domain => {
      dispatch(setCurrentDomain(domain))
    },
    onRouteChange: page => {
      AnalyticsService.trackPageView(page)
    },
    onMaintenanceDomain: () => {
      dispatch(setLoggedOut())
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(View)
