const styles = () => ({
  contributorElement: {
    position: 'relative',
    width: '141px',
  },
  tooltipWidth: {
    maxWidth: '400px',
    minWidth: '400px',
  },
})

export default styles
