import React, { useState, Fragment } from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import good_img from 'img/submit.png'
import close_img from 'img/cancel-or-no-ideas.png'
import styles from './View.Style'

const SuccessPopup = props => {
  const {
    classes,
    onClickClose
  } = props

  
  return (
    <div className={classes.container}>
        <img src={good_img} className={classes.img} />
        <div className={classes.text}>
            Your request is shared with the investor.
        </div>
        <img 
            src={close_img} 
            className={classes.close} 
            onClick={onClickClose}
        />
    </div>
  )
}



export default withStyles(styles)(SuccessPopup)
