const styles = theme => ({
    background:{
        backgroundColor: "#fbfaff",
        fontFamily: 'VodafoneRg-Regular',
        color: '#333333'
    },
    standard_button: {
        boxSizing:'border-box',
        minWidth: '114px',
        height: 'auto',
        borderRadius: '22.5px',
        border: '1px solid #6551FF',
        color: '#6551FF',
        fontFamily: 'VodafoneRg-Regular',
        fontWeight:'400',
        fontSize: '1.125rem',
        cursor: 'pointer',
        textAlign:'center',
        padding:'0.5rem',
        backgroundColor: '#FFFFFF',
        transition: '0.5s',
        display:'inline-block',
        '&:hover':{
            backgroundColor: '#6551FF',
            color: '#FFFFFF'
        }
    },
    heroContainer: {
        height: '400px',
        backgroundColor: '#000000',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'white',
        textAlign: 'center',
        fontFamily: 'VodafoneLt-Regular',
        '& div': {
            margin: '20px'
        }
    },
    heroContent: {
        position: 'relative'
    },
    heroImg:{
        objectFit: 'cover',
        objectPosition: 'center',
        position: 'absolute',
        height: '100%',
        width: '100%',
        opacity: '0.8'
    },
    postIdea: {
        height: "44px",
        alignSelf: "center",
        borderRadius: "44px",
        backgroundColor: "#F63F40",
        color: "#FFFFFF",
        width: "161px",
        border: "none",
        cursor: "pointer",
        fontFamily: 'VodafoneRg-Regular',
        fontSize: "18px",
        whiteSpace: "nowrap",
        overflow: "hidden",
        margin: 0,
    },
    generic_holder:{
        // display: 'flex',
        // padding: '48px 85px',
        // backgroundColor: 'white',
        width:'100%',
        
        // justifyContent: 'space-evenly',
        // flexWrap: 'wrap',
        // flexDirection: 'row',
        '@media (max-width:480px)':{
            padding:'0'
        }
    },
    info_card:{
        width: '580px',
        minHeight: '234px',
        minWidth: '314px',
        border: '1px solid #CCCCCC',
        borderRadius: '6px',
        marginBottom: '10px',
    },
    info_card_bulk:{
        display: 'flex',
        minHeight: '182px',
    },
    infoCardSection:{
        width: '50%',
        margin: '21px 0px',
        padding: '0px 21px',
        
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        
        '& .countHeading':{
            display:'flex',
            alignItems:'center'
        },

        '& h3' :{
            fontWeight:'normal',
            color: '#333333',
            fontSize: theme.fontSize.fontSize24,
            fontFamily: theme.fontFamily.fontFamilyBold,
            margin:0,

            '& p' :{
                fontSize: '12px',
                fontFamily:theme.fontFamily.fontFamilyRegular,
                margin:0,
            },
        },

        
        '& img':{
            width:'24px',
            marginRight:'4px',
            display:'block'
        }

        


    },
    number_info: {
        color: '#6551FF',
        fontSize: '48px',
        marginTop: '29px'
    },
    info_card_footer:{
        height:'51px',
        backgroundColor: '#F6F5FF',
        borderRadius: '0 0 5px 5px',
        display: 'flex'
    },
    info_card_footer_section:{
        width: '50%',
        color: '#6D45FF',
        fontSize: '16px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    info_card_footer_subsection:{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    generic_list:{
        display:'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        margin:'80px 0px'
    },
    chevron_right:{
        width: '16px',
        paddingBottom: '5px',
        transform: 'rotate(90deg)',
        cursor: 'pointer'
    },
    title_text:{
        fontSize:'40px',
        textAlign:'center'
    },
    alignbtn:{textAlign:'center',margin:'24px 0px'},
    subtitle_text: {
        fontSize: '24px', 
        // maxWidth: '785px',
        textAlign: 'center',
        lineHeight: '32px',
        fontFamily: 'VodafoneLt-Regular',
        marginTop: '16px'
    },
    contributors: {
        backgroundColor: '#edebff',
        minHeight: '580px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'relative'
    },
    contributors_container: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap'
    },
    contributors_bg: {
        height: '100%',
        width: '100%',
        position: 'absolute',
        objectFit: 'cover',
        objectPosition: 'top'
    },
    btnTrasparents :{
        backgroundColor:'transparent',
        border:0,
        fontSize:'0.925rem',
        color:'#6D45FF',
        margin:0,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        cursor:'pointer'
    },
    exploreheading:{
        fontSize:'60px',
        '@media (max-width:767px)':{
            fontSize:'42px'
        }
    },
    exploresubheading:{
        fontSize:'30px',
        '@media (max-width:767px)':{
            fontSize:'20px'
        }
    }
  })
  

export default styles
