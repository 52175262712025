const styles = theme => ({
  root: {
    listStyle: 'none',
    padding: 0,
    margin: 0,
    position: 'relative',
    '& > li': {
      display: 'flex',
      borderTop: '1px solid #dad7d7',
      padding: theme.spacing(2, 1),
    },
    '& > li:first-child': {
      border: 'none',
    },
  },
  textField: {
    width: '100%',
  },
})

export default styles
