import { connect } from 'react-redux'
import { getSponsorSuggestions } from 'services/challenge_steps'
import { postAskForInvestments } from 'services/create_idea'
import { resetSponsorSuggestions } from 'actions/sync/challenge_steps'
import { hideDialog } from 'support/popup_dialogs/modal_alert_controller/ModalActions'
import i18n from 'support/i18n'
import { showPopupSuccessSnackbar, showPopupErrorSnackbar } from 'support/popup_dialogs'
import View from 'components/send_email_dialog/View'

const mapStateToProps = state => ({
  usersSuggestion: state.data.challengeSteps.sponsorSuggestions,
  isLoadingUsers: state.data.challengeSteps.sponsorStarted,
  isLoading: state.data.createIdea.isLoadingAskForInvestments,
  currencyIcon: state.data.domain.domainConfiguration.currencyIcon,
  searchEmailLabel: i18n.t('IDEA.REQUEST_INVEST.SEARCH_HEADING'),
  emailTextLabel: i18n.t('ASK_FOR_INVESTMENT_FORM.EXPLAIN_WHY'),
})

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getSuggestions: username => {
      dispatch(getSponsorSuggestions(username))
    },
    onCloseDialog: () => {
      dispatch(hideDialog())
    },
    onUnmount: () => {
      dispatch(resetSponsorSuggestions())
    },
    onSendInvite: (usersEmails, content) => {
      const { ideaId } = ownProps
      const onSuccess = () => {
        dispatch(hideDialog())
        showPopupSuccessSnackbar(i18n.t('IDEA.REQUEST_INVEST.SUCCESS_HEADING'), dispatch)
      }
      const onFailure = () => {
        dispatch(hideDialog())
        showPopupErrorSnackbar(i18n.t('IDEA.REQUEST_INVEST.ERROR_HEADING'), dispatch)
      }
      dispatch(postAskForInvestments(ideaId, usersEmails, content, onSuccess, onFailure))
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(View)
