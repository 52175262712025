import React, { Fragment } from 'react'
import * as PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'
import style from './View.Style'
import EmailsSearchInput from './components/emails_search_input/View'
import SelectedUsersList from './components/selected_users_list/View'
import SearchedUserElement from './components/searched_user_element/View'
import UsersNotFound from './components/users_not_found/View'

const EmailsSearch = props => {
  const {
    classes,
    onRetrieveSuggestions,
    isLoading,
    selectedUsers,
    usersSuggestion,
    onUserClicked,
    firstSearchDone,
    searchEmailLabel,
  } = props
  return (
    <Fragment>
      <EmailsSearchInput
        searchEmailLabel={searchEmailLabel}
        onRetrieveSuggestions={onRetrieveSuggestions}
        isLoading={isLoading}
      />
      {firstSearchDone && (
        <Fragment>
          <SelectedUsersList
            users={selectedUsers.map(user => ({
              photoSrc: user.photoSrc,
              fullName: user.fullName,
            }))}
          />
          <div className={classes.resultsWrapper}>
            {usersSuggestion.length > 0 || isLoading ? (
              usersSuggestion.map(user => (
                <SearchedUserElement
                  key={user.email}
                  photoSrc={user.photoSrc}
                  balance={user.balance}
                  fullName={user.fullName}
                  investedFunds={user.investedFunds}
                  isSelected={!!selectedUsers.find(innerUser => innerUser.email === user.email)}
                  onItemClicked={() => onUserClicked(user)}
                />
              ))
            ) : (
              <UsersNotFound />
            )}
          </div>
        </Fragment>
      )}
    </Fragment>
  )
}

EmailsSearch.propTypes = {
  onRetrieveSuggestions: PropTypes.func.isRequired,
  onUserClicked: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  firstSearchDone: PropTypes.bool.isRequired,
  selectedUsers: PropTypes.arrayOf(
    PropTypes.shape({
      email: PropTypes.string,
      photo: PropTypes.string,
      balance: PropTypes.string,
      fullName: PropTypes.string,
      investedFunds: PropTypes.string,
    }),
  ).isRequired,
  usersSuggestion: PropTypes.arrayOf(
    PropTypes.shape({
      email: PropTypes.string,
      photo: PropTypes.string,
      balance: PropTypes.string,
      fullName: PropTypes.string,
      investedFunds: PropTypes.string,
    }),
  ).isRequired,
  searchEmailLabel: PropTypes.string.isRequired,
}

export default withStyles(style)(EmailsSearch)
