
const styles = theme => ({
    investortable: {
        fontFamily: theme.fontFamily.fontFamilyregular,
        fontSize: '16px',
        '& table': {
            width: '100%',
            borderCollapse: 'collapse',
            border: '0',
            borderSpace: '0',

        },
        '& th.default': {
            cursor: 'pointer',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: '90% 24px',
            backgroundSize: '12px'
        },
        '& th.down': {
            cursor: 'pointer',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: '90% 24px',
            backgroundSize: '12px'

        },
        '& th.up': {
            cursor: 'pointer',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: '90% 24px',
            backgroundSize: '12px'
        },
        '& td ,& th': {
            padding: '8px',
            fontFamily: theme.fontFamily.fontFamilyBold,
            fontSize: '18px',

        },
        '& thead': {
            backgroundColor: '#d1ccfb',
        },
        '& th': {

            padding: '16px',
            textAlign: 'left',
            color: '#000',
            fontSize: '18px',
            fontWeight: '700',
            backgroundSize: '12px'
        },
        '& td': {
            padding: '16px',
            textAlign: 'left',
            color: '#333333',
            fontSize: '18px',
            fontWeight: '700',
            fontFamily: theme.fontFamily.fontFamilyregular,
            '& img': {
                width: '40px',
                height: '40px',
                borderRadius: '50px',
                // border: '1px solid',
                marginRight: '8px'
            },
            '& div': {
                display: 'flex',
                alignItems: 'center'
            }
        },

    }
})

export default styles
