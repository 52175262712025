const styles = theme => ({
  resultsWrapper: {
    padding: theme.spacing(0, 1),
    overflowY: 'scroll',
    justifyContent: 'space-between',
    display: 'flex',
    flexWrap: 'wrap',
    maxHeight: '50vh',
    '& > div:nth-child(1)': {
      borderTop: 'none',
    },
    '& > div:nth-child(2)': {
      borderTop: 'none',
    },
    '& > div:nth-child(odd)': {
      marginRight: theme.spacing(1),
    },
    '& > div:nth-child(even)': {
      marginLeft: theme.spacing(1),
    },
    borderTop: '1px solid #E1E1E1',
  },
})

export default styles
