import { connect } from 'react-redux'
import AlertModalView from 'support/popup_dialogs/views/components/alert_modal_view/AlertModalView'
import { hideDialog } from 'support/popup_dialogs/modal_alert_controller/ModalActions'
import i18n from 'support/i18n'

const mapStateToProps = (state, ownProps) => {
  const { title, description, mainButtonText, cancelButtonText } = ownProps
  return {
    title,
    description,
    mainButtonText: mainButtonText || i18n.t('ACCEPT'),
    cancelButtonText: cancelButtonText || i18n.t('CANCEL'),
    cancelable: true,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const { onMainButtonClick, onCancelButtonClick } = ownProps
  return {
    mainButtonAction: onMainButtonClick,
    cancelButtonAction: onCancelButtonClick || (() => dispatch(hideDialog())),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AlertModalView)
