import { UserAgentApplication } from "msal";
import { Logger } from "msal";

export const requiresInteraction = errorMessage => {
    if (!errorMessage || !errorMessage.length) {
        return false;
    }

    return (
        errorMessage.indexOf("consent_required") > -1 ||
        errorMessage.indexOf("interaction_required") > -1 ||
        errorMessage.indexOf("login_required") > -1
    );
};

export const fetchMsGraph = async (url, accessToken) => {
    const response = await fetch(url, {
        headers: {
            Authorization: `Bearer ${accessToken}`
        }
    });

    return response.json();
};

export const isIE = () => {
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf("MSIE ") > -1;
    const msie11 = ua.indexOf("Trident/") > -1;

    // If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
    // const isEdge = ua.indexOf("Edge/") > -1;

    return msie || msie11;
};

export const GRAPH_SCOPES = {
    OPENID: "openid",
    PROFILE: "profile",
    USER_READ: "User.Read",
    MAIL_READ: "Mail.Read"
};

export const GRAPH_ENDPOINTS = {
    ME: "https://graph.microsoft.com/v1.0/me",
    MAIL: "https://graph.microsoft.com/v1.0/me/messages"
};

export const GRAPH_REQUESTS = {
    LOGIN: {
        scopes: [
            GRAPH_SCOPES.OPENID,
            GRAPH_SCOPES.PROFILE,
            GRAPH_SCOPES.USER_READ
        ]
    },
    EMAIL: {
        scopes: [GRAPH_SCOPES.MAIL_READ]
    }
};

export const msalApp = new UserAgentApplication({
    auth: {

         //clientId: "ffa25e67-06a1-42e2-9955-64b54d4ea4f8",
         //authority: "https://login.microsoftonline.com/600fc84d-b45f-4cc5-8ea4-63619872e13f",
         //redirectUri:"https://dev.eris.vodafone.com/eris/explore",
        // postLogoutRedirectUri: "https://dev.eris.vodafone.com/logout",


        clientId: "fdefd2ec-fad9-4e94-a123-4ee6e2798d93",
        //clientId: "Hqy8Q~wO8EJbMeUspmZ10ZnDs1Z3RN~yffK7gcPC",
        authority: "https://login.microsoftonline.com/68283f3b-8487-4c86-adb3-a5228f18b893",
        redirectUri:"https://nova.vodafone.com/eris/explore",
        postLogoutRedirectUri: "https://nova.vodafone.com/logout",
        prompt:"login",
        validateAuthority: true,
        navigateToLoginRequestUrl: false,
    },
    cache: {
        // cacheLocation: "sessionStorage",
        // storeAuthStateInCookie: isIE()
        storeAuthStateInCookie: false

    },
    system: {
        navigateFrameWait: 0,
        Logger: {
            error: console.error,
            errorPii: console.error,
            info: console.log,
            infoPii: console.log,
            verbose: console.log,
            verbosePii: console.log,
            warning: console.warn,
            warningPii: console.warn
        }
    }
    
});

