


import React, { Component, useState } from 'react'
import * as PropTypes from 'prop-types'
import { withStyles, Paper, Step, StepLabel, Stepper, Button } from '@material-ui/core'
import i18n from 'support/i18n'
import styles from './View.Style'

const ChallengeHeader = (props) =>{
    const { activeStep, classes, bannerContent, title, showStepper } = props
    const [showInfoMessage,setShowInfoMessage] = useState(true)

  const handleShowInfoMessageChange = () =>{
    setShowInfoMessage(!showInfoMessage)
  }

    return (
      <div className={classes.root}>
        
        {showInfoMessage && (
          <Paper id="info-message-box" className={classes.infoMessage}>
            <div className={classes.infoMessageContainer}>{bannerContent}</div>
            <Button
              disableFocusRipple
              disableTouchRipple
              disableRipple
              className={classes.closeButton}
              component="span"
              onClick={handleShowInfoMessageChange}
            >
              <span className="icon-close" />
            </Button>
          </Paper>
        )}
      </div>
    )
  }


export default withStyles(styles)(ChallengeHeader)
