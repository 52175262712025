import { HIDE_LOADER, SHOW_LOADER } from './ActionConstants'
import { checkLoaderType } from './Reducer.Utils'

const initialState = { retrievingData: 0 }

const reducer = (state = initialState, action) => {
  switch (checkLoaderType(action.type)) {
    case SHOW_LOADER:
      return { ...state, retrievingData: state.retrievingData + 1 }

    case HIDE_LOADER:
      return { ...state, retrievingData: state.retrievingData > 0 ? state.retrievingData - 1 : 0 }

    default: {
      return state
    }
  }
}

export default reducer
