const styles = theme => ({
  root: {
    padding: theme.spacing(2.5),
    
  },
  subtitle: {
    fontFamily:theme.fontFamily.fontFamilyBold,
    '@media (max-width:767px)':{
      fontSize:'14px'
    }
  }
  
})

export default styles
