import { withStyles } from '@material-ui/core/styles'
import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ChallengeCard from 'components_newui/challenge_card/View'
import styles from './View.Style'



const ChallengeCarousel = props => {
    const sliderSettings = {
        dots: true,
        autoplay:true,
        autoplaySpeed:1000,
        infinite: false,
        speed:1000,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 2,
              }
            },
              {
                breakpoint: 480,
                settings: {
                  slidesToShow: 1,
                }
              },
        ]
      };

    const {classes, challenges,navigateToChallengeDetail} = props
    // const [visibleChallenges, setvisibleChallenges] = useState(3)
    // const updatevisibleChallenges = () => {
    //     if (window.innerWidth < 800){
    //         setvisibleChallenges(1)
    //     } else if (window.innerWidth < 1050){
    //         setvisibleChallenges(2)
    //     }else{
    //         setvisibleChallenges(3)
    //     }
    // };

    // useEffect(() => {
    //     window.addEventListener('resize', updatevisibleChallenges);
    //     updatevisibleChallenges()
    // })

    // Populate the carousel
    // let challengePanels = []
    // let carouselContent = []
    // for (const [index, challenge] of challenges.entries()){
    //     challengePanels.push(<div style={{margin: '10px'}}><ChallengeCard onClick={onMainButtonClick} challenge={challenge}/></div>)
    //     if ((index+1) % visibleChallenges === 0 || index+1 === challenges.length){
    //         carouselContent.push(<div className={classes.flex_and_center}>{challengePanels}</div>)
    //         challengePanels = []
    //     }
    // }

    return(
        <div className={classes.container} >
            {/* <div className={classes.flex_and_center}> */}
                <Slider {...sliderSettings}>
                    {
                        challenges.map((challengeItem)=>{
                           return <div ><ChallengeCard key={challengeItem.id} urlID={challengeItem.id} onClick={()=>navigateToChallengeDetail(challengeItem.id)} challenge={challengeItem}  /></div>
                        })
                    }
                </Slider>
        </div>
    )
}


ChallengeCarousel.defaultProps = {
    bg_color: '#FFFFFF'
}
  

export default withStyles(styles)(ChallengeCarousel)