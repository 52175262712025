import React, { useState, Fragment } from 'react'
import * as PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  InputAdornment,
  CircularProgress,
} from '@material-ui/core'
import i18n from 'support/i18n'
import styles from './View.Style'
import Button from 'components_newui/button/View'

const RequestInvestment = props => {
  const {
    classes,
    myIdeasToInvestIn,
    isSendingInvestmentRequest,
    hasMyIdeasLoaded,
    onCancelClick,
    onSendRequestClick,
  } = props

  const inputLabel = React.useRef(null)
  const [labelWidth, setLabelWidth] = React.useState(0)
  React.useEffect(() => {
    if (hasMyIdeasLoaded && inputLabel && inputLabel.current) {
      setLabelWidth(inputLabel.current.offsetWidth)
    }
  }, [hasMyIdeasLoaded])

  const [ideaIdToInvestIn, setIdeaIdToInvestIn] = useState('')
  const [textToSend, setTextToSend] = useState('')

  const isValid = ideaIdToInvestIn && textToSend
  return (
    <div className={classes.root}>
      <div style={{
        marginBottom: '32px',
        fontSize: '32px',
        color: '#333333'
      }}>Request Investment</div>
      <FormControl variant="outlined" fullWidth>
        <div className={classes.subtext}>Select idea for investment</div>
        {!hasMyIdeasLoaded && (
          <TextField
            label={i18n.t('PROFILE.SELECT_IDEA_LABEL')}
            margin="normal"
            variant="outlined"
            disabled
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <CircularProgress size={20} />
                </InputAdornment>
              ),
            }}
          />
        )}
        {hasMyIdeasLoaded && (
          <Fragment>
            <InputLabel ref={inputLabel}>{i18n.t('PROFILE.SELECT_IDEA_LABEL')}</InputLabel>
            <Select
              value={ideaIdToInvestIn}
              onChange={event => setIdeaIdToInvestIn(event.target.value)}
              labelWidth={labelWidth}
            >
              {myIdeasToInvestIn.map(idea => (
                <MenuItem value={idea.id} key={idea.id}>
                  {idea.title}
                </MenuItem>
              ))}
            </Select>
          </Fragment>
        )}
        <div className={classes.subtext}>Write a message</div>
        <TextField
          className={classes.inputContainer}
          id="outlined-multiline-static"
          label={i18n.t('PROFILE.MESSAGE_LABEL')}
          multiline
          rows="4"
          value={textToSend}
          onChange={event => setTextToSend(event.target.value)}
          variant="outlined"
        />
        <div className={classes.buttons}>
          <Button 
            text={i18n.t('CANCEL')}
            onClick={onCancelClick}
            styled={false} />
          
          <Button 
            text={i18n.t('PROFILE.SEND_REQUEST')}
            onClick={() => onSendRequestClick(ideaIdToInvestIn, textToSend)}
            styled={true} />
        </div>
        
      </FormControl>
    </div>
  )
}

RequestInvestment.propTypes = {
  myIdeasToInvestIn: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  hasMyIdeasLoaded: PropTypes.bool.isRequired,
  isSendingInvestmentRequest: PropTypes.bool.isRequired,
  onCancelClick: PropTypes.func.isRequired,
  onSendRequestClick: PropTypes.func.isRequired,
}

export default withStyles(styles)(RequestInvestment)
