import { connect } from 'react-redux'
import { deleteKpi, getKpis } from 'services/create_idea'
import AlertModalView from 'support/popup_dialogs/views/components/alert_modal_view/AlertModalView'
import { hideDialog } from 'support/popup_dialogs/modal_alert_controller/ModalActions'
import i18n from 'support/i18n'
import { showPopupErrorSnackbar } from 'support/popup_dialogs'

const mapStateToProps = () => ({
  title: i18n.t('CONFIRMATION_MESSAGE'),
  description: i18n.t('EXPLORE_IDEA.DELETE_KPI_CONFIRM'),
  mainButtonText: i18n.t('ACCEPT'),
  cancelable: true,
  cancelButtonText: i18n.t('CANCEL'),
})

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    mainButtonAction: () => {
      const onSuccess = () => {
        dispatch(getKpis(ownProps.ideaId))
      }
      const onFailureKpis = () => {
        showPopupErrorSnackbar(i18n.t('ERROR'), dispatch)
      }
      dispatch(deleteKpi({ id: ownProps.id, ideaId: ownProps.ideaId }, onSuccess, onFailureKpis))
      dispatch(hideDialog())
    },
    cancelButtonAction: () => dispatch(hideDialog()),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AlertModalView)
