const styles = theme => ({
  container: {
    position: 'fixed',
    width: '100%',
    height: '80px',
    background: '#6551FF',
    bottom: 0,
    zIndex:'1300',
    display:'flex',
    justifyContent:'center',
    alignItems:'center'
  },
  img: {
    width: '48px',
    height: '48px'
  },
  text: {
    color: 'white',
    fontFamily: 'VodafoneRg-Regular',
    fontSize: '20px',
    marginLeft: '8px'
  },
  close: {
    position: 'fixed', 
    right: '16px',
    height: '40px',
    width: '40px',
    cursor: 'pointer',
    filter: 'invert(100%) sepia(100%) saturate(0%) hue-rotate(182deg) brightness(104%) contrast(101%)'
  }
})

export default styles
