import * as types from 'actions/sync/evaluation/Constants'

export const initialState = {
  activeStep: 0,
  data: [],
  items: 0,
  total: 0,
  hasMore: true,
  ideaevaluation: {
    // id: '',
    statusType: '',
    roundClosed: '',
    ownerEmail: '',
    ideaTitle: '',
    ideaText: '',
    boardReviewDate: '',
    boardStatus: '',
    feedbackForIdeaOwner: '',
    boardComment: '',
    ideaPitchDate: ' ',
    pitchStatus: '',
    images: [],
  },
  archiveidea: {
    // id: '',
    statusType: '',
    roundClosed: '',
    ownerEmail: '',
    ideaTitle: '',
    ideaText: '',
    boardReviewDate: '',
    boardStatus: '',
    feedbackForIdeaOwner: '',
    boardComment: '',
    ideaPitchDate: ' ',
    pitchStatus: '',
    images: [],
  },
  list: [],
  commentsList: [],
  evaluationLoading: false,
  expertGroups: [],
  memberUsersLoaded: false,
  usersOnExpertGroup: [],
  memberUsersTotal: 0,
  expertCommentMail: [],
}
const EvaluationAdminReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_EVALUATION_IDEA_BY_ID_STARTED:
    case types.SAVE_EVALUATION_BY_ID_STARTED:
    case types.SHARE_EVALUATION_BY_ID_STARTED:
      return {
        ...state,
        evaluationLoading: true,
      }

    case types.GET_EVALUATION_IDEA_BY_ID_SUCCESS:
      return {
        ...state,
        ideaevaluation: action.response.ideaevaluation,
        list: action.response.list,
        commentsList: action.response.list,
        // images: action.response.images,
        evaluationLoading: false,
      }
    case types.SAVE_EVALUATION_BY_ID_SUCCESS:
    case types.SHARE_EVALUATION_BY_ID_SUCCESS:
      return {
        ...state,
        evaluationLoading: false,
      }

    case types.GET_EVALUATION_IDEA_BY_ID_FAILURE:
    case types.SAVE_EVALUATION_BY_ID_FAILURE:
    case types.SHARE_EVALUATION_BY_ID_FAILURE:
      return {
        ...state,
        evaluationLoading: false,
      }

    // Evaluation Archived  idea reducers

    case types.GET_EVALUATION_ARCHIVED_IDEA_BY_ID_STARTED:
    case types.RESTORE_EVALUATION_ARCHIVED_IDEA_BY_ID_STARTED:
      return {
        ...state,
        evaluationLoading: true,
      }

    case types.GET_EVALUATION_ARCHIVED_IDEA_BY_ID_SUCCESS:
      return {
        ...state,
        archiveidea: action.response.archiveidea,
        list: action.response.list,
        commentsList: action.response.list,
        // images: action.response.images,
        evaluationLoading: false,
      }

    case types.RESTORE_EVALUATION_ARCHIVED_IDEA_BY_ID_SUCCESS:
      return {
        ...state,
        evaluationLoading: false,
      }
    case types.GET_EVALUATION_ARCHIVED_IDEA_BY_ID_FAILURE:
    case types.RESTORE_EVALUATION_ARCHIVED_IDEA_BY_ID_FAILURE:
      return {
        ...state,
        evaluationLoading: false,
      }

    case types.GET_EXPERT_GROUP_SUCCESS:
      return {
        ...state,
        expertGroups: action.response.expertGroups,
      }

    case types.GET_EXPERT_GROUP_STARTED:
      return {
        ...state,
        expertGroups: [],
        memberUsersLoaded: true,
      }
    case types.GET_USERS_EXPERT_GROUP_STARTED:
      return {
        ...state,
        usersOnExpertGroup: [],
        memberUsersLoaded: true,
      }
    case types.GET_USERS_EXPERT_GROUP_SUCCESS:
      return {
        ...state,
        usersOnExpertGroup: action.response.usersOnExpertGroup,
        memberUsersTotal: action.response.memberUsersTotal,
        memberUsersLoaded: false,
      }
    case types.POST_SHARE_IDEA_SUCCESS:
      return {
        ...state,
      }
    case types.GET_EVALUATION_IDEA_EXPERT_COMMENTS_MAIL_STARTED:
      return {
        ...state,
      }
    case types.GET_EVALUATION_IDEA_EXPERT_COMMENTS_MAIL_SUCCESS:
      return {
        ...state,
        expertCommentMail: action.response.expertCommentMail,
      }
    case types.GET_EVALUATION_IDEA_EXPERT_COMMENTS_MAIL_FAILURE:
      return {
        ...state,
      }

    case types.SET_LOGEDOUT:
      return initialState
    default:
      return state
  }
}

export default EvaluationAdminReducer
