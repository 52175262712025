import React, {Fragment} from 'react'
import { Button ,ClickAwayListener} from '@material-ui/core'
import Popover from '@material-ui/core/Popover';
import withStyles from '@material-ui/core/styles/withStyles'
import chevronDown from '../../img/Chev@2x.png'
import logo from '../../img/Logo@3x.svg'
import banner from '../../img/Hero banner.png'
import styles from './View.Style'

const Header = (props) => {
  const {classes} = props
  const [open, setOpen] = React.useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null)

  const showLogin = (event) => {
    setOpen((prev) => !prev)
    setAnchorEl(event.currentTarget)
  };

  const hideLogin = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  const boolOpen = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined;

  return (
    <Fragment>
      <div className={classes.header}>
        <img className={classes.headerImage} src={banner} alt="Banner" />
        <img className={classes.logo} src={logo} alt="logo" />
        <div className={classes.headerOverlay}>
          <span className={classes.welcomeSpan}>
            <p className={classes.welcomeHeader}>Welcome to Nova</p>
            <p className={classes.welcomeText}>Innovation without boundaries</p>
            <ClickAwayListener onClickAway={hideLogin}>
              <span>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.getStarted}
                  onClick={showLogin}
                >
                  Get started
                </Button>
                {open ? (
                  <Popover
                    id={id}
                    open={boolOpen}
                    anchorEl={anchorEl}
                    onClose={hideLogin}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                  >
                    {window.location.origin === 'http://localhost:3000' ||
                    window.location.origin === 'https://dev.eris.vodafone.com' ? (
                      <div className={classes.loginPopup}>
                        <p>Please choose a log in option</p>
                        <div className={classes.loginButtons}>
                          <Button
                            variant="contained"
                            color="primary"
                            className={classes.getStarted}
                            onClick={() => {
                              hideLogin()
                              props.onSignIn()
                            }}
                          >
                            Vodafone User
                          </Button>
                          <Button
                            variant="contained"
                            color="primary"
                            className={classes.getStarted}
                            onClick={() => {
                              hideLogin()
                              props.setShowExternalLogin(true)
                            }}
                          >
                            External User
                          </Button>
                        </div>
                      </div>
                    ) : (
                      <div className={classes.loginPopup}>
                        <p>Sign In</p>
                        <div className={classes.loginButtons}>
                          <Button
                            variant="contained"
                            color="primary"
                            className={classes.getStarted}
                            onClick={() => {
                              hideLogin()
                              props.onSignIn()
                            }}
                          >
                            Vodafone User
                          </Button>
                        
                        </div>
                      </div>
                    )}
                  </Popover>
                ) : null}
              </span>
            </ClickAwayListener>
          </span>
          <img className={classes.down} src={chevronDown} alt="Chevron Down" />
        </div>
      </div>
    </Fragment>
  )
}

export default withStyles(styles)(Header)
