import { connect } from 'react-redux'
import View from './View'
import {
  getExpertPool,
  getUsersOnExpertService,
  //postUsersOnExpertService,
  postShareIdeaExpertService,
} from 'services/evaluation'

import {
  showPopupErrorSnackbar,
  showPopupSuccessSnackbar,
  showPopupDialog,
} from 'support/popup_dialogs'

import i18n from 'support/i18n'
import { increaseEvaluationStep, decreaseEvaluationStep } from 'actions/sync/evaluation'
import Navigation from 'support/navigation'

const mapStateToProps = state => {
  return {
    expertGroups: state.data.evaluationAdmin.expertGroups,
    usersOnExpertGroup: state.data.evaluationAdmin.usersOnExpertGroup,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getExpertPool: () => {
      dispatch(getExpertPool())
    },
    getUsersOnExpertService: (groupId, ideaid, offset, limit) => {
      dispatch(getUsersOnExpertService(groupId, ideaid, offset, limit))
    },
    // postUsersOnExpertService: (id, groupId, ideaid, offset, limit) => {
    //   dispatch(postUsersOnExpertService(groupId, ideaid, offset, limit))
    // },
    postShareIdeaExpertService: (id, groupUserData, emailMessage, manualEmail) => {
      const onSuccess = data => {
        showPopupSuccessSnackbar('Idea shared successfully', dispatch)
      }
      const onFailure = ({ data }) => {
        const messageTag =
          data && data.error && data.error.error
            ? `BACKEND_ERRORS.${data.error.error}`
            : 'BACKEND_ERRORS.UNEXPECTED'
        showPopupErrorSnackbar(i18n.t(messageTag), dispatch)
      }
      dispatch(
        postShareIdeaExpertService(
          id,
          groupUserData,
          emailMessage,
          manualEmail,
          onSuccess,
          onFailure,
        ),
      )
    },
    showError: msz => {
      showPopupErrorSnackbar(i18n.t(msz), dispatch)
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(View)
