import React from 'react'
import * as PropTypes from 'prop-types'
import withTheme from '@material-ui/core/styles/withTheme'
import withStyles from '@material-ui/core/styles/withStyles'
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import styles from './View.Style'

const NxtSemiCircleGraph = props => {
  const { classes, children, maxValue, value, color, strokeLinecap, theme, trailColor } = props

  return (
    <div className={classes.container}>
      <CircularProgressbarWithChildren
        circleRatio={0.5}
        value={value}
        maxValue={maxValue || 100}
        styles={buildStyles({
          strokeLinecap,
          pathColor: theme.palette[color].main,
          trailColor,
          rotation: 0.75,
        })}
      >
        {children}
      </CircularProgressbarWithChildren>
    </div>
  )
}

NxtSemiCircleGraph.defaultProps = {
  value: '0',
  strokeLinecap: 'butt',
  maxValue: '100',
  color: 'primary',
  trailColor: '#999999',
}

NxtSemiCircleGraph.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.oneOf(['primary', 'secondary']),
  strokeLinecap: PropTypes.string,
  theme: PropTypes.shape({}).isRequired,
  trailColor: PropTypes.string,
}

export default withTheme(withStyles(styles)(NxtSemiCircleGraph))
