import { withStyles } from '@material-ui/core/styles'
import styles from './View.Style'
import {Link,useParams} from 'react-router-dom'
import React, { Fragment, useEffect, useState } from 'react'
import {getStyledStatus} from 'services/GetStatusStyle'
import default_img from 'img/hero_image.png'
import strip_tags from 'support/removeTags'
import  ChallengeTag  from '../challengeTag/View'

import * as pages from '../../common_constants/Pages'
import NavigationService from '../../support/navigation/NavigationService'


const Idea_Panel = props => {
    // const {urlId} = useParams();
    const {classes, idea, onMainButtonClick,urlId} = props
    const { 
        title: idea_name, 
        status: idea_state, 
        images,
        challenged,
        id,
        textWithoutTags: idea_description, 
        pledged: investment_total, 
        investment,
        investors: no_of_investors,
    } = idea

    const [ideaStatusName, setIdeaStatusName] = useState('')
    const [ideaStatusImg, setIdeaStatusImg] = useState('')
    const [ideaStatusColor, setIdeaStatusColor] = useState('')

    const idea_img = (images && images.length > 0 && images[0].url) || default_img
    let trimmed_description = (idea_description && strip_tags(idea_description)) || ''
    if (trimmed_description.length > 60) {
        trimmed_description = `${trimmed_description.substring(0,60)}...`
    }

    const internationalNumberFormat = new Intl.NumberFormat('en-US')
    const formatted_investment_total = (investment_total && internationalNumberFormat.format(investment_total)) || investment || null

    useEffect(()=> {
        const {name,image,color} = getStyledStatus(idea_state)
        setIdeaStatusColor(color)
        setIdeaStatusName(name)
        setIdeaStatusImg(image)
    }, [idea_state])

    // const onSelect = () =>{
    //     onMainButtonClick(id)
    // }


    const ROUTES = NavigationService.getRoutes()
    let detailIdeaPath = ROUTES[pages.DETAIL_IDEA].pathWithOutID;

// console.log("idea panel link ========> ", detailIdeaPath)

   
    return(
    <Link to={`${detailIdeaPath}/${urlId}` }>
        <div className={classes.container}  >
            <div className={classes.label} style={{backgroundColor: ideaStatusColor}}>
                <img className={classes.img_icon} src={ideaStatusImg}/>
                <p>{ideaStatusName}</p>
            </div>
            <div className={classes.box} style={{border: `2px solid ${ideaStatusColor}`}}>
              {challenged &&  <ChallengeTag challenged={challenged} /> } 
                <div className={classes.imgblocks}>
                <img className={classes.idea_img} src={idea_img} onError={({currentTarget}) => {
                    currentTarget.onerror = null
                    currentTarget.src = default_img
                }} /></div>
                <div className="ideaOverlay">
                    <h3 className={classes.idea_name}>{idea_name}</h3>
                    <p className={` ${classes.idea_description}`}>{trimmed_description}</p>
                    
                        <div className={classes.invest_component}>
                            <p className={classes.invest_title}>{formatted_investment_total && 'Investment'}</p>
                            <p className={classes.invest_title}>{no_of_investors && 'Investors'}</p>
                            <p className={classes.invest_value}>{formatted_investment_total && formatted_investment_total}</p>
                            <p className={classes.invest_value}>{no_of_investors && no_of_investors}</p>
                        </div>
                    
                </div>
                
            </div>
        </div>
    </Link>
    )
}

export default withStyles(styles)(Idea_Panel)
