const styles = theme => ({
  root: {
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '50vh',
    fontSize: '1.5rem',
    color: '#C7C7C7',
    width: '100%',
  },
  noText: {
    marginRight: theme.spacing(1 / 2),
  },
  noResultEmoticon: {
    fontWeight: 700,
    marginLeft: theme.spacing(1 / 2),
    fontSize: '2rem',
  },
})

export default styles
