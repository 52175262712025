import { createStyles } from '@material-ui/core'


const styles = theme =>
    createStyles({
        innerWidth:{
            width: '720px',
            height:'90%',
            overflow:'auto',
            textAlign: 'left',
            fontFamily: 'VodafoneRg-Regular',
            fontSize:'18px',
            backgroundColor:'#FFFFFF',
            padding:'2%',
            borderRadius:'8px',

            boxSizing:'border-box',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%,-50%);',
            "& p":{
                margin:'8px 0px 16px'
            }
        },
        firstblock:{
            flex:1,
        },
        secondblock:{
            flex:1,
        },
        heading:{
            fontFamily: 'VodafoneRg-Regular', 
            fontSize: '32px',
            marginBottom:'0px',
            fontWeight:400,
        },
        boldFont:{
            fontFamily: 'VodafoneRg-Bold', 
            fontSize: '18px',
        },
        titleDesc:{
            fontFamily: 'VodafoneRg-Regular', 
            fontSize: '24px',
            marginTop:theme.spacing(2)
        },
        uploadedPicsAndVids:{
            display: 'flex', 
            flexDirection: 'row',
            justifyContent: 'center',
        },
        picIcon:{
            margin: '10px 0px',
            borderRadius: '5px',
            '& img':{
                margin: '0px',
                width: '97px',
                height: '97px',
                borderRadius: '5px',
            },
            '& figcaption':{
                textAlign: 'left',
                fontSize: '16px'
            },
        },
        uploadedImages:{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr 1fr',
            // gridTemplateRows: '50px 150px',
            gridGap:'8px',
            width: '100%',
        }, 
        uploadedVideos:{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr 1fr',
            gridTemplateRows: '50px 150px',
            width: '100%',
        },
        ideaDescription:{
            // display: 'grid', 
            // gridTemplateColumns: '1fr 1fr', 
            // gridTemplateRows: '1fr 1fr 1fr',
        },
        answers:{
            textAlign: 'left',
            // width: '373px',
        },
        challengeCard:{
            height: '250px',
        }, 
        documentLabel:{
            fontSize: '16px',
        },
        documentUpload:{
            margin: '0px',
            '& img' :{
                marginLeft: '30px',
            },
        },
        cancel: {
            fontFamily: 'VodafoneRg-Regular',
            color: '#6551FF',
            border: '2px solid #6551FF', 
            borderRadius: '22.5px',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '177px',
            height: '44px',
            '&:disabled':{
                backgroundColor:'#00000066',
                border:0,
                color:'#fff'
                
            }
        },
        submit: {
            fontFamily: 'VodafoneRg-Regular',
            fontSize: '18px',
            color: '#FFFFFF',
            border: '2px solid #F63F40', 
            borderRadius: '22.5px',
            backgroundColor: '#F63F40',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '177px',
            height: '44px',
            '&:disabled':{
                backgroundColor:'#00000066',
                border:0,

            }
        },
        end_buttons: {
            marginTop: '32px',
            display: 'flex',
            justifyContent: 'space-between',
        },
        horizontal_line: {
            height: '0.8px',
            width: '100%',
            backgroundColor: '#CCCCCC',
            alignSelf: 'center',
            justifySelf: 'center'
        },
        vertical_line: {
            height: '233px',
            width: '0.8px',
            backgroundColor: '#CCCCCC',
            alignSelf: 'center',
            justifySelf: 'center',
            marginLeft: '110px',
            marginRight: '40px',
            marginBottom:'24px'
        },
        twocols:{
            display:'grid',
            gridTemplateColumns:'1fr 1fr',
            gridGap:'8px'
        },
        fourcols:{
            display:'grid',
            gridTemplateColumns:'1fr 1fr 1fr 1fr',
            gridGap:'8px',
            marginTop:theme.spacing(2)
        },
        blocks:{
            textAlign:'center'
        },
        removeIconBtn:{
            background:'transparent',
            border:'none',
            cursor:'pointer'
            
        }
        
  })

export default styles
