import { persistReducer } from 'redux-persist'
import * as types from 'actions/sync/auth/Constants'
import { MESSAGE_TYPES } from 'components/auth_message_box/View.Utils'
import { createPersistReducerConfig } from '../store/Persistence.Utils'

const initialState = {
  loggedIn: false,
  logging: false,
  isLogging: false,
  isSigning: false,
  isChangingPassword: false,
  isUserAlreadyExisting: false,
  isSavingUserDetails: false,
  isEmailNotVerifiedYet: false,
  csrf: '',
  messageBox: {
    messageType: MESSAGE_TYPES.SUCCESS,
    messageText: '',
  },
  signUpStep: 0,
}

const domainReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_LOGGEDIN:
      return {
        ...state,
        loggedIn: true,
        logging: false,
      }
    case types.SHOW_LOADING_SPINNER:
    case types.PASSWORD_LOGIN_STARTED:
    case types.SSO_PASSWORD_LOGIN_STARTED:
    case types.POST_GOOGLE_LOGIN_STARTED:
      return {
        ...state,
        isLogging: true,
        logging: true,
      }
    case types.POST_ADFS_LOGIN_SUCCESS:
    case types.POST_AZURE_LOGIN_SUCCESS:
    case types.POST_GOOGLE_LOGIN_SUCCESS:
    case types.SSO_PASSWORD_LOGIN_SUCCESS:
        return {
          ...state,
          isLogging: false,
          csrf: action.response.csrf,
        }
    case types.PASSWORD_LOGIN_SUCCESS:
      return {
        ...state,
        isLogging: false,
        csrf: action.response.csrf,
      }

    case types.PASSWORD_LOGIN_FAILURE:
    case types.SSO_PASSWORD_LOGIN_FAILURE:
    case types.POST_GOOGLE_LOGIN_FAILURE:
      return {
        ...state,
        isLogging: false,
        logging: false,
      }
    case types.SET_LOGGEDOUT:
      return initialState
    case types.POST_RECOVER_PASSWORD_STARTED:
      return {
        ...state,
        isUserAlreadyExisting: false,
        isEmailNotVerifiedYet: false,
      }
    case types.POST_RECOVER_PASSWORD_SUCCESS:
    case types.SET_AUTH_MESSAGE_BOX_CONTENT:
      return {
        ...state,
        messageBox: {
          ...action.response,
        },
        isUserAlreadyExisting: false,
        isEmailNotVerifiedYet: false,
      }
    case types.POST_CHANGE_PASSWORD_STARTED: {
      return {
        ...state,
        isChangingPassword: true,
      }
    }
    case types.POST_CHANGE_PASSWORD_FAILURE:
    case types.POST_CHANGE_PASSWORD_SUCCESS: {
      return {
        ...state,
        isChangingPassword: false,
      }
    }
    case types.POST_SIGN_UP_STARTED: {
      return {
        ...state,
        isSigning: true,
      }
    }
    case types.POST_SIGN_UP_FAILURE:
    case types.POST_SIGN_UP_SUCCESS: {
      return {
        ...state,
        isSigning: false,
      }
    }
    case types.RESET_AUTH_MESSAGE_BOX_CONTENT: {
      return {
        ...state,
        messageBox: {
          ...initialState.messageBox,
        },
        isUserAlreadyExisting: false,
      }
    }
    case types.USER_ALREADY_EXISTS: {
      return {
        ...state,
        isUserAlreadyExisting: action.isUserAlreadyExisting,
        messageBox: {
          ...state.messageBox,
          messageType: MESSAGE_TYPES.ERROR,
        },
      }
    }
    case types.INCREASE_SIGN_UP_STEP: {
      return {
        ...state,
        signUpStep: state.signUpStep + 1,
      }
    }
    case types.POST_USER_DETAILS_STARTED: {
      return {
        ...state,
        isSavingUserDetails: true,
      }
    }
    case types.POST_USER_DETAILS_FAILURE:
    case types.POST_USER_DETAILS_SUCCESS: {
      return {
        ...state,
        isSavingUserDetails: false,
      }
    }
    case types.IDENTITY_NOT_VERIFIED_YET: {
      return {
        ...state,
        isEmailNotVerifiedYet: action.isEmailNotVerifiedYet,
        messageBox: {
          ...state.messageBox,
          messageType: MESSAGE_TYPES.ERROR,
        },
      }
    }
    default:
      return state
  }
}

const persistAuthReducerConfig = createPersistReducerConfig('RootAuth', ['loggedIn', 'csrf'], true)

const persistAuthReducer = persistReducer(persistAuthReducerConfig, domainReducer)

export default persistAuthReducer
