import { withThemeCreator, styled } from "@material-ui/styles"

const styles = theme =>({
    container:{
      //backgroundColor: "aqua",
      display: "flex",
      flexWrap:'wrap',
      // padding: "20px",
      height: "auto",
      justifyContent: "space-between",
      // paddingLeft: "7%",
      // paddingRight: "7%",
      '@media(min-width:900px)':{
        flexWrap:'initial'
      }
    },
    search_text_div: {
      width: "100%",
      height: "48px",
      border: "2px solid #6551FF",
      borderRadius: "24px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      backgroundColor: "#FFFFFF",
      position: 'relative',
      marginBottom:'10px',
      '@media(min-width:900px)':{
        width: "250px",
      },
      '@media(max-width:480px)':{
        height: "38px",
        border: "1px solid #6551FF",
      },
    },
    search_text_input: {
      width: "200px",
      marginLeft: "15px",
      fontSize: "18px",
      fontFamily: "VodafoneRg-Regular",
      color: "#999999",
      border: "0",
      '&:focus': {
        border: "0",
        outline: "none",
      },
      '@media(max-width:480px)':{
        fontSize: "14px",
      },
    },
    search_text_search_icon: {
      height: "24px",
      marginRight: "15px",
      '&:hover':{
        cursor: "pointer",
      },
      '@media(max-width:480px)':{
        height: "20px",
      },
    },
    cancel_search_icon: {
      height: '24px',
      position: 'absolute',
      right: '-32px',
      '&:hover':{
        cursor: "pointer",
      },
    },
    select_filters_container: {
      width:'100%',
      display: "flex",
      justifyContent:'space-between',
      alignItems: "center",
      '@media(min-width:900px)':{
        justifyContent:'flex-end'
      },
    }
  })

export default styles
