import React from 'react'
import * as PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'
import i18n from 'support/i18n'
import { Image } from 'components/index'
import defaultAvatar from 'img/default-photo.png'
import clsx from 'clsx'
import NxtVirtualCurrency from 'components/nxt_virtual_currency'
import style from './View.Style'

const SearchedUserElement = props => {
  const { classes, photoSrc, fullName, balance, investedFunds, onItemClicked, isSelected } = props
  return (
    <div
      className={clsx(classes.root, isSelected && classes.isSelected)}
      onClick={onItemClicked}
      role="button"
      tabIndex="0"
      onKeyDown={onItemClicked}
    >
      <span
        className="icon-tick"
        style={{
          color: `${isSelected ? '#009D00' : '#CACACA'}`,
        }}
      />
      <Image defaultSrc={defaultAvatar} src={photoSrc} />
      <div className={classes.userDetailWrapper}>
        <span className={classes.fullName}>{fullName}</span>
        <div className={classes.fundsWrapper}>
          <div className={classes.balanceWrapper}>
            <span className={classes.amountLabel}>{i18n.t('PROFILE.GLOBAL_BALANCE')}</span>
            <div className={classes.amountValue}>
              <NxtVirtualCurrency>{balance}</NxtVirtualCurrency>
            </div>
          </div>
          <div className={classes.investedFundsWrapper}>
            <span className={classes.amountLabel}>{i18n.t('PROFILE.INVESTED')}</span>
            <div className={classes.amountValue}>
              <NxtVirtualCurrency>{investedFunds}</NxtVirtualCurrency>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

SearchedUserElement.defaultProps = {
  photoSrc: '',
  isSelected: false,
}

SearchedUserElement.propTypes = {
  onItemClicked: PropTypes.func.isRequired,
  fullName: PropTypes.string.isRequired,
  balance: PropTypes.string.isRequired,
  investedFunds: PropTypes.string.isRequired,
  isSelected: PropTypes.bool,
  photoSrc: PropTypes.string,
}

export default withStyles(style)(SearchedUserElement)
