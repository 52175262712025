import React from 'react'
import * as PropTypes from 'prop-types'
import * as kpiTypes from 'common_constants/Kpis'
import i18n from 'support/i18n'
import NxtCheckbox from 'components/nxt_checkbox/View'
import { IconButton, FormControlLabel } from '@material-ui/core'
import NxtSemiCircleGraph from 'components/nxt_semi_circle_graph/View'
import { calcPercentage } from 'support/utils/circleGraph'

const KpiItem = props => {
  const {
    classes,
    id,
    type,
    unit,
    range,
    expected,
    current,
    name,
    success,
    ideaId,
    onUpdateKpiSuccess,
    onDeleteKpi,
    onItemEdit,
  } = props

  const percentageValue = calcPercentage(current, expected)
  return (
    <>
      <div className={classes.status}>
        {type === kpiTypes.QUALITATIVE && (
          <FormControlLabel
            control={<NxtCheckbox isChecked={success} />}
            onClick={() =>
              onUpdateKpiSuccess({
                id,
                ideaId,
                success: !success,
              })
            }
          />
        )}
        {type === kpiTypes.QUANTITATIVE && (
          <div className={classes.semiCircle}>
            <NxtSemiCircleGraph value={percentageValue}>
              {percentageValue < 100 ? percentageValue : <i className="icon-check" />}
            </NxtSemiCircleGraph>
            <div>
              <span>{current}</span>
              <span>{expected}</span>
            </div>
          </div>
        )}
      </div>
      <div className={classes.data}>
        <div className={classes.block}>
          <div className={classes.name}>
            <span>{name} </span>
            <span>
              (
              {type === kpiTypes.QUANTITATIVE
                ? i18n.t('EXPLORE_IDEA.QUANTITATIVE')
                : i18n.t('EXPLORE_IDEA.QUALITATIVE')}
              )
            </span>
          </div>

          <div className={classes.result}>
            {range && (
              <div>
                <b>{i18n.t('EXPLORE_IDEA.RANGE')}: </b>
                <span>{range}</span>
              </div>
            )}
            {unit && (
              <div>
                <b>{i18n.t('EXPLORE_IDEA.UNIT')}: </b>
                <span>{unit}</span>
              </div>
            )}
            <div>
              <b>{i18n.t('INPUT_PLACEHOLDERS.EXPECTED')}: </b>
              <span>{expected}</span>
            </div>
            <div>
              <b>{i18n.t('INPUT_PLACEHOLDERS.CUR_VALUE')}: </b>
              <span>{current}</span>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.actions}>
        <IconButton
          size="small"
          onClick={() =>
            onItemEdit({
              id,
              type,
              range,
              unit,
              expected,
              current,
              name,
              updated: true,
            })
          }
        >
          <i className="icon-draft" />
        </IconButton>
        <IconButton size="small" onClick={() => onDeleteKpi({ id, ideaId })}>
          <i className="icon-close" />
        </IconButton>
      </div>
    </>
  )
}

KpiItem.defaultProps = {
  id: '',
  success: false,
  type: '',
  range: '',
  unit: '',
  expected: '',
  current: '',
  name: '',
}

KpiItem.propTypes = {
  id: PropTypes.string,
  success: PropTypes.bool,
  type: PropTypes.string,
  range: PropTypes.string,
  unit: PropTypes.string,
  expected: PropTypes.string,
  current: PropTypes.string,
  name: PropTypes.string,
  onUpdateKpiSuccess: PropTypes.func.isRequired,
  onDeleteKpi: PropTypes.func.isRequired,
  onItemEdit: PropTypes.func.isRequired,
  ideaId: PropTypes.string.isRequired,
}

export default KpiItem
