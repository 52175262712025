import React from 'react'
import * as PropTypes from 'prop-types'
import i18n from 'support/i18n'
import RadioSelector from 'components/radio_selector/View'
import { Typography, withStyles } from '@material-ui/core'
import styles from './View.Style'

const OrganizationSelector = props => {
  const { classes, onOrganizationSelect, organization, organizationList } = props
  return (
    <div className={classes.root}>
      {/* <Typography>{i18n.t('CHALLENGES.SELECT_ORGANIZATION')}</Typography> */}
      <RadioSelector
        items={organizationList}
        onValueChange={onOrganizationSelect}
        value={organization}
        subItemsKey="organizations"
      />
    </div>
  )
}

OrganizationSelector.propTypes = {
  onOrganizationSelect: PropTypes.func.isRequired,
  organization: PropTypes.string.isRequired,
  organizationList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ).isRequired,
}

export default withStyles(styles)(OrganizationSelector)
