import { connect } from 'react-redux'
import { getRankingService } from 'services/ranking'
import Navigation from 'support/navigation'
import View from './View'

const mapStateToProps = state => {
  return {
    topInvestors: state.data.ranking.topInvestors,
    topPosts: state.data.ranking.topPosts,
    topPotentialInvestors: state.data.ranking.topPotentialInvestors,
    topIdeas: state.data.ranking.topIdeas,
    topIdeasInvestors: state.data.ranking.topIdeasInvestors,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getRanking: () => {
      dispatch(getRankingService())
    },
    navigateToProfile: email => {
      dispatch(Navigation.navigateToUserProfile(email))
    },
    navigateToIdea: id => {
      dispatch(Navigation.navigateToIdea(id))
    },
    navigateHome: () => dispatch(Navigation.navigateToExplore()),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(View)
