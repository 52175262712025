import {
  APPLY_FILTER_IDEAS_FAILURE,
  APPLY_FILTER_IDEAS_STARTED,
  APPLY_FILTER_IDEAS_SUCCESS,
  APPLY_SEARCH_IDEAS_FAILURE,
  APPLY_SEARCH_IDEAS_STARTED,
  APPLY_SEARCH_IDEAS_SUCCESS,
  GET_PENDING_VALIDATION_IDEAS_STARTED,
  GET_PENDING_VALIDATION_IDEAS_SUCCESS,
  IDEA_ME_SUCCESS,
  GET_PRIVATE_IDEAS_SUCCESS,
  IDEA_ME_STARTED,
  IDEA_ME_FAILURE,
  FIRST_SEARCH_APPLY_FILTER_STARTED,
  FIRST_SEARCH_APPLY_FILTER_SUCCESS,
  FIRST_SEARCH_APPLY_FILTER_FAILURE,
} from 'actions/sync/ideas/ideasActionConstans'

const initialState = {
  data: [],
  items: 0,
  total: 0,
  hasMore: false,
  ideasLoading: false,
  userIdeas: [],
}

const ideaReducer = (state = initialState, action) => {
  switch (action.type) {
    case APPLY_FILTER_IDEAS_STARTED:
    case APPLY_SEARCH_IDEAS_STARTED:
    case GET_PENDING_VALIDATION_IDEAS_STARTED:
    case FIRST_SEARCH_APPLY_FILTER_STARTED:
      return {
        ...state,
        ideasLoading: true,
      }
    case APPLY_SEARCH_IDEAS_SUCCESS:
    case FIRST_SEARCH_APPLY_FILTER_SUCCESS:
    case GET_PENDING_VALIDATION_IDEAS_SUCCESS:
    case GET_PRIVATE_IDEAS_SUCCESS:
      return {
        ...state,
        data: action.response.data,
        items: action.response.items,
        total: action.response.total,
        hasMore: action.response.items < action.response.total,
        ideasLoading: false,
      }
    case APPLY_FILTER_IDEAS_SUCCESS: {
      const newItems = [...state.data, ...action.response.data]
      return {
        ...state,
        data: newItems,
        items: action.response.items,
        total: action.response.total,
        hasMore: newItems.length < action.response.total,
        ideasLoading: false,
      }
    }
    case APPLY_FILTER_IDEAS_FAILURE:
    case APPLY_SEARCH_IDEAS_FAILURE:
    case FIRST_SEARCH_APPLY_FILTER_FAILURE:
      return {
        ...state,
        ideasLoading: false,
      }
    case IDEA_ME_STARTED:
      return {
        ...state,
        ideasLoading: true,
      }
    case IDEA_ME_SUCCESS:
      return {
        ...state,
        userIdeas: action.response.userIdeas,
        ideasLoading: false,
      }
    case IDEA_ME_FAILURE:
      return {
        ...state,
        ideasLoading: false,
      }
    default:
      return state
  }
}

export default ideaReducer
