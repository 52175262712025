import React, { Fragment, useState, useEffect } from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import { Menu, MenuItem, FormControl, InputLabel, Select, FormGroup, FormControlLabel, Checkbox } from '@material-ui/core';
import styles from './View.Style'
import VideoForm from 'components_newui/new_ui_video_form/View'
import Navbar from '../../components_newui/Navbar/View'
import Breadcrumbs from 'components_newui/pageBreadcrumbsMenu/View'
import TextInputOneLine from '../../components_newui/TextInputOneLine/View'
import TextInputMultiLine from '../../components_newui/TextInputMultiLine/View'
import ImageUploader from '../../components_newui/new_ui_image_uploader/View'
import DescriptionBlock from './components/DescriptionBlock'
import { VIDEO_STATE_TYPE } from 'common_constants/VzaarVideo'
import Challenge_card from '../../components_newui/challenge_card/View'
import FileUploader from 'components_newui/new_ui_file_uploader/View'
import Button from './components/Button'
import IdeaPreview from 'components_newui/idea_summary_preview/View'

const Post_Idea_old = props => {

    const {
        classes,
        draft_ideas,
        categories,
        challenges
    } = props

    const [ideaTitle, setIdeaTitle] = useState("")
    const [validIdea, setValidIdea] = useState(true)
    const [problemDescription, setProblemDescription] = useState('')
    const [whyNeeded, setWhyNeeded] = useState('')
    const [whatUnique, setWhatUnique] = useState('')
    const [similarExisting, setSimilarExisting] = useState('')
    const [planDescription, setPlanDescription] = useState('')
    const [benefitsDescription, setBenefitsDescription] = useState('')
    const [category, setCategory] = useState(null)
    const [taggedChallenges, setTaggedChallenges] = useState([])


    //These need to be refactored
    const [checkBoxCustSatis, setCheckBoxCustSatis] = useState(false)
    const [checkBoxReduceCosts, setCheckBoxReduceCosts] = useState(false)
    const [checkBoxImproveCulture, setCheckBoxImproveCulture] = useState(false)
    const [checkBoxIncreaseProduc, setCheckBoxIncreaseProduc] = useState(false)
    const [checkBoxGrowRev, setCheckBoxGrowRev] = useState(false)
    //To be generated from whatever the strategic priorities are


    const [showPreview, setShowPreview] = useState(false)
    ///////
    const [images, setImages] = useState([]) //DELETE THIS and move to index for dispatch/state

    const  [externalVideo, setExternalVideo] = useState(undefined)
    const  [fileVideo, setFileVideo] = useState(undefined)
    //////


    

    //Maybe have page hierarchy come from state????? at least get navigate functions from state
    const pageHierarchy = [
        {string: "Home", navigate: ()=>{}}, 
        {string: "Post an idea", navigate: ()=>{}}
    ]

    useEffect(() => {
        showPreview && window.scrollTo(0, 0)
      })

    return(
        <Fragment>
        <main className={classes.background}>
            <Breadcrumbs crumbs={pageHierarchy} />
            <div className={classes.content_container}>
                    <div className={classes.upper_background}>
                        <p className={classes.title}>Post an idea</p>
                        <p className={classes.invested_ideas_number}>Draft ideas ({draft_ideas.length})</p>
                    </div>
                    <div className={classes.lower_background}>
                        <div className={classes.create_idea_box}>
                            <div className={classes.create_title}>Let's create an innovative idea</div>
                            <p className={classes.idea_title_in}>Idea title <span style={{color: "#E60000"}}>*</span></p>
                            <TextInputOneLine 
                                width={780} 
                                placeholder={'Idea title (50 characters max)'} 
                                char_limit={50} 
                                value={ideaTitle} 
                                valid={validIdea}
                                handleChange={event => {const value=event.target.value; setIdeaTitle(value); setValidIdea(value.length > 0 ? true: false)}}
                            />
                            <div className={classes.idea_title_char_limit}>
                                <span className={classes.idea_title_chars}>{ideaTitle.length}</span>/50
                            </div>

                            <div className={classes.secondary_header}>Upload image/video <sup style={{color: "#E60000", fontSize: "18px"}}>*</sup></div>
                            <div className={classes.smaller_subtitle}>At least one is mandatory</div>
                            <div style={{display: "flex", alignItems: 'top', paddingBottom: '50px'}}>
                                <ImageUploader
                                    images={images}
                                    subtitle='Browse image (Max 5)'
                                    imageUploadError={""}
                                    onSelectImage={ image => setImages(prev => [...prev, image])}
                                    deleteImage={(newImages, _imageName, imageToDelete) => setImages(prev => prev.filter(im => im !== imageToDelete))}
                                />
                                <div className={classes.vertical_line}/>
                                <VideoForm
                                    onExternalVideoError={e => {}}
                                    externalVideoSrc={externalVideo}
                                    fileVideoSrc={fileVideo}
                                    onChangeExternalVideo={src => setExternalVideo(src)}
                                    onSelectFileVideo={() => {}}
                                    onRemoveFileVideo={() => {}}
                                    isVideoLoading={false}
                                    isVideoReady={false}
                                    isVideoRemoving={false}
                                    hasVideoError={false}
                                />
                            </div>

                            <div className={classes.horizontal_line}/>

                            <div className={classes.secondary_header} style={{marginBottom: '16px'}}>Idea description</div>
                            <div className={classes.description_grid}>
                                <DescriptionBlock 
                                    value={problemDescription} 
                                    setValue={setProblemDescription} 
                                    description='Tell us about the problem you are trying to solve.'
                                />
                                <DescriptionBlock 
                                    value={whyNeeded} 
                                    setValue={setWhyNeeded} 
                                    description='Why is your product/service needed?'
                                />
                                <DescriptionBlock 
                                    value={whatUnique} 
                                    setValue={setWhatUnique} 
                                    description='What is unique about this idea for Vodafone?'
                                />
                                <DescriptionBlock 
                                    value={similarExisting} 
                                    setValue={setSimilarExisting} 
                                    description='Is there anything similar existing in the market?'
                                />
                                <DescriptionBlock 
                                    value={planDescription} 
                                    setValue={setPlanDescription} 
                                    description='How do you plan to prototype or test your idea?'
                                />
                                <DescriptionBlock 
                                    value={benefitsDescription} 
                                    setValue={setBenefitsDescription} 
                                    description='What are the benefits if this project becomes successful?'
                                />
                            </div>

                            <div className={classes.horizontal_line}/>

                            <div className={classes.secondary_header} style={{marginBottom: '24px'}}>Choose idea category <sup style={{color: "#E60000", fontSize: "18px"}}>*</sup></div>

                            
                            <FormControl >
                                <Select
                                    value={category}
                                    onChange={e => setCategory(e.target.value)}
                                    className={classes.category_select}
                                    label="Choose category"
                                    defaultValue="Choose"
                                    disableUnderline
                                    displayEmpty
                                    renderValue={() => category || "Choose category"}
                                >
                                    {categories.map(cat => (
                                        <MenuItem value={cat}>{cat}</MenuItem>
                                    ))}

                                </Select>
                            </FormControl>

                            <div className={classes.horizontal_line} style={{marginTop: '56px'}}/>

                            <div className={classes.secondary_header} style={{marginBottom: '24px'}}>Choose strategic priorities</div>
                            <div className={classes.smaller_subtitle}>Craft your idea as per following organisational goals</div>
                            
                            {/* This needs to be generated from the strategic priorities*/}
                            <div style={{display: 'flex'}}>
                            <FormGroup className={classes.checkbox_formgroup}>
                                <FormControlLabel control={<Checkbox checked={checkBoxCustSatis} onChange={e => setCheckBoxCustSatis(e.target.checked)} />} label="Continue to increase customer satisfaction" />
                                <FormControlLabel control={<Checkbox checked={checkBoxReduceCosts} onChange={e => setCheckBoxReduceCosts(e.target.checked)}  />} label="Reduce costs" />
                                <FormControlLabel control={<Checkbox checked={checkBoxImproveCulture} onChange={e => setCheckBoxImproveCulture(e.target.checked)}  />} label="Improve company culture"/>
                            </FormGroup>
                            <FormGroup className={classes.checkbox_formgroup}>
                                <FormControlLabel control={<Checkbox checked={checkBoxIncreaseProduc} onChange={e => setCheckBoxIncreaseProduc(e.target.checked)}  />} label="Increase productivity" />
                                <FormControlLabel control={<Checkbox checked={checkBoxGrowRev} onChange={e => setCheckBoxGrowRev(e.target.checked)} />} label="Grow revenue in new market segments and with new offerings" />
                            </FormGroup>
                            </div>

                    <div className={classes.horizontal_line} style={{marginTop: '32px'}}/>

                    <div className={classes.upper_background}>
                            <div className={classes.secondary_header} style={{marginBottom: '24px'}}>Tag to challenges</div>
                                <p onClick={() => {}} className={classes.view_all_challenges}>View all</p>
                            </div>
                            
                            <div style={{display: 'flex'}}>
                                {challenges.slice(0,2).map(challenge => (
                                    <Challenge_card 
                                        challenge={challenge} 
                                        tagging={true}
                                        tagged={false}
                                        onClick={(e, chal, tagged) => {
                                            // console.log(chal)
                                            setTaggedChallenges(prev => {
                                                return tagged ? [...prev, chal] : prev.filter(c => c !== chal)
                                            })
                                        }} 
                                    />
                                ))}
                            </div>

                            <div className={classes.horizontal_line} style={{marginTop: '32px'}}/>
                            <div className={classes.secondary_header} style={{marginBottom: '24px'}}>Upload supporting documents</div>
                    
                            <FileUploader dropText={"Browse file"} onFileSelect={e => {}}/>

                            <div className={classes.horizontal_line} style={{marginTop: '32px', marginBottom: '32px'}}/>

                            <div style={{display: 'flex', paddingBottom: '48px'}}>
                                    <Button text='Cancel' clickCallBack={e => {}}/>
                                
                                    <div style={{marginLeft: 'auto', display: 'flex'}}>
                                        <Button text='Save as draft' clickCallBack={e => {}}/>
                                        <div style={{marginLeft: '20px'}}>
                                            <Button width={177} text='Preview and submit' clickCallBack={e => setShowPreview(true)}/>
                                        </div>
                                    </div>
                            </div>
                    </div>

                </div>
            </div>  

            {showPreview && 
                <Fragment>
                <div className={classes.faded_out}/>
                <div className={classes.idea_preview}>
                    <IdeaPreview 
                        handleCancleClick={() => setShowPreview(false)} 
                        handleSubmitClick={() => {}}
                        title={ideaTitle}
                        images={images}
                        video={fileVideo ? fileVideo : externalVideo}
                        problemDescription={problemDescription}
                        whyNeeded={whyNeeded}
                        whatUnique={whatUnique}
                        similarExisting={similarExisting}
                        planDescription={planDescription}
                        benefitsDescription={benefitsDescription}
                        category={category}
                        strategicPriorities={['Strategic priority 1', 'Strategic priority 2']}
                        challenges={taggedChallenges}
                        supportingDocs={[1]}
                    />
                </div>
                </Fragment>
            }


        </main>
        </Fragment>
    )
}

export default withStyles(styles)(Post_Idea_old)