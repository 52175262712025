const styles = () => ({
  root: {
    display: 'flex',
  },
  helpText: {
    textAlign: 'center',
    fontWeight: 500,
  },
  investNow: {
    fontFamily: 'VodafoneRg-Regular',
    fontSize: '18px',
    margin: 0,
    cursor: 'pointer',
    color: '#FFFFFF',
    border: '2px solid #333333', 
    borderRadius: '22px',
    backgroundColor: '#333333',
    float: 'right',
    height: '44px',
    width: '157px',
    marginLeft: '15px',
    '&:hover':{
        backgroundColor: '#333333',
    },
  },
})

export default styles
