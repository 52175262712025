
const styles = theme => ({
    
    container: {
      fontFamily: 'VodafoneRg-Regular',
      width: '380px',
      height: '292px',
      backgroundColor: '#FFFFFF',
      borderRadius: '10px',
      border: '1px solid #CCCCCC',
      display: 'flex',
      flexDirection: 'column',
    },
    containerwrp:{
        display:'flex',
        height:'100%'
    },
    left_sidebar:{
      flex:'1 1 50%',
    },
    right_sidebar:{
      flex:'1 1 50%',
      padding: '16px',
    },
    challenge_img:{
      maxWidth: "100%",
      width: "100%",
      maxHeight: "100%",
      height: "100%",
      objectFit: "cover",
      objectPosition: "center top",
      // borderRadius: "9px 9px 0 0", 
    },
    
   
    challenge_title: {
      minHeight:'78px',
      fontSize:theme.fontSize.fontsize18,
      fontFamily:theme.fontFamily.fontFamilyBold,
      color: '#333333'
    },
    creation_date: {
      fontSize: '14px',
      color: '#999999',
      fontFamily:theme.fontFamily.fontFamilyRegular,
      marginTop: '6px',
    },
    datebold:{
      color: '#333333',
      fontFamily:theme.fontFamily.fontFamilyBold,
    },

    buttons:{
      display:'block',
      marginTop:theme.spacing(2),
    },
    delete_btn: {
      width: '100%',
      height: '44px',
      borderRadius: '22.5px',
      border: '1px solid #6551FF',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: '#6551FF',
      marginRight: '20px',
      cursor: 'pointer',
      transition: '0.5s',
      '&:hover':{
        backgroundColor: '#6551FF',
        color: '#FFFFFF'
      },
      marginBottom:theme.spacing(2)
    },
    continue_btn:{
      width: '100%',
      height: '44px',
      borderRadius: '22.5px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      border: '1px solid #333333',
      backgroundColor: '#333333',
      color: 'white',
      cursor: 'pointer',
      transition: '0.5s',
      '&:hover':{
        backgroundColor: 'white',
        color: '#333333'
      },
    },
  })
  

export default styles
