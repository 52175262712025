import { persistReducer } from 'redux-persist'
import addDays from 'date-fns/addDays'
import { dashboardTabs } from 'pages/dashboard/Constants'
import * as types from 'actions/sync/dashboard/Constants'
import {
  APPLY_FILTER_IDEAS_STARTED,
  APPLY_FILTER_IDEAS_FAILURE,
  APPLY_FILTER_IDEAS_SUCCESS,
  FIRST_SEARCH_APPLY_FILTER_SUCCESS,
  GET_PRIVATE_IDEAS_SUCCESS,
  GET_PRIVATE_IDEAS_FAILURE,
  GET_PRIVATE_IDEAS_STARTED,
  GET_EXPIRING_IDEAS_SUCCESS,
} from 'actions/sync/ideas/ideasActionConstans'
import { createPersistReducerConfig } from 'store/Persistence.Utils'

const initialState = {
  activeTab: dashboardTabs.ACTIVITY,
  activityFromDate: addDays(new Date(), -8),
  activityToDate: addDays(new Date(), -1),
  ideasFromDate: addDays(new Date(), -8),
  ideasToDate: addDays(new Date(), -1),
  peopleFromDate: addDays(new Date(), -8),
  peopleToDate: addDays(new Date(), -1),
  privateIdeasFromDate: addDays(new Date(), -8),
  privateIdeasToDate: addDays(new Date(), -1),
  isLoading: false,
  isActivitySeriesLoading: false,
  isIdeasSeriesLoading: false,
  isPrivateIdeasSeriesLoading: false,
  isUsersParticipationSeriesLoading: false,
  expiring_ideas: {
    data: [],
  },
  activity: {
    tableData: {
      dataRows: [],
      comparisonRow: {},
    },
    chart: {
      series: [],
    },
  },
  ideas: {
    tableData: {
      dataRows: [],
      comparisonRow: {},
    },
    chart: {
      series: [],
    },
    tableDataBottom: {
      data: [],
      items: 0,
      total: 0,
      ideasLoading: false,
    },
    pagination: {
      page: 0,
      rowsPerPage: 20,
    },
  },
  people: {
    tableData: {
      dataRows: [],
      comparisonRow: {},
    },
    usersTable: {
      users: [],
      isLoading: false,
      totalSearchItems: 0,
      filter: {
        name: '',
        filterType: 'w_investments',
        page: 0,
        rowsPerPage: 20,
      },
    },
    usersParticipation: {
      hasMore: false,
      totalUsers: 0,
      participationAvg: '',
      successAvg: '',
      series: [],
    },
  },
  privateIdeas: {
    tableData: {
      dataRows: [],
      comparisonRow: {},
    },
    chart: {
      series: [],
    },
    tableDataBottom: {
      data: [],
      items: 0,
      total: 0,
      ideasLoading: false,
    },
    pagination: {
      page: 0,
      rowsPerPage: 20,
    },
  },
}

const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.activeTab,
      }
    case types.SET_DATES:
      return {
        ...state,
        ...action.dates,
      }
    case types.GET_SYSTEM_ACCESSES_STARTED:
      return {
        ...state,
        isActivitySeriesLoading: true,
      }
    case types.GET_ACTIVITY_TABLE_STARTED:
      return {
        ...state,
        isLoading: true,
      }
    case types.GET_ACTIVITY_TABLE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        activity: {
          ...state.activity,
          tableData: action.response,
        },
      }
    case types.GET_SYSTEM_ACCESSES_FAILURE:
      return {
        ...state,
        isActivitySeriesLoading: false,
      }
    case types.GET_SYSTEM_ACCESSES_SUCCESS:
      return {
        ...state,
        activity: {
          ...state.activity,
          chart: {
            series: action.response,
          },
        },
        isActivitySeriesLoading: false,
      }
    case types.GET_ACTIVITY_TABLE_FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case types.GET_IDEAS_TABLE_STARTED:
      return {
        ...state,
        isLoading: true,
      }
    case types.GET_IDEAS_TABLE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        ideas: {
          ...state.ideas,
          tableData: action.response,
        },
      }
    case types.GET_IDEAS_TABLE_FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case types.GET_PEOPLE_TABLE_STARTED:
      return {
        ...state,
        isLoading: true,
      }
    case types.GET_PEOPLE_TABLE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        people: {
          ...state.people,
          tableData: action.response,
        },
      }
    case types.GET_PEOPLE_TABLE_FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case types.GET_IDEAS_SERIES_FAILURE:
      return {
        ...state,
        isIdeasSeriesLoading: false,
      }
    case types.GET_IDEAS_SERIES_STARTED:
      return {
        ...state,
        isIdeasSeriesLoading: true,
      }
    case types.GET_IDEAS_SERIES_SUCCESS:
      return {
        ...state,
        ideas: {
          ...state.ideas,
          chart: {
            series: action.response,
          },
        },
        isIdeasSeriesLoading: false,
      }
    case types.GET_PRIVATE_IDEAS_TABLE_STARTED:
      return {
        ...state,
        isLoading: true,
      }
    case types.GET_PRIVATE_IDEAS_TABLE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        privateIdeas: {
          ...state.privateIdeas,
          tableData: action.response,
        },
      }
    case types.GET_PRIVATE_IDEAS_TABLE_FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case types.GET_PRIVATE_IDEAS_SERIES_FAILURE:
      return {
        ...state,
        isPrivateIdeasSeriesLoading: false,
      }
    case types.GET_PRIVATE_IDEAS_SERIES_STARTED:
      return {
        ...state,
        isPrivateIdeasSeriesLoading: true,
      }
    case types.GET_PRIVATE_IDEAS_SERIES_SUCCESS:
      return {
        ...state,
        privateIdeas: {
          ...state.privateIdeas,
          chart: {
            series: action.response,
          },
        },
        isPrivateIdeasSeriesLoading: false,
      }
    case types.GET_USERS_TABLE_STARTED:
      return {
        ...state,
        people: {
          ...state.people,
          usersTable: {
            ...state.people.usersTable,
            isLoading: true,
          },
        },
      }
    case types.GET_USERS_TABLE_SUCCESS:
      return {
        ...state,
        people: {
          ...state.people,
          usersTable: {
            ...state.people.usersTable,
            users: action.response.users,
            totalSearchItems: action.response.totalSearchItems,
            isLoading: false,
          },
        },
      }
    case types.GET_USERS_TABLE_FAILURE:
      return {
        ...state,
        people: {
          ...state.people,
          usersTable: {
            ...state.people.usersTable,
            isLoading: false,
          },
        },
      }
    case types.GET_USERS_PARTICIPATION_STARTED:
      return {
        ...state,
        isUsersParticipationSeriesLoading: true,
      }
    case types.GET_USERS_PARTICIPATION_SUCCESS:
      return {
        ...state,
        isUsersParticipationSeriesLoading: false,
        people: {
          ...state.people,
          usersParticipation: {
            hasMore: action.response.hasMore,
            totalUsers: action.response.totalUsers,
            participationAvg: action.response.participationAvg,
            successAvg: action.response.successAvg,
            series: action.response.series,
          },
        },
      }
    case types.GET_USERS_PARTICIPATION_FAILURE:
      return {
        ...state,
        isUsersParticipationSeriesLoading: false,
      }
    case types.SET_USERS_TABLE_FILTER:
      return {
        ...state,
        people: {
          ...state.people,
          usersTable: {
            ...state.people.usersTable,
            filter: action.usersTableFilter,
          },
        },
      }
    case types.SET_IDEAS_TABLE_FILTER:
      return {
        ...state,
        ideas: {
          ...state.ideas,
          pagination: action.tablePagination,
        },
      }
    case types.SET_PRIVATE_IDEAS_TABLE_FILTER:
      return {
        ...state,
        privateIdeas: {
          ...state.privateIdeas,
          pagination: action.tablePagination,
        },
      }
    case GET_PRIVATE_IDEAS_STARTED:
      return {
        ...state,
        privateIdeas: {
          ...state.privateIdeas,
          tableDataBottom: {
            ...state.privateIdeas.tableDataBottom,
            ideasLoading: true,
          },
        },
      }
    case GET_PRIVATE_IDEAS_SUCCESS:
      return {
        ...state,
        privateIdeas: {
          ...state.privateIdeas,
          tableDataBottom: {
            data: action.response.data,
            items: action.response.items,
            total: action.response.total,
            ideasLoading: false,
          },
        },
      }
    case GET_PRIVATE_IDEAS_FAILURE:
      return {
        ...state,
        privateIdeas: {
          ...state.privateIdeas,
          tableDataBottom: {
            ...state.privateIdeas.tableDataBottom,
            ideasLoading: false,
          },
        },
      }
    case APPLY_FILTER_IDEAS_STARTED:
      return {
        ...state,
        ideas: {
          ...state.ideas,
          tableDataBottom: {
            ...state.ideas.tableDataBottom,
            ideasLoading: true,
          },
        },
      }
    case APPLY_FILTER_IDEAS_SUCCESS:
    case FIRST_SEARCH_APPLY_FILTER_SUCCESS:
      return {
        ...state,
        ideas: {
          ...state.ideas,
          tableDataBottom: {
            data: action.response.data,
            items: action.response.items,
            total: action.response.total,
            ideasLoading: false,
          },
        },
      }
    case APPLY_FILTER_IDEAS_FAILURE:
      return {
        ...state,
        ideas: {
          ...state.ideas,
          tableDataBottom: {
            ...state.ideas.tableDataBottom,
            ideasLoading: false,
          },
        },
      }
    case  GET_EXPIRING_IDEAS_SUCCESS:
      return{
        ...state,
        expiring_ideas: {
          ...state.expiring_ideas,
          data: action.response.data,
        },
      }
    default:
      return state
  }
}

const persistDashboardReducerConfig = createPersistReducerConfig('Dashboard', ['activeTab'])

const persistDashboardReducer = persistReducer(persistDashboardReducerConfig, dashboardReducer)

export default persistDashboardReducer
