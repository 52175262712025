import { connect } from 'react-redux'
import { deleteChallengeById } from 'services/challenges'
import AlertModalView from 'support/popup_dialogs/views/components/alert_modal_view/AlertModalView'
import { hideDialog } from 'support/popup_dialogs/modal_alert_controller/ModalActions'
import i18n from 'support/i18n'
import Navigation from 'support/navigation'
import { mainButtonActionSetup } from './Utils'
import { deleteChallenge } from '../../../services/challenge_steps'

const dialogSetup = {
  navigateToAfter: () => Navigation.navigateToChallenges(),
  onClickActionButton: (ownProps, onSuccess, onFailure) =>
  // deleteChallengeById(ownProps.id, onSuccess, onFailure),
  // if (ownProps.shouldDeleteDraft) {
    // console.log('ownPro',ownProps),
    deleteChallenge(),
    successModalText: i18n.t('CHALLENGES.DELETE_OK'),
    
  // }
 
}

const mapStateToProps = () => ({
  title: i18n.t('CONFIRMATION_MESSAGE'),
  description: i18n.t('CHALLENGES.DELETE_ASK'),
  mainButtonText: i18n.t('ACCEPT'),
  cancelable: true,
  cancelButtonText: i18n.t('CANCEL'),
})

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    mainButtonAction: () => mainButtonActionSetup(dispatch, ownProps, dialogSetup),
    cancelButtonAction: () => dispatch(hideDialog()),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AlertModalView)
