import React from 'react'
import { createMuiTheme } from '@material-ui/core'
import { MuiThemeProvider } from '@material-ui/core/styles'
import createPalette from '@material-ui/core/styles/createPalette'
import * as PropTypes from 'prop-types'
import theme from './View.Style'

const ThemeProvider = props => {
  const { children, defaultColors } = props
  const customTheme = {
    ...theme,
    palette: createPalette({
      primary: {
        main: defaultColors.primary || '#365fbe',
      },
      secondary: {
        main: defaultColors.secondary || '#f50057',
      },
      headerText: {
        main: defaultColors.headerText || '#FFF',
      },
      BlueText:{
        TextBlue: "#6d45ff",
        TextLightBlue:  '#7836FF'
      },
      Text:{
        TextBlack33 :  "#333333",
        TextError : "#E60000",
      },
      borderColor: {
        main: '#CCCCCC',
      },
    }),
    fontFamily:{
      fontFamilyRegular:"VodafoneRg-Regular",
      fontFamilyBold:  "VodafoneRg-Bold",
    },
    fontSize:{
      fontsize10:'10px',
      fontsize12:'12px',
      fontsize16:'1rem',
      fontsize18:'1.125rem',
      fontsize24:'1.5rem',
      fontsize32:'2rem',
      fontsize20:'1.25rem'
    },
    lineHeight:{
      lineHeight24:'1.5',
      lineHieght32:'1.9'
    }
  }
  return <MuiThemeProvider theme={createMuiTheme(customTheme)}>{children}</MuiThemeProvider>
}

ThemeProvider.defaultProps = {
  defaultColors: {
    primary: '',
    secondary: '',
    headerText: '',
  },
}

ThemeProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  defaultColors: PropTypes.shape({
    primary: PropTypes.string,
    secondary: PropTypes.string,
    headerText: PropTypes.string,
  }),
}

export default ThemeProvider
