import React, { useEffect, useState } from 'react'
import * as PropTypes from 'prop-types'
import { withStyles, Button, CircularProgress } from '@material-ui/core'
import i18n from 'support/i18n'
import styles from './View.Style'
import MentionTextField from '../mention_text_field/View'

const AddComment = props => {
  const {
    classes,
    onPostComment,
    postingCommentStatus,
    onMentionType,
    userSuggestions,
    resetUserSuggestions,
  } = props
  const [comment, setComment] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (postingCommentStatus === 'success') {
      setComment('')
      setIsLoading(false)
    }
    if (postingCommentStatus === 'failure') {
      setIsLoading(false)
    }
    if (postingCommentStatus === 'loading') {
      setIsLoading(true)
    }
  }, [postingCommentStatus])
  return (
    <div className={classes.root}>
      <MentionTextField
        id="main-comment"
        label={i18n.t('COMMENT.INPUT_PLACEHOLDER')}
        onTextChange={setComment}
        textValue={comment}
        userSuggestions={userSuggestions}
        resetUserSuggestions={resetUserSuggestions}
        onMentionType={onMentionType}
        textFieldRows={3}
      />
      <Button
        disabled={isLoading || comment === ''}
        color="primary"
        variant="contained"
        onClick={() => onPostComment(comment)}
      >
        {i18n.t('IDEA.POST_COMMENT')}
        {isLoading && (
          <CircularProgress size={24} className={classes.buttonProgress} color="primary" />
        )}
      </Button>
    </div>
  )
}

AddComment.propTypes = {
  onPostComment: PropTypes.func.isRequired,
  postingCommentStatus: PropTypes.string.isRequired,
  onMentionType: PropTypes.func.isRequired,
  userSuggestions: PropTypes.arrayOf(PropTypes.shape({ email: PropTypes.string })).isRequired,
}

export default withStyles(styles)(AddComment)
