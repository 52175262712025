import { connect } from 'react-redux'
import { showPopupSuccessSnackbar } from 'support/popup_dialogs'
import { hideDialog } from 'support/popup_dialogs/modal_alert_controller/ModalActions'
import I18n from 'support/i18n'
import { submitHelpWidgetMessageService } from 'services/help_widget'
import View from './View'

const mapDispatchToProps = dispatch => {
  return {
    onSubmit: (subject, msg, email, image) => {
      const onSuccess = () => {
        showPopupSuccessSnackbar(I18n.t('HELP_WIDGET.MESSAGE_SENT'), dispatch)
        dispatch(hideDialog())
      }
      dispatch(submitHelpWidgetMessageService(subject, msg, email, image, onSuccess))
    },
    onClose: () => {
      dispatch(hideDialog())
    },
  }
}
export default connect(
  null,
  mapDispatchToProps,
)(View)
