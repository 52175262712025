
import React, { useCallback, useState } from 'react'
import { Container, withStyles } from '@material-ui/core'
import Navigation from 'support/navigation'
import CaseStudyCard from 'components_newui/caseCard/View'
import Breadcrumbs from 'components_newui/pageBreadcrumbsMenu/View'
import Button from 'components_newui/customButton/View'
import caseStudyImg from 'img/caseStudy.png'
import defaultImgNova from 'img/nova_not_support.jpg'
import styles from './View.Style'
import {tutorialSteps } from './caseStudydb'




const CaseStudy = (props) => {
  const { classes } = props
  const [displayedData, setDisplayedData] = useState(tutorialSteps.slice(0,5));
  
// Handler for the "Load More" button
const handleLoadMore = useCallback(()=>{
    setDisplayedData([...displayedData, ...tutorialSteps.slice(displayedData.length, displayedData.length + 5)]);
},[displayedData]) 


  const pageHierarchy = [
    { string: "Home", navigate: ()=>Navigation.navigateToExplore() },
    { string: "Case Studies ", navigate: () => {} }
  ]

  const CaseContent = () => {
    return (
      <div className={classes.blockContent}>
        <div className={classes.content}>
          <h1 className={classes.contentTitle}> Case Studies <span>({tutorialSteps.length})</span> </h1>
          <p className={classes.contentDesc}> NOVA has helped to inspire and deliver a number of proof-of-concept prototypes over the years, in partnership with the original idea-owners. Within this section, you can explore the prototypes for NOVA across local markets and Vodafone Group. These prototypes showcase the functionality and potential of an NOVA idea – from this stage they can be adopted throughout the wider company and be used where required. Explore some of our protypes below:</p>
        </div>
        <div className={classes.imgblock}>
          <img src={caseStudyImg} className={classes.img} alt='case study' />
        </div>
      </div>
    )
  }


  return (
    <>
      <Breadcrumbs crumbs={pageHierarchy} />
      <Container>
        <CaseContent />
        <div className={classes.wrpCard}>
          {
            displayedData.map((item) => {
              return (
                <CaseStudyCard
                  key={item.id}
                  title={item.header}
                  subTitle="Prototype Description"
                  desc={item.label}
                  imgsrc={item.imgPath !== '' ? item.imgPath : defaultImgNova}
                  cardNumber={item.id}
                  slug={item.slug}
                  onclick={()=>Navigation.navigateToCaseStudyDetails(item.slug)}
                />
              )
            })

          }
          
        </div>
      {  
      (tutorialSteps.length !== displayedData.length) ?
       (<div className={classes.caseCardLoadBlock}>
        <p className={classes.caseCardCount}>  Showing {displayedData.length} of {tutorialSteps.length} </p>
        <Button type='button' bgColor='transparent' action={handleLoadMore} text='View more' />
      </div> )
       : null
      }
      
      </Container>


    </>
  )
}

export default withStyles(styles)(CaseStudy)

