const styles = () => ({
  container: {
    display: 'flex',
    marginTop: '32px',
  },
  check: {
    maxWidth: '80px',
  },
  span: {
    marginTop: '8px',
  },
  spanTitle: {
    fontSize: '20px',
    marginBottom: '4px',
    textAlign: 'start'
  },
  spanDesc: {
    fontSize: '18px',
  },
})

export default styles
