import { withThemeCreator, styled } from "@material-ui/styles"
const styles = theme =>({
    container:{
        height: "auto",
        // paddingLeft: "6%",
        // paddingRight: "6%",
        paddingBottom: "60px",
        '& .slick-dots li':{
          margin:'0 2px'
        },
        '& .slick-dots li button:before':{
          fontSize:'12px'
        }
    },
    title: {
      textAlign: "center",
      fontFamily: 'VodafoneRg-Regular',
      fontSize: "40px",
      paddingTop: "32px",
      marginTop: "0",
      marginBottom: "16px"
    },
    description: {
      fontSize: "24px",
      fontFamily: 'VodafoneLt-Regular',
      textAlign: "center",
      marginTop: "16px"
    },
    flex_and_center: {
      display: "flex",
      justifyContent: "center",
    },
  })

export default styles
