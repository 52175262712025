
import { Link } from '@material-ui/core'
import React from 'react'

const Logout = props =>{

    return (
        <div style={{
            display:'flex',
            alignItems:'center',
            justifyContent:'center',
            flexDirection:'column',
            height:'100vh',
        }}>
            <h1 style={{
            margin:'1rem'
            }}>You have signed out successfully</h1>
            <Link href='/'>Go Home</Link>
        </div>
    )
}

export default Logout