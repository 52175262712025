const styles = theme => ({
    container: {
        width: '280px',
        height: '235px',
        fontFamily: 'VodafoneRg-Regular',
        color: '#333333',
        border: '1px solid #979797',
        borderRadius: '10px',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '22px'
    },
    corner: {
        position: 'absolute',
        right: -1,
        top: -1,
        width: '42px',
        height: '42px',
        color: 'white',
        fontFamily: "VodafoneRg-Bold",
        fontSize: '10px'
    },
    circle_cropper: {
        borderRadius: '50%',
        overflow: 'hidden',
        width: '150px',
        height: '150px',
        zIndex: 10,
        position: 'relative'
    },
    profile_photo: {
        display: 'inline',
        margin: '0 auto',
        height: '100%',
        width: '100%'
    },
    medal_img: {
        position: 'absolute',
        width: '195px',
        marginTop: '14px',
        top: 0,
        position: 'absolute',
        left: '-23px'
    },
    name: {
        fontSize: '20px',
        marginTop: '8px',
        marginBottom: '30px'
    },
    values: {
        display: 'flex',
        justifyContent:'space-between',
        width: '100%'
    },
    valueText: {
        color: '#666666',
        fontSize: '16px',
    },
    value: {
        fontSize: '20px'
    }
})

export default styles
