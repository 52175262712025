
const styles = theme => ({

    contactus:{
        padding:'0px 0px 24px'
    },
    content:{
        textAlign:'center',
        marginBottom:'3.5rem'
    },
    heading:{
        margin:'0px',
        fontSize: theme.fontSize.fontsize24,
        textAlign:'center',
        fontFamily: 'VodafoneRg-bold'
    },
    contentPara:{
        fontSize:theme.fontSize.fontsize18,
        lineHeight:'1.4',
        fontFamily: theme.fontFamily.fontFamilyRegular
    },
    block2Grid :{
        display:'grid',
        gridTemplateColumns:'1fr',
        gridGap:'16px',
        '@media (min-width:480px)':{
            gridTemplateColumns:'1fr 1fr',
        }
    },
    addstyle:{
        paddingBottom:'2rem',
        marginBottom:'2rem',
        borderBottom:'1px solid #bfbfbf',
    },
    imgblock:{
        width:'100%',
        display:'block',
        maxWidth:'220px',
        margin:'0 auto',
        '& img':{
            width:'100%',
            display:'block'
        }
    },
    fieldBlock : {
            marginBottom:'16px',
        '& label': {
            fontSize:theme.fontSize.fontsize18,
            color:'#333',
            fontFamily: theme.fontFamily.fontFamilyRegular,
            display:'block',
            marginBottom:'8px' 
        },
        '& input, & textarea':{
            fontSize:theme.fontSize.fontsize18,
            color:'#333',
            fontFamily: theme.fontFamily.fontFamilyRegular,
            borderRadius:'5px',
            width: '100%',
            border: '1px solid #bfbfbf',
            margin: '0',
            display: 'block',
            padding: '16px 8px',
            minWidth: '0',
            background:'none',
            boxSizing: 'border-box',
            letterSpacing: 'inherit',
            resize:'none'
        },
        '& input:invalid' :{
            'border-color': 'red'
        },
        '& input:focus-visible' :{
            'border-color': 'red',
            outline:'none'
          }
    },
    errorMessage : {
        fontSize: '12px',
        margin:'4px 0px',
        color: '#E60000'
    },

})

export default styles
