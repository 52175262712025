import React, { Fragment } from 'react'
import * as PropTypes from 'prop-types'
import i18n from 'support/i18n'
import clsx from 'clsx'
import { withStyles } from '@material-ui/core'
import NxtContributionCurrency from 'components/nxt_contribution_currency'
import styles from './View.Style'

const OwnContribution = props => {
  const { classes, isTimeActive, isBudgetActive, isOtherActive, contribution } = props
  return (
    <Fragment>
      <div className={classes.yourContribution}>
        <div className={classes.textFirstElement}>
          {i18n.t('EXPLORE_IDEA.CONTRIBUTE.YOUR_CONTRIBUTION')}
        </div>
        {isTimeActive && (
          <div className={clsx(classes.textValueElement, isBudgetActive && classes.rightBorder)}>
            <div>
              <span>{contribution.time || 0}</span>
              <span>h</span>
            </div>
            <span>{i18n.t('EXPLORE_IDEA.CONTRIBUTE.HOURS')}</span>
          </div>
        )}
        {isBudgetActive && (
          <div className={classes.textValueElement}>
            <div>
              <span>
                <NxtContributionCurrency>{contribution.budget}</NxtContributionCurrency>
              </span>
            </div>
            <span>{i18n.t('EXPLORE_IDEA.CONTRIBUTE.CASH')}</span>
          </div>
        )}
        {isOtherActive && (
          <div className={classes.textFourthElement}>
            <span>Other:</span>
            <span>{contribution.other}</span>
          </div>
        )}
      </div>
    </Fragment>
  )
}

OwnContribution.propTypes = {
  isTimeActive: PropTypes.bool.isRequired,
  isBudgetActive: PropTypes.bool.isRequired,
  isOtherActive: PropTypes.bool.isRequired,
  contribution: PropTypes.shape({
    time: PropTypes.string,
    budget: PropTypes.string,
    other: PropTypes.string,
  }).isRequired,
}

export default withStyles(styles)(OwnContribution)
