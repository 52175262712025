const styles = theme => ({
  yourContribution: {
    display: 'flex',
    justifyContent: 'space-between',
    flex: 2,
    alignItems: 'center',
  },
  textFirstElement: {
    flex: 0.6,
    fontSize: '1.25rem',
    fontWeight: '500',
  },
  textValueElement: {
    fontSize: '0.75rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(1),
    '& > div': {
      display: 'block',
      '& > span:first-child': {
        fontSize: '1.625rem',
        fontWeight: 600,
      },
    },
  },
  textFourthElement: {
    flex: 0.7,
    display: 'flex',
    flexDirection: 'column',
    fontSize: '0.75rem',
    fontWeight: 800,
    '& > span:last-child': {
      fontWeight: 400,
    },
  },
  rightBorder: {
    borderRight: '1px solid #CCCCCC',
  },
})

export default styles
