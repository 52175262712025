import React from 'react'
import * as PropTypes from 'prop-types'
import i18n from 'support/i18n'
import clsx from 'clsx'
import IconButton from '@material-ui/core/IconButton'
import withStyles from '@material-ui/core/styles/withStyles'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import styles from './View.Style'

const HelpWidgetEmailInput = props => {
  const { classes, email, errorMessageEmail, setHelpForm, onSubmit, handleCloseDialog } = props
  return (
    <div className={classes.container}>
      <IconButton className={classes.icon} onClick={() => handleCloseDialog()}>
        <span className={clsx(classes.iconStyle, 'icon-close')}> </span>
      </IconButton>
      <div className={classes.span}>
        <span className={classes.title}>{i18n.t('HELP_WIDGET.EMAIL_SCREEN')}</span>
      </div>
      <div className={classes.formContainer}>
        <div className={classes.emailContainer}>
          <p className={classes.inputTitle}>{i18n.t('HELP_WIDGET.EMAIL_INPUT_TITLE')}</p>
          <TextField
            className={classes.input}
            error={!!errorMessageEmail}
            helperText={errorMessageEmail}
            type="text"
            onChange={e => setHelpForm({ email: e.target.value })}
            name="username"
            margin="normal"
            variant="outlined"
            placeholder={i18n.t('HELP_WIDGET.EMAIL_INPUT_PLACEHOLDER')}
            value={email}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              classes: {
                input: classes.textInput,
              },
            }}
          />
        </div>

        <div className={classes.buttons}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setHelpForm({ email, mainStep: true })
            }}
          >
            {i18n.t('HELP_WIDGET.EDIT_MESSAGE')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              onSubmit()
            }}
          >
            {i18n.t('HELP_WIDGET.SEND_MESSAGE')}
          </Button>
        </div>
      </div>
    </div>
  )
}

HelpWidgetEmailInput.propTypes = {
  email: PropTypes.string.isRequired,
  errorMessageEmail: PropTypes.string.isRequired,
  setHelpForm: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
}

export default withStyles(styles)(HelpWidgetEmailInput)
