const styles = theme => ({
  editorButtons: {
    display: 'flex', justifyContent: 'space-between', marginTop: '20px'
  },
  joinTheTeamContainer: {
    padding: '1rem 0',
  },
  root: {
    padding: theme.spacing(3, 0, 3, 0),
    borderTop: '1px solid #b3b3b3',
  },
  titleSection: {
    // display: 'flex',
    // alignItems: 'center',
    fontWeight:400,
        fontFamily: theme.fontFamily.fontFamilyRegular, 
        fontSize: '32px',
        marginTop: '16px',
        marginBottom: '16px',
    '& > span': {
      fontSize: '32px',
      marginRight: theme.spacing(1),
    },
  },
  joinButton:{
  fontFamily: theme.fontFamily.fontFamilyRegular,
  fontSize: '12px',
  color: '#FFFFFF',
  background: '#333333',
  width: '120px',
  borderRadius: '20px',
    '&:hover': {
      background: '#333333b5',
    },
  },
  rows:{
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  idea_preview: {
    zIndex: 10,
    marginTop: '150px',
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    height: 'auto',
  },
  faded_out: {
    position: 'fixed',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    zIndex: 100,
    width: '100%',
    height: '100%',
    top: 0,
    left:0,
  },
  containerWrp: {
    backgroundColor: '#FFFFFF',
    borderRadius: '6px',
    width: '980px',
    padding: '32px 48px',
    fontFamily: 'VodafoneRg-Regular',
    color: '#333333'
},
title:{
    fontSize: '32px'
},
subtitle: {
    fontSize: '18px',
    margin: '16px 0',
}
})

export default styles
