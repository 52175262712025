const styles = theme => ({
    
    warning: {
        position: 'absolute',
        top: 16,
        right: 16,
        height: '24px',
        width: '24px',
    },
    invalid: {
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
          borderColor: "#E60000"
        },
        "& .MuiInputLabel-outlined": {
            color: "#E60000",
          },
      }
})

export default styles