import React, { Component } from 'react'
// import Grid from '@material-ui/core/Grid'
// import Card from '@material-ui/core/Card'
import Container from '@material-ui/core/Container'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
//import Feel from '../../assets/feel.png'
import Box from '@material-ui/core/Box'
import Bulb from '../../assets/bulb.png'

//import Slider from '@material-ui/core/Slider'
import PrettoSlider from '../pretto_slider/View'
import TextField from '@material-ui/core/TextField'
// import FormControl from '@material-ui/core/FormControl'
// import FormControlLabel from '@material-ui/core/FormControlLabel'
// import FormLabel from '@material-ui/core/FormLabel'
// import Radio from '@material-ui/core/Radio'
// import RadioGroup from '@material-ui/core/RadioGroup'
// import { withStyles, makeStyles } from '@material-ui/core/styles'
//import './view.style.js'
import withStyles from '@material-ui/core/styles/withStyles'
import EvaluatorCommentsList from '../evaluator_comments/View'
import styles from './View.Style'

const marks = [{ value: 1, label: 1 }, { value: 2, label: 2 }, { value: 3, label: 3 }]

class EvaluationInnovation extends Component {
  constructor(props) {
    super(props)
    this.state = {
      textValue: '',
      sliderVal: 0,
      loadComments: false,
    }

    this.handleTextChange = this.handleTextChange.bind(this)
    this.sendEvaluationInnovation = this.sendEvaluationInnovation.bind(this)
    this.loadPrevComments = this.loadPrevComments.bind(this)
    //this.handleCommentList = this.handleCommentList(this)
    this.sliderValue = this.sliderValue.bind(this)
  }

  componentDidMount() {
    const { getEvaluationDraft, title } = this.props
    if (title) getEvaluationDraft(title)
  }
  
  componentDidUpdate(prevProps) {
    const { getEvaluationDraft, title, evaluationIdea } = this.props
    const { Q1Comment, Q1measurement } = evaluationIdea
    if (prevProps.title !== this.props.title) {
      if (title) getEvaluationDraft(title)
    }
    if (prevProps.evaluationIdea !== this.props.evaluationIdea) {
      this.setState({ textValue: Q1Comment, sliderVal: Q1measurement })
    }
  }

  handleTextChange(event) {
    const { value } = event.target
    this.setState({ textValue: value })
  }

  sliderValue = (event, newValue) => {
    this.setState({ sliderVal: newValue })
  }

  sendEvaluationInnovation() {
    const { textValue, sliderVal } = this.state
    const { postEvaluationIdea, title, evaluationIdea } = this.props
    const { Q2Comment, Q2measurement, Q3Comment, Q3measurement } = evaluationIdea
    postEvaluationIdea(
      title,
      textValue,
      sliderVal || 0,
      Q2Comment,
      Q2measurement || 0,
      Q3Comment,
      Q3measurement || 0,
    )
  }

  loadPrevComments() {
    this.setState({ loadComments: !this.state.loadComments })
  }

  render() {
    const { sliderVal, textValue, loadComments } = this.state
    const { evaluationLoading, commentsList } = this.props
    return (
      <React.Fragment>
        <Box item xs={12} width="100%" maxHeight="420px">
          <Container
            style={{
              height: '100%',
              width: '100%',
              backgroundColor: 'white',
              padding: '30px',
            }}
          >
            <Box display="flex" justifyContent="center">
              {/*Disabled overrieds normal color="secondary" syntax, so will need ID for higher priority CSS*/}
              <Box px={1}>
                <Button variant="contained" px={3} color="primary"></Button>
              </Box>
              <Box px={1}>
                <Button variant="contained" px={3} disabled></Button>
              </Box>
              <Box px={1}>
                <Button variant="contained" px={3} disabled></Button>
              </Box>
              <Box px={1}>
                <Button variant="contained" px={3} disabled></Button>
              </Box>
            </Box>
            <Box py={1} display="flex" justifyContent="center">
              {/*Variant affects actual size, component keeps HTML nerds happy by having a logical progression of H1 to H6 etc*/}
              <Typography variant="h5" component="h1">
                1. How innovative is this idea?
                {/* {commentsList.map(comments => {
                  return <div>{comments.Q1Comment}</div>
                })} */}
              </Typography>
            </Box>
            <Box py={1} display="flex" justifyContent="center">
              <img src={Bulb} alt="logo" height="10%" width="10%" />
            </Box>
            <Box py={1} display="flex" justifyContent="center">
              <PrettoSlider
                //defaultValue={Q3measurement}
                value={sliderVal}
                onChange={this.sliderValue}
                step={1}
                marks={marks}
                min={0}
                max={3}
              />
            </Box>
            <Box pt={3} display="flex" justifyContent="center">
              <TextField
                id="outlined-multiline-static"
                label="Comments"
                value={textValue}
                onChange={this.handleTextChange}
                multiline
                rowsMax={3}
                variant="outlined"
                style={{ width: '50vw' }}
              />
            </Box>

            <Box pt={1} display="flex" justifyContent="center">
              <Box px={1}>
                <Button
                  disabled={evaluationLoading}
                  variant="contained"
                  px={3}
                  id="redBackground"
                  height="100%"
                  color="primary"
                  onClick={this.loadPrevComments}
                >
                  Prev Comments
                </Button>
              </Box>
              <Box px={1}>
                <Button
                  disabled={evaluationLoading}
                  variant="contained"
                  px={3}
                  id="redBackground"
                  height="100%"
                  color="primary"
                  onClick={this.sendEvaluationInnovation}
                >
                  Save & Next
                </Button>
              </Box>
              {/* <Box px={1}>
                <Button
                  disabled={evaluationLoading}
                  variant="contained"
                  px={3}
                  id="redBackground"
                  height="100%"
                  color="primary"
                  onClick={goNext}
                >
                  Next
                </Button>
              </Box> */}
            </Box>
            {loadComments && (
              <EvaluatorCommentsList
                stage={1}
                commentsList={commentsList}
                open={loadComments}
                handleDialog={this.loadPrevComments}
              />
            )}
          </Container>
        </Box>
      </React.Fragment>
    )
  }
}
export default withStyles(styles)(EvaluationInnovation)
