import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import styles from './View.Style'
import View from './View'

const mapStateToProps = (state, ownProps) => ({
  loggedIn: ownProps.loggedIn,
  logging: ownProps.logging,
  lastDomainVisited: state.data.domain.lastDomainVisited,
  isDomainInMaintenance: state.data.maintenance.isDomainInMaintenance,
  shouldGoToMaintenance: state.data.user.shouldGoToMaintenance,
})

export default connect(
  mapStateToProps,
  null,
)(withStyles(styles)(View))
