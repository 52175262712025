import { ServiceCall } from 'support/network/ServiceCallAction'
import * as helpWidgetTypes from 'actions/sync/help_widget/Constants'
import { submitHelpWidgetMessageBody } from './RequestUtils'

export const HELP_WIDGET_URL = '/ticketing'

export function submitHelpWidgetMessageService(subject, msg, email, image, onSuccess) {
  const apiCall = new ServiceCall()
  apiCall.startedReqType = helpWidgetTypes.SEND_HELP_WIDGET_MESSAGE_STARTED
  apiCall.successReqType = helpWidgetTypes.SEND_HELP_WIDGET_MESSAGE_SUCCESS
  apiCall.failureReqType = helpWidgetTypes.SEND_HELP_WIDGET_MESSAGE_FAILURE
  apiCall.setEndpointFromState = () => HELP_WIDGET_URL
  apiCall.method = 'POST'
  apiCall.setBodyFromState = submitHelpWidgetMessageBody(subject, msg, email, image)
  apiCall.onSuccess = onSuccess
  return apiCall.getAction()
}
