const styles = theme => ({
  root: {},
  infoMessageBox: {
    // backgroundColor: '#d9edf7',
    // color: '#3a87ad',
    color:'#222222',
    fontFamily:theme.fontFamily.fontFamilyBold,
    padding: theme.spacing(2),
    fontSize: theme.spacing(1.75),
  },
  datesContainer: {
    display: 'flex',
    // justifyContent: 'center',
    margin: theme.spacing(4, 2, 0, 2),
    '& > div:nth-child(1)': {
      marginRight: theme.spacing(2),
      ['@media(max-width:767px)']:{
        marginRight:'0px'
      }
    },
    '& > div:nth-child(2)': {
      marginLeft: theme.spacing(2),
      ['@media(max-width:767px)']:{
        marginLeft:'0px'
      }
    },
    marginTop:theme.spacing(3),
    fontFamily:theme.fontFamily.fontFamilyBold,
    fontSize:'1rem',
    ['@media(max-width:767px)']:{
      margin:'0px',
      display:'block'
    }
  },
  dateInfo: {
    margin: theme.spacing(1, 2, 1, 2),
    fontSize: theme.spacing(1.75),
    color: '#595959',
  },
})

export default styles
