const styles = theme => ({
    pendingValidationPg:{
        marginTop:theme.spacing(3),
        marginBottom:theme.spacing(2),
        minHeight:'550px'
    },
    heading:{
        paddingTop:'2rem',
        fontSize:'2rem',
        margin:0,
        marginBottom:'2rem',
        textAlign:'center'
    },
    IdeaGrid: {
        display: "grid",
        marginTop: "32px",
        marginBottom: "60px",
        gridTemplateColumns: "repeat(2,minmax(128px,1fr))",
        gridGap: '8px',
        alignItems: "center",
        justifyItems: "center",
        ['@media (min-width:480px)']: { 
            gridTemplateColumns: "repeat(3,minmax(144px,1fr))",
        },
        ['@media (min-width:1024px)']: { 
            gridTemplateColumns: "repeat(4,minmax(238px,1fr))",
        },
    },
    noIdeaImg:{
        marginTop:'12rem',
        width:'100%',
        maxWidth:'300px',
        display:'block',
    }


})


export default styles