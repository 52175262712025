import React from 'react'
import * as PropTypes from 'prop-types'
import { Image, Overlay } from 'components'
import { withStyles, IconButton } from '@material-ui/core'
import defaultPhoto from 'img/default-photo.png'
import clsx from 'clsx'
import styles from './View.Style'

const IdeaAvatar = props => {
  const {
    classes,
    name,
    secondLine,
    photoSrc,
    isAdmin,
    hasDeleteButton,
    onDeleteButtonClick,
  } = props
  return ( 

    <div className={classes.container}>
      {hasDeleteButton && (
        <IconButton
          disableFocusRipple
          disableTouchRipple
          disableRipple
          component="span"
          size="small"
          className={classes.sizesmallicon}
          onClick={event => {
            event.stopPropagation()
            onDeleteButtonClick()
          }}
        >
          <span className="icon-close" />
        </IconButton>
      )}
      <img src={photoSrc}
        alt={name}
        className={classes.member_img}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null
          currentTarget.src = defaultPhoto
        }} />

      <div className={classes.member_name} >{name}</div>
    </div>


  )
}

IdeaAvatar.defaultProps = {
  name: '-',
  secondLine: '',
  photoSrc: '',
  isAdmin: false,
  hasDeleteButton: false,
  onDeleteButtonClick: () => {},
}

IdeaAvatar.propTypes = {
  name: PropTypes.node,
  secondLine: PropTypes.node,
  photoSrc: PropTypes.string,
  isAdmin: PropTypes.bool,
  hasDeleteButton: PropTypes.bool,
  onDeleteButtonClick: PropTypes.func,
}

export default withStyles(styles)(IdeaAvatar)
