import { FULL_INFO_SUCCESS } from 'services/TypesConstants'

const initialState = {
  isDomainInMaintenance: false,
  title: '',
  text: '',
  androidAppUrl: '',
  iosAppUrl: '',
}

const maintenanceReducer = (state = initialState, action) => {
  switch (action.type) {
    case FULL_INFO_SUCCESS:
      return {
        ...state,
        isDomainInMaintenance: action.response.domain.isDomainInMaintenance,
        title: action.response.domainConfiguration.maintenanceTitle,
        text: action.response.domainConfiguration.maintenanceText,
        androidAppUrl: action.response.domainConfiguration.androidAppUrl,
        iosAppUrl: action.response.domainConfiguration.iosAppUrl,
      }
    default:
      return state
  }
}

export default maintenanceReducer
