const styles = ({
    container: {
        backgroundColor: '#FFFFFF',
        borderRadius: '6px',
        width: '980px',
        padding: '32px 48px',
        fontFamily: 'VodafoneRg-Regular',
        color: '#333333'
    },
    title:{
        fontSize: '32px'
    },
    subtitle: {
        fontSize: '18px',
        margin: '16px 0',
    }
})

export default styles