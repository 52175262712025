
import { createStyles } from '@material-ui/core/styles'

const styles = theme =>
  createStyles({
    badgeBox: {
      position: 'absolute',
      top: '0',
      right: '0',
      width: '70px',
      height: '70px',
      overflow: 'hidden',
    },
    badgeBoxCorner: {
      backgroundColor: theme.palette.primary.main,
      position: ' relative',
      width: ' 70px',
      height: ' 40px',
      top: ' -9px',
      right: ' -24px',
      transform: ' rotate(45deg)',
      '& > p': {
        color: '#fff',
        position: 'absolute',
        left: '0',
        bottom: 4,
        fontSize: '7px',
        fontWeight: 'bold',
        width: '100%',
        margin: '0',
        textAlign: 'center',
        textTransform: 'uppercase',
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'center',
      },
      '& > p > i': {
        margin: 0,
        fontSize: '14px',
      },
    },
  })

export default styles
