const styles = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingTop: theme.spacing(2),
    borderTop: '1px solid #E1E1E1',
    '& > button:first-child': {
      marginRight: theme.spacing(1),
    },
    '& > button:last-child': {
      marginLeft: theme.spacing(1),
    },
  },
})

export default styles
