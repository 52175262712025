import createStyles from '@material-ui/core/styles/createStyles'

const styles = theme =>
  createStyles({
    gridContainer: {
      height: "100%", // So that grids 1 & 4 go all the way down
      minHeight: 150, // Give minimum height to a div
      //border: "1px solid black",
      //fontSize: 30,
      textAlign: "left"
    },
    bottom: {
      gridArea: 'bottom',
      ...theme.gridMiddleColumnCenter,
      height: '25rem',
      color: '#fff',
    },
    cardContainer: {
      padding: '3rem',
    },
    center: {
      gridArea: 'center',
    },
    circleWithNoPadding: {
      padding: 0,
    },
    topCenter: {
      gridArea: 'center',
      '& > h1': {
        textAlign: 'center',
      },
    },
    centerDark: {
      backgroundColor: '#1c1c1c',
      color: '#fff',
    },
    exploreBottom: {
      gridArea: 'center',
    },
    fill1: {
      gridColumn: 1,
      gridRow: 2,
      backgroundColor: '#f1f1f1',
    },
    fill2: {
      gridColumn: 3,
      gridRow: 2,
      backgroundColor: '#f1f1f1',
    },
    gridCenter: {
      gridArea: 'center',
    },
    ideasFilter: {
      marginBottom: '1rem',
    },
    mainTitle: {
      margin: '2rem 0',
      fontFamily: '"HelveticaNeueCondensedBold", "Helvetica Neue", sans-serif',
      textAlign: 'center',
      fontWeight: 'normal',
    },
    middle: {
      gridArea: 'middle',
      ...theme.gridMiddleColumnCenter,
      gridTemplateColumns: 'auto 960px auto',
    },
    top: {
      gridArea: 'top',
      ...theme.gridMiddleColumnCenter,
      gridTemplateColumns: 'auto 960px auto',
      marginTop: '30px',
      marginBottom: '10px'
    },
    root: {
      flexGrow: 1,
      marginBottom:'10px'
    },
    card: {
        marginTop: '10px',
        marginBottom: '10px',
       
    },
    media: {
      margin: "10px auto 0",
      width: "100%",
      height: 600,
      borderRadius: "4px",
      boxShadow: "0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23)",
      position: "relative",
      zIndex: 1000
    },
    customResearchReportSpan: {
      fontWeight: "bold",
      color: "#e60000",
      textDecoration: "none",
      '&:hover': {
        cursor: "pointer",
        textDecoration: "underline",
      }
    }
  })

export default styles
