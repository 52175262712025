import React from 'react'
import * as PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import Chip from '@material-ui/core/Chip'
import i18n from 'support/i18n'
import clsx from 'clsx'
import styles from './View.Style'

const HelpWidgetMainDialog = props => {
  const {
    classes,
    subject,
    msg,
    image,
    onDeleteImage,
    errorMessageComment,
    errorSubjectComment,
    setHelpForm,
    handleCloseDialog,
    onNextStep,
    handleUploadImage,
  } = props

  return (
    <div className={classes.container}>
      <IconButton className={classes.icon} onClick={() => handleCloseDialog()}>
        <span className={clsx(classes.iconStyle, 'icon-close')}> </span>
      </IconButton>
      <div className={classes.span}>
        <span className={classes.title}>{i18n.t('HELP_WIDGET.TITLE')}</span>
      </div>
      <div className={classes.formContainer}>
        <TextField
          className={classes.input}
          error={!!errorSubjectComment}
          helperText={errorSubjectComment}
          type="text"
          onChange={e => setHelpForm({ subject: e.target.value })}
          name="req-topic"
          margin="normal"
          variant="outlined"
          placeholder={i18n.t('HELP_WIDGET.ISSUE_PLACEHOLDER')}
          value={subject}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            classes: {
              input: classes.textInput,
            },
          }}
        />
        <TextField
          className={classes.input}
          error={!!errorMessageComment}
          helperText={errorMessageComment}
          type="text"
          onChange={e => setHelpForm({ msg: e.target.value })}
          variant="outlined"
          placeholder={i18n.t('HELP_WIDGET.COMMENT_PLACEHOLDER')}
          value={msg}
          multiline
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            classes: {
              input: classes.inputDesc,
              root: classes.root,
            },
          }}
        />

        <div className={classes.buttons}>
          {image ? (
            <Chip label={image} onDelete={onDeleteImage} className={classes.chip} />
          ) : (
            <label htmlFor="contained-button-file">
              <input
                accept="image/*"
                className={classes.inputLoader}
                id="contained-button-file"
                type="file"
                onChange={handleUploadImage}
              />
              <Button variant="contained" color="primary" component="span">
                {i18n.t('HELP_WIDGET.ADD_IMAGE_BUTTON')}
              </Button>
            </label>
          )}

          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              onNextStep(subject, msg)
            }}
          >
            {i18n.t('NEXT')}
          </Button>
        </div>
      </div>
    </div>
  )
}

HelpWidgetMainDialog.propTypes = {
  subject: PropTypes.string.isRequired,
  msg: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  onDeleteImage: PropTypes.func.isRequired,
  errorMessageComment: PropTypes.string.isRequired,
  errorSubjectComment: PropTypes.string.isRequired,
  setHelpForm: PropTypes.func.isRequired,
  onNextStep: PropTypes.func.isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
  handleUploadImage: PropTypes.func.isRequired,
}

export default withStyles(styles)(HelpWidgetMainDialog)
