import React from 'react'
import * as PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'
import i18n from 'support/i18n'
import Image from 'components/image/View'
import defaultAvatar from 'img/default-photo.png'
import MainTooltip from 'components/main_tooltip/View'
import style from './View.Style'

const SelectedUserList = props => {
  const { classes, users } = props
  return (
    <div className={classes.root}>
      <div className={classes.avatarsContainer}>
        {users.map(user => (
          <MainTooltip
            title={user.fullName}
            key={`${user.fullName}-${user.photoSrc}`}
            placement="top"
          >
            <div>
              <Image defaultSrc={defaultAvatar} src={user.photoSrc} />
            </div>
          </MainTooltip>
        ))}
      </div>
      <div className={classes.selectedWrapper}>
        <div>
          <span className={classes.numberSelectedItems}>{users.length}</span>&nbsp;
          <span>{i18n.t('PEOPLE_SELECTOR.SELECTED')}</span>
        </div>
      </div>
    </div>
  )
}

SelectedUserList.propTypes = {
  users: PropTypes.arrayOf(
    PropTypes.shape({
      photoSrc: PropTypes.string,
      fullName: PropTypes.string.isRequired,
    }),
  ).isRequired,
}

export default withStyles(style)(SelectedUserList)
