
const styles = theme => ({
    bar_container: {
        backgroundColor: "#FFFFFF",
        padding:' 1rem 0px'
        // top: "0",
        // height: "60px",
       
    },
    BreadcrumbsList:{
        listStyle:'none',
        padding:0,
        margin:0,
        display: "flex",
        alignItems:'center',
        '& li':{
            '& button':{
                cursor:'pointer',
                background: 'transparent',
                border: 0,
                margin: '0 4px 0 0',
                padding:0,
                fontSize: theme.fontSize.fontsize18,
                fontFamily: theme.fontFamily.fontFamilyRegular,
            },
            '& svg':{
                marginTop:'4px'
            }
        }
    },
    parent_page: {
        display: "flex",
        alignItems:'center',
        fontSize: theme.fontSize.fontsize18,
        fontFamily: theme.fontFamily.fontFamilyRegular,
        color: "#333333",
        marginRight:'8px',
        '&:hover': {
            cursor: "pointer",
            color: "#e60000",
        },
        '@media (max-width:480px)': {
            fontSize: '13px'
        }
    },
    current_page: {
        color: "#999999"
    },
    // chevron_right: {
    //     height: "32px",
    //     paddingLeft: "10px",
    //     paddingRight: "10px",
    //     '@media (max-width:480px)': {
    //         height: '20px'
    //     }
    // }
})


export default styles
