const styles = theme => ({
  root: {
    padding: theme.spacing(3, 0),
    borderTop: '1px solid #b3b3b3',
  },
  titleSection: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '26px',
    fontWeight: 500,
    '& > span': {
      fontSize: '32px',
      marginRight: theme.spacing(1),
    },
  },
  contributorsHeader: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  yourContribution: {
    display: 'flex',
    justifyContent: 'flex-end',
    flex: 2,
    alignItems: 'center',
  },
  formSection: {
    display: 'flex',
    padding: theme.spacing(3),
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  textValueElement: {
    fontSize: '0.75rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(1),
    '& > div': {
      display: 'block',
      '& > span:first-child': {
        fontSize: '1.625rem',
        fontWeight: 600,
      },
    },
  },
  noBorder: {
    border: 0,
  },
})

export default styles
