import {
  HEADER_SECTION_SUCCESS,
  HELP_SECTION_FAILURE,
  HELP_SECTION_STARTED,
  HELP_SECTION_SUCCESS,
  RANKING_FAILURE,
  RANKING_STARTED,
  RANKING_SUCCESS,
  HELP_SECTION_CONTRY_TEST_SUCCESS,
  SET_SEARCH
} from '../actions/sync/explore/exploreActionConstans'

const initialState = {
  headerSection: {},
  helpList: [],
  countryApiResponse:[],
  helpLoading: false,
  rankingLoading: false,
  ranking: {
    footerEntrepreneurs: [],
    footerInvestors: [],
  },
  search_query: ''
}

const exploreReducer = (state = initialState, action) => {
  switch (action.type) {
    case HEADER_SECTION_SUCCESS:
      return {
        ...state,
        headerSection: action.response,
      }
      case HELP_SECTION_CONTRY_TEST_SUCCESS :
        return{
          ...state,
          countryApiResponse:action.response.countryApiResponse,
          helpLoading:false
        }

    case HELP_SECTION_STARTED:
      return {
        ...state,
        helpLoading: true,
      }
    case HELP_SECTION_SUCCESS:
      return {
        ...state,
        helpList: action.response.helpList,
        helpLoading: false,
      }
    case HELP_SECTION_FAILURE:
      return {
        ...state,
        helpLoading: false,
      }

    case RANKING_STARTED:
      return {
        ...state,
        rankingLoading: true,
      }
    case RANKING_SUCCESS:
      return {
        ...state,
        ranking: {
          footerEntrepreneurs: action.response.footerEntrepreneurs,
          footerInvestors: action.response.footerInvestors,
        },
        rankingLoading: false,
      }
    case RANKING_FAILURE:
      return {
        ...state,
        rankingLoading: false,
      }
    case SET_SEARCH:
      return {
        ...state,
        search_query: action.data
      }
    default:
      return state
  }
}

export default exploreReducer
