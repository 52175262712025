import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import { withStyles, TextField, InputAdornment, CircularProgress } from '@material-ui/core'
import i18n from 'support/i18n'
import style from './View.Style'

class EmailSearchInput extends Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange({ target }) {
    const { onRetrieveSuggestions, minLengthToSearch } = this.props
    const { value } = target
    if (value.length > minLengthToSearch) {
      onRetrieveSuggestions(value)
    }
  }

  render() {
    const { classes, isLoading, searchEmailLabel } = this.props
    return (
      <div className={classes.root}>
        <TextField
          label={searchEmailLabel}
          placeholder={i18n.t('PEOPLE_SELECTOR.INPUT_HELP')}
          onChange={this.handleChange}
          margin="dense"
          variant="outlined"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            endAdornment: isLoading ? (
              <InputAdornment position="end">
                <CircularProgress size={20} />
              </InputAdornment>
            ) : null,
          }}
        />
      </div>
    )
  }
}

EmailSearchInput.defaultProps = {
  minLengthToSearch: 3,
}

EmailSearchInput.propTypes = {
  onRetrieveSuggestions: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  minLengthToSearch: PropTypes.number,
  searchEmailLabel: PropTypes.string.isRequired,
}

export default withStyles(style)(EmailSearchInput)
