import React, { useEffect } from 'react'

import DialogActions from '@material-ui/core/DialogActions'
import withStyles from '@material-ui/core/styles/withStyles'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import { TextField, Dialog, Button, CircularProgress } from '@material-ui/core'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Slide from '@material-ui/core/Slide'
import DialogTitle from '@material-ui/core/DialogTitle'
import i18n from 'support/i18n'
import AuthMessageBox from 'components/auth_message_box/View'
import ButtonLink from 'components/button_link/View'

import styles from './View.Style'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />
})

const ExternalLogin = props => {
  const {
    classes,
    openDialog,
    closeDialog,
    onExternalSubmit,
    isLogging,
    forgotPassword,
    isEmailNotVerifiedYet,
    messageText,
    messageType,
    onSendMeActivationEmailClick,
    resetAuthMessageBox,
  } = props

  const [loginData, setLoginData] = React.useState({
    email: '',
    password: '',
  })
  const [hasEmailError, setEmailError] = React.useState(false)

  useEffect(() => {
    resetAuthMessageBox()
  }, [])

  function handleTextFieldChange(event) {
    const { name, value } = event.target
    const textField = { ...loginData, [name]: value }
    if (textField.email && hasEmailError) {
      setEmailError(false)
    }
    setLoginData(textField)
  }

  const handleForgot = () => {
    if (!loginData.email) {
      setEmailError(true)
    } else {
      closeDialog()
      forgotPassword(loginData.email)
    }
  }

  const handleClickSubmit = event => {
    event.preventDefault()
    if (!loginData.email) {
      setEmailError(true)
    } else {
      // closeDialog()
      onExternalSubmit(loginData.email, loginData.password)
    }
  }

  const handleClose = () => {
    props.closeDialog()
  }

  function renderMessageText() {
    if (isEmailNotVerifiedYet) {
      return (
        <div>
          <span>{i18n.t('COMMON_LOGIN.HEADER.EMAIL_NOT_CONFIRMED_YET')}</span>
          <ButtonLink
            classes={{ linkButton: classes.activationEmailLink }}
            onLinkClick={() => onSendMeActivationEmailClick(loginData.email)}
          >
            {i18n.t('COMMON_LOGIN.HEADER.RESEND_ACTIVATION_EMAIL')}
          </ButtonLink>
        </div>
      )
    }
    return messageText
  }

  return (
    <div className={classes.dialogRoot}>
      <Dialog
        open={openDialog}
        aria-labelledby="form-dialog-title"
        TransitionComponent={Transition}
        maxWidth="md"
      >
        <AppBar position="absolute" style={{backgroundColor: 'red'}}>
          <Toolbar>
            <Typography variant="h6">Welcome!</Typography>
          </Toolbar>
        </AppBar>

        <DialogTitle id="form-dialog-title">Welcome!</DialogTitle>
        <form method="post" onSubmit={handleClickSubmit}>
          <DialogContent>
            <DialogContentText>
              Log in with your email and password (Non Vodafone Users Only).
            </DialogContentText>
            <TextField 
              margin="normal"
              id="email"
              name="email"
              label="Email Address"
              type="email"
              fullWidth
              onChange={handleTextFieldChange}
              variant="outlined"
              error={hasEmailError}
              helperText={hasEmailError && i18n.t('COMMON_LOGIN.HEADER.MANDATORY_FIELD')}
            />
            <TextField
              margin="normal"
              id="password"
              name="password"
              label="Password"
              type="password"
              fullWidth
              variant="outlined"
              onChange={handleTextFieldChange}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleForgot} color="primary">
              Forgot Password
            </Button>
            <Button onClick={handleClose} color="primary">
              Cancel{' '}
              {isLogging && (
                <CircularProgress size={24} className={classes.buttonProgress} color="secondary" />
              )}
            </Button>
            <Button type="submit" onClick={handleClickSubmit} color="primary">
              Login
            </Button>
          </DialogActions>

          <AuthMessageBox messageType={messageType} messageText={renderMessageText()} />
        </form>
      </Dialog>
    </div>
  )
}

export default withStyles(styles)(ExternalLogin)
