const states = {
    PENDING_SUPPORT : 'PENDING_SUPPORT'
    , SEEKING_FUNDING : 'SUPPORTED',
    ROUND_CLOSED: 'ROUND_CLOSED'
    , APPROVED_BY_IT : 'APPROVED_BY_IT'
    , PILOT_ACTIVE : 'PILOT_ACTIVE'
    , IMPLEMENTED : 'IMPLEMENTED'
    , DISCARDED : 'DISCARDED'
    , PENDING_VALIDATION : 'PENDING_VALIDATION'
    , DRAFT : 'DRAFT'
}
export default states