import * as types from '../actions/sync/nova_research/Constants'

const initialState = {
  documents: [],
  errors: {},
  isDocumentLoading: false,
}

const novaResearchReducer = (state = initialState, action) => {
  // console.log(`documents:::111: ${JSON.stringify(action.response.documents)}`)
  switch (action.type) {
    case types.GET_NOVA_RESEARCH_DOCUMENT_SUCCESS:
      return {
        ...state,
        isDocumentLoading: false,
        errors: {
          ...state.errors,
          documentError: '',
        },
        documents: action.response,
      }

    default:
      return state
  }
}

export default novaResearchReducer
