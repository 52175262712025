// NEED TO IMPLEMENT THIS
// .radio_buttons {
//   display: grid;
//   grid-template-columns: 1fr 1fr;
//   grid-template-rows: 1fr 1fr;
//   grid-gap: 15px;
// }

// .radio_buttons > input[type="radio"] {
//   opacity: 0;
//   position: fixed;
//   width: 0;
// }

// .radio_buttons > label {
//   padding: 10px 20px;
//   font-family: sans-serif, Arial;
//   font-size: 16px;
//   text-align: center;
//   display: inline-flex;
//   align-items: center;
//   justify-content: center;
//   color: white;
//   border: 2px solid white;
// }

// .radio_buttons > label:hover {
//   border: 2px solid black;
// }

// .radio_buttons > input[type="radio"]:checked + label {
//   background-color: black;
// }

// .radioApproveLabel {
//   background-color: rgb(57, 219, 92);
// }

// .radioDropLabel {
//   background-color: rgb(231, 53, 40);
// }

// .radioBacklogLabel {
//   background-color: rgb(243, 140, 43);
// }

// .radioDivertLabel {
//   background-color: rgb(255, 238, 0);
// }


const styles = theme => ({
    root: {
      flexGrow: 1,
    },
    container: {
      display: 'flex',
      flexFlow: 'row wrap',
      '& > div': {
        height: theme.spacing(67),
        width: theme.spacing(38.75),
        margin: 4,
      },
    },
    divFirst: {
      display: 'flex',
      alignItems: 'center',
    },
    whiteBackground: {
      backgroundColor: 'white',
    },
  })
  
  export default styles
  