import { connect } from 'react-redux'
import { feedbackIdeaById } from 'services/ideas'
import { hideDialog } from 'support/popup_dialogs/modal_alert_controller/ModalActions'
import FeedbackView from './FeedbackView'
import { getIdea } from 'services/create_idea'
import i18n from 'support/i18n'
import {
  showPopupErrorSnackbar,
} from 'support/popup_dialogs'

const mapStateToProps = (state, ownProps) => ({
  id: ownProps.id,
  rejectedReasons: ownProps.rejectedReasons,
})

const mapDispatchToProps = dispatch => {
  return {
    onClick: (id, reason) => {
      const onSuccess = () => {
        //dispatch(getIdeaComments(id))
        dispatch(hideDialog())
        dispatch(getIdea(id))
      }
      const onFailure = () => {
        dispatch(hideDialog())
        showPopupErrorSnackbar(i18n.t('EXPLORE_IDEA.ERRO_FEEDBACK'), dispatch)
      }
      // dispatch(postComment(value, parentId, ideaId, onSuccess, onFailure))
      dispatch(feedbackIdeaById(id, reason, onSuccess, onFailure))

      //Navigation.navigateToExplore()
      // Navigation.navigateToIdea(id)
    },
    cancelButtonAction: () => dispatch(hideDialog()),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FeedbackView)
