import React from 'react'
import * as PropTypes from 'prop-types'
import i18n from 'support/i18n'
import { Typography, withStyles } from '@material-ui/core'
import NxtContributionCurrency from 'components/nxt_contribution_currency'
import styles from './View.Style'

const ContributorTooltip = props => {
  const { classes, name, time, budget, other } = props
  return (
    <div className={classes.root}>
      <Typography variant="h6">{name}</Typography>
      <div className={classes.secondLine}>
        {time && (
          <div className={classes.valueElement}>
            <span>{time}</span>
            <span>{i18n.t('EXPLORE_IDEA.CONTRIBUTE.HOURS')}</span>
          </div>
        )}
        {budget && (
          <div className={classes.valueElement}>
            <NxtContributionCurrency>{budget}</NxtContributionCurrency>
            <span>{i18n.t('EXPLORE_IDEA.CONTRIBUTE.CASH')}</span>
          </div>
        )}
      </div>
      <p>{other}</p>
    </div>
  )
}

ContributorTooltip.defaultProps = {
  name: '',
  time: '',
  budget: '',
  other: '',
}

ContributorTooltip.propTypes = {
  name: PropTypes.string,
  time: PropTypes.string,
  budget: PropTypes.string,
  other: PropTypes.string,
}

export default withStyles(styles)(ContributorTooltip)
