import React, { useEffect, useState } from 'react'
import {Container} from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import noDraftIdeasImg from '../../img/no_draft_idea.png'
import i18n from 'support/i18n'
import styles from './View.Style'
import IdeaPanels from 'components_newui/idea_panel/View'
import FilterComponent from 'components/filter_component/View'

const PendingValidation = (props) => {
    const { classes, ideas,getPendingIdeas,isLoadingPending } = props
    const [searchQuery, setSearchQuery] = useState("")
    const [pendingIdeaData, setPendingIdeaData ] = useState(ideas)
   

    useEffect(()=>{
       getPendingIdeas()
    },[])

  useEffect(() => {
        setPendingIdeaData(ideas)
    },[ideas])

    const ApplySeach = ()=>{
     const newSearchVal =  ideas.filter(item => (
        item.title.toLowerCase().includes(searchQuery.toLowerCase())
      ))
      setPendingIdeaData(newSearchVal)
    }


  return (
    <>
        <section className={classes.pendingValidationPg}>
            <Container>
                <h1 className={classes.heading}><i className="icon-idea" /> {i18n.t('PENDING_VALIDATION.TITLE')}</h1>

                <FilterComponent
                    // activeFilters={activeFilters}
                    serviceLineOrgs={[]}
                    types={[]}
                    ideaStatusFilter={false}
                    // handleActiveFilters={handleActiveFilters}
                    search={searchQuery}
                    setSearch={setSearchQuery}
                    applySearch={ ApplySeach }
                    resetSearch={
                      () => { setSearchQuery('');setPendingIdeaData(ideas)}
                    }
                />
                    
                {
                     isLoadingPending  ? 'loading.....' :

                    pendingIdeaData && pendingIdeaData.length > 0 ? ( 
                    <div className={classes.IdeaGrid}> 
                       { pendingIdeaData.map(ideaItem=>{ return <IdeaPanels key={ideaItem.id} idea={ideaItem} urlId={ideaItem.id} />})}
                    </div>) :
                    (
                    <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                        <img src={noDraftIdeasImg} className={classes.noIdeaImg} alt="no idea found" />
                        <p className={classes.no_idea_title}> No ideas found </p>
                    </div>
                    )

                }
                
            </Container>
        </section>

    </>
  )
}

export default withStyles(styles)(PendingValidation)