import React, { Fragment, forwardRef, useState } from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import { TextField } from '@material-ui/core'
import * as PropTypes from 'prop-types'
import warning_ico from 'img/warning.png'
import styles from './View.Style'


const TextInputOneLine = forwardRef( props => {
    const {
        classes,
        width,
        placeholder,
        char_limit,
        value,
        valid,
        handleChange,id,
        canEditTitle,
        titleRef
    } = props

    return(
        <div className={classes.inputrelative} >
        <TextField 
            // id="outlined-basic"
            ref={titleRef}
            disabled={canEditTitle}
            id={id} 
            label={placeholder}
            className={!valid ? classes.invalid : null}
            variant="outlined" 
            style={{ width: `${width}px` }}
            inputProps={{ maxLength: char_limit }}
            value={value}
            onChange={handleChange}
            />
        {!valid && <img src={warning_ico} className={classes.warning} alt='warning' />}
        </div>
    )
})

TextInputOneLine.defaultProps = {
    width: 350,
    placeholder: '',
    char_limit: 150,
    valid: true,
}

TextInputOneLine.propTypes = {
    width: PropTypes.number,
    placeholder: PropTypes.string,
    char_limit: PropTypes.number,
    value: PropTypes.string.isRequired,
    valid: PropTypes.bool,
    handleChange: PropTypes.func.isRequired,
}


export default withStyles(styles)(TextInputOneLine)