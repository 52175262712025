import React, { Component, useRef, useState } from 'react'
import { withStyles, Typography, Divider, TextField } from '@material-ui/core'
import * as PropTypes from 'prop-types'
import i18n from 'support/i18n'
// import ImageUploader from 'components/image_uploader/View'
import ImageUploader from '../../../../components_newui/new_ui_image_uploader/View'
import clsx from 'clsx'
import styles from './View.Style'
import RewardChoices from './components/reward_choices/View'
import upload_icon from 'img/Upload_file.png'
import remove_icon from 'img/remove.png'

const ExtraRewards = (props) =>{

// class ExtraRewards extends Component {
  // constructor(props) {
  //   super(props)
  //   this.title = i18n.t('CHALLENGES.REWARD')
  //   this.description = i18n.t('CHALLENGES.REWARD_DESC')
  //   this.descriptionHelp = i18n.t('CHALLENGES.REWARD_DESC_INFO')
  //   this.image = i18n.t('CHALLENGES.IMAGE')
  //   this.whoRewarded = i18n.t('CHALLENGES.WHO_REWARDED')
  //   this.descriptionRef = React.createRef()
  //   this.rewardedRef = React.createRef()
  // }

  // componentDidUpdate(prevProps) {
  //   const { errorObject } = this.props
  //   if (prevProps.errorObject !== errorObject && Object.keys(errorObject).length > 0) {
  //     const refToScroll = Object.keys(errorObject).find(key => key === 'rewardDescription')
  //       ? this.descriptionRef
  //       : this.rewardedRef
  //     refToScroll.current.scrollIntoView()
  //   }
  // }

  // render() {
    const {
      usingDelete,
      classes,
      reward,
      onDescriptionChange,
      onRewardedChange,
      onOtherChange,
      onSelectImage,
      deleteImage,
      errorObject,
    } = props

    // console.log("props extr   =>",this.props)

    const { description, image, rewarded, rewardedOther } = reward
    const images = image.key ? [image] : []


  const  fileInput = useRef([]);
  const [activeStep,setActiveStep] = useState(0)

  
  const handleUploadImage = (file) =>{
    const { onSelectImage } = props
    const reader = new FileReader()
    reader.readAsDataURL(file)

    reader.onload = event => {
      if (event.target.readyState === FileReader.DONE) {
        const src = event.target.result
        onSelectImage({ name: file.name, src })
        if (fileInput) {
          fileInput.current.value = ''
        }
      }
    }
  }
  const handleSelectedUploadImage =(e) =>{
    const {target} = e
    if (!target.files.length) return
    const file = target.files[0]
    handleUploadImage(file)
  }
  
  const handleDeleteButton =()=> {
 
    const activeImageName = images[activeStep] && images[activeStep].name
    if (activeImageName) {
      const imageToDelete = images[activeStep]
      const newImages = images.filter(image => image.name !== activeImageName)
      deleteImage(newImages, activeImageName, imageToDelete)
      setActiveStep( activeStep)
    }
  }

  const shortenFileName = (filename)=>{
    // console.log("filename",filename)
    const [name, extension] = filename.split('.')
    // console.log("extension",extension)
    let short_name = name
    if (short_name.length > 9){
      short_name = `${short_name.substring(0, 4)}..${short_name.substring(short_name.length-4)}`
    }
    // return `${short_name}.${extension}`
    return `${short_name}.`
  }


  
    return (
      <div className={classes.root}>
        <Typography variant="h5" className={classes.mainTitle}>
          {i18n.t('CHALLENGES.REWARD')}
        </Typography>
        <Divider />
        <div className={classes.rewardsWrapper}>
          <div className={classes.line}>
            <div
              className={clsx(classes.inputLabel )}
            >
              {i18n.t('CHALLENGES.REWARD')}
            </div>
            <div className={classes.inputContainer}>
              <TextField
                id="challenge-reward-description"
                placeholder={i18n.t('CHALLENGES.REWARD_DESC')}
                margin="dense"
                fullWidth
                multiline
                defaultValue={description}
                onChange={event => onDescriptionChange(event.target.value)}
                helperText={i18n.t('CHALLENGES.REWARD_DESC_INFO')}
                // error={errorObject.rewardDescription}
              />
            </div>
          </div>
          <div className={classes.line}>
            <div className={classes.imageLabel}>{i18n.t('CHALLENGES.IMAGE')}:</div>
            <div className={classes.inputContainer}>
              {/* <ImageUploader
                images={images}
                onSelectImage={onSelectImage}
                deleteImage={deleteImage}
                subtitle='Browse image'
                // imageUploadError={errorObject.imageUploadError}
                isOneImageOnly
                uniqueClassName='imgupload2'
              /> */}
              
             <label htmlFor="contained-button-file-reward">
              <input
                accept="image/*"
                className={classes.input}
                id="contained-button-file-reward"
                type="file"
                ref={fileInput}
                onChange={handleSelectedUploadImage}
              />
                
              <div className={classes.add_image} 
                onDragOver={e => e.preventDefault()} 
                onDrop={e => {e.preventDefault();handleUploadImage(e.dataTransfer.files[0])}}>
                <img className={classes.upload_icon} src={upload_icon}/>
                <div className={classes.upload_media_help_text_1}>Browse image</div>
              </div>
              <div className={classes.dragndrop_text}>Drag and drop here</div>
              <div className={classes.format_support_text}>Supports: JPG, PNG. | Limit 10 MB per image</div>
            </label>
              
              {
                images.length > 0 && 
                  <div className={classes.imageContainer} key={image.name}>
                    {/* {usingDelete && ( */}
                      <img src={remove_icon} className={classes.deleteButton} onClick={handleDeleteButton} />
                    {/* )} */}
                    <img className={classes.thumb_img} src={image.src} alt={image.name} />
                  </div>
                }
             

            </div>
          </div>
          <div className={classes.line}>
            <div
              className={clsx(classes.inputLabel)}
              
            >
              {i18n.t('CHALLENGES.WHO_REWARDED')}
            </div>
            <div className={classes.inputContainer}>
              <RewardChoices
                onRewardedChange={onRewardedChange}
                onOtherChange={onOtherChange}
                value={rewarded}
                otherValue={rewardedOther}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
// }
ExtraRewards.defaultProps = {}
ExtraRewards.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
    error: PropTypes.string,
  }).isRequired,
  reward: PropTypes.shape({
    description: PropTypes.string,
    image: PropTypes.shape({}).isRequired,
    rewarded: PropTypes.string,
    rewardedOther: PropTypes.string,
  }).isRequired,
  onDescriptionChange: PropTypes.func.isRequired,
  onRewardedChange: PropTypes.func.isRequired,
  onOtherChange: PropTypes.func.isRequired,
  onSelectImage: PropTypes.func.isRequired,
  deleteImage: PropTypes.func.isRequired,
  errorObject: PropTypes.shape({
    rewardDescription: PropTypes.bool,
    rewarded: PropTypes.bool,
    imageUploadError: PropTypes.string,
  }).isRequired,
}
export default withStyles(styles)(ExtraRewards)
