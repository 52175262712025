import { POINTS_INFO_SUCCESS } from 'actions/sync/how_it_works/Constants'

const initialState = {
  infoPoints: [],
  infoPointsLoaded: false,
}

const howItWorksReducer = (state = initialState, action) => {
  switch (action.type) {
    case POINTS_INFO_SUCCESS:
      return {
        ...state,
        infoPoints: action.response.infoPoints,
        infoPointsLoaded: true,
      }
    default:
      return state
  }
}

export default howItWorksReducer
