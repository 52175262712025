import React from 'react'
import * as PropTypes from 'prop-types'
import Image from 'components/image/View'
import helpFab from 'img/help-fab-image.svg'
import { IconButton, withStyles } from '@material-ui/core'
import styles from './View.Style'

const HelpWidget = props => {
  const { classes, children, onClickHelp } = props
  return (
    <div className={classes.container}>
      <IconButton
        id="confirm-delete"
        onClick={onClickHelp}
        className={classes.iconButton}
        aria-label="confirm"
      >
        <Image src={helpFab} defaultSrc={helpFab} />
      </IconButton>

      <div className={classes.children}>{children}</div>
    </div>
  )
}

HelpWidget.propTypes = {
  children: PropTypes.node.isRequired,
  onClickHelp: PropTypes.func.isRequired,
}

export default withStyles(styles)(HelpWidget)
