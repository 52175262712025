const styles = theme => ({
  root: {
    padding: theme.spacing(2.5),
  },
  mainContent: {
    padding: theme.spacing(0, 2.5, 2.5, 2.5),
    fontFamily:theme.fontFamily.fontFamilyRegular,
  },
  subtitle:{
    fontFamily:theme.fontFamily.fontFamilyBold,
  },
  roiSection: {
    marginTop: theme.spacing(5),
  },
  simplePaper: {
    padding: theme.spacing(3),
    backgroundColor: '#f5f5f5',
  },
})

export default styles
