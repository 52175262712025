import React from 'react'
import { connect } from 'react-redux'
import { showPopupDialog } from 'support/popup_dialogs'
import HelpWidgetDialog from './components/help_widget_dialog'
import View from './View'

const mapDispatchToProps = dispatch => {
  return {
    onClickHelp: () => {
      showPopupDialog(<HelpWidgetDialog />, dispatch)
    },
  }
}

export default connect(
  null,
  mapDispatchToProps,
)(View)
