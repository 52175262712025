import React from 'react'
import * as PropTypes from 'prop-types'
import { withStyles, Paper } from '@material-ui/core'
import i18n from 'support/i18n'
import ButtonLink from 'components/button_link/View'
import styles from './View.Style'
import AddComment from './components/add_comment/View'
import CommentList from './components/comment_list/View'
import Divider from '@material-ui/core/Divider'

const CommentsSection = props => {
  const {
    classes,
    onPostComment,
    postingCommentStatus,
    comments,
    onDeleteComment,
    onVoteComment,
    onEditComment,
    onUserClick,
    userInvestmentsMap,
    onShareIdea,
    isAdminOrInnovator,
    userEmail,
    searchSuggestions,
    suggestions,
    dividerclass,
    resetUserSuggestions,
  } = props
  return (
    <div className={classes.root}>
      <AddComment
        userSuggestions={suggestions}
        onMentionType={searchSuggestions}
        onPostComment={onPostComment}
        postingCommentStatus={postingCommentStatus}
        resetUserSuggestions={resetUserSuggestions}
      />
      <Divider className={dividerclass}  style={{marginTop: '31px'}}  />
      <div>
        <CommentList
          onPostComment={onPostComment}
          comments={comments}
          onDeleteComment={onDeleteComment}
          postingCommentStatus={postingCommentStatus}
          onVoteComment={onVoteComment}
          onEditComment={onEditComment}
          onUserClick={onUserClick}
          userInvestmentsMap={userInvestmentsMap}
          isAdminOrInnovator={isAdminOrInnovator}
          userEmail={userEmail}
          onMentionType={searchSuggestions}
          userSuggestions={suggestions}
          resetUserSuggestions={resetUserSuggestions}
        />
      </div>
      
    </div>
  )
}

CommentsSection.propTypes = {
  onPostComment: PropTypes.func.isRequired,
  postingCommentStatus: PropTypes.string.isRequired,
  comments: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      text: PropTypes.string,
    }),
  ).isRequired,
  onDeleteComment: PropTypes.func.isRequired,
  onVoteComment: PropTypes.func.isRequired,
  onEditComment: PropTypes.func.isRequired,
  onUserClick: PropTypes.func.isRequired,
  userInvestmentsMap: PropTypes.shape({}).isRequired,
  isAdminOrInnovator: PropTypes.bool.isRequired,
  userEmail: PropTypes.string.isRequired,
  searchSuggestions: PropTypes.func.isRequired,
  suggestions: PropTypes.arrayOf(PropTypes.shape({ email: PropTypes.string })).isRequired,
  resetUserSuggestions: PropTypes.func.isRequired,
}

export default withStyles(styles)(CommentsSection)
