import { POST_INVEST_IDEA_SUCCESS } from 'actions/sync/create_idea_form/Constants'
import { SET_USER_PREFS_SUCCESS } from 'actions/sync/settings/Constants'
import { FULL_INFO_SUCCESS } from 'actions/sync/domain/Constants'
import * as userTypes from 'actions/sync/user/userActionConstans'
import { SET_LOGGEDOUT } from 'actions/sync/auth/Constants'
import {
  NOTIFICATION_ME_FAILURE,
  NOTIFICATION_ME_SUCCESS,
  NOTIFICATION_ME_STARTED,
} from 'services/TypesConstants'
import defaultPhoto from 'img/default-photo.png'

export const initialState = {
  admin: false,
  isInnovator: false,
  localAdmin: false,
  localInnovation: false,
  evaluator: false,
  localEvaluator: false,
  shouldGoToMaintenance: false,
  balance: '',
  countryId:'',
  notifications: {
    notifications: [],
    activities: [],
    totalUnread: 0,
    unreadActivities: 0,
    unreadNotifications: 0,
    isLoading: false,
  },
  userName: '',
  userLastName: '',
  userPhoto: defaultPhoto,
  userEmail: '',
  investments: {
    ideas: [],
    totalInvested: 0,
    totalProfit: 0,
  },
  prefs: {
    ideastatus: false,
    invest: false,
    splash: false,
    newidea: false,
    challenge: false,
    newcoment: false,
    week: false,
  },
  isEditUserLoading: false,
  domains: [],
  nextinit: {
    corporateColor: '',
    corporateColorFG: '',
    id: '',
    name: '',
  },
}

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case FULL_INFO_SUCCESS:
      return {
        ...state,
        admin: action.response.admin,
        isInnovator: action.response.isInnovator,
        localAdmin: action.response.localAdmin,
        localInnovation: action.response.localInnovation,
        evaluator: action.response.evaluator,
        localEvaluator: action.response.localEvaluator,
        shouldGoToMaintenance: !action.response.admin || !action.response.isInnovator,
        balance: action.response.balance,
        userName: action.response.userName,
        userLastName: action.response.userLastName,
        userPhoto: action.response.userPhoto,
        userEmail: action.response.email,
        prefs: action.response.prefs,
        domains: action.response.nextinits,
        countryId: action.response.countryId,
      }
    case SET_LOGGEDOUT:
      return {
        ...initialState,
        shouldGoToMaintenance: state.shouldGoToMaintenance,
      }
    case NOTIFICATION_ME_STARTED:
      return { ...state, notifications: { ...state.notifications }, isLoading: true }
    case NOTIFICATION_ME_FAILURE:
      return { ...state, notifications: { ...state.notifications }, isLoading: false }
    case NOTIFICATION_ME_SUCCESS:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          ...action.response,
          isLoading: false,
        },
      }
    case POST_INVEST_IDEA_SUCCESS:
      return {
        ...state,
        balance: action.response.balance,
      }
      case userTypes.APPLY_FILTER_INVESTMENTS_STARTED:
        case userTypes.GET_INVESTMENTS_STARTED:
        return{
          ...state,
          isFilterInvestmentLoading:true
        }
    case userTypes.APPLY_FILTER_INVESTMENTS_SUCCESS:
    case userTypes.GET_INVESTMENTS_SUCCESS:
      return {
        ...state,
        isFilterInvestmentLoading:false,
        investments: action.response,
      }

    case userTypes.APPLY_FILTER_INVESTMENTS_FAILURE:
      case userTypes.GET_INVESTMENTS_FAILURE:
      return{
        ...state,
        isFilterInvestmentLoading:false
      }
    case userTypes.EDIT_PROFILE_STARTED:
      return {
        ...state,
        isEditUserLoading: true,
      }
    case userTypes.EDIT_PROFILE_FAILURE:
      return {
        ...state,
        isEditUserLoading: false,
      }
    case userTypes.EDIT_PROFILE_SUCCESS:
      return {
        ...state,
        userName: action.response.userName,
        userLastName: action.response.userLastName,
        userPhoto: action.response.userPhoto,
        isEditUserLoading: false,
      }
    case SET_USER_PREFS_SUCCESS:
      return {
        ...state,
        prefs: {
          ...state.prefs,
          ideastatus: action.response.ideastatus,
          invest: action.response.invest,
          splash: action.response.splash,
          week: action.response.week,
          newidea: action.response.newidea,
          challenge: action.response.challenge,
          newcoment: action.response.newcoment,
        },
      }
    default:
      return state
  }
}

export default userReducer
