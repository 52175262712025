
import * as types from 'actions/sync/challenge_steps/Constants'
// import {
//   POST_UPLOAD_CHALLENGE_VIDEO_SUCCESS,
//   POST_UPLOAD_CHALLENGE_VIDEO_STARTED,
//   POST_UPLOAD_CHALLENGE_VIDEO_FAILURE,
//   GET_CONFIRMATION_CHALLENGE_VIDEO_SUCCESS,
//   DELETE_CHALLENGE_VIDEO_SUCCESS,
//   DELETE_CHALLENGE_VIDEO_STARTED,
//   DELETE_CHALLENGE_VIDEO_FAILURE,
// } from 'actions/sync/video/Constants'
// import { type } from 'jquery'


export const initialState = {
  id: '',
  isLoading: false,
  createDate:'',
  sponsorSuggestions: [],
  sponsorStarted: false,
  proposerSuggestions: [],
  proposeItems:[],
  proposerStarted: false,
  selectedSponsor:{},
  selectedProposer: {},
  isNextStepInProgress: false,
  challengeDescription: {
    isLoaded: false,
    title: '',
    whyImportant: '',
    description: '',
    images: [],
    imagesLength:0,
    mainImageValue: '',
    externalVideo: '',
    fileVideoId: '',
    fileVideo: '',
    isVideoLoading: false,
    isVideoReady: false,
    isVideoRemoving: false,
    hasVideoError: false,
  },
  gaming: {
    activeTab: 0,
    easyMode: {
      settings: 'default',
    },
    advancedMode: {
      isLoaded: false,
      approveMult: 100,
      implementMult: 100,
      supportMult: 100,
      currentGoal: 100,
      defaultValues: {
        approveMult: 100,
        implementMult: 100,
        supportMult: 100,
        currentGoal: 20000,
      },
    },
  },
  reward: {
    description: '',
    image: {},
    rewarded: '',
    rewardedOther: '',
  },
  preview: {
    groupId: '',
    fromDate: new Date(),
    toDate: null,
    isGlobal: true,
    organization: '',
    defaultGroups: [],
    rightGroups: [],
    challengeLocation: true,
  },
  status: '',
  isActive: false,
  isProposed: false,
  errors: {
    imageUploadError: '',
  },
  isImageUploading: false,
}

const ChallengeDraftReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.RESET_CHALLENGE:
      return {
        ...state,
      }
      case types.SET_TITLE_DESCRIPTION: {
        
        return {
          ...state,
          challengeDescription: {
            ...state.challengeDescription,
            title: action.title,
          },
        }
      }
      case types.SET_WHY_IMPORTANT_DESCRIPTION: {
        return {
          ...state,
          challengeDescription: {
            ...state.challengeDescription,
            whyImportant: action.whyImportant,
          },
        }
      }
      case types.SET_DESCRIPTION: {
        return {
          ...state,
          challengeDescription: {
            ...state.challengeDescription,
            description: action.description,
          },
        }
      }

      case types.ADD_CHALLENGE_STEPS_IMAGE_STARTED:
      return {
        ...state,
        isImageUploading: true,
        errors: {
          ...state.errors,
          imageUploadError: '',
        },
      }
    case types.ADD_CHALLENGE_STEPS_IMAGE_SUCCESS:
      return {
        ...state,
        isImageUploading: false,
        challengeDescription: {
          ...state.challengeDescription,
          images: [...state.challengeDescription.images, action.response.image],
          imagesLength:[...state.challengeDescription.images, action.response.image].length,
          mainImageValue: state.challengeDescription.mainImageValue || action.response.mainImage,
        },
        errors: {
          ...state.errors,
          imageUploadError: '',
        },
      }
    case types.DELETE_CHALLENGE_INFO_IMAGE_SUCCESS:
      return {
        ...state,
        challengeDescription: {
          ...state.challengeDescription,
          images: action.response.images,
          imagesLength:action.response.images.length,
          mainImageValue: action.response.mainImageValue,
        },
      }
    case types.SET_CHALLENGE_IMAGE_UPLOAD_ERROR:
      return {
        ...state,
        isImageUploading: false,
        errors: {
          ...state.errors,
          imageUploadError: action.value,
        },
      }
    case types.SEARCH_USER_SPONSOR_STARTED:
      return {
        ...state,
        sponsorStarted: true,
      }
    case types.SEARCH_USER_SPONSOR_SUCCESS:
      return {
        ...state,
        sponsorSuggestions: action.response,
        sponsorStarted: false,
      }
    case types.SEARCH_USER_PROPOSER_STARTED:
      return {
        ...state,
        proposerStarted: true,
      }
    case types.SEARCH_USER_PROPOSER_SUCCESS:
      return {
        ...state,
        proposerSuggestions: action.response,
        proposerStarted: false,
      }
    case types.SET_SPONSOR_SUGGESTION:
      return {
        ...state,
        selectedSponsor: action.suggestion,
      }
    case types.SET_PROPOSER_SUGGESTION:
      return {
        ...state,
        selectedProposer: action.suggestion,
      }
    case types.SET_GAMING_ACTIVE_TAB:
      return {
        ...state,
        gaming: {
          ...state.gaming,
          activeTab: action.activeTab,
        },
      }
      
      case types.SET_EASY_SETTING:
        return {
          ...state,
          gaming: {
            ...state.gaming,
            easyMode: {
              settings: action.setting.setting,  
            },
            advancedMode: {
              ...state.gaming.advancedMode,
              ...action.setting.advancedModeMult,
            },
          },
        }
      case types.SET_ADVANCED_AMOUNT_TO_BE_SUPPORTED:
        return {
          ...state,
          gaming: {
            ...state.gaming,
            advancedMode: {
              ...state.gaming.advancedMode,
              currentGoal: parseInt(action.amount, 0),
            },
          },
        }
      case types.SET_ADVANCED_SUPPORT_IDEAS:
        return {
          ...state,
          gaming: {
            ...state.gaming,
            advancedMode: {
              ...state.gaming.advancedMode,
              supportMult: parseInt(action.amount, 0),
            },
          },
        }
      case types.SET_ADVANCED_APPROVED_IDEAS:
        return {
          ...state,
          gaming: {
            ...state.gaming,
            advancedMode: {
              ...state.gaming.advancedMode,
              approveMult: parseInt(action.amount, 0),
            },
          },
        }
      case types.SET_ADVANCED_IMPLEMENTED_IDEAS:
        return {
          ...state,
          gaming: {
            ...state.gaming,
            advancedMode: {
              ...state.gaming.advancedMode,
              implementMult: parseInt(action.amount, 0),
            },
          },
        }
      case types.GET_ROI_INFO_SUCCESS:
        return {
          ...state,
          gaming: {
            ...state.gaming,
            advancedMode: {
              ...state.gaming.advancedMode,
              ...action.response,
              defaultValues: action.response.defaultValues,
              isLoaded: true,
            },
          },
        }
     
    case types.PUT_CHALLENGE_INFO_STARTED:
    case types.PUT_PEOPLE_INFO_STARTED:
    case types.PUT_ROI_INFO_STARTED:  
    case types.PUT_CHALLENGE_DATES_STARTED:
    case types.PUT_PROPOSE_CHALLENGE_STARTED: 
    return{
      ...state,
      isLoading : true
    }
    
      case types.PUT_CHALLENGE_INFO_SUCCESS:
      case types.PUT_PEOPLE_INFO_SUCCESS:
      case types.PUT_ROI_INFO_SUCCESS:  
      case types.PUT_CHALLENGE_DATES_SUCCESS:
      return{
        ...state,
        isLoading : false
      }

      case types.PUT_CHALLENGE_INFO_FAILURE:
      case types.PUT_PEOPLE_INFO_FAILURE:
      case types.PUT_ROI_INFO_FAILURE:  
      case types.PUT_CHALLENGE_DATES_FAILURE:
      case types.PUT_PROPOSE_CHALLENGE_FAILURE: 
      return{
        ...state,
        isLoading : false
      }
      
    

    case types.DELETE_CHALLENGE_DRAFT_STARTED:
    case types.GET_CHALLENGE_STARTED:
    case types.GET_CHALLENGE_DRAFT_STARTED:
    case types.PUT_DRAFT_CHALLENGE_STARTED:
      return {
        ...state,
        isLoading: true,
      }

    

    case types.DELETE_CHALLENGE_DRAFT_SUCCESS:
    case types.GET_CHALLENGE_DRAFT_SUCCESS:
    case types.GET_CHALLENGE_SUCCESS:
    case types.PUT_DRAFT_CHALLENGE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        id: action.response.id,
        challengeDescription: action.response.challengeDescription,
        gaming: action.response.gaming,
        selectedSponsor: action.response.selectedSponsor,
        selectedProposer: action.response.selectedProposer,
        reward: action.response.reward,
        preview: { ...state.preview, ...action.response.preview },
        status: action.response.status,
        createDate:action.response.createDate,
        isActive: action.response.isActive,
        isProposed: action.response.isProposed,
      }

      case types.SET_CHALLENGE_EXTERNAL_VIDEO_URL:
        return {
          ...state,
          challengeDescription: {
            ...state.challengeDescription,
            externalVideo: action.value,
          },
        }
        case types.SET_CHALLENGE_MAIN_IMAGE_SUCCESS:
      return {
        ...state,
        challengeDescription: {
          ...state.challengeDescription,
          mainImageValue: action.response,
        },
      }
      case types.SET_CHALLENGE_REWARD_DESCRIPTION:
      return {
        ...state,
        reward: {
          ...state.reward,
          description: action.value,
        },
      }
    case types.SET_CHALLENGE_REWARD_REWARDED:
      return {
        ...state,
        reward: {
          ...state.reward,
          rewarded: action.value,
        },
      }
    case types.SET_CHALLENGE_REWARD_OTHER:
      return {
        ...state,
        reward: {
          ...state.reward,
          rewardedOther: action.value,
        },
      }
    case types.ADD_CHALLENGE_STEPS_REWARD_IMAGE_STARTED:
      return {
        ...state,
        isImageUploading: true,
        errors: {
          ...state.errors,
          imageUploadError: '',
        },
      }
    case types.ADD_CHALLENGE_STEPS_REWARD_IMAGE_SUCCESS:
      return {
        ...state,
        isImageUploading: false,
        reward: {
          ...state.reward,
          image: action.response.image,
        },
        errors: {
          ...state.errors,
          imageUploadError: '',
        },
      }
    case types.DELETE_CHALLENGE_REWARD_IMAGE_SUCCESS:
      return {
        ...state,
        reward: {
          ...state.reward,
          image: {},
        },
      }

      case types.SET_PREVIEW_FROM_DATE:
      return {
        ...state,
        preview: {
          ...state.preview,
          fromDate: action.fromDate,
        },
      }
    case types.SET_PREVIEW_TO_DATE:
      return {
        ...state,
        preview: {
          ...state.preview,
          toDate: action.toDate,
        },
      }
    case types.SET_CHALLENGE_ORGANIZATION:
      return {
        ...state,
        preview: {
          ...state.preview,
          organization: action.value,
        },
      }
      case types.PUT_PROPOSE_CHALLENGE_SUCCESS:
        return{
          ...state,
        isLoading : false,
          preview:{
            ...state.preview,
          },
          proposeItems: action.response.items
        }
    default:
      return state
  }
}

export default ChallengeDraftReducer
