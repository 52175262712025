import { applyMiddleware, createStore, compose } from 'redux'
import logger from 'redux-logger'
import thunk from 'redux-thunk'
import { persistStore } from 'redux-persist'
import reducers from '../reducers'
import { getServiceClientMiddleware } from '../support/network/NetworkMiddleware'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL

const getNetworkMiddleware = getServiceClientMiddleware(BASE_URL)

const middlewares = []

middlewares.push(thunk)
middlewares.push(getNetworkMiddleware)

if (!process.env.REACT_APP_LOGGER_DISABLED) {
  middlewares.push(logger)
}

export const store = createStore(reducers, {}, composeEnhancers(applyMiddleware(...middlewares)))

export const persistor = persistStore(store)
