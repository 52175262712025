const styles = theme => ({
  root: {
    padding: theme.spacing(3, 0),
    borderTop: '1px solid #b3b3b3',
  },
  titleSection: {
    ...theme.flexRowCenter,
    justifyContent: 'space-between',
    '& > h2': {
      display: 'flex',
      alignItems: 'center',
      fontSize: '26px',
      fontWeight: 500,
      '& > span': {
        fontSize: '32px',
        marginRight: theme.spacing(1),
      },
    },
  },
  total: {
    fontSize: '22px',
    '& strong': {
      fontSize: '30px',
      color: theme.palette.text.secondary,
      lineHeight: '0px',
    },
  },
  formContainer: {
    marginTop: theme.spacing(1),
    display: 'flex',
    padding: theme.spacing(1),
    '& > div:first-child': {
      flex: 1.3,
      '& > div': {
        width: '100%',
      },
    },
    '& > div:nth-child(2)': {
      flex: 6,
      display: 'flex',
      flexDirection: 'column',
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      '& > div:first-child': {
        marginBottom: theme.spacing(1),
      },
      '& > div:nth-child(2)': {
        display: 'flex',
        justifyContent: 'space-between',
      },
    },
    '& > div:last-child': {
      flex: 1.3,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-evenly',
    },
  },
  kpiError: {
    minHeight: theme.spacing(1),
    maxHeight: theme.spacing(1),
    marginBottom: '0',
    color: theme.palette.error.main,
  },
})

export default styles
