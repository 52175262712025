import withStyles from '@material-ui/core/styles/withStyles'
import styles from './View.Style'
import { Modal } from '@material-ui/core'
import React, { Fragment } from 'react'
import thumbnail from 'img/nxt-logo-fondo-ideas.png'
import Divider from '@material-ui/core/Divider'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import documentUpload from 'img/pdf_ico.png'
import Button from '../../pages_newui/post_idea/components/Button'
import * as PropTypes from 'prop-types'
import remove_icon from 'img/close-system.png'
import ChallengeCard from '../../components_newui/challenge_card/View'
import i18n from 'support/i18n'
import FileUploader from 'components_newui/new_ui_file_uploader/View'

const IdeaPreview = props => {
  const {
    isLoading,
    classes,
    handleCancleClick,
    handleSubmitClick,
    title,
    images,
    video,
    problemDescription,
    whyNeeded,
    whatUnique,
    similarExisting,
    planDescription,
    benefitsDescription,
    category,
    organisation,
    strategicPriorities,
    challenges,
    supportingDocs,
    csrfToken,
    editIdea,
    isSavaButtonEnable,
    handleEditSaveIdeaClick,
    handleReSubmitClick,
    textareaDescription,
    isOrganizationsActive,
    showPreview,
    setShowPreview,
  } = props

  const shortenFileName = filename => {
    const [name, extension] = filename.split('.')
    let shortName = name
    if (shortName.length > 9) {
      shortName = `${shortName.substring(0, 4)}..${shortName.substring(shortName.length - 4)}`
    }
    return `${shortName}`
  }
  // console.log("prev",category,category.filter(item=>item),category.filter(item=>item)[0].name)
  const ideaDesc = textareaDescription // convertToPlain(textareaDescription)
  const categoriesName = category && category.filter(item => item)[0].name
  const strategicPrioritiesLable = strategicPriorities.map(i => <p>{i}</p>)
  return (
    <Fragment>
      <Modal
        open={showPreview}
        onClose={() => setShowPreview(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className={classes.innerWidth}>
          <div style={{ display: 'flex', justifyContent: 'end' }}>
            <img
              src={remove_icon}
              style={{
                height: '30px',
                cursor: 'pointer',
              }}
              onClick={() => setShowPreview(false)}
            />
          </div>
          <p className={classes.heading}>Idea title</p>
          <p className={classes.titleDesc}>{title}</p>
          { /* <p className={classes.heading}>Upload Image/Video</p> */}
          <p className={classes.boldFont}>Upload Images</p>
          <div className={classes.uploadedPicsAndVids}>
            <div className={classes.uploadedImages}>
              {/* Needs to be updated with the relevant info from the api call, not sure about the format the api call returns the pics in so may need to be changed */}
              {images.map(picture => (
                <figure key={picture.name} className={classes.picIcon}>
                  <img src={picture.src} alt={picture.name} />
                  {picture.name ? <figcaption>{shortenFileName(picture.name)}</figcaption> : null}
                </figure>
              ))}
            </div>
            <div className={classes.secondblock}>
              {video && (
                <iframe
                  className={classes.iframe}
                  title="ytVideo"
                  width="320"
                  height="180"
                  src={video}
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              )}
            </div>
          </div>
          <div className={classes.horizontal_line} />

          {/* Not sure how the idea questions/answers are stored or will be returned in the api call so this may need to be changed */}
          <p className={classes.heading}>Idea description</p>
          <div className={classes.answers}>
            <p>
              {
                //parse(ideaDesc)
                <div dangerouslySetInnerHTML={{ __html: ideaDesc }} />
              }
            </p>
            {/* <p>{textareaDescription}</p> */}
          </div>
{
  category ? (<>

          <div className={classes.horizontal_line} />

          <p className={classes.heading}>Idea category</p>
          <p>{categoriesName}</p>
</>
  ):null}

          {isOrganizationsActive && (
            <div>
              <div className={classes.horizontal_line} />

              <p className={classes.heading}>Organization</p>

              <p>{organisation}</p>
            </div>
          )}

          <div className={classes.horizontal_line} />

          {strategicPrioritiesLable.length > 0 && (
            <>
              <p className={classes.heading}>Strategic priorities</p>
              <p>{strategicPrioritiesLable}</p>
            </>
          )}
          <div className={classes.horizontal_line} />

          <p className={classes.heading}>Tag to challenges</p>
          {/* To be changed into the challenge card component */}
          <div className={classes.marginbox}>
            {challenges &&
              challenges.map(challenge => (
                <ChallengeCard key={challenge.id} challenge={challenge} />
              ))}
          </div>

          <div className={classes.horizontal_line} />

          {supportingDocs && (
            <>
              <p className={classes.heading}>Supporting documents</p>
              <FileUploader
                documentfiles={supportingDocs}
                csrfToken={csrfToken}
                previewInputHideIdea
              />
            </>
          )}

          <div className={classes.horizontal_line} />

          <div className={classes.end_buttons}>
              <Button
                className={classes.submit}
                disabled={isLoading}
                clickCallBack={handleCancleClick}
                text="Cancel"
              />
            {/* <div className={classes.cancel} onClick={handleCancleClick}>
              Cancel
            </div> */}
            {!editIdea && (
              <Button
                className={classes.submit}
                disabled={isLoading}
                clickCallBack={handleSubmitClick}
                text="Submit"
              />
            )}
            {editIdea && (
              <>
                {isSavaButtonEnable && (
                  <Button
                    className={classes.submit}
                    disabled={isLoading}
                    clickCallBack={handleEditSaveIdeaClick}
                    text={i18n.t('IDEA.SAVE')}
                  />
                )}

                {!isSavaButtonEnable && (
                  <Button
                    className={classes.submit}
                    disabled={isLoading}
                    clickCallBack={handleReSubmitClick}
                    text="Submit Idea"
                  />
                )}
              </>
            )}
          </div>
        </div>
      </Modal>{' '}
    </Fragment>
  )
}

export default withStyles(styles)(IdeaPreview)
