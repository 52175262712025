import React, { Component, useEffect, useState } from 'react'
import * as PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import { Paper } from '@material-ui/core'
import i18n from 'support/i18n'
import Card from './components/ranking_card/View'
import styles from './View.Style'
import Carousel from 'react-material-ui-carousel'
import Breadcrumbs from 'components_newui/pageBreadcrumbsMenu/View'
import PeopleCarousel from './components/carousel/View'
import IdeaPanel from 'components_newui/idea_panel/View'

const Ranking = props => {
    const {
      classes,
      getRanking,
      topInvestors,
      topPotentialInvestors,
      topPosts,
      topIdeasInvestors,
      topIdeas,
      navigateHome,
      navigateToIdea,
    } = props

    const [visibleCards, setVisibleCards] = useState(3)
    const updateVisibleCards = () => {
        if (window.innerWidth < 800){
          setVisibleCards(1)
        } else if (window.innerWidth < 1050){
          setVisibleCards(2)
        }else{
          setVisibleCards(3)
        }
    };

    useEffect(() => {
        window.addEventListener('resize', updateVisibleCards);
        updateVisibleCards()
    })
    
    const [ideatorsCarousel, setIdeatorsCarousel] = useState()
    const [investorsCarousel, setInvestorsCarousel] = useState()
    const [potentialInvestorsCarousel, setPotentialInvestorsCarousel] = useState()
    const [ideasCarousel, setIdeasCarousel] = useState()

    useEffect(()=> {
      getRanking()
    }, [])

    const populate_carousel = (data, setter, value, valueText) => {
      let cards = []
      let carousel_content = []
      for (const [index, person] of data.entries()){
        cards.push(
          <div style={{margin: '10px'}}>
            <Card name={person.name} number={index+1} image={person.photo}
              valueText={valueText} value={person[value]}/>
          </div>
        )
        if ((index+1) % visibleCards === 0 || index+1 === data.length){
          carousel_content.push(<div className={classes.flex_and_center}>{cards}</div>)
          cards = []
        }
      }
      setter(carousel_content)
    }

    useEffect(() => {
      populate_carousel(topPosts, setIdeatorsCarousel, 'ideas', 'Ideas Created')
    }, [topPosts])

    useEffect(() => {
      populate_carousel(topInvestors, setInvestorsCarousel, 'amountInvested','Invested Amount')
    }, [topInvestors])

    useEffect(() => {
      populate_carousel(topPotentialInvestors, setPotentialInvestorsCarousel, 'balance','Current Balance')
    }, [topPotentialInvestors])
    
    useEffect(() => {
      let cards = []
      let carousel_content = []
      for (const [index, idea] of topIdeas.entries()){
        cards.push(
          <div style={{margin: '10px'}}>
            <IdeaPanel  idea={idea} urlId={idea.id} />
          </div>
        )
        if ((index+1) % visibleCards === 0 || index+1 === topIdeas.length){
          carousel_content.push(<div className={classes.flex_and_center}>{cards}</div>)
          cards = []
        }
      }
      setIdeasCarousel(carousel_content)
    }, [topIdeas])

    const pageHierarchy = [
      {string: "Home", navigate: navigateHome}, 
      {string: "Ranking", navigate: ()=>{}}
    ] 


    


    return(
      <>
       <Breadcrumbs crumbs={pageHierarchy} />
      <div className={classes.background}>
        <div className={classes.title}>Top Ranked Ideators</div>
        <PeopleCarousel content={ideatorsCarousel} />
        
        <div className={classes.title}>Top Ideas</div>
        <Carousel 
          indicatorIconButtonProps={{
              style: {
                  color: '#CCCCCC'
              }
          }}
          activeIndicatorIconButtonProps={{
              style: {
                  color: '#8512FF'
              }
          }}
          navButtonsProps={{
              style: {
                  marginLeft: '15px',
                  marginRight: '15px'
              }
          }}
        >
          {ideasCarousel}
        </Carousel>

        <div className={classes.title}>Top Ranked Investors</div>
        <PeopleCarousel content={investorsCarousel} />
        
        <div className={classes.title}>Top Potential Investors</div>
        <PeopleCarousel content={potentialInvestorsCarousel} />
      </div>
      </>
    )
}
 
export default withStyles(styles)(Ranking)