import React from 'react'
import * as PropTypes from 'prop-types'
import { Button, Card, FormControl, MenuItem, Select } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import i18n from 'support/i18n'
import * as kpiTypes from 'common_constants/Kpis'
import NxtCircularLoader from 'components/nxt_circular_loader/View'

const KpiForm = props => {
  const { classes, formValue, onSubmit, onChangeValue, onCancel, isFormInUse } = props
  const { type, range, unit, expected, current, name, touched, error } = formValue

  return (
    <NxtCircularLoader isLoading={isFormInUse}>
      <Card className={classes.formContainer}>
        <div>
          <FormControl variant="outlined" className={classes.formControl}>
            <Select
              value={type}
              onChange={e => onChangeValue({ ...formValue, type: e.target.value })}
            >
              <MenuItem value={kpiTypes.QUANTITATIVE}>
                {i18n.t('EXPLORE_IDEA.QUANTITATIVE')}
              </MenuItem>
              <MenuItem value={kpiTypes.QUALITATIVE}>{i18n.t('EXPLORE_IDEA.QUALITATIVE')}</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div>
          <TextField
            error={error}
            type="text"
            onChange={e => onChangeValue({ ...formValue, name: e.target.value, touched: true })}
            name="title"
            margin="none"
            variant="outlined"
            fullWidth
            placeholder={i18n.t('INPUT_PLACEHOLDERS.TITLE')}
            value={name}
          />
          <div>
            {type === kpiTypes.QUALITATIVE && (
              <TextField
                error={error}
                type="text"
                onChange={e =>
                  onChangeValue({ ...formValue, range: e.target.value, touched: true })
                }
                name="range"
                margin="none"
                variant="outlined"
                placeholder={i18n.t('INPUT_PLACEHOLDERS.RANGE')}
                value={range}
              />
            )}
            {type === kpiTypes.QUANTITATIVE && (
              <TextField
                error={error}
                type="text"
                onChange={e => onChangeValue({ ...formValue, unit: e.target.value, touched: true })}
                name="unit"
                margin="none"
                variant="outlined"
                placeholder={i18n.t('INPUT_PLACEHOLDERS.UNIT')}
                value={unit}
              />
            )}
            <TextField
              error={error}
              type="number"
              onChange={e =>
                onChangeValue({ ...formValue, expected: e.target.value, touched: true })
              }
              name="expected"
              margin="none"
              variant="outlined"
              placeholder={i18n.t('INPUT_PLACEHOLDERS.EXPECTED')}
              value={expected}
            />
            <TextField
              type="number"
              onChange={e =>
                onChangeValue({ ...formValue, current: e.target.value, touched: true })
              }
              name="current"
              margin="none"
              variant="outlined"
              placeholder={i18n.t('INPUT_PLACEHOLDERS.CUR_VALUE')}
              value={current}
            />
          </div>
        </div>
        <div>
          <Button variant="contained" color="primary" onClick={onSubmit}>
            {i18n.t('ADD')}
          </Button>
          <Button disabled={!touched} variant="contained" color="secondary" onClick={onCancel}>
            {i18n.t('CANCEL')}
          </Button>
        </div>
      </Card>
      <p className={classes.kpiError}>{error && i18n.t('EXPLORE_IDEA.PLEASE_FILL_FIELDS')}</p>
    </NxtCircularLoader>
  )
}

KpiForm.defaultProps = {
  formValue: {
    type: kpiTypes.QUANTITATIVE,
    range: '',
    unit: '',
    expected: '',
    current: '',
    name: '',
    touched: false,
  },
  isFormInUse: false,
}

KpiForm.propTypes = {
  formValue: PropTypes.shape({
    type: PropTypes.string,
    range: PropTypes.string,
    unit: PropTypes.string,
    expected: PropTypes.string,
    current: PropTypes.string,
    name: PropTypes.string,
    touched: PropTypes.bool,
  }),
  onSubmit: PropTypes.func.isRequired,
  onChangeValue: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isFormInUse: PropTypes.bool,
}

export default KpiForm
