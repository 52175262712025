const challenge_status = {
    DRAFT: 'DRAFT',
    PROPOSED: 'PROPOSED',
    PLANNED: 'PLANNED',
    ACTIVE: 'ACTIVE',
    FINISHED: 'FINISHED',
    DELETED: 'DELETED',
    ALL: 'ALL'
}

export default challenge_status