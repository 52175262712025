import React, { Component, Fragment } from 'react'

import * as Proptypes from 'prop-types'
import { withStyles} from '@material-ui/core'
import defaultPhoto from 'img/default-photo.png'
import styles from './View.Style'
import corner_img from 'img/champion_corner_img.png'
import floar from 'img/floar_bg3@3x.png'

const Card = props => {
  const {
    classes,
    number,
    name,
    value,
    valueText,
    image
  } = props
  return(
    <div className={classes.container}>
      <img src={corner_img} className={classes.corner} />
      <div className={classes.corner} style={{right:'-23px', top: '6px'}}>
        #{number}
      </div>
      <div style={{position: 'relative'}}>
        <div className={classes.circle_cropper}>
            <img className={classes.profile_photo} src={image || defaultPhoto} onError={({currentTarget}) => {
                currentTarget.onerror = null
                currentTarget.src = defaultPhoto
            }} />
        </div>
        <img src={floar} className={classes.medal_img} />
      </div>
      <div className={classes.name}>{name}</div>
      <div className={classes.values}>
        <div className={classes.valueText}>{valueText}</div>
        <div className={classes.value}>{value}</div>
      </div>
    </div>
  )
}

Card.defaultProps = {
}

Card.propTypes = {
  number: Proptypes.number.isRequired,
  name: Proptypes.string.isRequired,
  value: Proptypes.string.isRequired,
  valueText: Proptypes.string.isRequired,
  image: Proptypes.string.isRequired
}

export default withStyles(styles)(Card)
