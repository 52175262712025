import React from 'react'
import Carousel from 'react-material-ui-carousel'

const PeopleCarousel = ({content}) => {
    return(
        <Carousel 
          indicatorIconButtonProps={{
              style: {
                  color: '#CCCCCC'
              }
          }}
          activeIndicatorIconButtonProps={{
              style: {
                  color: '#8512FF'
              }
          }}
          navButtonsProps={{
              style: {
                  marginLeft: '15px',
                  marginRight: '15px'
              }
          }}
        >
          {content}
        </Carousel>
    )
}


export default PeopleCarousel