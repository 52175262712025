
const styles = theme => ({

    WrapContainer:{
        backgroundColor: "#FFFFFF",
        width:'100%',
        maxWidth: "780px",
        boxShadow: "4px 4px 20px 0 #EFEFEF",
        marginTop: "32px",
        marginBottom: "48px",
        borderRadius: "6px",
        // paddingLeft: "100px",
        // paddingRight: "100px",
        marginLeft:'auto',
        marginRight:'auto' ,
        padding:'2rem',
        boxSizing:'border-box'
    }

    


})

export default styles
