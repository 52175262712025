

const styles = theme => ({
    caseStudySection: {
        padding: '0px 0px 24px'
    },

    blockContent: {
        padding: '2rem 0',
        width: '100%'
    },
    imgblock: {
        maxWidth: '300px',
        marginLeft: 'auto'
    },
    img: {
        width: '100%',
        display: 'block'
    },
    contentTitle:{
        fontSize:theme.fontSize.fontsize32,
        fontFamily:theme.fontFamily.fontFamilyRegular,
        fontWeight:400,
        lineHeight:1,
        color:theme.palette.Text.TextBlack33,
        margin:'0px  0px 16px 0px'
    },
    caseStudyContentSubTitle: {
        fontSize: theme.fontSize.fontsize20,
        fontFamily: theme.fontFamily.fontFamilyBold,
        lineHeight: 1,
        color: theme.palette.Text.TextBlack33,
        margin: '0 0 8px',
    },
    caseStudyContent: {
        fontSize: theme.fontSize.fontsize16,
        fontFamily: theme.fontFamily.fontFamilyRegular,
        fontWeight: 400,
        lineHeight: 1.6,
        color: theme.palette.Text.TextBlack33
    },
    blockquoteTop: {
        fontSize: '4.5rem',
        color: '#7934ff',
        fontWeight: 700,
        lineHeight: '0',
        verticalAlign: 'bottom',
        padding: '0px 10px',

    },
    blockquoteBottom: {
        fontSize: '4.5rem',
        color: '#7934ff',
        fontWeight: 700,
        lineHeight: '0',
        verticalAlign: 'bottom',
        padding: '0px 10px',
    },
    caselist:{
        listStyle:'inside',
        padding:0,
        margin:0,
       
    },
    carouselContainer:{
        padding:'2rem 0'
    },
    carouselContainer__image:{
        width:'100%',
        maxHeight:'540px',

        '& img':{
            width:'100%',
            maxWidth:'100%',
            height:'auto'
        }
    },

    memberimg:{
        width: '100px',
        height: '100px',
        objectFit: 'fill',
        borderRadius: '10px',
        objectPosition: 'center',
        boxSizing:'boder-box',
        padding:'0px',
        boxShadow:'0px 2px 3px #33333389'
    },
    collist:{
        marginTop:'16px',
        display:'flex',
        flexWrap:'wrap',
        justifyContent:'space-between',
        '@media (min-width:400px)':{
            justifyContent:'flex-start',
        }
    },
    teamblock:{
        maxWidth: '130px',
        display: 'block',
        marginBottom:'16px',
        '@media (min-width:400px)':{
            marginRight: '24px',
        }
    },
    teamName:{
        fontSize: theme.fontSize.fontsize16,
        fontFamily: theme.fontFamily.fontFamilyBold,
        fontWeight: 700,
        lineHeight: 1.6,
        color: theme.palette.Text.TextBlack33,
        margin:'0 0 8px'
    },
    teamrole:{
        fontSize: theme.fontSize.fontsize16,
        fontFamily: theme.fontFamily.fontFamilyRegular,
        fontWeight: 400,
        lineHeight: 1.4,
        color: theme.palette.Text.TextBlack33,
        margin:'8px 0 0'
    },


})

export default styles 