

import Drone from './img/drones.png'
import Iot from './img/iot_bio.jpg'
import Site from './img/on_site.jpg'
import Chain from './img/the_chain.png'
import Sami from './img/Vodafone_SAMI.jpg'
// import Podcast from '../../img/Speechmark_Podcast.png'
import Rhino from './img/Project_Rhino.JPG'
import IotAnti from './img/IoT_Anti_Counterfeit.JPG'
import GOGreen from './img/Go_Green_Dashboard.png'
import Onboard from './img/vodafone_onboarding.png'
import Podcast from './img/speechmark.jpg'



 export const tutorialSteps = [
  {
    id:1,
    header: 'IoT Biometrics',
    label:
      'This idea used existing Vodafone competences - IOT and Connectivity, and created a low cost and highly secure Fingerprint Reading IOT device that can be used as a UAM authentication appliance. This is a Multi Factor Authentication demonstrator to provide frictionless User Access Management to Staff, Enterprise Customers and Suppliers using Biometrics. There is various potential use cases this prototype will be used for in consumer as well as enterprise customers that are currently being explored.',
    imgPath: Iot,
    slug:'iot-boimetrics'
  },
  {
    id:2,
    header: 'ON SITE recycled - IT shop',
    label:
      'This idea is to reuse the spare kit we have here at Vodafone. Why are we (as a business) ordering new kit and throwing it away, worse still we pay someone to collect and recycle it as we waste. The ON Site IT Recycle shop provides a place where you can find second hand IT equipment and at the same time be able to recycle yours.',
    imgPath: Site,
    slug:'on-site-recycled-it-shop'
  },
  {
    id:3,
    header: 'Blockchain & IoT Analytics',
    label:
      'This prototype was built using blockchains to generate value in conjunction with our IoT & Security Products. Using block chain for analytics for use case: Audit trails for Law Enforcement Agency disclosures, sufficient to satisfy a Court of Law Audit trails for data traffic for use by IOT customers – so that device activity for life/money/mission critical application can be proved (to be separately pitched to Business to be implemented as a service for customers)',
    imgPath: Chain,
    slug:'blockchain-iot-analytics'
  },
  {
    id:4,
    header: 'Drones as a service',
    label:
      'This was initially an idea experimenting the use of Drones for cell tower inspection. The prototype captured visual & thermal Imagery, captured Omnidirectional EMF data. Proof of Concept to support the Business Case for an MVP.',
    imgPath: Drone,
    slug:'drones-as-a-service',
  },
  {
    id:5,
    header: 'IoT Anti-Counterfeit',
    label:
      'Counterfeiting was considered the largest criminal enterprise in the world in 2018 by Forbes. Counterfeit merchandise in 2014 cost projected 2.5m jobs. Our solution uses a Narrow Band IOT equipped device (embedded into the device communicating with a central platform to allow customers to register their purchases and reference the central IOT platform to confirm that the product they have purchased is genuine. Potential markets include beverages, pharmaceuticals and high value customer goods.',
    imgPath: IotAnti,
    slug:'iot-anti-counterfeit'
  },
  {
    id:6,
    header: 'Speechmark Podcast',
    label:
      'Vodafone launched in October 2019 its <strong>first podcast&nbsp;</strong>through an NOVA project aiming to attract Digital Talent. <p>Speechmark is podcast about connectivity, and the people working on technology that will make societies future-ready. In this series we cover everything from disaster response teams, tech startups, 5G, and drone delivery. The first three episodes are available now, with releases every two weeks. You can listen now on Apple Podcasts, Spotify, or wherever you get your podcasts!</p>',
    imgPath: Podcast,
    slug: 'speechmark-podcast',
  },
  {
    id:7,
    header: 'Project RHINO',
    label:
      '<strong>Project RHINO</strong> aims to use the power of IoT to recude poaching and Illegal Wildlife Trade. For the prototype we have partnered with world leading conservation organisation Zoological Society of London (ZSL) who own a product called Instant detect. The solution uses stationary field sensors like camera and magntic detectors which connect via LoRa to a sensor ‘gateway’ which sends the data from the sensors to a platform.',
    imgPath: Rhino,
    slug: 'project-RHINO',
  },
  {
    id:8,
    header: 'Go-Green Dashboard',
    label:
      'This new responsive dashboard proposal allows employees to track their commute, business travel, and office energy usage (this list could be expanded to include things like water use, diet, etc) and be rewarded for minimising their carbon footprint. It also encourages employees to check their anonymized progress against team, department, opco, and global performance. Using existing data from Smart Building project, SCM Travel Dashboard, and location awareness on IM programs, we hope to build a functional prototype to combine data inputs, giving real insight into individual impact on Vodafone’s emissisons reduction targets.',
    imgPath: GOGreen,
    slug:'go-green-dashboard',
  },
  {
    id:9,
    header: 'Enhanced On-boarding',
    label:
      'This project focused on Digital data collection for new Enterprise Customers. The objective of this solution is to improve the onboarding experience for Enterprise Customers, especially the ~ 75 new large Enterprise customers annually with > 120,000 end mobile users. The solution allow for digital data capture of new Customer estate. It also allows users to seamlessly complete forms with their details such as mobile number and office location and book events online as part of the New Customer onboarding programme. This solution enabled Vodafone programme team to effectively manage new mobile handset rollout with accurate and reliable data throughout. The solution itself was developed on different platforms such as Microsoft forms and PEGA. It is currently being developed further as part of a transformation programme.',
    imgPath: Onboard,
    slug:'enhanced-on-boarding',
  },
  {
    id:10,
    header: 'Product Safety Recall (Vodafone SAMI)',
    label:
      'Vodafone SAMI stands for Safety Alert Messaging Indicator. The idea is to help solve white-good manufacturers problem of recalling their products, which they do only for 20% of their products. This product is an IoT module that communicates over the Vodafone network, to relay product safety recall status. This product received an Innovation Award during the Electrical Safety First (ESF) Innovation Awards ceremony for 2019 held in the UK. Vodafone won the second place in the <strong><em>IET Innovation Awards 2019 &nbsp;</em></strong></span>.',
    imgPath: Sami,
    // imgPath: 'https://view.vzaar.com/21646920/player',
    videoPath: 'https://view.vzaar.com/21646920/player',
    slug: 'product-safety-recall-vodafone-SAMI',
  },
  {
    id:11,
    header: 'Red Pages',
    label:
      'Red Pages – your employee directory and guide is here! Red Pages is a slick and easy to use directory where you can access the links that you need based on predictive searches, tags and categories. The directory is community driven which means we are counting on you to suggest new links and share the website with your colleagues!',
    imgPath: '',
    videoPath:'https://view.vzaar.com/21521212/player',
    slug: 'red-pages',
  },
]


