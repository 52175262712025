import { createMuiTheme } from '@material-ui/core'
import createPalette from '@material-ui/core/styles/createPalette'

const basePalette = createMuiTheme({
  palette: createPalette({
    primary: {
      main: '#365fbe',
    },
    secondary: {
      main: '#f50057',
    },
  }),
})

const theme = {
  aside: {
    drawerPaper: '250px',
    gridArea: 'aside',
    marginLeft: '0.5rem',
  },
  base: {
    height: '100vh',
    display: 'grid',
    gridTemplateColumns: 'auto 960px auto',
    gridTemplateRows: 'auto 1fr auto auto',
    gridTemplateAreas:
      '"header header header" "main main main" "footer footer footer" "links links links"',
    
  },
  background: {
    default: '#f0f0f0',
  },
  flexRowCenter: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  flexColumnCenter: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  gridMiddleColumnCenter: {
    display: 'grid',
    gridTemplateColumns: 'auto 960px auto',
    gridTemplateAreas: '". center ."',
  },
  loginBase: {
    display: 'grid',
    gridTemplateAreas: `"side content"`,
    gridTemplateColumns: '460px 1fr',
    gridTemplateRows: 'auto',
    gridGap: '0px',
    height: '100vh',
    '@media (max-width:767px)':{
      display:'block'
    },
  },
  main: {
    gridArea: 'main',
    height: '100%',
    display: 'grid',
    gridTemplateColumns: 'auto 960px auto',
    gridTemplateRows: 'auto auto auto',
    gridTemplateAreas: '"top top top" "middle middle middle" "bottom bottom bottom"',
  },
  MuiListItemText: {
    inset: {
      paddingLeft: '40px',
    },
  },
  palette: {
    primary: {
      main: basePalette.palette.primary.main,
    },
    secondary: {
      main: basePalette.palette.secondary.main,
    },
  },
  overrides: {
    MuiCard: {
      root: {
        '&.MuiEngagementCard--01': {
          transition: '0.3s',
          maxWidth: 300,
          margin: 'auto',
          boxShadow: '0 8px 40px -12px rgba(0,0,0,0.3)',
          '&:hover': {
            boxShadow: '0 16px 70px -12.125px rgba(0,0,0,0.3)',
          },
          '& .MuiCardMedia-root': {
            paddingTop: '56.25%',
          },
          '& .MuiCardContent-root': {
            textAlign: 'left',
            padding: basePalette.spacing(3),
          },
          '& .MuiDivider-root': {
            margin: `${basePalette.spacing(3)}px 0`,
          },
          '& .MuiTypography--heading': {
            fontWeight: 'bold',
          },
          '& .MuiTypography--subheading': {
            lineHeight: 1.8,
          },
          '& .MuiAvatar-root': {
            display: 'inline-block',
            border: '2px solid white',
            '&:not(:first-of-type)': {
              marginLeft: -basePalette.spacing(),
            },
          },
        },
      },
    },
    MuiDrawer: {
      root: {
        zIndex: basePalette.zIndex.drawer,
      },
    },
    routes: {
      gridArea: 'main',
      padding: '1rem',
      backgroundColor: 'pink',
    },
  },
}

export default theme
