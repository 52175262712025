const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: theme.spacing(2.5),
    marginBottom: theme.spacing(1),
    '@media (max-width:767px)':{
      display:'block'
    }
  },
  paperChecked: {
    backgroundColor: '#f5f5f5',
    '@media (max-width:767px)':{
      display:'block'
    }

  },
  radioWrapper: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    
  },
  radioLabel: {
    fontFamily:theme.fontFamily.fontFamilyBold,
    '@media (max-width:767px)':{
      fontSize:'13px'
    }
  },
  textWrapper: {
    fontFamily:theme.fontFamily.fontFamilyRegular,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    fontSize: theme.spacing(1.75),
    '& ul': {
      margin: theme.spacing(1 / 2, 0),
      paddingLeft: theme.spacing(4),
    },
  },
})

export default styles
