const styles = theme => ({
    vf_container_flexbox:{
        width: 'calc(100% + 20px)',
        margin: '24px -10px 10px',
        "& .MuiGrid-item": {
            padding: '0px 10px',
            marginBottom: '24px',
            flex: '0 0 33.33%'
        }
    },
    
    mychallengeFilter:{
        display:'flex',
        justifyContent:'space-between',
        marginBottom:theme.spacing(4),
        marginTop:theme.spacing(2)
    },
    
    
    title: {
        fontFamily: 'VodafoneRg-Regular',
        fontSize: "32px",
        color: "#333333",
    },
    noIdeaImg: {
        height: '288px',
    },
    noIdeas: {
        fontFamily: 'VodafoneRg-Regular',
        fontSize: '47.8px',
        color: '#333333',
    },
    purpleNo: {
        fontFamily: 'VodafoneRg-Bold',
        color: '#7836FF'
    },
    invested_ideas_number: {
        fontFamily: 'VodafoneRg-Regular',
        fontSize: "20px",
        paddingTop: "16px",
        color: "#7836FF",
        cursor: 'pointer'
    },
    idea_grid: {
        display: "grid",
        marginTop: "32px",
        marginBottom: "60px",
        gridTemplateColumns: "repeat(4, 1fr)",
        alignItems: "center",
        justifyItems: "center",
        '@media (max-width:1250px)': { 
            gridTemplateColumns: "repeat(3, 1fr)",
        },
        '@media (max-width:1050px)': { 
            gridTemplateColumns: "repeat(2, 1fr)",
        },
        '@media (max-width:720px)': { 
            gridTemplateColumns: "1fr",
        },
    },
    draftidea_grid: {
        display: "grid",
        marginTop: "32px",
        marginBottom: "60px",
        gridTemplateColumns: "repeat(3, 1fr)",
        gridRowGap: '20px',
        alignItems: "center",
        justifyItems: "center",
        '@media (max-width:1250px)': { 
            gridTemplateColumns: "repeat(2, 1fr)",
        },
        '@media (max-width:1050px)': { 
            gridTemplateColumns: "1fr",
        }
    },

    mywrp:{
        width:'100%',
        display:'flex',
        justifyContent:'space-between',
        alignItems:'center',
        marginTop:theme.spacing(4),
        marginBottom:theme.spacing(2),
    },
    myText:{
        fontFamily:theme.fontFamily.fontFamilyRegular,
        fontSize:theme.fontSize.fontsize32,
        fontWeight:'normal',
        marginTop:'0px',  
        marginBottom:'1rem',
    },
    draftText:{
        textDecoration:'none',
        fontFamily:theme.fontFamily.fontFamilyRegular,
        fontSize:theme.fontSize.fontsize20,
        color:theme.palette.BlueText.TextLightBlue,
    },
    filterContainer:{
        display:'flex',
        justifyContent:'space-between',
        alignItems:'center'
        
    },
    filterSelect:{
        border:'2px solid #6551FF',
        padding: '0.6rem 1rem',
        background:'transparent',
        fontSize:theme.fontSize.fontsize20,
        fontFamily:theme.fontFamily.fontFamilyRegular,
        marginLeft:'0.8rem',
        borderRadius:'26px',
        minWidth:'112px',
        minHeight:'48px'
    }
    


  })
  

export default styles
