import { withThemeCreator, styled, createStyles } from "@material-ui/styles"
import { createTheme } from '@material-ui/core'

const styles = theme =>({
    container:{
      display: "flex",
      alignItems: "center",
      marginLeft: "22px",
    },
    select_container: {
      width: "150px",
      height: "48px",
      border: "2px solid #6551FF",
      borderRadius: "24px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#FFFFFF",
      '@media(max-width:480px)':{
        height: "38px",
        border: "1px solid #6551FF",
      },
    },
    label: {
      fontFamily: "VodafoneRg-Regular",
      fontSize: "16px",
      color: "#666666",
      paddingRight: "10px"
    },
  })


export const myTheme = createTheme({
  overrides: {
    MuiSelect: createStyles({
      root: {
        "&&:focus": {
          backgroundColor: "transparent",
        },
        fontFamily: "VodafoneRg-Regular",
        fontSize: "18px",
        paddingBottom: "3px",
      }
    }),
    MuiMenuItem: createStyles({
      root: {
        
        "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
          fontFamily: "VodafoneRg-Regular",
          fontSize: "18px",
        },
      },
    })
  }
});

export default styles
