const styles = theme => ({
    container: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '213px',
        height: '210px',
        color: '#333',
        fontSize: '16px',
        margin: '0 20px 32px 20px'
    },
    circle_cropper: {
        borderRadius: '50%',
        overflow: 'hidden',
        width: '150px',
        height: '150px'
    },
    profile_photo: {
        display: 'inline',
        margin: '0 auto',
        height: '100%',
        width: '100%'
    },
    medal_img: {
        position: 'absolute',
        width: '210px',
        marginTop: '14px'
    },
    name: {
        marginTop: '18px',
        fontFamily: 'VodafoneRg-Bold',
        marginBottom: '4px'
    },
    value: {
        fontFamily: 'VodafoneRg-Regular',
    }
  })
  

export default styles