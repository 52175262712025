
const styles = theme => ({
    about:{
        textAlign: 'center',
        color: '#333333',
        margin: '0 0px',
        padding: '0 15px',
        '@media(min-width:768px)':{
          margin  : '0 85px'
        }
    },
    header: {
        fontFamily: 'VodafoneRg-Regular', 
        fontSize: '40px',
        margin: '25px auto 10px', 
        textAlign: 'center',
        paddingTop: '15px',
    },
    infoText: {
        fontFamily: 'VodafoneLt-Regular',
        fontSize: '24px',
        width: '100%',
        margin: '15px auto 10px', 
        textAlign: 'center',
        '@media(min-width:768px)':{
          width: '825px',
        }
    }, 
    aboutBlocks: {
      padding:'0 15px',
      '@media(min-width:768px)':{
        display: 'flex', 
        justifyContent: 'space-evenly',
        margin: '64px 85px 0',
        padding:'0'
      }
    },
    howItWorks:{
      backgroundColor: 'rgb(101,81,255, 0.06)',
      padding:'0 15px',
      '@media(min-width:768px)':{
        padding:'0',
      }
    }, 
    steps: {
      '@media(min-width:768px)':{
        display: 'flex', 
        flexDirection: 'column',
        maxWidth: '1024px',
        margin: 'auto',
      },
      display: 'block', 
      
    },
    floatLeft:{
      '@media(min-width:768px)':{
        display: 'flex', 
      justifyContent: 'left',
      marginLeft: '165px',
      },
      
    },
    floatRight:{
      '@media(min-width:768px)':{
        display: 'flex', 
      justifyContent: 'right',
      marginRight: '165px',
      },
      
    },
    up: {
      position: 'absolute',
        right: '0',
        textAlign: 'right',
        transform: 'translate(-50%, -110%)',
    },
    upIcon: {
      width: '64px',
    },
   
  })
  

export default styles
