import React from 'react'
import * as PropTypes from 'prop-types'
import { withStyles, Button, CircularProgress } from '@material-ui/core'
import i18n from 'support/i18n'
import style from './View.Style'

const SendEmailStepButtons = props => {
  const { classes, activeStep, canGoNext, onNextClick, onBackClick, isLoading } = props
  return (
    <div className={classes.root}>
      <Button color="primary" onClick={onBackClick}>
        {activeStep === 0 ? i18n.t('CANCEL') : i18n.t('PREVIOUS')}
      </Button>
      <Button variant="contained" color="primary" disabled={!canGoNext} onClick={onNextClick}>
        {i18n.t('NEXT')}
        {isLoading && <CircularProgress size={20} color="secondary" />}
      </Button>
    </div>
  )
}

SendEmailStepButtons.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  activeStep: PropTypes.number.isRequired,
  canGoNext: PropTypes.bool.isRequired,
  onNextClick: PropTypes.func.isRequired,
  onBackClick: PropTypes.func.isRequired,
}

export default withStyles(style)(SendEmailStepButtons)
