export default theme => ({
  root: {
    border: '2px dashed #ccc',
    height: '88px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#f5f5f5',
    '& > p': {
      marginLeft: theme.spacing(1),
    },
  },
  add_image: {
    width: '280px',
    height: '120px',
    border: '1px dashed #666666',
    borderRadius: '10px',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  upload_icon: {
    width: '62px',
    height: '62px',
    padding: '2px',
  },
  upload_media_help_text_1: {
    fontFamily: 'VodafoneRg-Bold',
    color: '#6551FF',
    fontSize: '14px',
    padding: '2px',
  },
  dragndrop_text:{
    fontFamily: 'VodafoneRg-Regular',
    color: '#333333',
    fontSize: '16px',
    paddingTop: '6px',
  },
  format_support_text: {
    fontFamily: 'VodafoneRg-Regular',
    color: '#999999',
    fontSize: '14px',
    paddingTop: '6px',
    paddingBottom: '15px',
  },
  documentUpload:{
    margin:'8px',
    '& img':{
      width:'50px'
    }
  },
  deletegrp:{
    position:'relative',
    width:'62px',
    margin:'auto'
  },
  closeIcon:{
    position:'absolute',
    top:'-10px',
    right:'0px',
    maxWidth:'22px',
    cursor:'pointer'
  },
  listfile:{
    padding:0,
    display: 'flex', 
    flexWrap: 'wrap' 
  }
})
