import * as types from 'actions/sync/user_profile/Constants'
import {
  EDIT_PROFILE_SUCCESS,
  POST_REQUEST_INVESTMENT_STARTED,
  POST_REQUEST_INVESTMENT_SUCCESS,
  POST_REQUEST_INVESTMENT_FAILURE,
} from 'actions/sync/user/userActionConstans'
import {
  GET_MY_IDEAS_TO_INVEST_IN_FAILURE,
  GET_MY_IDEAS_TO_INVEST_IN_STARTED,
  GET_MY_IDEAS_TO_INVEST_IN_SUCCESS,
} from 'actions/sync/ideas/ideasActionConstans'

export const initialState = {
  hasLoaded: false,
  isLoading: false,
  fullName: '',
  balance: '',
  invested: '',
  portfolio: '',
  email: '',
  photoSrc: '',
  ideas: [],
  teamIdeas: [],
  teamPilotIdeas: [],
  contributions: [],
  badges: [],
  organisations: [],
  hasUserOrganisationsLoaded: false,
  timeline: [],
  timelineLimit: 6,
  timelineOffset: 0,
  isTimelineComplete: false,
  isTimelineLoading: false,
  myIdeasToInvestIn: [],
  isMyIdeasLoading: false,
  hasMyIdeasLoaded: false,
  isSendingInvestmentRequest: false,
}

const userProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case POST_REQUEST_INVESTMENT_STARTED:
      return {
        ...state,
        isSendingInvestmentRequest: true,
      }
    case POST_REQUEST_INVESTMENT_SUCCESS:
    case POST_REQUEST_INVESTMENT_FAILURE:
      return {
        ...state,
        isSendingInvestmentRequest: false,
      }
    case GET_MY_IDEAS_TO_INVEST_IN_STARTED: {
      return {
        ...state,
        isMyIdeasLoading: true,
        hasMyIdeasLoaded: false,
      }
    }
    case GET_MY_IDEAS_TO_INVEST_IN_SUCCESS:
      return {
        ...state,
        myIdeasToInvestIn: action.response,
        isMyIdeasLoading: false,
        hasMyIdeasLoaded: true,
      }
    case GET_MY_IDEAS_TO_INVEST_IN_FAILURE:
      return {
        ...state,
        isMyIdeasLoading: false,
        hasMyIdeasLoaded: true,
      }
    case types.GET_MY_PROFILE_STARTED:
    case types.GET_USER_PROFILE_STARTED:
      return {
        ...state,
        hasLoaded: false,
        isLoading: true,
      }
    case types.GET_MY_PROFILE_FAILURE:
    case types.GET_USER_PROFILE_FAILURE:
      return {
        ...state,
        hasLoaded: true,
        isLoading: false,
      }
    case types.GET_USER_PROFILE_SUCCESS:
    case types.GET_MY_PROFILE_SUCCESS:
      return {
        ...state,
        ...action.response,
        hasLoaded: true,
        isLoading: false,
      }
    case types.GET_USER_IDEAS_SUCCESS:
      return {
        ...state,
        ideas: action.response,
      }
    case types.GET_USER_IDEAS_TEAM_SUCCESS:
      return {
        ...state,
        teamIdeas: action.response,
      }
    case types.GET_USER_IDEAS_PILOT_TEAM_SUCCESS:
      return {
        ...state,
        teamPilotIdeas: action.response,
      }
    case types.GET_USER_ORGANISATIONS_SUCCESS:
      return {
        ...state,
        organisations: action.response,
        hasUserOrganisationsLoaded: true,
      }
    case types.GET_USER_ORGANISATIONS_STARTED:
      return {
        ...state,
        hasUserOrganisationsLoaded: false,
      }
    case types.GET_USER_ORGANISATIONS_FAILURE:
      return {
        ...state,
        organisations: [],
        hasUserOrganisationsLoaded: true,
      }
    case types.SET_USER_ORGANISATIONS:
      return {
        ...state,
        organisations: action.organisations,
      }
    case types.GET_USER_TIMELINE_STARTED:
      return {
        ...state,
        isTimelineLoading: true,
      }
    case types.GET_USER_TIMELINE_FAILURE:
      return {
        ...state,
        isTimelineLoading: false,
      }
    case types.GET_USER_TIMELINE_SUCCESS:
      return {
        ...state,
        timeline: [...state.timeline, ...action.response],
        timelineOffset: state.timelineOffset + state.timelineLimit,
        isTimelineComplete: action.response.length < 6,
        isTimelineLoading: false,
      }
    case types.RESET_USER_TIMELINE:
      return {
        ...state,
        timeline: [],
        timelineOffset: 0,
        isTimelineComplete: false,
        isLoading: false,
      }
    case types.GET_USER_CONTRIBUTIONS_SUCCESS:
      return {
        ...state,
        contributions: action.response,
      }
    case EDIT_PROFILE_SUCCESS:
      return {
        ...state,
        photoSrc: action.response.userPhoto,
      }
    default:
      return state
  }
}

export default userProfileReducer
