import { connect } from 'react-redux'
import View from './View'

const mapStateToProps = (state, ownProps) => ({
  show: state.loader.retrievingData > 0,
  loader: ownProps.loader,
})

export default connect(
  mapStateToProps,
  null,
)(View)
