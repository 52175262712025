import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import * as PropTypes from 'prop-types'
import upload_icon from 'img/Upload_file.png'
import { withStyles } from '@material-ui/core'
import styles from './View.Style'
import { Fragment } from 'react'
import NxtCircularLoader from 'components/nxt_circular_loader/View'
import closeIcon from 'img/remove.png'
import pngIcon from 'img/imgs/png.png';
import jpgIcon from 'img/imgs/jpg.png';
import fileIcon from 'img/imgs/document-system.png';
import docIcon from 'img/imgs/document-doc-system.png';
import zipIcon from 'img/imgs/document-zip-system.png';
import mediaIcon from 'img/imgs/video-frame-system.png'
import pdfIcon from 'img/imgs/document-pdf-system.png';

const FileUploader = props => {
  const { classes, csrfToken,dropText, onFileSelect,documentfiles,canDelete,handleDeleteDocument,previewInputHideIdea,isDocumentLoading } = props

  const onDrop = useCallback(acceptedFile => {
    onFileSelect(acceptedFile[0])
     
  }, [])
 
  const { acceptedFiles,getRootProps, getInputProps } = useDropzone({ onDrop })


  const trim_name = name => {
    const name_arr = name.split('/')
    return(name_arr[name_arr.length - 1])
  }

  const files = documentfiles.map((file) => {
    // console.log('i==>',file)
    let uploadFileIcon = fileIcon
    let a = trim_name(file.name).split(".")
    // console.log("tri",a)
    let f = a[a.length-1]
    // console.log("f",f)

    switch(f){
        case 'pdf' :
        uploadFileIcon = pdfIcon
        break;
        case 'doc' :
            uploadFileIcon = docIcon
            break;
        case 'png' :
        uploadFileIcon = pngIcon
        break;
        case 'txt' :
        uploadFileIcon = fileIcon
        break;
        case 'jpg' :
        uploadFileIcon = jpgIcon
        break;
        case 'zip' :
        uploadFileIcon = zipIcon
        break;
        case 'mp4':
        case 'mp3':
            uploadFileIcon = mediaIcon
            break;
        default:
        uploadFileIcon = fileIcon
    }

    return(
        <div className={classes.documentUpload} key={file.name}>
          <div className={classes.deletegrp}>
              {canDelete && <img src={closeIcon} onClick={()=>handleDeleteDocument(file.key)} className={classes.closeIcon} /> }
              <img src={uploadFileIcon} alt={trim_name(file.name)} />
            </div>
            <a href={`${file.src}?_nxt_csrf=${csrfToken}`} target='_blank' className={classes.docsAlign}>
                <figcaption>{trim_name(file.name).substring(0,20)}</figcaption>
            </a>
        </div>
    )

  }
  
  
  // (
  //   <li key={file.name}>
  //     {/* {file.path} - {file.size} bytes */}
  //     {file.name} {file.size} bytes
  //   </li>
  // )
  
  );
  return (
    <Fragment>
                     <NxtCircularLoader isLoading={isDocumentLoading}> 
      
      {  previewInputHideIdea ? null : <>
    <div className={classes.add_image}  {...getRootProps()}>
      <input {...getInputProps()} />
      <img className={classes.upload_icon} src={upload_icon}/>
      <div className={classes.upload_media_help_text_1}>{dropText}</div>
    </div>
    <div className={classes.dragndrop_text}>Drag and drop here</div>
    <div className={classes.format_support_text}>Supports: doc, pdf, txt. | Limit 10 MB</div>
  </> }
    {
      files.length>0 && <aside>
          {/* <h4>Files</h4> */}
          <ul className={classes.listfile}>{files}</ul>
        </aside>
   }
    </NxtCircularLoader>
    </Fragment>
  )
}

FileUploader.defaultProps = {
  dropText: '',
}

FileUploader.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
  }).isRequired,
  dropText: PropTypes.string,
  onFileSelect: PropTypes.func.isRequired,
}

export default withStyles(styles)(FileUploader)
