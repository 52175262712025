import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Container } from '@material-ui/core';
import styles from './View.Style'


const Breadcrumbs = ({ classes,crumbs }) => {
    return (
      <nav aria-label="breadcrumbs" className={classes.bar_container}>
        <Container>
        <ul className={classes.BreadcrumbsList }>
          {crumbs.map((crumb, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <li key={index}  className={classes.parent_page}>
              {index === crumbs.length - 1 ? (
                <span >{crumb.string}</span>
              ) : (
                <>
                <button type='button' onClick={crumb.navigate}>{crumb.string}</button> 
                <span>
                        <svg width="20" height="20" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.4133 5.04431L21.9427 16.1781L10.8088 27.7075" stroke="#7834FF" 
                            strokeWidth="1.5" 
                            strokeLinecap="round" 
                            strokeLinejoin="round" />
                        </svg>
                    </span>
                
                </>
              )}
            </li>
          ))}
        </ul>
        </Container>
      </nav>
    );
  };
  

// const HierachyBar = props => {
//     const {classes, pageHierarchy} = props
//     const output = []


//     for (const [index, value] of pageHierarchy.entries()){
//         if (index === pageHierarchy.length - 1){
//             output.push(<p className={classes.current_page}>{value.string}</p>)
//         } else{
//             output.push(<a href='javascript:void(0)' className={classes.parent_page} onClick={value.navigate}>{value.string}</a>)
//             output.push(<img className={classes.chevron_right} src={chevronRight} alt="icon" />)
//         }
//     }

//     return(
//         <div className={classes.bar_container}>
//             {output}
//         </div>
//     )
// }

export default withStyles(styles)(Breadcrumbs)