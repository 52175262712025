import React from 'react'
import * as PropTypes from 'prop-types'
import { FormControlLabel, IconButton, Radio, withStyles } from '@material-ui/core'
import i18n from 'support/i18n'
import removeIcon from 'img/remove.png'
import styles from './View.Style'

const ImageItem = props => {
  const {
    classes,
    canChooseMainImage,
    image,
    mainImageValue,
    usingDelete,
    onSelectMainImage,
    onDeleteButton,
  } = props
  return (
    <div className={classes.imageContainer} key={image.name}>
      {canChooseMainImage && (
        <FormControlLabel
          id="image-item-radio"
          className={classes.radioButtonMain}
          control={<Radio />}
          label={
            image.src === mainImageValue
              ? i18n.t('CHALLENGES.MAIN_PHOTO')
              : i18n.t('CHALLENGES.SELECT_AS_MAIN_PHOTO')
          }
          value={image.src}
          onChange={() => onSelectMainImage(image.name)}
        />
      )}
      {usingDelete && (
        <button type='button' className={classes.deleteBtn} onClick={onDeleteButton} aria-label='delete' >
          <img src={removeIcon} className={classes.deleteButton} alt='remove' />

        </button>
        // <img src={removeIcon} className={classes.deleteButton} onClick={onDeleteButton} alt='remove' />
      )}
      <img className={classes.img} src={image.src} alt={image.name} />
    </div>
  )
}

ImageItem.defaultProps = {
  canChooseMainImage: false,
  mainImageValue: '',
  usingDelete: true,
  onSelectMainImage: () => {},
}

ImageItem.propTypes = {
  classes: PropTypes.shape({
    imageContainer: PropTypes.string,
    radioButtonMain: PropTypes.string,
    deleteButton: PropTypes.string,
    img: PropTypes.string,
  }).isRequired,
  canChooseMainImage: PropTypes.bool,
  image: PropTypes.shape({
    name: PropTypes.string,
    key: PropTypes.string,
    src: PropTypes.string,
  }).isRequired,
  mainImageValue: PropTypes.string,
  usingDelete: PropTypes.bool,
  onSelectMainImage: PropTypes.func,
  onDeleteButton: PropTypes.func.isRequired,
}

export default withStyles(styles)(ImageItem)
