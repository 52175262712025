const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  selectedUserText: {
    padding: theme.spacing(2),
  },
  selectedUserContainer: {
    borderTop: 'unset',
  },
  tellYourContactWrapper: {
    padding: theme.spacing(2, 2, 4, 2),
  },
})

export default styles
