import * as types from 'actions/sync/evaluation/Constants'

export const initialState = {
  activeStep: 0,
  data: [],
  items: 0,
  total: 0,
  hasMore: true,
  dataArchived: [],
  itemsArchived: 0,
  totalArchived: 0,
  hasMoreArchived: true,
  dataPending: [],
  itemsPending: 0,
  totalPending: 0,
  hasMorePending: true,
  ideaevaluation: {
    id: '',
    statusType: '',
    roundClosed: '',
    ownerEmail: '',
    ideaTitle: '',
    ideaText: '',
    Q1Comment: '',
    Q1measurement: 0,
    Q2Comment: '',
    Q2measurement: 0,
    Q3Comment: '',
    Q3measurement: 0,
    type: '',
  },
  list: [],
  evaluationLoading: false,
}
const evaluationReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_EVALUATION_IDEA_STARTED:
      return {
        ...state,
        ideaevaluation: {},
        evaluationLoading: true,
      }
    case types.GET_EVALUATION_DRAFT_STARTED:
    case types.POST_EVALUATION_DRAFT_STARTED:
    case types.POST_EVALUATION_STATUS_STARTED:
      return {
        ...state,
        evaluationLoading: true,
      }
    case types.GET_EVALUATION_IDEA_SUCCESS: {
      const newItems = [...state.data, ...action.response.data]
      return {
        ...state,
        data: newItems,
        items: action.response.items,
        total: action.response.total,
        hasMore: newItems.length < action.response.total,
        evaluationLoading: false,
      }
    }
    case types.GET_EVALUATION_DRAFT_SUCCESS: {
      return {
        ...state,
        ideaevaluation: action.response.ideaevaluation,
        list: action.response.list,
        evaluationLoading: false,
      }
    }

    case types.POST_EVALUATION_DRAFT_SUCCESS:
    case types.POST_EVALUATION_STATUS_SUCCESS:
      return {
        ...state,
        evaluationLoading: false,
      }

    case types.GET_EVALUATION_DRAFT_FAILURE:
    case types.POST_EVALUATION_DRAFT_FAILURE:
    case types.POST_EVALUATION_STATUS_FAILURE:
      return {
        ...state,
        evaluationLoading: false,
      }

    case types.INCREASE_EVALUATION_STEP:
      return {
        ...state,
        activeStep: state.activeStep + 1,
      }

    case types.DECREASE_EVALUATION_STEP:
      return {
        ...state,
        activeStep: state.activeStep - 1,
      }

    // Evaluation Archived reducers
    case types.GET_EVALUATION_IDEA_PENDING_STARTED:
    case types.GET_EVALUATION_IDEA_ARCHIVED_STARTED:
      return {
        //initialState,
        ...state,
        evaluationLoading: true,
      }

    case types.GET_EVALUATION_IDEA_PENDING_SUCCESS: {
      const newItems = [...state.dataPending, ...action.response.dataPending]
      return {
        ...state,
        dataPending: newItems,
        itemsPending: action.response.itemsPending,
        totalPending: action.response.totalPending,
        hasMorePending: newItems.length < action.response.totalPending,
        evaluationLoading: false,
      }
    }
    case types.GET_EVALUATION_IDEA_ARCHIVED_SUCCESS: {
      const newItems = [...state.dataArchived, ...action.response.dataArchived]
      return {
        ...state,
        dataArchived: newItems,
        itemsArchived: action.response.itemsArchived,
        totalArchived: action.response.totalArchived,
        hasMoreArchived: newItems.length < action.response.totalArchived,
        evaluationLoading: false,
      }
    }

    case types.GET_EVALUATION_IDEA_ARCHIVED_FAILURE:
    case types.GET_EVALUATION_IDEA_PENDING_FAILURE:
      return {
        ...state,
        evaluationLoading: false,
      }

    case types.SET_LOGEDOUT:
      return initialState
    default:
      return state
  }
}

export default evaluationReducer
