import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles,makeStyles } from '@material-ui/core/styles';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import styles from './View.Style';
import ReactHtmlParser from 'react-html-parser'; 


function SimpleExpansionPanel(props) {
  const { classes, text,headertext, isExapndable} = props;
  const [showFullDescription, setFullDescription] = useState(!isExapndable);
  const showFullDescriptionHandler = () => {
    setFullDescription(!showFullDescription);
  };

  const description = showFullDescription
    ? ReactHtmlParser(text)
    : ReactHtmlParser(headertext);

  return (
    <>
    <p className="lead">{description}</p>
      {/*<button className={classes.showMore} onClick={showFullDescriptionHandler}>
       <span>Show {showFullDescription ? "Less" : "More"}</span> 
       <span>{showFullDescription ? <ExpandLess /> :<ExpandMore/>}</span>
      </button>
      */}
    </>

  );
}

SimpleExpansionPanel.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SimpleExpansionPanel);
