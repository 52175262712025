import React from 'react'
import * as PropTypes from 'prop-types'
import { MainTooltip } from 'components'
import { withStyles } from '@material-ui/core'
import IdeaAvatar from 'pages/idea/detail/components/idea_avatar/View'
import NxtContributionCurrency from 'components/nxt_contribution_currency'
import ContributorTooltip from '../contributor_tooltip/View'
import styles from './View.Style'

const ContributorElement = props => {
  const { classes, name, time, budget, other, photoSrc, isAdmin } = props
  const parsedTime = time && `${time}h`
  return (
    <div className={classes.contributorElement}>
      <MainTooltip
        classes={{ tooltip: classes.tooltipWidth }}
        interactive
        placement="bottom"
        title={<ContributorTooltip budget={budget} name={name} other={other} time={parsedTime} />}
      >
        <div>
          <IdeaAvatar
            name={name}
            secondLine={
              (budget && <NxtContributionCurrency>{budget}</NxtContributionCurrency>) ||
              parsedTime ||
              other
            }
            photoSrc={photoSrc}
            isAdmin={isAdmin}
          />
        </div>
      </MainTooltip>
    </div>
  )
}

ContributorElement.defaultProps = {
  name: '',
  time: '',
  budget: '',
  other: '',
  photoSrc: '',
  isAdmin: false,
}

ContributorElement.propTypes = {
  name: PropTypes.string,
  time: PropTypes.string,
  budget: PropTypes.string,
  other: PropTypes.string,
  photoSrc: PropTypes.string,
  isAdmin: PropTypes.bool,
}

export default withStyles(styles)(ContributorElement)
