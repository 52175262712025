const styles = theme => ({
  root: {
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
    minHeight: '44px',
    borderTop: '1px solid #E1E1E1',
  },
  avatarsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    '& > div > img': {
      borderRadius: '50%',
      padding: theme.spacing(1 / 2, 1 / 2),
      width: '2rem',
      height: '2rem',
    },
  },
  selectedWrapper: {
    maxHeight: '40px',
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
  },
  numberSelectedItems: {
    fontWeight: 700,
  },
})

export default styles
