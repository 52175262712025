import React, { Component, Fragment } from 'react'
import * as PropTypes from 'prop-types'
import { withStyles, Button, TextField, CircularProgress } from '@material-ui/core'
import i18n from 'support/i18n'
import { transformToEmbedUrl } from 'support/utils/file'
import styles from './View.Style'
import upload_icon from 'img/Upload_file.png'
import submit_icon from 'img/submit.png'
import remove_icon from 'img/remove.png'
import { checkYoutubeUrl } from './View.Utils'
import TextInputOneLine from '../../components_newui/TextInputOneLine/View'

class VideoForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      externalVideo: '',
      externalVideoError: false,
      showExternalVideo: false,
      isVideoLoading: props.isVideoLoading,
    }

    this.handleUploadExternalVideo = this.handleUploadExternalVideo.bind(this)
    this.onRemoveVideo = this.onRemoveVideo.bind(this)
    this.handleCancelClick = this.handleCancelClick.bind(this)
  }

  componentDidUpdate(prevProps) {
    const { isVideoLoading } = this.props
    if (prevProps.isVideoLoading !== isVideoLoading) {
      this.setState({ isVideoLoading })
    }
  }

  onRemoveVideo() {
    const { externalVideoSrc, fileVideoSrc, onChangeExternalVideo, onRemoveFileVideo } = this.props
    if (externalVideoSrc) {
      onChangeExternalVideo('')
      this.setState({ externalVideo: '' })
    }
    if (fileVideoSrc) onRemoveFileVideo()
  }

  handleUploadExternalVideo() {
    const { onChangeExternalVideo, onExternalVideoError } = this.props
    const { externalVideo } = this.state
    let error = false
    if (checkYoutubeUrl(externalVideo)) {
      const url = transformToEmbedUrl(externalVideo)
      onChangeExternalVideo(url)
    } else {
      error = true
    }
    onExternalVideoError(error)
    this.setState({ externalVideoError: error })
  }

  handleCancelClick() {
    const { onExternalVideoError } = this.props
    onExternalVideoError(false)
    this.setState({ showExternalVideo: false, externalVideoError: false, externalVideo: '' })
  }

  render() {
    const {
      classes,
      externalVideoSrc,
      fileVideoSrc,
      onSelectFileVideo,
      isVideoReady,
      isVideoRemoving,
      hasVideoError,
    } = this.props
    const { externalVideo, showExternalVideo, externalVideoError, isVideoLoading } = this.state
    const areAnyVideo = !!(externalVideoSrc || fileVideoSrc)
    const isVideoEncoding = fileVideoSrc && !isVideoReady
    return (
      <div className={classes.videoFormContainer}>
        {/*<div className={classes.mainButtons}>
          {areAnyVideo && (
            <Button variant="contained" onClick={this.onRemoveVideo} disabled={isVideoRemoving}>
              {isVideoRemoving && (
                <CircularProgress className={classes.spinner} size={20} color="primary" />
              )}
              {i18n.t('VIDEO.REMOVE')}
            </Button>
          )}
          <Button
            variant="contained"
            color="primary"
            disabled={areAnyVideo || isVideoLoading}
            onClick={() => this.setState({ showExternalVideo: true })}
          >
            {i18n.t('VIDEO.NEW_YOUTUBE_VIDEO')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={onSelectFileVideo}
            disabled={areAnyVideo || showExternalVideo || isVideoLoading}
          >
            {i18n.t('VIDEO.UPLOAD_TITLE')}
          </Button>
        </div>
        <div>
          {showExternalVideo && !areAnyVideo && (
            <Fragment>
              <TextField
                className={classes.inputItem}
                margin="normal"
                fullWidth
                value={externalVideo}
                onChange={event => this.setState({ externalVideo: event.target.value })}
                helperText={
                  <span dangerouslySetInnerHTML={{ __html: i18n.t('IDEA.YOUTUBE_LINK') }} />
                }
                error={externalVideoError}
              />
              <div className={classes.buttonsWrapper}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.handleUploadExternalVideo}
                >
                  {i18n.t('VIDEO.UPLOAD_TITLE')}
                </Button>
                <Button variant="contained" onClick={this.handleCancelClick}>
                  {i18n.t('VIDEO.CANCEL')}
                </Button>
              </div>
            </Fragment>
          )}
          {externalVideoSrc && (
            <iframe
              className={classes.iframe}
              title="ytVideo"
              width="560"
              height="315"
              src={externalVideoSrc}
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          )}
          {fileVideoSrc && isVideoReady && (
            <Fragment>
              <video className={classes.video} controls>
                <source src={fileVideoSrc} type="video/mp4" />
                <track label="video_challenge" src="captions_en.vtt" kind="captions" srcLang="en" />
              </video>
            </Fragment>
          )}
          {isVideoLoading && <p>{i18n.t('VIDEO.UPLOAD_WAIT')}</p>}
          {isVideoEncoding && !hasVideoError && <p>{i18n.t('VIDEO.VIDEO_ENCODING')}</p>}
          {hasVideoError && <p>{i18n.t('VIDEO.VIDEO_ENCODING_ERROR')}</p>}
        </div>
          */}
        {/* {!areAnyVideo && 
        <Fragment>
          <div className={classes.add_image} 
            onDragOver={e => e.preventDefault()} 
            onDrop={e => {e.preventDefault();console.log('Dropped!')}}
            onClick={() => console.log('clicked')}
          >
            <img className={classes.upload_icon} src={upload_icon}/>
            <div className={classes.upload_media_help_text_1}>Browse video</div>
          </div>
          <div className={classes.dragndrop_text}>Drag and drop here</div>
          <div className={classes.format_support_text}>Supports: MP4. | Limit 10 MB</div>
          <div className={classes.or}>Or</div>  
        </Fragment>
        } */}
        
        {!areAnyVideo && 
        <div className={classes.videobox}>
        <TextInputOneLine
          width={320} 
          placeholder='Only youtube links are valid (https://www.youtube.com/watch?v=j4amX_o5Esk) '
          char_limit={500} 
          value={externalVideo} 
          valid={!externalVideoError}
          handleChange={event => this.setState({ externalVideo: event.target.value })}
        />

        <div className={classes.buttonsWrapper}>
          <Button
            variant="contained"
            color="primary"
            onClick={this.handleUploadExternalVideo}
          >
            {i18n.t('VIDEO.UPLOAD_TITLE')}
          </Button>
        </div>

        {/* <img 
            className={classes.external_video_upload_img} 
            src={upload_icon} 
            onClick={this.handleUploadExternalVideo}
          /> */}
        </div>
        
        }
         
        <div style={{position: 'relative',flex:'1'}}>
          {externalVideoSrc && (
            <iframe
              className={classes.iframe}
              title="ytVideo"
              width="320"
              height="180"
              src={externalVideoSrc}
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          )}
          {fileVideoSrc && isVideoReady && (
            <Fragment>
              <video className={classes.video} controls>
                <source src={fileVideoSrc} type="video/mp4" />
                <track label="video_challenge" src="captions_en.vtt" kind="captions" srcLang="en" />
              </video>
            </Fragment>
          )}
          {isVideoLoading && <p>{i18n.t('VIDEO.UPLOAD_WAIT')}</p>}
          {isVideoEncoding && !hasVideoError && <p>{i18n.t('VIDEO.VIDEO_ENCODING')}</p>}
          {hasVideoError && <p>{i18n.t('VIDEO.VIDEO_ENCODING_ERROR')}</p>}
          {areAnyVideo && (
            
            <img src={remove_icon} onClick={this.onRemoveVideo} className={classes.remove_ext_video}/>
          )}
        </div>
      </div>
    )
  }
}

VideoForm.defaultProps = {
  externalVideoSrc: '',
  fileVideoSrc: '',
}

VideoForm.propTypes = {
  externalVideoSrc: PropTypes.string,
  fileVideoSrc: PropTypes.string,
  onChangeExternalVideo: PropTypes.func.isRequired,
  onExternalVideoError: PropTypes.func.isRequired,
  onSelectFileVideo: PropTypes.func.isRequired,
  onRemoveFileVideo: PropTypes.func.isRequired,
  isVideoLoading: PropTypes.bool.isRequired,
  isVideoReady: PropTypes.bool.isRequired,
  isVideoRemoving: PropTypes.bool.isRequired,
  hasVideoError: PropTypes.bool.isRequired,
}

export default withStyles(styles)(VideoForm)
