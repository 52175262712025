import React, { Fragment, useEffect, useState } from 'react'
import { withStyles, Button } from '@material-ui/core'
import { Send } from '@material-ui/icons'
import * as PropTypes from 'prop-types'
import i18n from 'support/i18n'
import ButtonLink from 'components/button_link/View'
import styles from './View.Style'
// eslint-disable-next-line import/no-cycle
import CommentItem from './components/comment_item/View'
import MentionTextField from '../mention_text_field/View'

const CommentList = props => {
  const {
    classes,
    comments,
    isSubComment,
    onPostComment,
    parentId,
    onDeleteComment,
    postingCommentStatus,
    onVoteComment,
    onEditComment,
    onUserClick,
    userInvestmentsMap,
    isAdminOrInnovator,
    userEmail,
    onMentionType,
    resetUserSuggestions,
    userSuggestions,
  } = props

  const [commentText, setCommentText] = useState('')
  const [showAll, setShowAll] = useState(false)

  useEffect(() => {
    if (postingCommentStatus === 'success') {
      setCommentText('')
    }
  }, [postingCommentStatus])

  return (
    <Fragment>
      {!isSubComment && comments.length > 2 && (
        <ButtonLink onLinkClick={() => setShowAll(!showAll)}>
          {showAll ? (
            <span>
              {i18n.t('IDEA.HIDE_COMMENTS')}
              <span className="icon-up" />
            </span>
          ) : (
            <span>
              {i18n.t('IDEA.SHOW_ALL_COMMENT')}
              <span className="icon-down" />
            </span>
          )}
        </ButtonLink>
      )}
      <ul className={classes.root}>
        {comments.map((comment, index) => {
          return (
            (showAll || isSubComment || index > comments.length - 3) && (
              <li key={comment.id}>
                <CommentItem
                  isSubComment={isSubComment}
                  commentId={comment.id}
                  text={comment.text}
                  photoSrc={comment.photoSrc}
                  userName={comment.name}
                  userEmail={comment.email}
                  timeAgo={comment.timeAgo}
                  onUserClick={() => onUserClick(comment.email)}
                  userInvested={`${userInvestmentsMap[comment.email] || 0}`}
                  userBalance={comment.balance}
                  replies={comment.replies}
                  votes={comment.votes}
                  subComments={comment.subComments}
                  onPostComment={onPostComment}
                  onDeleteComment={onDeleteComment}
                  isDeleted={comment.isDeleted}
                  postingCommentStatus={postingCommentStatus}
                  onVoteComment={onVoteComment}
                  isVoted={comment.isVoted}
                  onEditComment={onEditComment}
                  userInvestmentsMap={userInvestmentsMap}
                  isAdminOrInnovator={isAdminOrInnovator}
                  loggedUserEmail={userEmail}
                  onMentionType={onMentionType}
                  resetUserSuggestions={resetUserSuggestions}
                  userSuggestions={userSuggestions}
                />
              </li>
            )
          )
        })}
        {isSubComment && (
          <li>
            <Fragment>
              <MentionTextField
                id="sub-comment"
                className={classes.textField}
                label={i18n.t('COMMENT.INPUT_PLACEHOLDER')}
                onMentionType={onMentionType}
                onTextChange={setCommentText}
                resetUserSuggestions={resetUserSuggestions}
                textValue={commentText}
                userSuggestions={userSuggestions}
                autoFocus
              />
              <Button
                disabled={!commentText}
                color="primary"
                onClick={() => onPostComment(commentText, parentId)}
              >
                <Send />
              </Button>
            </Fragment>
          </li>
        )}
      </ul>
    </Fragment>
  )
}

CommentList.defaultProps = {
  isSubComment: false,
  parentId: '',
  postingCommentStatus: '',
}

CommentList.propTypes = {
  comments: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      text: PropTypes.string,
    }),
  ).isRequired,
  isSubComment: PropTypes.bool,
  onPostComment: PropTypes.func.isRequired,
  parentId: PropTypes.string,
  onDeleteComment: PropTypes.func.isRequired,
  postingCommentStatus: PropTypes.string,
  onVoteComment: PropTypes.func.isRequired,
  onEditComment: PropTypes.func.isRequired,
  onUserClick: PropTypes.func.isRequired,
  userInvestmentsMap: PropTypes.shape({}).isRequired,
  isAdminOrInnovator: PropTypes.bool.isRequired,
  userEmail: PropTypes.string.isRequired,
}

export default withStyles(styles)(CommentList)
