import { withThemeCreator, styled, createStyles } from "@material-ui/styles"
import { createTheme } from '@material-ui/core'

const styles = theme =>({
    container:{
      // 
      margin:'5px',
      flex:1,
      '@media(min-width:900px)':{
        alignItems: "center",
        display: "flex",
        marginLeft: "22px",
        flex:'unset',
      }
    },
    select_container: {
      width: "100%",
      height: "48px",
      border: "2px solid #6551FF",
      borderRadius: "24px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#FFFFFF",
      // marginBottom:'8px',
      '@media(min-width:500px)':{
        width: "150px",
      },
      '@media(max-width:480px)':{
        height: "38px",
        border: "1px solid #6551FF",
      },
    },
    label: {
      fontFamily: "VodafoneRg-Regular",
      fontSize: "16px",
      color: "#666666",
      paddingRight: "10px"
    },
    selected_service_lines: {
      backgroundColor: "#6551FF",
      display: "inline-flex",
      alignItems: "center",
      position: "absolute",
      borderRadius: "20px",
      color: "#FFFFFF",
      fontFamily: "VodafoneRg-Bold",
      fontSize: "18px",
      zIndex: "100px",
      height: "32px",
      marginRight: "13px",
      cursor: "pointer",
      "& p": {
        paddingTop: "0",
        paddingBottom: "0",
        margin: "0",
        paddingLeft: "10px",
        paddingRight: "8px"
      }
    },
    close_icon: {
      height: "16px",
      paddingRight: "10px",
    }
  })


export const myTheme = createTheme({
  overrides: {
    MuiSelect: createStyles({
      root: {
        "&&:focus": {
          backgroundColor: "transparent",
        },
        fontFamily: "VodafoneRg-Regular",
        fontSize: "18px",
        paddingBottom: "6px",
      }
    }),
    MuiMenuItem: createStyles({
      root: {
        
        "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
          fontFamily: "VodafoneRg-Regular",
          fontSize: "18px",
        },
      },
    })
    
  }
});

export default styles
