const styles = theme => ({
  container: {
    borderRadius: '5px',
    textAlign: 'center',
    margin: theme.spacing(1.25),
    width: 500,
  },
  icon: {
    display: 'flex',
    float: 'right',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  iconStyle: {
    fontSize: '18px',
    border: '1px solid #646464',
    color: '#646464',
    borderRadius: '10px',
    cursor: 'pointer',
  },
  span: {
    marginTop: theme.spacing(1.25),
  },
  title: {
    fontSize: '18px',
    fontWeight: 600,
    color: '#4b4d4f',
  },
  formContainer: {
    height: 312,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
  },
  textInput: {
    fontSize: '14px',
    color: '#646464',
    padding: 8,
  },
  input: {
    width: '90%',
    marginTop: theme.spacing(2.5),
    margin: 'auto',
  },
  inputDesc: {
    fontSize: '14px',
    backgroundColor: '#fff',
    color: '#646464',
    padding: 8,
    borderRadius: '5px',
    minHeight: 120,
    maxHeight: 120,
    overflowY: 'auto !important',
  },
  root: {
    display: 'flex',
    backgroundColor: '#fff',
    alignItems: 'center',
    padding: '0px',
    '&:hover': {
      borderRadius: '2px',
    },
  },
  inputLoader: {
    display: 'none',
  },
  chip: {
    maxWidth: 250,
    '& > span': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: 'unset',
    },
  },
  buttons: {
    margin: '24px auto 25px',
    width: '90%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
})

export default styles
