const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center',
    '& > button': {
      marginLeft: '16px',
      width: '138px',
      height: '44px',
      fontFamily: 'VodafoneRg-Regular',
      backgroundColor: '#333333',
      borderRadius: '22px'
    },
    '& > div': {
      width: '78%',
    },
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '16px',
    fontWeight: '700',
    '& > span:first-child': {
      fontSize: '24px',
      marginRight: theme.spacing(1),
    },
  },
  subtitle: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: '12px',
  },
  suggestions: {
    position: 'absolute',
    bottom: '50px',
  },
})

export default styles
