import {
  FULL_INFO_FAILURE,
  FULL_INFO_STARTED,
  FULL_INFO_SUCCESS,
  GET_DOMAIN_LOGIN_INFO_STARTED,
  GET_DOMAIN_LOGIN_INFO_FAILURE,
  GET_DOMAIN_LOGIN_INFO_SUCCESS,
} from 'actions/sync/domain/Constants'
import {
  GET_IDEA_STARTED,
  GET_IDEA_FAILURE,
  GET_IDEA_SUCCESS,
} from 'actions/sync/create_idea_form/Constants'
import {
  GET_USER_PROFILE_STARTED,
  GET_MY_PROFILE_STARTED,
  GET_USER_PROFILE_SUCCESS,
  GET_USER_PROFILE_FAILURE,
  GET_MY_PROFILE_SUCCESS,
  GET_MY_PROFILE_FAILURE,
  GET_USER_IDEAS_FAILURE,
  GET_USER_IDEAS_STARTED,
  GET_USER_IDEAS_SUCCESS,
} from 'actions/sync/user_profile/Constants'
import {
  GET_CHALLENGE_STARTED,
  GET_CHALLENGE_SUCCESS,
  GET_CHALLENGE_FAILURE,
} from 'actions/sync/challenge_steps/Constants'
import {
  GET_ACTIVE_CHALLENGES_FAILURE,
  GET_ACTIVE_CHALLENGES_STARTED,
  GET_ACTIVE_CHALLENGES_SUCCESS,
  GET_CHALLENGE_BY_ID_STARTED,
  GET_CHALLENGE_BY_ID_SUCCESS,
  GET_CHALLENGE_BY_ID_FAILURE,
} from 'actions/sync/challenges/Constants'

export const REDUX_TYPES_TO_SHOW_LOADER = [
  FULL_INFO_STARTED,
  GET_IDEA_STARTED,
  GET_USER_PROFILE_STARTED,
  GET_MY_PROFILE_STARTED,
  GET_USER_IDEAS_STARTED,
  GET_CHALLENGE_STARTED,
  GET_DOMAIN_LOGIN_INFO_STARTED,
  GET_USER_IDEAS_STARTED,
  GET_ACTIVE_CHALLENGES_STARTED,
  GET_CHALLENGE_BY_ID_STARTED,
]

export const REDUX_TYPES_TO_HIDE_LOADER = [
  FULL_INFO_SUCCESS,
  FULL_INFO_FAILURE,
  GET_IDEA_FAILURE,
  GET_IDEA_SUCCESS,
  GET_USER_IDEAS_SUCCESS,
  GET_USER_IDEAS_FAILURE,
  GET_USER_PROFILE_FAILURE,
  GET_USER_PROFILE_SUCCESS,
  GET_MY_PROFILE_SUCCESS,
  GET_MY_PROFILE_FAILURE,
  GET_CHALLENGE_FAILURE,
  GET_CHALLENGE_SUCCESS,
  GET_ACTIVE_CHALLENGES_FAILURE,
  GET_ACTIVE_CHALLENGES_SUCCESS,
  GET_CHALLENGE_BY_ID_SUCCESS,
  GET_CHALLENGE_BY_ID_FAILURE,
  GET_DOMAIN_LOGIN_INFO_FAILURE,
  GET_DOMAIN_LOGIN_INFO_SUCCESS,
]
