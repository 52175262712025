import React from 'react'
import * as PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'
import IdeaAvatar from 'pages/idea/detail/components/idea_avatar/View'
import NxtVirtualCurrency from 'components/nxt_virtual_currency'
import ButtonLink from 'components/button_link/View'
import styles from './View.Style'

const RoundRow = props => {
  const {
    classes,
    hasBar,
    investors,
    roundTitle,
    roundSubtitle,
    circleContent,
    onInvestorClick,
  } = props
  return (
    <div className={classes.root}>
      <div className={classes.icon}>{circleContent}</div>
      <div className={classes.title}>
        <span>{roundTitle}</span>
        <span>{roundSubtitle}</span>
      </div>
      {hasBar && <div className={classes.bar} />}
      <ul className={classes.images}>
        {investors.map(investor => (
          <li key={investor.name}>
            <ButtonLink
              id={`investor-avatar-${investor.name}`}
              onLinkClick={() => onInvestorClick(investor.email)}
            >
              <IdeaAvatar
                name={investor.name}
                secondLine={<NxtVirtualCurrency>{investor.amount}</NxtVirtualCurrency>}
                photoSrc={investor.photoSrc}
                isAdmin={investor.isAdmin}
              />
            </ButtonLink>
          </li>
        ))}
      </ul>
    </div>
  )
}

RoundRow.defaultProps = {
  hasBar: true,
  circleContent: <span className="icon-bean" />,
}

RoundRow.propTypes = {
  onInvestorClick: PropTypes.func.isRequired,
  investors: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  circleContent: PropTypes.node,
  hasBar: PropTypes.bool,
  roundTitle: PropTypes.string.isRequired,
  roundSubtitle: PropTypes.string.isRequired,
}

export default withStyles(styles)(RoundRow)
