import React from 'react'
import { connect } from 'react-redux'
import View from './View'

// import React from 'react'
// import { connect } from 'react-redux'
import i18n from 'support/i18n'
import { applyIdeaMeService } from 'services/ideas'
import Navigation from 'support/navigation'
import { showPopupDialog } from 'support/popup_dialogs'
import AnalyticsService from 'support/analytics'
import NewPrivateIdea from 'components/nxt_modal_new_private_idea'
import ConfirmDeleteIdeaModal from './components/dialogs/ConfirmDeleteContainer'
import {
  editIdea,
} from 'services/create_idea'

const mapStateToProps = state => {
  return {
    isIdeasLoading: state.data.idea.ideasLoading,
    userIdeas: state.data.idea.userIdeas,
    ideaStatusStepper: state.data.domain.domainConfiguration.ideaStatusStepper,
    isPrivateFlowActive: state.data.domain.domainConfiguration.isPrivateFlowActive,
    isMyIdeasLoading: state.data.idea.isMyIdeasLoading,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getMyIdeas: () => {
      dispatch(applyIdeaMeService())
    },
    onDeleteClick: id => {
      showPopupDialog(<ConfirmDeleteIdeaModal id={id} />, dispatch)
    },
    navigateToIdea: id => {
      dispatch(Navigation.navigateToIdea(id))
    },
    
    navigateToUserProfile: email => {
      dispatch(Navigation.navigateToUserProfile(email))
    },
    navigateToNewIdea: (isPrivate = false) => {
      AnalyticsService.trackEvent({ category: 'create-idea', action: 'click', label: 'myideas' })
      if (isPrivate) {
        showPopupDialog(<NewPrivateIdea />, dispatch)
      } else {
        dispatch(Navigation.navigateToNewIdea())
      }
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(View)
