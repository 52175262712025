const styles = () => ({
  root: {
    listStyle: 'none',
    display: 'flex',
    flexDirection: 'row',
    padding: 0,
    margin: 0,
  },
})

export default styles
