const popup = {
  position: 'fixed',
  left: '50%',
  transform: 'translate(-50%, 0)',
  zIndex: 36,
  height: '60%',
  overflowY: 'scroll',
  backgroundColor: 'white',
  borderRadius: '8px',
  padding: '20px',
}

const styles = theme => ({
  background:{
    backgroundColor: "#fbfaff", //body should have this background color in the main .css for the new site design 
    padding: "32px 0",
    fontFamily: 'VodafoneRg-Regular',
    color: '#333333'
  },
  topStrip: {
    height: '191px',
    background: '#FFFFFF',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 85px',
    marginBottom: '48px'
  },
  profilePhoto: {
    width: '150px',
    height: '150px',
    objectPosition: 'center',
    objectFit: 'cover',
    borderRadius: '100%',
  },
  icon: {
    height: '32px',
    filter: 'brightness(0) saturate(100%)',
    marginRight: '4px'
  },
  balanceSubtitle: {
    fontSize: '20px',
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px'
  },
  balance: {
    fontSize: '48px'
  },
  detailsLink:{
    color: '#6D45FF',
    fontSize: '16px',
    textDecoration: 'underline',
    cursor: 'pointer'
  },
  badge: {
    display:'inline-flex', 
    flexDirection:'column',
    alignItems:'center',
    justifyContent:'center',
    margin:'30px'
  },
  badgeText: {
    fontFamily:'VodafoneRg-Bold', 
    fontSize:'20px',
    width: '180px',
    textAlign:'center',
    overflow: 'hidden'
  },
  heading: {
    fontSize: '32px',
    marginBottom: '16px'
  },
  badges_popup: {
    ...popup,
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
  },
  photo_upload: {
    ...popup,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '760px'
  },
  idea_grid: {
    display: "grid",
    marginTop: "32px",
    marginBottom: "60px",
    gridTemplateColumns: "repeat(4, 1fr)",
    alignItems: "center",
    justifyItems: "center",
    '@media (max-width:1250px)': { 
        gridTemplateColumns: "repeat(3, 1fr)",
    },
    '@media (max-width:1050px)': { 
        gridTemplateColumns: "repeat(2, 1fr)",
    },
    '@media (max-width:720px)': { 
        gridTemplateColumns: "1fr",
    },
  },
})

export default styles
