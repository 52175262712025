const styles = () => ({
  container: {
    color: 'black',
    height: '100%',
    width: '100%',
    '& > div > div': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      maxHeight: '3.5rem',
      overflow: 'hidden',
      '& > svg': {
        marginTop: '3.5rem',
      },
      '& > div': {
        marginTop: '1.25rem !important',
        justifyContent: 'center',
        alignItems: 'center',
      },
    },
  },
})

export default styles
