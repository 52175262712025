const styles = theme => ({
  background:{
    backgroundColor: "#fbfaff", //body should have this background color in the main .css for the new site design 
    padding: "32px 85px",
    fontFamily: 'VodafoneRg-Regular',
    color: '#333333'
  },
  timeSection:{
    width:'80px', 
    height: '48px', 
    border: '1px solid #6551FF', 
    backgroundColor: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems:'center'
  },
  investmentsGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4,1fr)',

  },
  gridHeader: {
    background:'#F2EDFE',
    height: '74px',
    display: 'flex',
    alignItems: 'center',
    fontSize: '18px',
    paddingLeft: '32px',
    fontFamily: 'VodafoneRg-Bold'
  },
  gridItem: {
    paddingLeft: '32px',
    fontSize: '18px',
    height: '89px',
    display: 'flex',
    alignItems: 'center'
  }
})

export default styles
