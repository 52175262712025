import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'

import * as PropTypes from 'prop-types'

const Button = props => {
    const {classes, text, width, clickCallBack} = props
    return(
        <button type='button'
            style={{width: `${width}px`}} 
            className={classes.button_shell}
            onClick={e => clickCallBack(e)}
        >
            {text}
        </button>
    )
}

const styles = theme => ({
    button_shell:{
        height: '44px',
        border: '1px solid #6551FF',
        backgroundColor: '#FFFFFF',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#6551FF',
        fontFamily: 'VodafoneRg-Regular',
        fontSize: '18px',
        borderRadius: '22.5px',
        cursor: 'pointer',
        transition: '0.5s',
        '@media(max-width:767px)':{
            fontSize:'14px',
            width:'100% !important'
        },
        '&:hover':{
            backgroundColor: '#6551FF',
            color: '#FFFFFF',
        },
    }
})

export default withStyles(styles)(Button)


Button.defaultProps = {
    text: 'Push',
    width: 145
}

Button.propTypes = {
    text: PropTypes.string.isRequired,
    width: PropTypes.number,
    clickCallBack: PropTypes.func.isRequired,
}