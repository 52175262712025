const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
  },
  secondLine: {
    display: 'flex',
    flexDirection: 'row',
  },
  valueElement: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: theme.spacing(2),
    '& > span:first-child': {
      fontSize: '1.625rem',
    },
  },
})

export default styles
