import createStyles from '@material-ui/core/styles/createStyles'

const styles = theme =>
    createStyles({
        infocard:{
            'box-shadow':'0px 1px 2px #33333380',
            padding:'16px',
            margin:'16px 0px',
            
            '& p':{
                margin:'0px 0px 8px'
            }
        },
        innerWidth: {
            fontFamily: 'VodafoneRg-Regular',
            fontSize: '18px',
            paddingTop: '16px',
            backgroundColor: '#fbfaff'
        },
        adminBox: {
            display: 'flex',
            alignItems: 'center',
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2)
        },
        titularPartIdea: {
            marginTop: '32px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start'
        },
        leftToInvest: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        },
        divider: {
            width: '100%',
            textAlign: 'center',
            margin: '15px 0px',
        },
        status: {
            color: '#FFFFFF',
            marginLeft: '0px',
            minWidth: '134px',
            height: '24px',
            fontFamily: 'VodafoneRg-Bold',
            fontSize: '14px',
            borderRadius: '35px',
            marginBottom: '0px',
            display: 'inline-flex',
            alignItems: 'center',
            padding: '8px',
            '& img': {
                margin: '2px 8px',
                width: '9px',
                float: 'left',
            },
        },
        titleBar: {
            display: 'flex',
            alignItems: 'center',
            fontFamily: 'VodafoneRg-Regular',
            fontSize: '16px',
            maxHeight: '45px',
            width: '480px',
            justifyContent: 'space-between',
            '& div': {
                display: 'flex',
                alignItems: 'center'
            },
            marginBottom: "30px",
        },
        summaryHolder: {
            // padding: "10px 25px 10px 25px",
            minWidth: "320px",
            flex: 1,
            display: "flex",
            flexDirection: "column",
        },
        ideaDescription: {
            display: 'flex',
            justifyContent: 'space-between',
        },
        reportIdea: {
            fontFamily: 'VodafoneRg-Regular',
            fontSize: '16px',
            color: '#6D45FF',
            textDecoration: 'underline',
            '&:hover': {
                background: 'none',
            },
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center'
        },

        modal_style: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '80%',
            height: '80%',
            backgroundColor: '#FFFFFF',
            // border: '2px solid #CCCCCC',
            borderRadius: '6px',
            overflow: 'auto',
            fontFamily: 'VodafoneRg-Regular',
            padding: '2.2% 3.6%',
            color: '#333333',
        },
        modal_header: {
            fontSize: '32px',
        },
        modal_subtext: {
            fontSize: '18px',
            margin: '8px 0px'
        },
        investors_grid: {
            display: 'grid',
            gridTemplateColumns: '1fr 6fr 6fr 3fr 3fr',
            alignItems: 'center',
            gridRowGap: '1px',
            backgroundColor: '#CCCCCC'
        },
        row_wrapper: {
            display: 'contents',
            '& .grid_item': {
                backgroundColor: '#FBFAFF',
                height: '74px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                paddingLeft: '4px',
                '& img': {
                    width: '40px',
                    height: '40px',
                    borderRadius: '50%',
                    objectFit: 'cover',
                    objectPosition: 'center',
                    border: '1px solid #CCCCCC'
                }
            }
        },
        investorGridHeader: {
            paddingLeft: '4px',
            backgroundColor: 'pink',
            height: '30px',
            display: 'flex',
            alignItems: 'center'
        },
        share: {
            height: '32px',
            '&:hover': {
                background: 'none',
            },
        },
        heading: {
            fontFamily: 'VodafoneRg-Regular',
            fontSize: '32px',
            marginTop: '16px',
            marginBottom: '16px',
            '& img': {
                width: '18px',
            },
        },
        boldFont: {
            fontFamily: 'VodafoneRg-Bold',
            fontSize: '18px',
        },
        titleDesc: {
            fontFamily: 'VodafoneRg-Regular',
            fontSize: '24px',
        },
        uploadedPicsAndVids: {
            display:'grid',
            gridGap:'24px',
            gridTemplateColumns:'1fr',
            ['@media (min-width:990px)']:{
                display:'grid',
                gridGap:'24px',
                gridTemplateColumns:'1fr 1fr'
            }
            // display: 'flex',
            // flexDirection: 'row',
            // justifyContent: 'space-around',
            // flexWrap: 'wrap',
        },
        ideaCarousel: {
            flex: 1,
            minWidth: "320px",
            // padding: "10px 25px 10px 25px",
            "& img" : {
                height : "auto",
                width: "-webkit-fill-available",
                minWidth: "300px",
            }
        },
        mainHolder: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
        },
        ideaPic: {
            width: '680px',
            height: '290px',
            objectFit: 'cover',
            objectPosition: 'center',
            borderRadius: '20px'
        },
        ideaSummaryCard: {

        },
        answers: {
            textAlign: 'left',
            '& p:first-of-type': {
                fontSize: '24px',
            },

        },
        bulletPoint: {
            height: '6px',
            marginBottom: '4px',
        },
        challengeCard: {
            height: '250px',
        },
        rows: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: theme.spacing(4)
        },
        removeMargin: {
            margin: '0',
        },
        emptyTeam: {
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
        },
        joinButton: {
            fontFamily: 'VodafoneRg-Regular',
            fontSize: '12px',
            color: '#FFFFFF',
            background: '#333333',
            width: '120px',
            borderRadius: '20px',
            '&:hover': {
                background: '#333333b5',
            },
            // position: 'absolute',
            // right: '0px',
            // top: '-59px',
        },
        joinTeamDiv: {
            position: 'relative',
            display: 'grid',
            rowGap: '30px',
            gridTemplateColumns: "repeat(5, 1fr)",
            '@media (max-width:1250px)': {
                gridTemplateColumns: "repeat(4, 1fr)",
            },
            '@media (max-width:1050px)': {
                gridTemplateColumns: "repeat(3, 1fr)",
            },
            '@media (max-width:720px)': {
                gridTemplateColumns: "repeat(2, 1fr)",
            },
        },
        teamMembers: {
            display: 'flex',
            margin: '10px',

        },
        strategicBullets: {
            listStyle: 'none',

        },
        strategicBullet: {
            fontSize: '16px',
            color: '#363636',
            margin: '16px 0px',
            '&:before': {
                content: '"\\2022"',  /* Add content: \2022 is the CSS Code/unicode for a bullet */
                color: '#7834FF', /* Change the color */
                fontWeight: 'bold', /* If you want it to be bold */
                display: 'inline-block', /* Needed to add space between the bullet and the text */
                width: '1em', /* Also needed for space (tweak if needed) */
                marginLeft: '-1em'
            },
        },
        documentLabel: {
            fontSize: '16px',
        },
        documentUpload: {
            margin: '0px',
            '& img': {
                marginLeft: '30px',
            },
        },
        paragraph: {
            marginTop: '16px',
            fontSize: '16px'
        },
        idea_preview: {
            zIndex: 10,
            marginTop: '150px',
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            height: 'auto',
        },
        faded_out: {
            position: 'fixed',
            left:0,
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
            zIndex: 9,
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
        },
        investortable: {
            width: '100%',
            borderCollapse: 'collapse',
        },
        images: {
            objectFit: "cover",
            objectPosition: "center top",
            width: '604px',
            height: '290px',
            borderRadius: '10px',
            '@media (max-width:480px)':{
                width:'100%',
                height:'170px'
            },
            '@media (min-width:481px) and (max-Width:767px)':{
                width:'100%',
                height:'230px'
            },
            '@media (min-width:768px) and (max-Width:1024px)':{
                width:'100%'
            },
            '@media (min-width:1025px) and (max-Width:1199px)':{
                width:'100%'
            }
        },
        imgsize:{
            maxWidth:'100%',
            '@media (min-width:900px)':{
                maxWidth:'604px'
            },
        }
    })

export default styles
