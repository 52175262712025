import { formatLocaleDistanceToNow } from '../../support/date_fns_locale'

export function transformDocumentUploadResponse(documentFile) {
  // console.log(`documents::abc:: ${JSON.stringify(documentFile)}`)

  // return {
  //   name: `${documentFile.name}`,
  //   size: `${(documentFile.size || 0) / 1024}`,
  //   src: `${documentFile.url}`,
  //   key: `${documentFile.deleteUrl || documentFile.key}`,
  //   timeAgo: formatLocaleDistanceToNow(
  //     new Date(
  //       documentFile.created ? documentFile.created.timestamp || documentFile.created : new Date(),
  //     ),
  //   ),
  //   deleteUrl: documentFile.deleteUrl,
  // }

  return documentFile.map(doc => {
    return {
      name: `${doc.name}`,
      size: `${(doc.size || 0) / 1024}`,
      src: `${doc.url}`,
      key: `${doc.deleteUrl || doc.key}`,
      timeAgo: formatLocaleDistanceToNow(
        new Date(doc.created ? doc.created.timestamp || doc.created : new Date()),
      ),
      deleteUrl: doc.deleteUrl,
    }
  })
  // console.log(`documents::after:: ${JSON.stringify(documentFile)}`)
}
