import React, { Fragment, useState } from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import { TextField } from '@material-ui/core'
import * as PropTypes from 'prop-types'
import warning_ico from 'img/warning.png'
import styles from './View.Style'


const TextInputMultiLine = props => {
    const {
        classes,
        width,
        placeholder,
        char_limit,
        value,
        valid,
        handleChange,
        row_no,
    } = props

    return(
        <div style={{position: 'relative', width: `${width}px`}}>
        <TextField 
            id="outlined-multiline-static" 
            label={placeholder}
            multiline
            className={!valid ? classes.invalid : null}
            variant="outlined" 
            style={{ width: `${width}px` }}
            inputProps={{ maxLength: char_limit }}
            value={value}
            rows={row_no}
            onChange={handleChange}
            />
        {!valid && <img src={warning_ico} className={classes.warning}/>}
        </div>
    )
}

TextInputMultiLine.defaultProps = {
    width: 350,
    placeholder: '',
    char_limit: 1000,
    valid: true,
    rows: 4,
}

TextInputMultiLine.propTypes = {
    width: PropTypes.number,
    placeholder: PropTypes.string,
    char_limit: PropTypes.number,
    value: PropTypes.string.isRequired,
    valid: PropTypes.bool,
    handleChange: PropTypes.func.isRequired,
    rows: PropTypes.number,
}


export default withStyles(styles)(TextInputMultiLine)