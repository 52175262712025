import React, { Component } from 'react'
import Container from '@material-ui/core/Container'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Feel from '../../assets/feel.png'
import Box from '@material-ui/core/Box'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
//import FormLabel from '@material-ui/core/FormLabel'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
// import { withStyles, makeStyles } from '@material-ui/core/styles'
// import style from './View.Style.js'

class EvaluationFeel extends Component {
  constructor(props) {
    super(props)
    this.state = {
      radioVal: '',
    }
    this.sendEvaluationFeel = this.sendEvaluationFeel.bind(this)
    this.handleChange = this.handleChange.bind(this)
  }

  sendEvaluationFeel() {
    const { postEvaluationIdeaStatus, title } = this.props
    const { radioVal } = this.state
    if (radioVal) postEvaluationIdeaStatus(title, radioVal)
  }

  componentDidMount() {
    const { getEvaluationDraft, title } = this.props
    if (title) getEvaluationDraft(title)
  }

  componentDidUpdate(prevProps) {
    const { evaluationIdea } = this.props
    const { type } = evaluationIdea
    if (prevProps.evaluationIdea !== this.props.evaluationIdea) {
      this.setState({ radioVal: type })
    }
  }

  handleChange = event => {
    this.setState({ radioVal: event.target.value })
  }

  render() {
    const { goBack, evaluationLoading } = this.props
    const { radioVal } = this.state

    return (
      <React.Fragment>
        <Box item xs={12} width="100%" maxHeight="420px">
          <Container
            style={{ height: '100%', width: '100%', backgroundColor: 'white', padding: '30px' }}
          >
            <Box display="flex" justifyContent="center">
              {/*Disabled overrieds normal color="secondary" syntax, so will need ID for higher priority CSS*/}
              <Box px={1}>
                <Button variant="contained" px={3} color="primary"></Button>
              </Box>
              <Box px={1}>
                <Button variant="contained" px={3} color="primary"></Button>
              </Box>
              <Box px={1}>
                <Button variant="contained" px={3} color="primary"></Button>
              </Box>
              <Box px={1}>
                <Button variant="contained" px={3} color="primary"></Button>
              </Box>
            </Box>
            <Box py={3} display="flex" justifyContent="center">
              {/*Variant affects actual size, component keeps HTML nerds happy by having a logical progression of H1 to H6 etc*/}
              <Typography variant="h5" component="h1">
                4. How do you feel about this idea?
              </Typography>
            </Box>
            <Box py={3} display="flex" justifyContent="center">
              <img src={Feel} alt="logo" height="10%" width="10%" />
            </Box>
            <Box pt={3} display="flex" justifyContent="center">
              <FormControl component="fieldset">
                {/* <FormLabel component="legend">Gender</FormLabel> */}
                <RadioGroup
                  row
                  aria-label="option"
                  name="option"
                  value={radioVal}
                  onChange={this.handleChange}
                >
                  <FormControlLabel value="APPROVED" control={<Radio />} label="Approve" />
                  <FormControlLabel value="DROP" control={<Radio />} label="Drop" />
                  <FormControlLabel value="BACKLOG" control={<Radio />} label="Backlog" />
                  <FormControlLabel value="DIVERTED" control={<Radio />} label="Divert" />
                </RadioGroup>
              </FormControl>
            </Box>
            {/* <Box pt={3} display="flex" justifyContent="center">
              <Box mx={1}>
                <Button variant="contained" px={3} id="redBackground" height="100%">
                  Approve
                </Button>
              </Box>
              <Box mx={1}>
                <Button variant="contained" px={3} id="redBackground" height="100%">
                  Drop
                </Button>
              </Box>
            </Box>
            <Box pt={1} display="flex" justifyContent="center">
              <Box mx={1}>
                <Button variant="contained" px={3} id="redBackground" height="100%">
                  Backlog
                </Button>
              </Box>
              <Box mx={1}>
                <Button variant="contained" px={3} id="redBackground" height="100%">
                  Divert
                </Button>
              </Box>
            </Box> */}
            {/* <Box pt={1} display="flex" justifyContent="center">
              <Box px={1}>
                <Button variant="contained" px={3} id="redBackground" height="100%">
                  Save
                </Button>
              </Box>
              <Box px={1}>
                <Button variant="contained" px={3} id="redBackground" height="100%">
                  Submit
                </Button>
              </Box>
            </Box> */}
            <Box pt={1} display="flex" justifyContent="center">
              <Box px={1}>
                <Button
                  disabled={evaluationLoading}
                  variant="contained"
                  px={3}
                  id="redBackground"
                  color="primary"
                  height="100%"
                  onClick={goBack}
                >
                  Back
                </Button>
              </Box>
              <Box px={1}>
                <Button
                  disabled={radioVal ? false : true}
                  variant="contained"
                  px={3}
                  id="redBackground"
                  color="primary"
                  height="100%"
                  onClick={this.sendEvaluationFeel}
                >
                  Submit
                </Button>
              </Box>
              {/*<Box px={1}>
                <Button variant="contained" px={3} id="redBackground" height="100%">
                  Submit
                </Button>
              </Box> */}
            </Box>
          </Container>
        </Box>
      </React.Fragment>
    )
  }
}
export default EvaluationFeel
