const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  container: {
    display: 'flex',
    flexFlow: 'row wrap',
    '& > div': {
      height: theme.spacing(67),
      width: theme.spacing(38.75),
      margin: 4,
    },
  },
  divFirst: {
    display: 'flex',
    alignItems: 'center',
  },
  whiteBackground: {
    backgroundColor: 'white',
  },
})

export default styles
