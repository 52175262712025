import React, { Component } from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import EvaluationInnovation from './components/a_evaluation_innovation/View'
import EvaluationValue from './components/b_evaluation_value/View'
import EvaluationMature from './components/c_evaluation_mature/View'
import EvaluationFeel from './components/d_evaluation_feel/View'
import EvaluationThankyou from './components/e_evaluation_thankyou/View'
import EvaluatorIdeaDescription from '../idea_description/View'
import EvaluationPending from '../pending/View'
import ShareIdea from '../share_idea'
import Ecomments from '../fab_action/components/comments/View'
import styles from './View.Style'

class EvaluationDashboard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      imageUrl: [],
      videoUrl: [],
      openShareIdeaDialog: false,
      openViewExpertComments: false,
    }
    this.handleShareIdea = this.handleShareIdea.bind(this)
    this.handleViewExpertComments = this.handleViewExpertComments.bind(this)
  }

  componentDidUpdate(prevProps) {
    const { videoSrc, images } = this.props
    if (prevProps.images !== images) {
      this.setState({ imageUrl: images, videoUrl: videoSrc })
    }
  }

  handleShareIdea() {
    const { openShareIdeaDialog } = this.state
    this.setState({ openShareIdeaDialog: !openShareIdeaDialog })
  }

  handleViewExpertComments() {
    const { openViewExpertComments } = this.state
    this.setState({ openViewExpertComments: !openViewExpertComments })
  }

  render() {
    const {
      id,
      images,
      title,
      description,
      getEvaluationIdea,
      postEvaluationIdea,
      getEvaluationDraft,
      postEvaluationIdeaStatus,
      evaluationIdea,
      commentsList,
      commentListGraph,
      activeStep,
      goNext,
      goBack,
      evaluationLoading,
      isPendingClicked,
      isDashboardClicked,

      getExpertCommentsMail,
      expertCommentMail,
    } = this.props
    const { videoUrl, openShareIdeaDialog, openViewExpertComments } = this.state

    return (
      <div style={{ flexGrow: 1 }}>
        {/* <Grid container direction="column" justify="center" alignItems="center"> */}
        {openShareIdeaDialog && <ShareIdea ideaId={id} handleShareIdea={this.handleShareIdea} />}
        {openViewExpertComments && (
          <Ecomments
            expertCommentMail={expertCommentMail}
            handleDialog={this.handleViewExpertComments}
          />
        )}

        <EvaluatorIdeaDescription
          images={images}
          videoUrl={videoUrl}
          title={title}
          description={description}
          showFab={true}
          id={id}
          handleShareIdea={this.handleShareIdea}
          handleViewExpertComments={this.handleViewExpertComments}
          getExpertCommentsMail={getExpertCommentsMail}
        />

        {/* Right grid */}
        <React.Fragment>
          {activeStep === 0 && (
            <EvaluationInnovation
              title={title}
              commentsList={commentsList}
              postEvaluationIdea={postEvaluationIdea}
              getEvaluationDraft={getEvaluationDraft}
              evaluationIdea={evaluationIdea}
              evaluationLoading={evaluationLoading}
              goNext={goNext}
              goBack={goBack}
            />
          )}
          {activeStep === 1 && (
            <EvaluationValue
              title={title}
              commentsList={commentsList}
              postEvaluationIdea={postEvaluationIdea}
              getEvaluationDraft={getEvaluationDraft}
              evaluationIdea={evaluationIdea}
              evaluationLoading={evaluationLoading}
              goBack={goBack}
              goNext={goNext}
            />
          )}
          {activeStep === 2 && (
            <EvaluationMature
              title={title}
              commentsList={commentsList}
              postEvaluationIdea={postEvaluationIdea}
              getEvaluationDraft={getEvaluationDraft}
              evaluationIdea={evaluationIdea}
              evaluationLoading={evaluationLoading}
              goBack={goBack}
              goNext={goNext}
            />
          )}
          {activeStep === 3 && (
            <EvaluationFeel
              title={title}
              commentsList={commentsList}
              postEvaluationIdea={postEvaluationIdea}
              getEvaluationDraft={getEvaluationDraft}
              evaluationIdea={evaluationIdea}
              postEvaluationIdeaStatus={postEvaluationIdeaStatus}
              evaluationLoading={evaluationLoading}
              goBack={goBack}
              goNext={goNext}
            />
          )}
          {isDashboardClicked && isPendingClicked && (
            <EvaluationPending
              title={title}
              getEvaluationDraft={getEvaluationDraft}
              commentListGraph={commentListGraph}
            />
          )}
          {activeStep === 4 && <EvaluationThankyou goBack={goNext} goHome={getEvaluationIdea} />}
        </React.Fragment>
        {/* </Grid> */}
      </div>
    )
  }
}

export default withStyles(styles)(EvaluationDashboard)
