import { red } from "@material-ui/core/colors"
import { withThemeCreator } from "@material-ui/styles"

const padding_in_box = "8px"

const styles = theme =>({
  draftstatus:{
    padding:'6px 18px',
    borderTopLeftRadius:'10px',
    borderTopRightRadius:'10px',
    backgroundColor:'#E60000',
    display:'inline-block',
    fontSize:theme.fontSize.fontsize16,
    fontFamily:theme.fontFamily.fontFamilyRegular,
    color:'#fff',
    '@media (max-width:767px)':{
      fontSize:'12px',
      padding:'4px 14px'
    }
  },
    container: {
      fontSize: "0",
      position: "relative",
      '&:hover':{
        cursor: "pointer",
      },
      maxWidth: "400px",
      margin:'0px 8px',
      backgroundColor: '#FFFFFF',
      // ['@media (min-width:480px) and (max-width:767px)']:{
      //   maxWidth:'100%',
      // }
    },
    corner_img: {
      position: "absolute",
      display: "block",
      width: "24px",
      zIndex: 2,
      right: 0,
    },
    box:{
      height: "215px",
      // width: "380px",
      borderRadius: "16px", 
      // borderTopLeftRadius:'0px',
      margin: "0",
      border: "1px solid #CCCCCC",
      display: "grid",
      gridTemplateColumns: "1fr 1fr",
      overflow:'hidden',
      '@media (max-width:767px)':{
        height:'155px'
      }
    },
    // left_col: {
    //   gridRowStart: 1,
    //   gridRowEnd: 6,
    // },
    expiry_box: {
      backgroundColor: "#666796",
      border: "1px solid #FFFFFF",
      borderRadius: "3px",
      opacity: 0.95,
      height: "50px",
      width: "142px",
      position: "relative",
      top: -66,
      left: 16,
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      "&.expired": {
        backgroundColor: "#FFFFFF",
        border: "1px solid black",
      },
      '@media (max-width:767px)':{
        height:'34px',
        width: '80%',
        top: 'inherit',
        bottom: '46px',
      }
    },
    activeRadioChallenge:{
      borderColor: "#007c92",
      boxShadow: '0px 0px 0px 1px #007c92',
      borderWidth:'2px'
    },
    challenge_img: {
      maxWidth: "100%",
      width: "100%",
      maxHeight: "100%",
      height: "100%",
      objectFit: "cover",
      objectPosition: "center top",
      // borderRadius: "14px 0 0 14px",
      // borderTopLeftRadius:'0px'
    },
    right_col:{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    challenge_name: {
      textTransform:'capitalize',
      fontSize: "18px",
      fontFamily: "VodafoneRg-Bold",
      fontWeight:'normal',
      margin: "0",
      color: "#333333",
      paddingLeft: padding_in_box,
      paddingRight: padding_in_box,
      marginTop: "10px",
      lineHeight: "1.3",
      
      wordBreak: 'break-word',
      display: '-webkit-box',
  '-webkit-line-clamp': '2',
  '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    textOverflow:'ellipsis',

      '@media (max-width:767px)':{
        fontSize:'14px',
        // lineHeight:'16px',
        minHeight:'auto'
      }
    },
    challenge_author: {
      fontSize: "13px",
      fontFamily: theme.fontFamily.fontFamilyRegular,
      color: "#999999",
      margin: "0",
      display: "flex",
      alignItems: "center",
      paddingLeft: padding_in_box,
      paddingRight: padding_in_box,
      marginTop: "4px",
      height: "24px",
      wordBreak: 'break-word',
      '@media (max-width:767px)':{
        fontSize:'12px',
        // display:'block',
        height:'auto',
        '& >span':{
          display:'block'
        }
      }
    },
    author_name: {
      fontFamily: "VodafoneRg-Bold",
      color: "#151515",
      flex:1,
      whiteSpace: 'nowrap',
      width: '100%',
      overflow: "hidden",
      textOverflow: 'ellipsis'
    },
    challenge_desc: {
      marginTop: "10px",
      fontSize: "16px",
      lineHeight:'1.4',
      fontFamily: theme.fontFamily.fontFamilyRegular,      //height: "25%",
      paddingLeft: padding_in_box,
      paddingRight: padding_in_box,
      // display: "flex",
      // alignItems: "center",
      color: "#333333",
      // minHeight: "66px",
      paddingTop: 0,
      paddingBottom: 0,

      wordBreak: 'break-word',
      display: '-webkit-box',
  '-webkit-line-clamp': '3',
  '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    textOverflow:'ellipsis',
      '@media (max-width:767px)':{
        fontSize:'12px',
        minHeight:'auto'
      }
    },
    details: {
      fontSize: "16px",
      fontFamily: theme.fontFamily.fontFamilyRegular,
      margin: "8px 0",
      display: "flex",
      alignItems: "center",
      paddingLeft: padding_in_box,
      paddingRight: padding_in_box,
      marginTop: "6px",
      // height: "24px",
      color: "#6551FF",
      '@media (max-width:767px)':{
        fontSize:'13px'
      }
    },
    star_image: {
      width: "20px",
      paddingRight: "8px",
      '@media (max-width:767x)':{
        width:'12px',
      }
    },
    expiry_text: {
      fontFamily:theme.fontFamily.fontFamilyRegular,
      color: "#FFFFFF",
      fontSize: "12px",
      padding: 0,
      paddingTop: "2px",
      paddingBottom: "2px",
      margin: 0,
      marginLeft: "6px",
      "&.expired": {
        color: "black",
      },
      '@media (max-width:767px)':{
        fontSize:'10px'
      }
    },
    bold_expiry: {
      fontSize: "13px",
      fontFamily: theme.fontFamily.fontFamilyBold,
      "&.expired": {
        color: "#6551FF",
      },
      '@media (max-width:767px)':{
        fontSize:'11px'
      }
    },
    rewardwrp:{
      display:'flex',
      alignItems:'center',
      paddingLeft:padding_in_box,
      paddingRight:padding_in_box,    
      '@media (max-width:767px)':{
          margin:'8px 0px',
      }
    },
    rewardstar:{
      width:'20px',
      marginRight:'0.5rem',
      '@media (max-width:767px)':{
        width:'12px'
      }
    },
    rewardsText:{
      fontSize:theme.fontSize.fontsize16,
      fontFamily:theme.fontFamily.fontFamilyBold,
      width:'150px',
      display:'block',
      overflow:'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      '@media (max-width:767px)':{
        fontSize:'12px'
      }
    }
  })
  

export default styles
