const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(1),
        textAlign: 'left',
        color: theme.palette.text.secondary,
    },

    background:{
        backgroundColor: "#fbfaff", //body should have this background color in the main .css for the new site design 
    },
    content_container:{
        // paddingLeft: "6%",
        // paddingRight: "6%"
    },
    inputContainer:{
        position:'relative',
        marginTop:theme.spacing(1),
        marginBottom:theme.spacing(2),
    },
    warning: {
        position: 'absolute',
        top: 8,
        right: 16,
        height: '24px',
        width: '24px',
    },
    invalid: {
        border:`1px solid ${theme.palette.Text.TextError}`,
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
          borderColor:theme.palette.Text.TextError
        },
        "& .MuiInputLabel-outlined": {
            color:theme.palette.Text.TextError,
          },
    },
    upper_background: {
        display: "flex",
        alignItems: "center", 
        justifyContent: "space-between", 
    },
    title: {
        fontFamily:theme.fontFamily.fontFamilyRegular,
        fontSize: "32px",
        color:theme.palette.Text.TextBlack333,
    },
    invested_ideas_number: {
        fontFamily:theme.fontFamily.fontFamilyRegular,
        fontSize: "20px",
        paddingTop: "16px",
        color: "#7836FF",
        cursor:'pointer'
    },
    lower_background: {
        display: "flex",
        justifyContent: "center", 
    },

    create_idea_box: {
        backgroundColor: "#FFFFFF",
        width: "780px",
        boxShadow: "4px 4px 20px 0 #EFEFEF",
        marginBottom: "48px",
        borderRadius: "6px",
        paddingLeft: "100px",
        paddingRight: "100px",
        ['@media(max-width:767px)']:{
            width:'100%',
            padding:0,
        }
    },
    create_title: {
        textAlign: "center",
        fontFamily:theme.fontFamily.fontFamilyRegular,
        color: theme.palette.Text.TextBlack333,
        fontSize: "40px",
        lineHeight: "40px",
        paddingTop: "48px",
    },
    create_subtitle: {
        fontFamily:theme.fontFamily.fontFamilyRegular,
        color:theme.palette.Text.TextBlack333,
        fontSize: '18px',
        marginTop: '16px',
        textAlign: 'center',
    },
    idea_title_in: {
        fontFamily:theme.fontFamily.fontFamilyRegular,
        fontSize: "16px",
        color: theme.palette.Text.TextBlack333,

    },
    idea_title_char_limit: {
        fontFamily:theme.fontFamily.fontFamilyRegular,
        fontSize: "14px",
        color: "#2A2A2A",
        float: "right",
        paddingTop: "4px",
        paddingRight: "15px",
    },
    idea_title_chars: {
        fontFamily:theme.fontFamily.fontFamilyBold,
        color: "#6551FF",
        fontSize: "16px",
    },
    secondary_header: {
        fontFamily:theme.fontFamily.fontFamilyRegular,
        color: theme.palette.Text.TextBlack333,
        fontSize: "32px",
        lineHeight: "40px",
        marginTop: "32px",
    },
    smaller_subtitle:{
        // fontFamily: "VodafoneRg-Regular",
        fontFamily:theme.fontFamily.fontFamilyRegular,
        color: theme.palette.Text.TextBlack333,
        fontSize: "18px",
        lineHeight: "24px",
        margin: "15px 0 15px 0",
    },
    vertical_line: {
        height: '233px',
        width: '0.8px',
        backgroundColor:theme.palette.borderColor.main,
        alignSelf: 'center',
        justifySelf: 'center',
        marginLeft: '110px',
        marginRight: '40px',
    },
    horizontal_line: {
        marginTop:theme.spacing(1),
        marginBottom:theme.spacing(1),
        height: '1px',
        width: '780px',
        backgroundColor: theme.palette.borderColor.main,
        alignSelf: 'center',
        justifySelf: 'center'
    },
    description_grid: {
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        columnGap: "20px",
        rowGap: "8px",
        marginBottom: '32px',
    },
    category_select: {
        width: '380px', 
        height: '48px',
        border: '1px solid #bfbfbf',
        borderRadius: '6px',
        '& .MuiInputBase-input': {
            padding: '7px 10px 6px 12px'
        },
        '& .MuiSelect-select:focus':{
            backgroundColor: '#FFFFFF'
        }
    },
    checkbox_formgroup: {
        color: theme.palette.Text.TextBlack333,
        width: '100%',
        '& .MuiTypography-body1': {
            fontFamily:theme.fontFamily.fontFamilyRegular,
            fontSize: '18px',
            lineHeight: '24px',
            height: '24px',
        },
    },
    view_all_challenges: {
        textDecoration: 'underline',
        cursor: 'pointer',
        fontFamily:theme.fontFamily.fontFamilyRegular,
        fontSize: "18px",
        paddingTop: "16px",
        color: "#7836FF",
    },
    idea_preview: {
        position: 'absolute',
        zIndex: 10,
        paddingTop: '150px',
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        minHeight: '100%',
        height: 'auto',
        top: 0
    },
    faded_out: {
        position: 'fixed',
        backgroundColor: '#000000',
        zIndex: 9,
        width: '100%',
        height: '100%',
        top: 0,
        left:0,
        opacity: 0.7
    },
    error_message:{
        fontSize: '12px',
        color: theme.palette.Text.TextError,
    },
    astrickColor:{
        color: theme.palette.Text.TextError,
    }
  })
  

export default styles
