import { withStyles, styled } from '@material-ui/core/styles'
import Carousel from 'react-material-ui-carousel'
import styles from './View.Style'
import React, { Fragment, useEffect, useState } from 'react'
import IdeaPanel from 'components_newui/idea_panel/View'
import * as PropTypes from 'prop-types'
import IdeaSlider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";



const Recommended_Ideas = props => {
    const {classes, recommendedIdeas, onMainButtonClick, bg_color} = props
    const [visibleIdeas, setVisibleIdeas] = useState(4)

    var sliderSettings = {
        dots: true,
        autoplay:true,
        autoplaySpeed:10000,
        infinite: recommendedIdeas.length > 4 ,
        speed: 800,
        slidesToShow: 4,
        slidesToScroll: 4,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
              }
            },
              {
                breakpoint: 480,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                }
              },
        ]
      };



    // const updateVisibleIdeas = () => {
    //     if (window.innerWidth < 800){
    //         setVisibleIdeas(1)
    //     } else if (window.innerWidth < 1050){
    //         setVisibleIdeas(2)
    //     } else if (window.innerWidth < 1200){
    //         setVisibleIdeas(3)
    //     } else{
    //         setVisibleIdeas(4)
    //     }
    // };

    // useEffect(() => {
    //     window.addEventListener('resize', updateVisibleIdeas);
    //     updateVisibleIdeas()
    // })


    //Populate the carousel
    // let idea_panels = []
    // let carouselContent = []
    // for (const [index, idea] of recommendedIdeas.entries()){
    //     idea_panels.push(<IdeaPanel onMainButtonClick={onMainButtonClick} idea={idea}/>)
    //     if ((index+1) % visibleIdeas === 0 || index+1 === recommendedIdeas.length){
    //         carouselContent.push(<div className={classes.flex_and_center}>{idea_panels}</div>)
    //         idea_panels = []
    //     }
    // }


    return(
        <div className={classes.container} style={{backgroundColor: bg_color}}>
            <p className={classes.title}>Latest Ideas</p>
            <p className={classes.description}>See what others have proposed</p>
            <IdeaSlider {...sliderSettings}>
                {
                    recommendedIdeas.map((idea)=>{
                       return <IdeaPanel key={idea.id} urlId={idea.id} idea={idea}/>
                    })
                }
            </IdeaSlider>
        </div>
    )
}


Recommended_Ideas.defaultProps = {
    bg_color: '#FFFFFF'
}
  

export default withStyles(styles)(Recommended_Ideas)